import { useState } from "react";
import { Alert } from "@material-ui/lab";
import { Box, Container, Typography } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { prescriptionStatsTblColumns, prescriptionStatsTableOptions } from "./config.js";
import { KeyboardDatePicker } from "@material-ui/pickers";
import MediPlusPrescriptionStatisticsService from "../../../services/MediPlus/MediPlusPrescriptionStatisticsService";
import { formatMaterialUiDate, getCurrentDate } from "../../Utilities/Utilities";
import "./statistics.css";

function PrescriptionStatistics() {
    const [statistics, setStatistics] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const [state, setState] = useState({
        from: getCurrentDate(),
        to: getCurrentDate(),
    });

    const onSubmit = (e) => {
        e.preventDefault();

        if (Number.isNaN(Date.parse(state.from)) || Number.isNaN(Date.parse(state.to))) {
            setError({ data: { dates: "Οι ημερομηνίες δεν είναι έγκυρες" }, status: 410 });
            return;
        }
        setError({});
        setLoading(true);
        MediPlusPrescriptionStatisticsService.getAllPrescriptionStatisticsByIssueDateRange(state)
            .then(({ data }) => {
                setStatistics(data);
            })
            .catch((err) => {
                // console.log(err.response);
                setError(err.response);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Box mt={5}>
            <Container>
                {error.status !== undefined ? (
                    <Alert severity="error">{error.status === 410 ? error.data.dates : null}</Alert>
                ) : null}
                <form onSubmit={onSubmit} className="mb-5">
                    <Typography>Ημερομηνία Έκδοσης</Typography>
                    <KeyboardDatePicker
                        format="dd/MM/yyyy"
                        label="Από"
                        value={state.from}
                        onChange={(date) => {
                            setState({
                                ...state,
                                from: formatMaterialUiDate(date),
                            });
                        }}
                        disableToolbar
                        className="mr-3"
                    />
                    <KeyboardDatePicker
                        format="dd/MM/yyyy"
                        label="Έως"
                        value={state.to}
                        onChange={(date) => {
                            setState({
                                ...state,
                                to: formatMaterialUiDate(date),
                            });
                        }}
                        disableToolbar
                    />
                    <button type="submit" className="btn buttonPrimary" disabled={loading}>
                        Αναζήτηση
                    </button>
                </form>
            </Container>
            <MUIDataTable
                className="mb-5"
                title={"Στατιστικά"}
                data={statistics}
                columns={prescriptionStatsTblColumns}
                options={prescriptionStatsTableOptions}
            />
        </Box>
    );
}

export default PrescriptionStatistics;