import React, { useState, useContext, useEffect } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Alert from "@material-ui/lab/Alert";
import IdikaVisitService from "../../../../../../services/MediPlus/Idika/IdikaVisitService";
import IdikaDoctorService from "../../../../../../services/MediPlus/Idika/IdikaDoctorService";
import { CircularProgress } from "@material-ui/core";
import { PatientContext } from "../../../Esyntagografisi.js";
import IdikaMasterDataService from "../../../../../../services/MediPlus/Idika/IdikaMasterDataService";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

function CloseVisit({ visitDetails, setVisitDetails, setClosePopUp }) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [inQuota, setInQuota] = useState(false);
    const [isPayable, setIsPayable] = useState(false);
    const [payableMsg, setPayableMsg] = useState("");

    const [overrides, setOverrides] = useState([]);

    const patient = useContext(PatientContext);

    const getSocialInsuranceId = () => {
        let idx = patient.selectedActiveInsurance;
        if (
            patient !== null &&
            patient.activeInsurances !== null &&
            patient.activeInsurances.activeInsurance !== null &&
            idx < patient.activeInsurances.activeInsurance.length
        ) {
            return patient.activeInsurances.activeInsurance[idx].socialInsurance.id;
        }

        return 0;
    };

    const getQuotaStatus = () => {
        IdikaDoctorService.getQuotaVisits(sessionStorage.unitId, getSocialInsuranceId())
            .then((resp) => {
                if (resp !== null && resp.data !== null) {
                    setIsPayable(Boolean(resp.data.VisitQuota.payable) === true);

                    if (isPayable) {
                        setPayableMsg(
                            `(έχουν χρεωθεί ${resp.data.VisitQuota.currentVisits} απο ${resp.data.VisitQuota.maxVisits} επισκέψεις)`
                        );

                        IdikaVisitService.getVisit(visitDetails.id)
                            .then((resp2) => {
                                setLoading(false);

                                if (
                                    resp2.data !== null &&
                                    resp2.data.visitDetails !== null &&
                                    resp2.data.visitDetails.inQuota !== null
                                )
                                    setInQuota(parseInt(resp2.data.visitDetails.inQuota) === 1);
                            })
                            .catch((err) => {
                                throw err;
                            });
                    } else {
                        setLoading(false);
                    }
                } else {
                    setLoading(false);
                }
            })
            .catch((err) => {
                // console.log(err);
                setLoading(false);
            });
    };

    const onClose = () => {
        IdikaVisitService.closeVisit(visitDetails.id, inQuota)
            .then(({ data }) => {
                setVisitDetails({ ...visitDetails, ...data.visitDetails });
                setClosePopUp(false);
            })
            .catch((error) => {
                if (error.response.data.status === 6036) {
                    setOverrides(JSON.parse(error.response.data.errors.error));
                } else {
                    setError(error.response);
                }
            });
    };

    /* in quota = true */

    return loading ? (
        <div align="center">
            {getQuotaStatus()}
            <CircularProgress />
        </div>
    ) : (
        <div>
            {error && (
                <div>
                    <Alert severity="error">
                        {error.data.status === 1102
                            ? "Υπάρχουν Παραπεμπτικά σε κατάσταση προσωρινής αποθήκευσης"
                            : error.data.status === 518
                            ? "Έχετε φτάσει το μέγιστο όριο επισκέψεων γι' αυτόν τον μήνα!"
                            : error.data.status === 519
                            ? "Η επίσκεψη δεν μπορεί να καλυφθεί απο την κοινωνική ασφάλιση!"
                            : error.data.status === 1101
                            ? "Υπάρχουν Συνταγές σε κατάσταση προσωρινής αποθήκευσης"
                            : error.status === 400
                            ? error.data.errors.error
                            : "Κάτι πήγε στραβά!"}
                    </Alert>
                    <br />
                </div>
            )}
            <div>Είστε σίγουρος οτι θέλετε να κλείσετε την επίσκεψη;</div>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={inQuota}
                        name="inQuota"
                        onChange={() => {
                            setInQuota(!inQuota);
                        }}
                        color="primary"
                        disabled={!isPayable}
                    />
                }
                label={"Υπολογίζεται στο Οριο Επισκέψεων " + payableMsg}
            />
            {inQuota && overrides.length > 0 && (
                <Override
                    overrides={overrides}
                    inQuota={inQuota}
                    visitDetails={visitDetails}
                    setVisitDetails={setVisitDetails}
                    setClosePopUp={setClosePopUp}
                />
            )}
            {(!inQuota || overrides.length === 0) && (
                <div align="center">
                    <button className="btn buttonPrimary" onClick={() => onClose()}>
                        Ναι
                    </button>
                </div>
            )}
        </div>
    );
}

function Override({ overrides, inQuota, visitDetails, setVisitDetails, setClosePopUp }) {
    const mainColumns = ["Αποδοχή", "Εξαίρεση", "Περιγραφή υπέρβασης κανόνα", "Αιτιολόγηση"];

    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [state, setState] = useState([]);

    useEffect(() => {
        //get current override IDs
        const overrideIds = [];
        const overridesArray = [];
        for (let override of overrides) {
            overrideIds.push(override.overrideTypeId);
            overridesArray.push(override);
        }

        IdikaMasterDataService.getOverrideTypes()
            .then(({ data }) => {
                const overridesToShow = [];
                for (let item of data.Page.contents.item) {
                    //if overrides match current overrides keep all the information
                    if (overrideIds.includes(item.id)) {
                        for (let x of overridesArray) {
                            if (x.overrideTypeId === item.id) {
                                x = {
                                    ...x,
                                    exception: item.description,
                                    reason: "",
                                    isChecked: false,
                                };
                                overridesToShow.push(x);
                            }
                        }
                    }
                    setState(overridesToShow);
                }
            })
            .catch(() => {
                setIsError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [overrides]);

    const [filledComponentsError, setFilledComponentsError] = useState(false);

    const onSubmit = () => {
        if (state.find((s) => s.isChecked === false || s.reason.length === 0) !== undefined) {
            setFilledComponentsError(true);
            return;
        }
        setFilledComponentsError(false);

        let items = [...state];

        let request = {
            items: items,
        };

        IdikaVisitService.closeVisit(visitDetails.id, inQuota, request)
            .then(({ data }) => {
                setVisitDetails({ ...visitDetails, ...data.visitDetails });
                setClosePopUp(false);
            })
            .catch((error) => {
                setIsError(true);
            });
    };

    const handleStateAcceptChange = (override) => (e) => {
        let { checked } = e.target;

        //setState([...newState]);
        let tempState = [...state];
        for (let temp of tempState) {
            if (temp.overrideTypeId === override.overrideTypeId) {
                temp.isChecked = checked;
            }
        }
        setState(tempState);
    };

    const handleOnChange = (override) => (e) => {
        e.preventDefault();
        const { value } = e.target;
        let tempState = [...state];
        for (let temp of tempState) {
            if (temp.overrideTypeId === override.overrideTypeId) {
                temp.reason = value;
            }
        }
        setState(tempState);
    };

    return (
        <div>
            {isLoading ? (
                <CircularProgress />
            ) : isError ? (
                <Alert severity="error">Κάτι πήγε στραβά!</Alert>
            ) : (
                <div>
                    {filledComponentsError && (
                        <Alert className="mt-3" severity="error">
                            Παρακαλώ επιλέξτε όλα τα πλαίσια αποδοχής και αιτιολόγησης εξαιρέσεων,
                            προκειμένου να ολοκληρώσετε το κλείσιμο της επίσκεψης
                        </Alert>
                    )}
                    <TableContainer component={Paper} className="mt-3 mb-3">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {mainColumns.map((column, index) => {
                                        return (
                                            <TableCell key={index}>
                                                <b>{column}</b>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {state.map((override, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <Checkbox
                                                    name={index.toString()}
                                                    color="primary"
                                                    checked={override.isChecked}
                                                    onChange={handleStateAcceptChange(override)}
                                                />
                                            </TableCell>
                                            <TableCell>{override.exception}</TableCell>
                                            <TableCell>{override.violationDetails}</TableCell>
                                            <TableCell>
                                                <textarea
                                                    cols="50"
                                                    value={override.reason}
                                                    name="reason"
                                                    onChange={handleOnChange(override)}
                                                    className="formItem"
                                                />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div align="center">
                        <button className="btn buttonPrimary" onClick={onSubmit}>
                            Ναι
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default CloseVisit;
