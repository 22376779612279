import React, { useState } from "react";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { formatMaterialUiDateTime } from "../../../Utilities/Utilities";
import MediPlusCalendarEventService from "../../../../services/MediPlus/MediPlusCalendarEventService";
import { Alert } from "@material-ui/lab";

function DeleteOrUpdateEvent({ events, setEvents,setDeleteOrUpdateEventPopUp,selectedEvent }) {
    const [start, setStart] = useState(new Date(selectedEvent.start));
    const [end, setEnd] = useState(new Date(selectedEvent.end));
    const [title, setTitle] = useState(selectedEvent.title);

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const onUpdate = (e) => {
        e.preventDefault();
        let eventIndexFound = events.findIndex((event) => event.id === selectedEvent.id);
        if (eventIndexFound !== -1) {
            let newEvent = {
                start: formatMaterialUiDateTime(start),
                end: formatMaterialUiDateTime(end),
                title: title,
            };
            for (let event of events) {
                if (event.id !== selectedEvent.id) {
                    if (
                        (start > event.start && start < event.end) ||
                        (end > event.start && end < event.end) ||
                        (start < event.start && end > event.end)
                    ) {
                        setIsError(true);
                        setErrorMessage("Υπάρχει άλλο ραντεβού εκείνη τη μέρα και ώρα");
                        return;
                    }
                }
            }
            setIsError(false);
            setErrorMessage("");
            setIsLoading(true);
            MediPlusCalendarEventService.updateCalendarEvent(selectedEvent.id, newEvent)
                .then(({ data }) => {
                    let newEvents = events.filter((event) => event.id !== selectedEvent.id);
                    newEvents.push(data);
                    setEvents(newEvents);
                    setDeleteOrUpdateEventPopUp(false);
                })
                .catch((err) => {
                    setIsError(true);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const onDelete = (e) => {
        e.preventDefault();
        setIsError(false);
        setIsLoading(true);
        MediPlusCalendarEventService.deleteCalendarEvent(selectedEvent.id)
            .then(({ data }) => {
                let newEvents = events.filter((event) => event.id !== selectedEvent.id);
                setEvents(newEvents);
                setDeleteOrUpdateEventPopUp(false);
            })
            .catch((err) => {
                setIsError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <div>
            {isError && (
                <Alert severity="error">
                    {errorMessage !== "" ? errorMessage : "Κάτι πήγε στραβά!"}
                </Alert>
            )}
            <form onSubmit={onUpdate}>
                <div align="center">
                    <KeyboardDateTimePicker
                        className="mr-2"
                        variant="inline"
                        ampm={false}
                        label="Αρχή"
                        value={start}
                        onChange={setStart}
                        format="dd/MM/yyyy HH:mm"
                    />
                    <KeyboardDateTimePicker
                        variant="inline"
                        ampm={false}
                        label="Τέλος"
                        value={end}
                        onChange={setEnd}
                        format="dd/MM/yyyy HH:mm"
                    />
                    <textarea
                        rows="10"
                        cols="72"
                        placeholder="Όνομα και Σχόλια Ραντεβού"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                    />
                    <div>
                        <button type="submit" className="btn buttonPrimary" disabled={isLoading}>
                            Ενημέρωση Ραντεβού
                        </button>
                        <button
                            className="btn buttonDanger"
                            onClick={onDelete}
                            disabled={isLoading}
                        >
                            Διαγραφή Ραντεβού
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default DeleteOrUpdateEvent;
