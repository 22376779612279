import React, { useState } from "react";
import PopUp from "../../../../../Utilities/PopUp.js";
import PatientBiometrics from "./PatientBiometrics/PatientBiometrics.js";

function GeneralData({
    patientData,
    prescriptions,
    referrals,
    prescriptionsToBeRegistered,
    referralsToBeRegistered,
}) {
    const [openPopUp, setOpenPopUp] = useState(false);
    return (
        <div className="tabContent">
            <div className="flex">
                <p className="infoP">Σύνολο Συνταγών Επίσκεψης:</p>
                <span id="medPrice">{prescriptions.length}</span>
            </div>
            <div className="flex">
                <p className="infoP">Σύνολο Παραπεμπτικών Επίσκεψης : </p>
                <span id="medPricePerc">{referrals.length}</span>
            </div>
            <div className="flex">
                <p className="infoP">Σύνολο Συνταγών προς Καταχώρηση Επίσκεψης : </p>
                <span id="medPricePerc">{prescriptionsToBeRegistered.length}</span>
            </div>
            <div className="flex">
                <p className="infoP">Σύνολο Παραπεμπτικών προς Καταχώρηση Επίσκεψης : </p>
                <span id="medPricePerc">{referralsToBeRegistered.length}</span>
            </div>
            <br />
            <button className="btn buttonPrimary" onClick={() => setOpenPopUp(true)}>
                Βιομετρικά Στοιχεία Ασθενή
            </button>
            <PopUp
                openPopUp={openPopUp}
                setOpenPopUp={setOpenPopUp}
                title={"Βιομετρικά Στοιχεία Ασθενή"}
            >
                <PatientBiometrics patientData={patientData} />
            </PopUp>
        </div>
    );
}

export default GeneralData;
