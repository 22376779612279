import axios from "axios";
import {
    baseUrl,
    deleteOptions,
    getOptions,
    postOptions,
    putOptions,
    putOptionsWithToken,
} from "../config.js";

class MediPlusUserService {
    constructor(baseUrl) {
        this.basePath = "/users";
        this.baseUrl = `${baseUrl}${this.basePath}`;
    }

    getUsers() {
        return axios(getOptions(`${this.baseUrl}`));
    }

    getNoAdminUsers() {
        return axios(getOptions(`${this.baseUrl}`, "/noAdminUsers"));
    }

    getAdminUsersExceptSuperAdmin() {
        return axios(getOptions(`${this.baseUrl}`, "/adminUsersExceptSuperAdmin"));
    }

    getPhAgents() {
        return axios(getOptions(`${this.baseUrl}`, "/phAgents"));
    }

    getUserDetails() {
        return axios(getOptions(this.baseUrl, "/userDetails"));
    }

    updatePassword(data) {
        return axios(putOptions(this.baseUrl, "/updatePassword", data));
    }

    updateUser(data) {
        return axios(putOptions(this.baseUrl, "", data));
    }

    updateUserModifiableFieldsByAdmin(userId, data) {
        return axios(putOptions(this.baseUrl, `/${userId}`, data));
    }

    getUserByAuthToken() {
        return axios(getOptions(`${this.baseUrl}/profile`));
    }

    getPatients(keyword) {
        return axios(getOptions(this.baseUrl, "/patients", { keyword: keyword }));
    }

    updateUserDetails(data) {
        return axios(putOptions(this.baseUrl, "/userDetails", data));
    }

    updateUserDetailsAll(data) {
        return axios(putOptions(this.baseUrl, "/userDetailsAll", data));
    }

    updateUserDetailsAllWithToken(data, token) {
        return axios(putOptionsWithToken(this.baseUrl, "/userDetailsAll", data, token));
    }

    getPatient(parameters) {
        return axios(getOptions(this.baseUrl, "/patient", parameters));
    }

    newAdmin(user) {
        return axios(postOptions(this.baseUrl, "/newAdmin", user));
    }

    newPhAgent(phAgent) {
        return axios(postOptions(this.baseUrl, "/newPhAgent", phAgent));
    }

    getDistinctProducers() {
        return axios(getOptions(`${this.baseUrl}`, "/distinctProducers"));
    }

    setNewCompany(company) {
        return axios(getOptions(`${this.baseUrl}`, "/newCompany", company));
    }

    editCompany(company) {
        return axios(getOptions(`${this.baseUrl}`, "/editCompany", company));
    }

    deleteCompany(company) {
        return axios(getOptions(`${this.baseUrl}`, "/deleteCompany", company));
    }

    getCompanies() {
        return axios(getOptions(`${this.baseUrl}`, "/companies"));
    }

    assignUsers(request) {
        return axios(postOptions(this.baseUrl, "/assignUsers", request));
    }

    getAssignedUsers(adminId) {
        return axios(getOptions(`${this.baseUrl}`, "/myAssignedUsers", adminId));
    }

    getUserCompanyPreference(userId) {
        return axios(getOptions(this.baseUrl, "/company-preference", userId));
    }

    addUserCompanyPreference(data) {
        return axios(postOptions(this.baseUrl, "/addCompanyPreference", data));
    }

    deleteCompanyPreference(userId, companyName) {
        return axios(
            deleteOptions(this.baseUrl, `/deleteCompanyPreference/${userId}/${companyName}`)
        );
    }
}

export default new MediPlusUserService(baseUrl);
