import axios from "axios";
import { baseUrl, getOptions, postOptions } from "../config.js";

class MediPlusPrescriptionStatisticsService {
    constructor(baseUrl) {
        this.basePath = "/prescriptionstatistics";
        this.baseUrl = `${baseUrl}${this.basePath}`;
    }

    getAllPrescriptionStatisticsByUserId() {
        return axios(getOptions(this.baseUrl));
    }

    getAllPrescriptionStatisticsByIssueDateRange(request) {
        return axios(getOptions(this.baseUrl, "/range", request));
    }

    getMedicineTotals(request) {
        return axios(getOptions(this.baseUrl, "/medicineTotals", request));
    }

    addSubmittedPrintouts(data) {
        return axios(postOptions(this.baseUrl, "/addSubmittedPrintouts", data));
    }

}

export default new MediPlusPrescriptionStatisticsService(baseUrl);
