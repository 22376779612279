import axios from "axios";
import { baseUrl, getOptions, postOptions, putOptions, deleteOptions } from "../config.js";

class MediPlusFavoritePrescriptionPackageService {
    constructor(baseUrl) {
        this.basePath = "/favoritePrescriptionPackages";
        this.baseUrl = `${baseUrl}${this.basePath}`;
    }

    getFavoritePrescriptionPackages(parameters) {
        return axios(getOptions(this.baseUrl, "", parameters));
    }

    addFavoritePrescriptionPackage(favoritePrescriptionPackage) {
        return axios(postOptions(this.baseUrl, "", favoritePrescriptionPackage));
    }

    updateFavoritePrescriptionPackage(id, favoritePrescriptionPackage) {
        return axios(putOptions(this.baseUrl, `/${id}`, favoritePrescriptionPackage));
    }

    deleteFavoritePrescriptionPackage(id) {
        return axios(deleteOptions(this.baseUrl, `/${id}`));
    }
}

export default new MediPlusFavoritePrescriptionPackageService(baseUrl);
