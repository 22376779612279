import axios from "axios";
import { baseUrl, getOptions } from "../config.js";

class MediPlusCustomPrescriptionImagePositionService {
    constructor(baseUrl) {
        this.basePath = "/customprescriptions/imagepositions";
        this.baseUrl = `${baseUrl}${this.basePath}`;
    }

    getCustomPrescriptionImagePositions() {
        return axios(getOptions(this.baseUrl));
    }
}

export default new MediPlusCustomPrescriptionImagePositionService(baseUrl);
