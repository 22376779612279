const toolbar = [
    "heading",
    "|",
    "bold",
    "italic",
    "blockQuote",
    "numberedList",
    "bulletedList",
    "insertTable",
    "tableColumn",
    "tableRow",
    "mergeTableCells",
    "|",
    "undo",
    "redo",
];

const columns = [{ name: "Περιεχόμενο" }, { name: "Ημερομηνία" }, { name: "" }];

export { toolbar,columns };
