import React, { useState, useEffect } from "react";
import "./DoctorHome.css";
import MediPlusEPrescriptionHomePageService from "../../../../services/MediPlus/MediPlusEPrescriptionHomePageService.js";
import { Spinner } from "../../../Utilities/Utilities.js";
import Alert from "@material-ui/lab/Alert";

function DoctorHome({ links, setLinks }) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const organizedLinks = [
        {
            name: "Βοήθεια στη χρήση του συστήματος",
            links: [],
        },
        {
            name: "Όροι χρήσης και πληροφορίες",
            links: [],
        },
        {
            name: "Σύνδεσμοι",
            links: [],
        },
    ];

    useEffect(() => {
        if (links === null) {
            setLoading(true);
            MediPlusEPrescriptionHomePageService.getLinks()
                .then((response) => {
                    for (let link of response.data) {
                        if (organizedLinks[0].name === link.category.name)
                            organizedLinks[0].links.push(link);
                        else if (organizedLinks[1].name === link.category.name)
                            organizedLinks[1].links.push(link);
                        else if (organizedLinks[2].name === link.category.name)
                            organizedLinks[2].links.push(link);
                    }
                    setLinks(organizedLinks);
                    setLoading(false);
                })
                .catch((error) => {
                    setError(error);
                    setLoading(false);
                });
        }
        // eslint-disable-next-line
    }, []);

    if (error !== null) return <Alert severity="error"> Κάτι πήγε στραβά! </Alert>;
    else if (loading === true) return <Spinner align="center" animation="spinner-border" />;
    else if (links === null) return null;

    return (
        <div>
            {links.map((category, index) => (
                <div className="flexBoxWrap" key={index}>
                    <div className="flexBox">
                        <div className="fullBox">
                            <div className="helpBody">
                                <div>
                                    <div className="boxTitle">
                                        <h6 className="helpTitle">{category.name}</h6>
                                    </div>
                                    <div className="helpList">
                                        <ul>
                                            {category.links.map((link, index) => {
                                                return (
                                                    <li key={index}>
                                                        <a
                                                            rel="noopener noreferrer"
                                                            id="pt1:gl22"
                                                            href={link.link}
                                                            target="_blank"
                                                        >
                                                            {link.name}
                                                        </a>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default DoctorHome;
