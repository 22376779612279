const mainTabs = [
    {
        className: "tabBttns",
        label: "Ιστορικό Συνδρομών",
    },
    {
        className: "tabBttns",
        label: "Ιατρικό Σημείωμα",
    },
    {
        className: "tabBttns",
        label: "Ρυθμίσεις",
    },
    {
        className: "tabBttns",
        label: "Στοιχεία Επικοινωνίας",
    },
];

export { mainTabs };
