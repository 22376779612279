import { useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import MediPlusUserService from "../../../services/MediPlus/MediPlusUserService";
import MUIDataTable from "mui-datatables";
import PopUp from "../../Utilities/PopUp";
import { Switch } from "@material-ui/core";
import { Done, Error } from "@material-ui/icons";
import { formatDateAndTime, formatMaterialUiDate, trimString } from "../../Utilities/Utilities";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { materialUiDataTableOptions } from "../../../config";
import "../../SignUp/SignUp.css";
import Alert from "@material-ui/lab/Alert";
import AssignUsers from "./AssignUsers";
import UserCompanyPreference from "./UserCompanyPreference";

function User({ data, columns, users, setUsers }) {
    const dataObjectArray = Array.isArray(data.tableData[data.rowIndex])
        ? data.tableData[data.rowIndex].map((value, index) => {
              return {
                  name: columns[index].name,
                  label: columns[index].label,
                  value: value,
              };
          })
        : columns.map((column) => {
              return {
                  name: column.name,
                  label: column.label,
                  value: data.tableData[data.rowIndex][column.name],
              };
          });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [successAlert, setSuccessAlert] = useState(false);

    const onSave = () => {
        setSuccessAlert(false);
        setLoading(true);
        setError(false);

        let request = {
            status,
            membershipEndDate: formatMaterialUiDate(membershipEndDate),
            commentsA,
            commentsB,
        };

        MediPlusUserService.updateUserModifiableFieldsByAdmin(getValueByName("id"), request)
            .then(({ data }) => {
                let newUsers = users;
                let userIndex = users.findIndex((u) => u.id === data.id);
                newUsers[userIndex] = data;
                setUsers([...newUsers]);
                setSuccessAlert(true);
            })
            .catch((err) => {
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getValueByName = (name) => {
        for (let object of dataObjectArray) if (object.name === name) return object.value;
        return null;
    };

    const [status, setStatus] = useState(getValueByName("isEnabled"));
    const [membershipEndDate, setMembershipEndDate] = useState(
        getValueByName("membershipEndDate") !== null
            ? new Date(getValueByName("membershipEndDate"))
            : null
    );
    const [commentsA, setCommentsA] = useState(getValueByName("commentsA"));
    const [commentsB, setCommentsB] = useState(getValueByName("commentsB"));

    return (
        <div>
            {successAlert && (
                <Alert severity="success" className="mb-3">
                    Η Αποθήκευση έγινε με επιτυχία!
                </Alert>
            )}
            {error && (
                <Alert severity="error" className="mb-3">
                    Κάτι πήγε στραβά!
                </Alert>
            )}
            <div className="d-flex flex-column mb-3" align="center">
                {dataObjectArray
                    .filter(
                        (object) =>
                            object.name !== "id" &&
                            object.name !== "isEnabled" &&
                            object.name !== "membershipEndDate" &&
                            object.name !== "commentsA" &&
                            object.name !== "commentsB" &&
                            object.name !== "userDetails"
                    )
                    .map((object, index) => {
                        return (
                            <div key={index} className="d-flex justify-content-around">
                                <div className="col-6">
                                    <b>{object.label}</b>
                                </div>
                                <div className="col-6">
                                    {object.name !== "createdDate"
                                        ? object.value
                                        : formatDateAndTime(object.value)}
                                </div>
                            </div>
                        );
                    })}
                <div className="d-flex justify-content-around">
                    <div className="col-6">
                        <b>Status</b>
                    </div>
                    <div className="col-6">
                        <Switch checked={status} onChange={() => setStatus(!status)} />
                    </div>
                </div>
                <div className="d-flex justify-content-around">
                    <div className="col-6">
                        <b>Ημ/νία λήξης Συνδρομής</b>
                    </div>
                    <div className="col-6">
                        <KeyboardDatePicker
                            ampm={false}
                            value={membershipEndDate}
                            onChange={setMembershipEndDate}
                            format="dd/MM/yyyy"
                        />
                    </div>
                </div>
                <div className="d-flex justify-content-around">
                    <div className="col-6">
                        <b>Σχόλια 1</b>
                    </div>
                    <div className="col-6">
                        <textarea
                            rows="3"
                            cols="60"
                            value={commentsA}
                            onChange={(e) => setCommentsA(e.target.value)}
                        />
                    </div>
                </div>
                <div className="d-flex justify-content-around">
                    <div className="col-6">
                        <b>Σχόλια 2</b>
                    </div>
                    <div className="col-6">
                        <textarea
                            rows="3"
                            cols="60"
                            value={commentsB}
                            onChange={(e) => setCommentsB(e.target.value)}
                        />
                    </div>
                </div>
            </div>
            <div align="center">
                <button className="btn buttonPrimary" onClick={() => onSave()} disabled={loading}>
                    Αποθήκευση
                </button>
            </div>
        </div>
    );
}

function ButtonPopUp({
    data,
    rowIndex,
    columns,
    users,
    setUsers,
    doctors,
    agents,
    assign,
    type,
    companyPreference,
}) {
    const [openPopUp, setOpenPopUp] = useState(false);
    return (
        <div>
            <button className="btn buttonPrimary" onClick={() => setOpenPopUp(true)}>
                {!assign ? (!companyPreference ? "Προβολή" : "Προτιμήσεις") : "Ανάθεση"}
            </button>
            {!assign ? (
                !companyPreference ? (
                    <PopUp
                        openPopUp={openPopUp}
                        setOpenPopUp={setOpenPopUp}
                        title={"Στοιχεία χρήστη"}
                        maxWidth="md"
                    >
                        <User
                            data={data}
                            rowIndex={rowIndex}
                            columns={columns}
                            users={users}
                            setUsers={setUsers}
                        />
                    </PopUp>
                ) : (
                    <PopUp
                        openPopUp={openPopUp}
                        setOpenPopUp={setOpenPopUp}
                        title={"Προτίμηση Εταιρειών Χρήστη"}
                        maxWidth="md"
                    >
                        <UserCompanyPreference
                            data={data}
                            rowIndex={rowIndex}
                            columns={columns}
                            users={users}
                            setUsers={setUsers}
                            doctors={doctors}
                            agents={agents}
                            type={type}
                        />
                    </PopUp>
                )
            ) : (
                <PopUp openPopUp={openPopUp} setOpenPopUp={setOpenPopUp} maxWidth="md">
                    <AssignUsers
                        data={data}
                        rowIndex={rowIndex}
                        columns={columns}
                        users={users}
                        setUsers={setUsers}
                        doctors={doctors}
                        agents={agents}
                        type={type}
                    />
                </PopUp>
            )}
        </div>
    );
}

//here

function UserManagement({ type }) {
    const [users, setUsers] = useState([]);
    const [agents, setAgents] = useState([]);
    const [doctors, setDoctors] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        MediPlusUserService.getNoAdminUsers()
            .then(({ data }) => {
                setDoctors(data);
                
                if (type === "user") {
                    setUsers(data);
                }
            })
            .catch((err) => {
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
        MediPlusUserService.getAdminUsersExceptSuperAdmin()
            .then(({ data }) => {
                if (type === "admin") {
                    setUsers(data);
                }
            })
            .catch((err) => {
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });

        MediPlusUserService.getPhAgents()
            .then(({ data }) => {
                setAgents(data);
                if (type === "phAgent") {
                    setUsers(data);
                }
            })
            .catch((err) => {
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [type]);

    const columns = [
        // { name: "id", label: "ID", options: { filter: false, sort: false } },
        { name: "firstName", label: "Όνομα", options: { filter: false } },
        { name: "lastName", label: "Επώνυμο", options: { filter: false } },
        { name: "userName", label: "Username", options: { filter: false } },
        { name: "email", label: "Email", options: { filter: false } },
        { name: "address", label: "Διεύθυνση", options: { filter: false } },
        { name: "city", label: "Πόλη" },
        { name: "phoneNumber", label: "Τηλέφωνο", options: { filter: false } },
        { name: "specialty", label: "Ειδικότητα" },
        ...(type === "phAgent" ? [{ name: "level", label: "Επίπεδο" }] : []),
        ...(type === "phAgent" ? [{ name: "company", label: "Εταιρεία" }] : []),
        {
            name: "isEnabled",
            label: "Status",
            options: {
                customBodyRender: (value) => {
                    return <div>{value ? <Done color="primary" /> : <Error color="error" />}</div>;
                },
                customFilterListOptions: { render: (v) => `${v}` },
            },
        },
        {
            name: "createdDate",
            label: "Ημ/νία Εγγραφής",
            options: {
                filter: false,
                customBodyRender: (value) => {
                    return <div>{formatDateAndTime(value)}</div>;
                },
            },
        },
        // {
        //     name: "userDetails",
        //     label: "Ημ/νία Εγγραφής",
        //     options: {
        //         filter: false,
        //         customBodyRender: (value) => {
        //             return <div>{formatDateAndTime(value.createdDate)}</div>;
        //         },
        //         sortCompare: (order) => {
        //             return (obj1, obj2) => {
        //                 if (obj1.data.createdDate < obj2.data.createdDate)
        //                     return -1 * (order === "asc" ? 1 : -1);
        //                 if (obj1.data.createdDate > obj2.data.createdDate)
        //                     return 1 * (order === "asc" ? 1 : -1);
        //                 return 0;
        //             };
        //         },
        //     },
        // },
        {
            name: "membershipEndDate",
            label: "Ημ/νία λήξης Συνδρομής",
            options: {
                filter: false,
                customBodyRender: (value) => {
                    return <div>{formatDateAndTime(value)}</div>;
                },
            },
        },
        {
            name: "commentsA",
            label: "Σχόλια 1",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => {
                    return <div>{trimString(value, 10)}</div>;
                },
            },
        },
        {
            name: "commentsB",
            label: "Σχόλια 2",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => {
                    return <div>{trimString(value, 10)}</div>;
                },
            },
        },
        {
            name: "id",
            label: " ",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (rowIndex, data) => {
                    return (
                        <ButtonPopUp
                            data={data}
                            rowIndex={rowIndex}
                            columns={columns}
                            users={users}
                            setUsers={setUsers}
                            assign={false}
                            companyPreference={false}
                        />
                    );
                },
            },
        },
        ...(type === "admin"
            ? [
                  {
                      name: "id",
                      label: " ",
                      options: {
                          filter: false,
                          sort: false,
                          empty: true,
                          customBodyRender: (rowIndex, data) => {
                              return (
                                  <ButtonPopUp
                                      data={data}
                                      rowIndex={rowIndex}
                                      columns={columns}
                                      users={users}
                                      setUsers={setUsers}
                                      doctors={doctors}
                                      agents={agents}
                                      assign={true}
                                      type={type}
                                      companyPreference={false}
                                  />
                              );
                          },
                      },
                  },
              ]
            : []),
        ...(type === "user"
            ? [
                  {
                      name: "company-preference",
                      label: " ",
                      options: {
                          filter: false,
                          sort: false,
                          empty: true,
                          customBodyRender: (rowIndex, data) => {
                              return (
                                  <ButtonPopUp
                                      data={data}
                                      rowIndex={rowIndex}
                                      columns={columns}
                                      users={users}
                                      setUsers={setUsers}
                                      doctors={doctors}
                                      agents={agents}
                                      assign={false}
                                      type={type}
                                      companyPreference={true}
                                  />
                              );
                          },
                      },
                  },
              ]
            : []),
        ...(type !== "admin"
            ? [
                  {
                      name: "userDetails",
                      label: "Eνημερώσεις μέσω στοιχείων επικοινωνίας",
                      options: {
                          filter: false,
                          sort: false,
                          customBodyRender: (value) => {
                              return (
                                  <div align="center">
                                      {value.acceptReceivingUpdatesViaContactDetails ? (
                                          <Done color="primary" />
                                      ) : (
                                          <Error color="error" />
                                      )}
                                  </div>
                              );
                          },
                      },
                  },
              ]
            : []),
        ...(type !== "admin"
            ? [
                  {
                      name: "userDetails",
                      label: "Eνημερώσεις μέσω εφαρμογής",
                      options: {
                          filter: false,
                          sort: false,
                          customBodyRender: (value) => {
                              return (
                                  <div align="center">
                                      {value.acceptReceivingUpdatesViaApp ? (
                                          <Done color="primary" />
                                      ) : (
                                          <Error color="error" />
                                      )}
                                  </div>
                              );
                          },
                      },
                  },
              ]
            : []),
    ];

    return (
        <div>
            {loading ? (
                <div align="center">
                    <CircularProgress />
                </div>
            ) : error ? (
                <Alert severity="error">Κάτι πήγε στραβά!</Alert>
            ) : (
                <MUIDataTable
                    className="mb-5"
                    title={"Χρήστες"}
                    data={users}
                    assignData={users}
                    columns={columns}
                    options={materialUiDataTableOptions}
                />
            )}
        </div>
    );
}

export default UserManagement;
