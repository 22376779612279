import axios from "axios";
import { baseUrl, getOptions, getPdfOptions, putOptions, postOptions } from "../../config.js";

class IdikaReferralService {
    constructor(baseUrl) {
        this.basePath = "/idika/referrals";
        this.baseUrl = `${baseUrl}${this.basePath}`;
    }

    getReferrals(parameters) {
        return axios(getOptions(this.baseUrl, "", parameters));
    }

    getReferral(barcode, parameters) {
        return axios(getOptions(this.baseUrl, `/${barcode}`, parameters));
    }

    getReferralForEdit(barcode) {
        return axios(getOptions(this.baseUrl, `/${barcode}/edit`));
    }

    printReferral(barcode) {
        return axios(getPdfOptions(this.baseUrl, `/${barcode}/print`));
    }

    cancelReferral(barcode) {
        return axios(putOptions(this.baseUrl, `/${barcode}/cancel`));
    }

    createReferral(data) {
        return axios(postOptions(this.baseUrl, "", data));
    }

    sendNoPaperReferral(barcode) {
        return axios(postOptions(this.baseUrl,`/nopaper/${barcode}/send`));
    }

    createTempReferral(data) {
        return axios(postOptions(this.baseUrl, "/temp", data));
    }
}

export default new IdikaReferralService(baseUrl);
