import React, { useState } from "react";

import Alert from "@material-ui/lab/Alert";
import { Spinner } from "../../../../../../Utilities/Utilities";
import MediPlusVisitReasonService from "../../../../../../../services/MediPlus/MediPlusVisitReasonService";

function AddVisitReason({ visitReasons, setVisitReasons }) {
    const [visitReason, setVisitReason] = useState("");
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const [successAlert, setSuccessAlert] = useState(false);

    const onSubmit = (e) => {
        e.preventDefault();

        setError({});
        setLoading(true);
        setSuccessAlert(false);

        MediPlusVisitReasonService.addVisitReason(visitReason)
            .then(({ data }) => {
                let newVisitReasons = [...visitReasons, data];
                setVisitReasons(newVisitReasons);
                setSuccessAlert(true);
                setLoading(false);
            })
            .catch((error) => {
                setError(error.response.data);
                setLoading(false);
            });
    };

    return (
        <div>
            {Object.keys(error).length !== 0 && (
                <div>
                    <Alert severity="error">Κάτι πήγε στραβά!</Alert>
                    <br />
                </div>
            )}
            {successAlert === true && (
                <div>
                    <Alert severity="success">Η Προσθήκη έγινε με επιτυχία!</Alert>
                    <br />
                </div>
            )}
            <form onSubmit={onSubmit}>
                <div className="form-group">
                    <label>Λόγος Επίσκεψης *</label>
                    <input
                        type="text"
                        className="form-control"
                        name="visitReason"
                        value={visitReason}
                        onChange={(e) => setVisitReason(e.target.value)}
                        required
                    />
                </div>
                <div align="center">
                    {loading === true ? (
                        <Spinner align="center" animation="spinner-border" />
                    ) : (
                        <div>
                            <button className="btn buttonPrimary">Προσθήκη</button>
                        </div>
                    )}
                </div>
            </form>
        </div>
    );
}

export default AddVisitReason;
