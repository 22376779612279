import axios from "axios";
import { baseUrl, getOptions, postOptions, putOptions, deleteOptions } from "../config.js";

class MediPlusCommentService {
    constructor(baseUrl) {
        this.basePath = "/comments";
        this.baseUrl = `${baseUrl}${this.basePath}`;
    }

    getPatientComments(patientId, parameters) {
        return axios(getOptions(this.baseUrl, `/patients/${patientId}`, parameters));
    }

    addComment(patientId, comment) {
        return axios(postOptions(this.baseUrl, `/patients/${patientId}`, comment));
    }

    updateComment(commentId, patientId, comment) {
        return axios(putOptions(this.baseUrl, `/${commentId}/patients/${patientId}`, comment));
    }

    deleteComment(commentId, patientId) {
        return axios(deleteOptions(this.baseUrl, `/${commentId}/patients/${patientId}`));
    }
}

export default new MediPlusCommentService(baseUrl);
