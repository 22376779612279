import React, { useState, useEffect } from "react";

import { mainColumns } from "./config.js";
import MediPlusCommentService from "../../../../../../../../services/MediPlus/MediPlusCommentService.js";
import { formatDate, Spinner } from "../../../../../../../Utilities/Utilities.js";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import PopUp from "../../../../../../../Utilities/PopUp.js";
import AddComment from "./AddComment/AddComment.js";
import EditComment from "./EditComment/EditComment.js";
import DeleteComment from "./DeleteComment/DeleteComment.js";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Pagination from "@material-ui/lab/Pagination";

function Comments({ patientId }) {
    const [comments, setComments] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [addComment, setAddComment] = useState(false);

    const [currentComment, setCurrentComment] = useState({
        id: 0,
        commentContent: null,
    });

    const [deleteComment, setDeleteComment] = useState(false);
    const onDelete = ({ id, commentContent }) => {
        setCurrentComment({
            id: id,
            commentContent: commentContent,
        });
        setDeleteComment(true);
    };

    const [editComment, setEditComment] = useState(false);
    const onEdit = ({ id, commentContent }) => {
        setCurrentComment({
            id: id,
            commentContent: commentContent,
        });
        setEditComment(true);
    };

    const [page, setPage] = useState(1);
    const handlePageChange = (event, value) => {
        setPage(value);
        setFetch(true);
    };

    const [fetch, setFetch] = useState(true);
    useEffect(() => {
        if (fetch === true) {
            MediPlusCommentService.getPatientComments(patientId, {
                size: 5,
                sortField: "createdDate",
                sortDirection: "descending",
                page: page - 1,
            })
                .then(({ data }) => {
                    setComments(data);
                })
                .catch((error) => {
                    setError(error);
                })
                .finally(() => {
                    setLoading(false);
                    setFetch(false);
                });
        }
    }, [patientId, page, fetch]);

    if (error !== null) return <Alert severity="error"> Κάτι πήγε στραβά! </Alert>;
    else if (loading === true) return <Spinner align="center" animation="spinner-border" />;

    return (
        <div>
            <div>
                <div align="center">
                    <button className="btn buttonPrimary" onClick={() => setAddComment(true)}>
                        Εισαγωγή
                    </button>
                </div>
                <br />
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {mainColumns.map((column, index) => {
                                return (
                                    <TableCell key={index}>
                                        <b>{column.name}</b>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {comments.content.map((comment) => {
                            return (
                                <TableRow key={comment.id}>
                                    <TableCell component="th" scope="row">
                                        {comment.id}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {comment.content}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {formatDate(comment.createdDate)}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {comment.updatedDate !== null
                                            ? formatDate(comment.updatedDate)
                                            : ""}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <EditIcon
                                            color="primary"
                                            onClick={() =>
                                                onEdit({
                                                    id: comment.id,
                                                    commentContent: comment.content,
                                                })
                                            }
                                            style={{ cursor: "pointer" }}
                                        />
                                        <DeleteIcon
                                            color="secondary"
                                            onClick={() =>
                                                onDelete({
                                                    id: comment.id,
                                                    commentContent: comment.content,
                                                })
                                            }
                                            style={{ cursor: "pointer" }}
                                        />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
                <Pagination count={comments.totalPages} page={page} onChange={handlePageChange} />
            </TableContainer>
            <PopUp
                openPopUp={addComment}
                setOpenPopUp={setAddComment}
                title={"Προσθήκη νέου σχολίου"}
                maxWidth="sm"
            >
                <AddComment
                    patientId={patientId}
                    comments={comments}
                    setComments={setComments}
                    setFetch={setFetch}
                />
            </PopUp>
            <PopUp
                openPopUp={editComment}
                setOpenPopUp={setEditComment}
                title={"Επεξεργασία σχολίου"}
                maxWidth="sm"
            >
                <EditComment
                    commentId={currentComment.id}
                    commentContent={currentComment.commentContent}
                    patientId={patientId}
                    comments={comments}
                    setComments={setComments}
                />
            </PopUp>
            <PopUp
                openPopUp={deleteComment}
                setOpenPopUp={setDeleteComment}
                title={"Διαγραφή σχολίου"}
                maxWidth="sm"
            >
                <DeleteComment
                    commentId={currentComment.id}
                    commentContent={currentComment.commentContent}
                    patientId={patientId}
                    comments={comments}
                    setComments={setComments}
                    setFetch={setFetch}
                    page={page}
                    setPage={setPage}
                />
            </PopUp>
        </div>
    );
}

export default Comments;
