import React, { useContext } from "react";

import { PatientContext } from "../../Esyntagografisi.js";

const formatDate = (date) => {
    const dateArray = date.split(" ")[0].split("-");
    return `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;
};

function InsuredDetails() {
    const patient = useContext(PatientContext);
    return (
        <div className="section">
            <div className="boxTitle">
                <h6>Στοιχεία Ασφαλισμένου</h6>
            </div>
            <div className="boxContent">
                <div className="insurInfo">
                    <div className="flexBoxInfoWrap">
                        <div className="flexColumn">
                            <div className="flexWrap">
                                <p className="infoP alignRight">Ονοματεπώνυμο</p>
                                <p className="noMargin" id="insurdFullname">
                                    {patient.firstName} {patient.lastName}
                                </p>
                            </div>
                            <div className="flexWrap">
                                <p className="infoP alignRight">
                                    {patient.amka.length === 20 ? "Ε.Κ.Α.Α." : "Α.Μ.Κ.Α."}
                                </p>
                                <p className="noMargin" id="insuredAmka">
                                    {patient.amka}
                                </p>
                            </div>
                            <div className="flexWrap">
                                <p className="infoP alignRight">Τηλέφωνο</p>
                                <p className="noMargin" id="insuredPhone">
                                    {patient.telephone}
                                </p>
                            </div>
                            <div className="flexWrap">
                                <p className="infoP alignRight">Οδός/Αριθμός</p>
                                <p className="noMargin" id="insuredAddress">
                                    {patient.address}
                                </p>
                            </div>
                            <div className="flexWrap">
                                <p className="infoP alignRight">Πόλη</p>
                                <p className="noMargin" id="insuredTown">
                                    {patient.city}
                                </p>
                            </div>
                            <div className="flexWrap">
                                <p className="infoP alignRight">Τ.Κ.</p>
                                <p className="noMargin" id="insuredTk">
                                    {patient.postalCode}
                                </p>
                            </div>
                        </div>
                        <div className="flexColumn">
                            <div className="flexWrap">
                                <p className="infoP alignRight">Ημ/νία Γέννησης</p>
                                <p className="noMargin" id="insuredBirthdate">
                                    {formatDate(patient.birthDate)}
                                </p>
                            </div>
                            <div className="flexWrap">
                                <p className="infoP alignRight">Φύλο</p>
                                <p className="noMargin" id="insuredSex">
                                    {patient.sex.name}
                                </p>
                            </div>
                            <div className="flexWrap">
                                <p className="infoP alignRight">Φορέας Κοινωνικής Ασφάλισης</p>
                                <p className="noMargin" id="insuredSocialInsurance">
                                    {
                                        patient.activeInsurances.activeInsurance[
                                            patient.selectedActiveInsurance
                                        ].socialInsurance.shortName
                                    }
                                </p>
                            </div>
                            {patient.amka.length === 20 ? (
                                <div className="flexWrap">
                                    <p className="infoP alignRight">Χώρα</p>
                                    <p className="noMargin" id="insuredDirectIndirect">
                                        {patient.country.name} {patient.country.codeNum}
                                    </p>
                                </div>
                            ) : (
                                <div className="flexWrap">
                                    <p className="infoP alignRight">Άμεσος/Έμμεσος</p>
                                    <p className="noMargin" id="insuredDirectIndirect">
                                        {
                                            patient.activeInsurances.activeInsurance[
                                                patient.selectedActiveInsurance
                                            ].memberType.name
                                        }
                                    </p>
                                </div>
                            )}
                            <div className="flexWrap">
                                <p className="infoP alignRight">Αριθμός Μητρώου</p>
                                <p className="noMargin" id="insuredRegistrationNumber">
                                    {
                                        patient.activeInsurances.activeInsurance[
                                            patient.selectedActiveInsurance
                                        ].ama
                                    }
                                </p>
                            </div>
                            <div className="flexWrap">
                                <p className="infoP alignRight">Ημ/νία Λήξης Ασφαλ. Ικανότητας</p>
                                <p className="noMargin" id="insuredExpirationInsuranceDate">
                                    {formatDate(
                                        patient.activeInsurances.activeInsurance[
                                            patient.selectedActiveInsurance
                                        ].expiryDate
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InsuredDetails;
