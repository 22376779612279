import React, { useState, useEffect } from "react";
import axios from "axios";
import IdikaMasterDataService from "../../../../../services/MediPlus/Idika/IdikaMasterDataService.js";
import Alert from "@material-ui/lab/Alert";
import { Spinner } from "../../../../Utilities/Utilities.js";
import PopUp from "../../../../Utilities/PopUp.js";
// import Radio from "@material-ui/core/Radio";
// import RadioGroup from "@material-ui/core/RadioGroup";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { mainColumns, icd10Columns } from "./config.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { TextField } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import DeleteIcon from "@material-ui/icons/Delete";
import Grid from "@material-ui/core/Grid";

function SearchIcd10({ checkIfIcd10IsSelected, addOrRemoveIcd10 }) {
    // const [radio, setRadio] = useState("Όλα");
    // const handleRadioChange = (event) => {
    //     setRadio(event.target.value);
    // };

    const [state, setState] = useState({
        icd10description: "",
        icd10code: "",
    });

    const handleStateChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setState({ ...state, [name]: value });
    };

    const [page, setPage] = useState(1);
    const handleChange = (event, value) => {
        setPage(value);
        setFetch(true);
    };
    const [icd10s, setIcd10s] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const onSubmit = (e) => {
        e.preventDefault();
        handleChange(e, 1);
    };

    const [fetch, setFetch] = useState(false);
    useEffect(() => {
        if (fetch === false) return;
        setLoading(true);
        setError(null);
        IdikaMasterDataService.getIcd10s({ ...state, size: 5, page: page - 1 })
            .then(({ data }) => {
                setIcd10s(data);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
                setFetch(false);
            });
        // eslint-disable-next-line
    }, [fetch]);

    return (
        <div>
            <form onSubmit={onSubmit}>
                {/* <FormControl className="p-1" component="fieldset">
                    <FormLabel component="legend">Αναζήτηση σε</FormLabel>
                    <RadioGroup row value={radio} onChange={handleRadioChange}>
                        <FormControlLabel
                            value="Όλα"
                            control={<Radio color="primary" />}
                            label="Όλα"
                        />
                        <FormControlLabel
                            value="Συχνά Χρησιμοποιούμενα"
                            control={<Radio color="primary" />}
                            label="Συχνά Χρησιμοποιούμενα"
                        />
                    </RadioGroup>
                </FormControl>
                <br /> */}
                <FormControl component="fieldset">
                    <FormLabel component="legend">Κριτήρια</FormLabel>
                    <div className="form-inline">
                        <h1 className="infoP">Περιγραφή </h1>
                        <input
                            type="text"
                            name="icd10description"
                            value={state.icd10description}
                            onChange={handleStateChange}
                        />
                        <h1 className="infoP">Κωδικός ICD-10 </h1>
                        <input
                            type="text"
                            name="icd10code"
                            value={state.icd10code}
                            onChange={handleStateChange}
                        />
                        <button
                            type="submit"
                            onClick={onSubmit}
                            className="btn buttonPrimary"
                            disabled={loading}
                        >
                            {loading ? "Φόρτωση..." : "Αναζήτηση"}
                        </button>
                    </div>
                </FormControl>
            </form>
            {loading ? (
                <Spinner align="center" animation="spinner-border" />
            ) : error ? (
                <Alert severity="error">Kάτι πήγε στραβά!</Alert>
            ) : icd10s && icd10s.icd10s.length === 0 ? (
                <Alert severity="info">Δεν υπάρχουν ICD10s</Alert>
            ) : (
                icd10s && (
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    {mainColumns.map((column, index) => {
                                        return (
                                            <TableCell key={index}>
                                                <b>{column.name}</b>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {icd10s.icd10s.map((icd10) => {
                                    let buttonText =
                                        checkIfIcd10IsSelected(icd10) !== -1
                                            ? "Διαγραφή"
                                            : "Προσθήκη";
                                    return (
                                        <TableRow key={icd10.code}>
                                            <TableCell>{icd10.code}</TableCell>
                                            <TableCell>{icd10.title}</TableCell>
                                            <TableCell>{icd10.description.split("?")}</TableCell>
                                            <TableCell>
                                                <button
                                                    className={
                                                        buttonText === "Προσθήκη"
                                                            ? "btn buttonPrimary"
                                                            : "btn buttonDanger"
                                                    }
                                                    onClick={() => addOrRemoveIcd10(icd10)}
                                                >
                                                    {buttonText}
                                                </button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        <Pagination count={icd10s.totalPages} page={page} onChange={handleChange} />
                    </TableContainer>
                )
            )}
        </div>
    );
}

function compareExamGroupNames(a, b) {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
}

function compareExamNoPaticipationCases(a, b) {
    if (a.noParticipationCase < b.noParticipationCase) return -1;
    if (a.noParticipationCase > b.noParticipationCase) return 1;
    return 0;
}

function ReferralDetails({ state, setState, handleOnChange }) {
    const [examGroups, setExamGroups] = useState({ count: 0, examinationGroups: [] });
    const [examsNoPaticipationCases, setExamsNoPaticipationCases] = useState({
        count: 0,
        list: [],
    });
    const [examTypes, setExamTypes] = useState({ count: 0, prescriptionTypes: [] });
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const examinationGroups = IdikaMasterDataService.getExaminationGroups();
        const examinationNoPaticipationCases = IdikaMasterDataService.getExamsNoPaticipationCases();
        const examinationTypes = IdikaMasterDataService.getPrescriptionTypes();

        axios
            .all([examinationGroups, examinationNoPaticipationCases, examinationTypes])
            .then(
                axios.spread((...responses) => {
                    responses[0].data.examinationGroups.sort(compareExamGroupNames);
                    setExamGroups(responses[0].data);
                    responses[1].data.list.sort(compareExamNoPaticipationCases);
                    setExamsNoPaticipationCases(responses[1].data);
                    setExamTypes(responses[2].data);
                })
            )
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const handleSelect = (e) => {
        e.preventDefault();
        let value = JSON.parse(e.target.value);
        if (value.needsDecision === false)
            setState({ ...state, needsDecision: false, noParticipationCase: e.target.value });
        else setState({ ...state, needsDecision: true, noParticipationCase: e.target.value });
    };
    const [openPopUp, setOpenPopUp] = useState(false);

    const checkIfIcd10IsSelected = (icd10) => {
        return state.diagnoses.findIndex((selectedIcd10) => selectedIcd10.id === icd10.id);
    };

    const addOrRemoveIcd10 = (icd10) => {
        let index = checkIfIcd10IsSelected(icd10);
        if (index !== -1) {
            let newSelectedIcd10s = state.diagnoses.filter(
                (selectedIcd10, selIcd10Index) => selIcd10Index !== index
            );
            setState({ ...state, diagnoses: newSelectedIcd10s });
        } else {
            let arrayObject = {
                id: icd10.id,
                code: icd10.code,
                title: icd10.title,
            };
            let newSelectedIcd10s = [...state.diagnoses, arrayObject];
            setState({ ...state, diagnoses: newSelectedIcd10s });
        }
    };

    return (
        <div className="section">
            <div className="boxTitle">
                <h6>
                    Στοιχεία Παραπεμπτικού{" "}
                    {state.barcode !== "" && `(Αριθμός Παραπεμπτικού: ${state.barcode})`}
                </h6>
            </div>
            {loading ? (
                <Spinner align="center" animation="spinner-border" />
            ) : error ? (
                <div>
                    <Alert severity="error">Κάτι πήγε στραβά!</Alert>
                </div>
            ) : (
                <div className="boxContent">
                    <Grid container spacing={1} id="referralDetails">
                        <Grid container item spacing={2}>
                            <Grid item xs={4}>
                                <p className="infoP alignRight">* Τύπος Παραπεμπτικού</p>
                            </Grid>
                            <Grid item xs={8}>
                                <select
                                    value={state.typeId}
                                    name="typeId"
                                    onChange={handleOnChange}
                                    className="formItem"
                                >
                                    {examTypes.prescriptionTypes.slice(1).map((examType) => {
                                        return (
                                            <option key={examType.id} value={examType.id}>
                                                {examType.nameForExamPrescription}
                                            </option>
                                        );
                                    })}
                                </select>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={2}>
                            <Grid item xs={4}>
                                <p className="infoP alignRight">* Κατηγορία Εξετάσεων</p>
                            </Grid>
                            <Grid item xs={8}>
                                <select
                                    style={{ width: "300px" }}
                                    value={state.examinationGroupId}
                                    name="examinationGroupId"
                                    onChange={handleOnChange}
                                    className="formItem"
                                >
                                    <option value=""></option>
                                    {examGroups.examinationGroups.map((examGroup) => {
                                        return (
                                            <option key={examGroup.id} value={examGroup.id}>
                                                {examGroup.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={2}>
                            <Grid item xs={4}>
                                <p className="infoP alignRight">* Αιτιολογία Παραπεμπτικού</p>
                            </Grid>
                            <Grid item xs={8}>
                                <input
                                    type="text"
                                    value={state.reason}
                                    name="reason"
                                    onChange={handleOnChange}
                                    className="formItem"
                                />
                            </Grid>
                        </Grid>
                        <Grid container item spacing={2}>
                            <Grid item xs={4}>
                                <p className="infoP alignRight">Σημειώσεις</p>
                            </Grid>
                            <Grid item xs={8}>
                                <textarea
                                    rows="3"
                                    cols="50"
                                    value={state.comment}
                                    name="comment"
                                    onChange={handleOnChange}
                                    className="formItem"
                                ></textarea>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={2}>
                            <Grid item xs={4}>
                                <p className="infoP alignRight">Περιπτώσεις Μηδενικής Συμμετοχής</p>
                            </Grid>
                            <Grid item xs={8}>
                                <select
                                    style={{ width: "350px" }}
                                    onChange={handleSelect}
                                    value={state.noParticipationCase}
                                    className="formItem"
                                >
                                    <option value={'{"needsDecision":false}'}></option>
                                    {examsNoPaticipationCases.list.map((exam) => {
                                        return (
                                            <option key={exam.id} value={JSON.stringify(exam)}>
                                                {exam.noParticipationCase}
                                            </option>
                                        );
                                    })}
                                </select>
                            </Grid>
                        </Grid>
                        {state.needsDecision && (
                            <Grid container item spacing={2} style={{ marginLeft: "40px" }}>
                                <Grid item xs={4}>
                                    <TextField
                                        label="* Ημ/νία Απόφασης"
                                        type="date"
                                        value={state.decisionDate}
                                        name="decisionDate"
                                        onChange={handleOnChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    container
                                    item
                                    spacing={2}
                                    xs={8}
                                    style={{ marginTop: "10px" }}
                                >
                                    <Grid item xs={4}>
                                        <p className="infoP alignRight">* Αριθμός Απόφασης</p>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <input
                                            type="number"
                                            value={state.decisionNumber}
                                            name="decisionNumber"
                                            onChange={handleOnChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    <br />
                    <hr />
                    <button
                        className="btn buttonPrimary"
                        onClick={() => {
                            setOpenPopUp(true);
                        }}
                        disabled={
                            state.examinationGroupId === "" || state.reason === "" ? true : false
                        }
                    >
                        Προσθήκη Διάγνωσης ICD-10
                    </button>
                    <SelectedIcd10s
                        state={state}
                        setState={setState}
                        selectedIcd10s={state.diagnoses}
                        addOrRemoveIcd10={addOrRemoveIcd10}
                    />
                </div>
            )}
            <PopUp openPopUp={openPopUp} setOpenPopUp={setOpenPopUp} title={"Αναζήτηση ICD10"}>
                <SearchIcd10
                    checkIfIcd10IsSelected={checkIfIcd10IsSelected}
                    addOrRemoveIcd10={addOrRemoveIcd10}
                />
            </PopUp>
        </div>
    );
}

function SelectedIcd10s({ state, setState, selectedIcd10s, addOrRemoveIcd10 }) {
    if (selectedIcd10s.length === 0) return null;

    const automaticCorrelation = (icd10) => {
        let referrals = state.referrals;

        for (let referralIndex in referrals) {
            if (referrals[referralIndex].diagnosisCode === "")
                referrals[referralIndex].diagnosisCode = icd10.code;
        }

        setState({ ...state, referrals: referrals });
    };

    return (
        <TableContainer className="mt-2" component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        {icd10Columns.map((column, index) => {
                            return (
                                <TableCell key={index}>
                                    <b>{column.name}</b>
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {selectedIcd10s.map((icd10, indexRow) => {
                        return (
                            <TableRow key={indexRow}>
                                <TableCell>{icd10.code}</TableCell>
                                <TableCell>{icd10.title}</TableCell>
                                <TableCell>
                                    <button
                                        className="btn buttonPrimary"
                                        style={{ margin: "0px", padding: "1px" }}
                                        onClick={() => automaticCorrelation(icd10)}
                                    >
                                        Αυτόματη συσχέτιση
                                    </button>
                                </TableCell>
                                <TableCell>
                                    <DeleteIcon
                                        onClick={() => addOrRemoveIcd10(icd10)}
                                        color="secondary"
                                        style={{ cursor: "pointer" }}
                                    />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default ReferralDetails;
