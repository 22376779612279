import React from "react";
import "./Homepage.css";
import { Redirect } from "react-router-dom";
import { getJwt } from "../Utilities/JwtUtilities";

function Homepage() {
    const token = getJwt();
    if (token !== null) if (token !== null) return <Redirect to="/Doctor" />;

    return (
        <div className="bodyWrap">
            <img className="homepage-logo" src={"/images/MediPlusLogo.png"} alt="MediPlusLogo" />
        </div>
    );
}

export default Homepage;
