import axios from "axios";
import { baseUrl, postPdfOptions } from "../config.js";

class MediPlusPdfService {
    constructor(baseUrl) {
        this.basePath = "/pdf";
        this.baseUrl = `${baseUrl}${this.basePath}`;
    }

    getCustomPrescription(data) {
        return axios(postPdfOptions(this.baseUrl, "/customprescription", data));
    }

    generateMedicineTotalsPdf(data) {
        return axios(postPdfOptions(this.baseUrl, "/generateMedicineTotalsPdf", data));
    }
}

export default new MediPlusPdfService(baseUrl);
