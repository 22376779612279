import { useState } from "react";
import { mainTabs} from "./config";
import { Redirect } from "react-router";
import { getJwt } from "../Utilities/JwtUtilities";
import { Tabs, Tab, AppBar, Toolbar } from "@material-ui/core";
import UserManagement from "./UserManagement/UserManagement";
import Company from "./CompanyManagement/Company";
import CustomPrescription from "./CustomPrescription/CustomPrescription";
import ApplicationTerms from "./ApplicationTerms/ApplicationTerms";
import AppContactDetails from "./AppContactDetails/AppContactDetails";
import SignUp from "../SignUp/SignUp";
import Settings from "../Doctor/Management/Settings/Settings";
import './Admin.css';

function SuperAdmin() {
    const [selectedTab, setSelectedTab] = useState(0);
    const handleTabs = (e, value) => {
        setSelectedTab(value);
    };

    const signOut = () => {
        sessionStorage.clear();
        window.location = "/";
    };

    const showContent = () => {
        if (selectedTab === 0) return <UserManagement type="user" key={selectedTab}/>;
        if (selectedTab === 1) return <UserManagement type="admin" key={selectedTab}/>;
        if (selectedTab === 2) return <UserManagement type="phAgent" key={selectedTab}/>;
        if (selectedTab === 3) return <Company key={selectedTab}/>;
        else if (selectedTab === 4) return <CustomPrescription key={selectedTab}/>;
        else if (selectedTab === 5) return <ApplicationTerms key={selectedTab}/>;
        else if (selectedTab === 6) return <AppContactDetails key={selectedTab}/>;
        else if (selectedTab === 7) return <SignUp admin={true} phAgent ={false} key={selectedTab}/>;
        else if (selectedTab === 8) return <SignUp admin={false} phAgent ={true} key={selectedTab}/>;
        else if (selectedTab === 9) return <Settings admin={true} key={selectedTab}/>;
        else return null;
    };
    
    const token = getJwt();
    if (token === null) return <Redirect to="/" />;

    return (
        <div className="bodyWrap">
            <div className="mainHeaderAdmin mb-5">
                <AppBar position="static" className="mainTabs">
                    <Toolbar>
                        <img
                            className="mr-2"
                            height="40px"
                            src="/images/MediPlusLogoNoPadding.png"
                            alt="MediPlusLogoNoPadding"
                        />
                        <Tabs
                            className="mainTabs"
                            value={selectedTab}
                            onChange={handleTabs}
                            variant="scrollable"
                            scrollButtons="auto"
                            indicatorColor="secondary"
                        >
                            {mainTabs.map((tab, index) => {
                                return <Tab key={index} {...tab} 
                                
                                />;
                            })}
                        </Tabs>
                        <button className="btn buttonPrimary HeaderButton" onClick={signOut}>
                            <i className="fa fa-sign-out"></i>
                            Αποσύνδεση
                        </button>
                    </Toolbar>
                </AppBar>
            </div>
            {showContent()}
        </div>
    );
}

export default SuperAdmin;
