import React, { useState, useEffect } from "react";
import PopUp from "../../../../Utilities/PopUp";
import Protocol from "./Protocol/Protocol";
import { mainColumns } from "./config.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IdikaVisitService from "../../../../../services/MediPlus/Idika/IdikaVisitService.js";
import { CircularProgress } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

function TreatmentProtocols({ tabItem, addPrescriptionState, setAddPrescriptionState }) {
    const [openPopUp, setOpenPopUp] = useState(false);

    function popupHandler() {
        setOpenPopUp(true);
    }

    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    useEffect(() => {
        IdikaVisitService.getTreatmentProtocolSteps(tabItem.visitDetails.id)
            .then(({ data }) => {
                setAddPrescriptionState({
                    ...addPrescriptionState,
                    insertedTreatmentProtocols: data,
                });
            })
            .catch((err) => {
                setIsError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
        // eslint-disable-next-line
    }, []);

    const [deletePopUp, setDeletePopUp] = useState(false);
    const [selectedTreatmentProtocol, setSelectedTreatmentProtocol] = useState(null);
    const onDelete = (treatmentProtocol) => {
        setSelectedTreatmentProtocol(treatmentProtocol);
        setDeletePopUp(true);
    };

    const [deleteError, setDeleteError] = useState(null);
    const deleteTreatmentProtocol = () => {
        setDeleteError(null);
        IdikaVisitService.deleteTreatmentProtocolStep(
            tabItem.visitDetails.id,
            selectedTreatmentProtocol.visitPastId
        )
            .then(({ data }) => {
                let newTreatmentProtocols =
                    addPrescriptionState.insertedTreatmentProtocols.treatmentProtocols.filter(
                        (treatmentProtocol) =>
                            treatmentProtocol.visitPastId !== selectedTreatmentProtocol.visitPastId
                    );
                setAddPrescriptionState({
                    ...addPrescriptionState,
                    insertedTreatmentProtocols: {
                        count: newTreatmentProtocols.length,
                        treatmentProtocols: newTreatmentProtocols,
                    },
                });
                setDeletePopUp(false);
            })
            .catch((err) => {
                setDeleteError(err.response);
            });
    };

    return (
        <>
            {addPrescriptionState.galenicPrescription ? null : (
                <div className="fullBox">
                    <div className="boxTitle">
                        <h6>Θεραπευτικά Πρωτόκολλα</h6>
                    </div>
                    <div className="boxContent">
                        <div className="insurInfo">
                            <br />
                            <button className="btn buttonPrimary" onClick={popupHandler}>
                                Προσθήκη Θεραπευτικού Πρωτοκόλλου
                            </button>
                        </div>
                        <br />
                        {isLoading ? (
                            <CircularProgress />
                        ) : isError ? (
                            <Alert severity="error">Κάτι πήγε στραβά!</Alert>
                        ) : (
                            <div>
                                {addPrescriptionState.insertedTreatmentProtocols.count !== 0 &&
                                    addPrescriptionState.insertedTreatmentProtocols
                                        .treatmentProtocols.length !== 0 && (
                                        <div>
                                            <TableContainer
                                                component={Paper}
                                                // style={{ maxHeight: "150px", overflowY: "auto" }}
                                            >
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            {mainColumns.map((column, index) => {
                                                                return (
                                                                    <TableCell key={index}>
                                                                        <b>{column.name}</b>
                                                                    </TableCell>
                                                                );
                                                            })}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {addPrescriptionState.insertedTreatmentProtocols.treatmentProtocols.map(
                                                            (treatmentProtocol, index) => {
                                                                return (
                                                                    <TableRow key={index}>
                                                                        <TableCell>
                                                                            {
                                                                                treatmentProtocol.description
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {
                                                                                treatmentProtocol.atcaDescription
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {treatmentProtocol.treatmentInserted ===
                                                                            false ? (
                                                                                <button
                                                                                    className="btn buttonDanger"
                                                                                    onClick={() =>
                                                                                        onDelete(
                                                                                            treatmentProtocol
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    Αφαίρεση
                                                                                </button>
                                                                            ) : (
                                                                                "-- Έχει χρησιμοποιηθεί στην τρέχουσα επίσκεψη --"
                                                                            )}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                );
                                                            }
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <br />
                                        </div>
                                    )}
                            </div>
                        )}
                        <p>
                            * Θεραπευτικά Πρωτόκολλα στα οποία έχει ήδη εισαχθεί ο ασθενής και τα
                            οποία έχετε επιλέξει να χρησιμοποιήσετε στην τρέχουσα επίσκεψη του
                            ασθενή
                        </p>
                    </div>
                    <PopUp
                        openPopUp={openPopUp}
                        setOpenPopUp={setOpenPopUp}
                        title={"Προσθήκη Θεραπευτικού Πρωτοκόλλου"}
                        maxWidth="xl"
                    >
                        <Protocol
                            setOpenPopUp={setOpenPopUp}
                            addPrescriptionState={addPrescriptionState}
                            setAddPrescriptionState={setAddPrescriptionState}
                            tabItem={tabItem}
                        />
                    </PopUp>
                    <PopUp
                        openPopUp={deletePopUp}
                        setOpenPopUp={setDeletePopUp}
                        title={"Προσοχή!"}
                        maxWidth="sm"
                    >
                        <div>
                            {deleteError && <Alert severity="error">Κάτι πήγε στραβά!</Alert>}
                            <div align="center">
                                Είστε σίγουρος ότι θέλετε να αφαιρέσετε τη συγκεκριμένη Δραστική
                                Ουσία από τις επιλεγμένες;
                                <button
                                    className="btn buttonDanger"
                                    onClick={() => deleteTreatmentProtocol()}
                                >
                                    Αφαίρεση
                                </button>
                            </div>
                        </div>
                    </PopUp>
                </div>
            )}
        </>
    );
}

export default TreatmentProtocols;
