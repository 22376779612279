import React, { useState } from "react";

import { Spinner } from "../../../../../../../Utilities/Utilities.js";
import Alert from "@material-ui/lab/Alert";
import MediPlusPatientService from "../../../../../../../../services/MediPlus/MediPlusPatientService.js";

function EditInsuredData({ patient, setPatient }) {
    const [state, setState] = useState({
        address: patient.address,
        postalCode: patient.postalCode,
        city: patient.city,
        telephone: patient.telephone,
        email: patient.email,
    });
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const [successAlert, setSuccessAlert] = useState(false);

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setState({ ...state, [name]: value });
    };

    const onSubmit = (e) => {
        e.preventDefault();

        let request = { amka: patient.amka, ...state };
        if (state.email === "") delete request.email;

        setError({});
        setLoading(true);
        setSuccessAlert(false);
        MediPlusPatientService.updatePatient(request)
            .then((response) => {
                const data = response.data.Patient;
                setPatient({ ...patient, ...data });
                setSuccessAlert(true);
                setLoading(false);
            })
            .catch((error) => {
                setError(error.response.data);
                setLoading(false);
            });
    };

    return (
        <div>
            {Object.keys(error).length !== 0 && (
                <div>
                    <Alert severity="error">
                        {error.status === 811
                            ? "Το Τηλέφωνο πρέπει να ξεκινάει είτε με 2 είτε με 6 και να έχει μήκος 10!"
                            : error.status === 812
                            ? "Ο Τ.Κ. πρέπει να έχει μήκος 5!"
                            : "Κάτι πήγε στραβά!"}
                    </Alert>
                    <br />
                </div>
            )}
            {successAlert === true && (
                <div>
                    <Alert severity="success">Η Προσθήκη έγινε με επιτυχία!</Alert>
                    <br />
                </div>
            )}
            <form onSubmit={onSubmit}>
                <div className="form-group">
                    <label>Οδός/Αριθμός *</label>
                    <input
                        type="text"
                        className="form-control"
                        name="address"
                        value={state.address}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>T.K. *</label>
                    <input
                        type="text"
                        className={
                            error.status === 812 ? "form-control is-invalid" : "form-control"
                        }
                        name="postalCode"
                        value={state.postalCode}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Πόλη *</label>
                    <input
                        type="text"
                        className="form-control"
                        name="city"
                        value={state.city}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Τηλέφωνο *</label>
                    <input
                        type="text"
                        className={
                            error.status === 811 ? "form-control is-invalid" : "form-control"
                        }
                        name="telephone"
                        value={state.telephone}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Email</label>
                    <input
                        type="email"
                        className="form-control"
                        name="email"
                        value={state.email}
                        onChange={handleChange}
                    />
                </div>
                {loading === true ? (
                    <Spinner align="center" animation="spinner-border" />
                ) : (
                    <div align="center">
                        {loading === true ? (
                            <Spinner align="center" animation="spinner-border" />
                        ) : (
                            <div>
                                <button className="btn buttonPrimary">Αποθήκευση</button>
                            </div>
                        )}
                    </div>
                )}
            </form>
        </div>
    );
}

export default EditInsuredData;
