import React from "react";
import { mainColumns } from "./config.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

function Diagnosis({ prescription }) {
    return (
        <div className="fullBox">
            <div className="boxTitle">
                <h6>Διάγνωση</h6>
            </div>
            <div className="boxContent">
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {mainColumns.map((column, index) => {
                                    return (
                                        <TableCell key={index}>
                                            <b>{column.name}</b>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {prescription.diagnosis.diagnosis.map((diagnosis, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{diagnosis.code}</TableCell>
                                        <TableCell>{diagnosis.title}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
}

export default Diagnosis;
