import React from "react";
import DoctorInfo from "../DoctorVisit/DoctorInfo/DoctorInfo";
import InsuredDetails from "../AddPrescription/InsuredDetails/InsuredDetails";
import ReferralInfo from "./ReferralInfo/ReferralInfo";
import ExaminationInfo from "./ExaminationInfo/ExaminationInfo";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
        flexGrow: 1,
    },
}));

function ViewReferral({ tabItem, setTabItem, popPrescriptionOrReferralForRegistration,noPaperReferralConsent }) {
    const doctorData = JSON.parse(sessionStorage.getItem("mediPlusDoctorData"));
    const referral = tabItem.viewReferral;

    const classes = useStyles();

    if (Object.keys(referral).length === 0) return null;

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={5}>
                    <DoctorInfo
                        firstname={doctorData.firstname}
                        lastname={doctorData.lastname}
                        specialty={doctorData.specialty}
                        amka={doctorData.amka}
                        etaaRegNo={doctorData.etaaRegNo}
                    />
                </Grid>
                <Grid item xs={12} md={7}>
                    <InsuredDetails />
                </Grid>
                <Grid item xs={12} md={5}>
                    <ReferralInfo
                        referral={referral}
                        tabItem={tabItem}
                        setTabItem={setTabItem}
                        popPrescriptionOrReferralForRegistration={
                            popPrescriptionOrReferralForRegistration
                        }
                        noPaperReferralConsent={noPaperReferralConsent}
                    />
                </Grid>
                <Grid item xs={12} md={7}>
                    <ExaminationInfo referral={referral} />
                </Grid>
            </Grid>
        </div>
    );
}

export default ViewReferral;
