import React from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

function UnitResults({ units }) {
    if (units === null) return null;

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <b>ID</b>
                        </TableCell>
                        <TableCell>
                            <b>Μονάδα Συνταγογράφησης</b>
                        </TableCell>
                        <TableCell>
                            <b>Ονομασία</b>
                        </TableCell>
                        <TableCell>
                            <b>Ημ/νια Έναρξης</b>
                        </TableCell>
                        <TableCell>
                            <b>Ημ/νια Λήξης</b>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {units.map((unit) => {
                        return (
                            <TableRow key={unit.id}>
                                <TableCell component="th" scope="row">
                                    {unit.id}
                                </TableCell>
                                <TableCell>{unit.healthCareUnit.description}</TableCell>
                                <TableCell>{unit.description}</TableCell>
                                <TableCell>{unit.startDate}</TableCell>
                                <TableCell>{unit.expiryDate}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default UnitResults;
