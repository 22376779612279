import React, { useState, useRef, useEffect } from "react";
import "./SignIn.css";
import TextField from "@material-ui/core/TextField";
import MediPlusAuthService from "../../services/MediPlus/MediPlusAuthService.js";
import { saveJwt } from "../Utilities/JwtUtilities.js";
import { Spinner } from "../Utilities/Utilities.js";
import { findRole } from "./config.js";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import ReCAPTCHA from "react-google-recaptcha";
import { locationMap, RECAPTCHA_SITE_KEY } from "../../config";
import PopUp from "../Utilities/PopUp";
import ConfirmNewTerms from "./ConfirmNewTerms";

const useStyles = makeStyles(() => ({
    root: {
        width: "80%",
        marginTop: "10px",
        marginBottom: "10px",
    },
}));

function SignIn() {
    const classes = useStyles();
    const [state, setState] = useState({
        userNameOrEmail: "",
        password: "",
    });
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setState({ ...state, [name]: value });
    };

    const [reCaptchaToken, setReCaptchaToken] = useState("");
    const [signInRequest, setSignInRequest] = useState(false);
    // every time the user clicks the submit button we have to get the recaptcha token first
    const onSubmit = async (e) => {
        e.preventDefault();

        const reCaptchaTokenResponse = await reCaptchaRef.current
            .executeAsync()
            .then((value) => {
                return value;
            })
            .catch((err) => {
                // console.log(err);
            });
        setReCaptchaToken(reCaptchaTokenResponse);
        reCaptchaRef.current.reset();

        setSignInRequest(true);
    };

    useEffect(() => {
        if (signInRequest === false) return;
        setError(null);
        setLoading(true);
        let request = { ...state, reCaptchaToken };
        MediPlusAuthService.signIn(request)
            .then((response) => {
                if (response.data.acceptNewTerms === true) {
                    setSignInResponse(response.data);
                    setTermsPopUp(true);
                    setLoading(false);
                } else {
                    saveJwt(response.data.token);
                    const role = findRole(response.data.authorities);
                    sessionStorage.setItem("role", role);
                    sessionStorage.setItem(
                        "membershipNotification",
                        response.data.membershipNotification
                    );
                    sessionStorage.setItem("membershipDiffDays", response.data.membershipDiffDays);
                    
                    //if agent scanned submitted print add this to url
                    let link = sessionStorage.getItem("link");

                    if(!link || link === "undefined"){
                        window.location = locationMap[role];
                    }else{
                        window.location = locationMap[role]  + "/" + link;
                    }                   
                }
            })
            .catch((error) => {
                setError(error.response.data);
                setSignInRequest(false);
                setLoading(false);
            });
    }, [signInRequest, state, reCaptchaToken]);

    const reCaptchaRef = useRef();

    const [signInResponse, setSignInResponse] = useState({});
    const [termsPopUp, setTermsPopUp] = useState(false);

    // const token = getJwt();
    // if (token !== null) return <Redirect to={locationMap[sessionStorage.getItem("role")]} />;

    return (
        <div className="loginForm bodyWrap">
            <div className="loginBody">
                <div className="flexBox">
                    <div className="fullBox" id="fullBox">
                        <div className="authForm">
                            <h4> ΕΙΣΟΔΟΣ </h4>
                            <div align="center">
                                <div className={classes.root}>
                                    {error !== null ? (
                                        <Alert severity="error">
                                            {error.status === 401
                                                ? "Τα στοιχεία που δώσατε δεν είναι σωστά!"
                                                : error.status === 403
                                                ? "Ο λογαριασμός σας έχει απενεργοποιηθεί!"
                                                : error.status === 400 &&
                                                  error.errors.membership ===
                                                      "The membership has expired"
                                                ? "Η συνδρομή σας έχει τελειώσει!"
                                                : error.status === 400 &&
                                                  error.errors.membership ===
                                                      "There is no membership for this user"
                                                ? "Δεν έχει προστεθεί κάποια συνδρομή!"
                                                : "Δεν υπάρχει χρήστης με αυτό το Username ή Email!"}
                                        </Alert>
                                    ) : null}
                                </div>
                            </div>
                            <form onSubmit={onSubmit}>
                                <TextField
                                    className="formInputUI"
                                    label="Όνομα χρήστη (Username) ή Email"
                                    name="userNameOrEmail"
                                    value={state.userNameOrEmail}
                                    onChange={handleChange}
                                    error={error !== null ? true : false}
                                    required
                                />
                                <br />
                                <br />
                                <TextField
                                    className="formInputUI"
                                    type="password"
                                    label="Κωδικός (Password)"
                                    name="password"
                                    value={state.password}
                                    onChange={handleChange}
                                    error={error !== null ? true : false}
                                    required
                                />
                                <br />
                                <a href="/ForgotPassword">Ξεχάσατε τον Κωδικό σας;</a>
                                <br />
                                <br />
                                <ReCAPTCHA
                                    sitekey={RECAPTCHA_SITE_KEY}
                                    size="invisible"
                                    ref={reCaptchaRef}
                                />
                                {loading === true ? (
                                    <Spinner align="center" animation="spinner-border" />
                                ) : (
                                    <button className="btn buttonPrimary">Είσοδος</button>
                                )}
                                <br />
                                <br />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <PopUp
                openPopUp={termsPopUp}
                setOpenPopUp={setTermsPopUp}
                title={
                    "Οι όροι χρήσης της εφαρμογής MEDIPLUS άλλαξαν. Παρακαλώ αποδεχτείτε τους νέους όρους για να συνεχίσετε."
                }
                maxWidth="md"
                showCloseButton={false}
            >
                <ConfirmNewTerms signInResponse={signInResponse} />
            </PopUp>
        </div>
    );
}

export default SignIn;
