import axios from "axios";
import { baseUrl, getOptions, postOptions} from "../config.js";

class MediPlusCustomPrescriptionHistoryService {
    constructor(baseUrl) {
        this.basePath = "/customprescriptionhistory";
        this.baseUrl = `${baseUrl}${this.basePath}`;
    }

    addCustomPrescriptionHistory(customPrescriptionHistory) {
        return axios(postOptions(this.baseUrl, "", customPrescriptionHistory));
    }

    getUserPatientCustomPrescriptions(patientAmka) {
        return axios(getOptions(`${this.baseUrl}` , `/${patientAmka}` ));
    }

}

export default new MediPlusCustomPrescriptionHistoryService(baseUrl);
