import React, { useState, useEffect } from "react";
import { formatDateAndTime, Spinner } from "../../../../../../../Utilities/Utilities";
import Alert from "@material-ui/lab/Alert";
import {
    mainColumns,
    diagnosisColumns,
    referralColumns,
    diagnosisFields,
    referralFields,
} from "./config.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import PopUp from "../../../../../../../Utilities/PopUp";
import Pagination from "@material-ui/lab/Pagination";
import IdikaReferralService from "../../../../../../../../services/MediPlus/Idika/IdikaReferralService";

function ReferralHistory({ patient, setReferralHistory, tabItem, setTabItem }) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [page, setPage] = useState(1);
    const handleChange = (event, value) => {
        setPage(value);
    };

    useEffect(() => {
        let request = {
            page: page - 1,
            size: 5,
        };
        if (patient.amka.length === 20) request.ekaa = patient.amka;
        else request.amka = patient.amka;
        IdikaReferralService.getReferrals(request)
            .then(({ data }) => {
                setData(data);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [patient.amka, page]);

    const [more, setMore] = useState(false);
    const [selectedReferral, setSelectedReferral] = useState(null);

    const handleMore = (referralBarcode) => {
        IdikaReferralService.getReferral(referralBarcode)
            .then(({ data }) => {
                setSelectedReferral(data);
                setMore(true);
            })
            .catch((error) => {
                setError(error);
            });
    };

    const [selectedExamBarcode, setSelectedExamBarcode] = useState(0);
    const addReferral = (exam) => {
        setSelectedExamBarcode(exam.barcode);
        IdikaReferralService.getReferral(exam.barcode)
            .then(({ data }) => {
                let referrals = data.clinicalExaminations.map((ref) => {
                    return {
                        code: ref.code,
                        displayName: ref.title,
                        relationType: ref.relationTypeId,
                        diagnosisCode: ref.diagnosisCode,
                        comment: ref.comment,
                    };
                });
                let referralToBeRegistered = {
                    name: data.barcode,
                    examinationGroupId: data.examinationGroupId,
                    diagnoses: JSON.stringify(data.diagnosis),
                    examinationGroup: data.category,
                    numberOfReferrals: data.clinicalExaminations.length,
                    reason: data.reason,
                    referrals: JSON.stringify(referrals),
                    comment: data.notes,
                    prescriptionType: data.prescriptionType,
                    type: "history",
                };
                setTabItem({
                    ...tabItem,
                    visitDetails: {
                        ...tabItem.visitDetails,
                        referralsToBeRegistered: [
                            ...tabItem.visitDetails.referralsToBeRegistered,
                            referralToBeRegistered,
                        ],
                    },
                });
                setSelectedExamBarcode(0);
            })
            .catch((error) => {
                setError(error);
            });
    };

    const removeReferral = (exam) => {
        let newReferrals = tabItem.visitDetails.referralsToBeRegistered.filter(
            (alreadyAddedExam) =>
                alreadyAddedExam.type === undefined ||
                (alreadyAddedExam.type === "history" && alreadyAddedExam.name !== exam.barcode)
        );
        setTabItem({
            ...tabItem,
            visitDetails: {
                ...tabItem.visitDetails,
                referralsToBeRegistered: newReferrals,
            },
        });
    };

    const checkIfThePackageIsAlreadyAdded = (exam) => {
        let found = tabItem.visitDetails.referralsToBeRegistered.find(
            (alreadyAddedExam) =>
                alreadyAddedExam.type === "history" && alreadyAddedExam.name === exam.barcode
        );
        if (found === undefined) return false;
        return true;
    };

    return (
        <div>
            {loading ? (
                <Spinner align="center" animation="spinner-border" />
            ) : error ? (
                <Alert severity="error">Κάτι πήγε στραβά!</Alert>
            ) : data.count === 0 ? (
                <Alert severity="info">
                    {`Δεν υπάρχουν παλαιότερα Παραπεμπτικά για τον ασθενή '${patient.lastName}
            ${patient.firstName}'`}
                </Alert>
            ) : (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {mainColumns.map((column, index) => {
                                    return (
                                        <TableCell key={index}>
                                            <b>{column.name}</b>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.referrals.map((referral) => {
                                return (
                                    <TableRow key={referral.barcode}>
                                        <TableCell>{referral.barcode}</TableCell>
                                        <TableCell>
                                            {formatDateAndTime(referral.issueDate)}
                                        </TableCell>
                                        <TableCell>{referral.examinationGroup.name}</TableCell>
                                        <TableCell>{referral.status.status}</TableCell>
                                        <TableCell>
                                            {checkIfThePackageIsAlreadyAdded(referral) === false ? (
                                                <button
                                                    className="btn buttonPrimary small-padding"
                                                    onClick={() => addReferral(referral)}
                                                    disabled={
                                                        selectedExamBarcode === referral.barcode
                                                    }
                                                >
                                                    {selectedExamBarcode === referral.barcode ? (
                                                        <div>
                                                            <i className="fa fa-spinner fa-pulse"></i>
                                                            Άντληση....
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <i className="fa fa-repeat"></i>
                                                            Επανάληψη
                                                        </div>
                                                    )}
                                                </button>
                                            ) : (
                                                <button
                                                    className="btn buttonDanger small-padding"
                                                    onClick={() => removeReferral(referral)}
                                                >
                                                    <i className="fa fa-trash"></i>
                                                    Αφαίρεση
                                                </button>
                                            )}
                                            <button
                                                className="btn buttonPrimary small-padding"
                                                onClick={() => handleMore(referral.barcode)}
                                            >
                                                <i className="fa fa-search"></i>
                                                Λεπτομέρειες
                                            </button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                    <Pagination count={data.totalPages} page={page} onChange={handleChange} />
                </TableContainer>
            )}
            <PopUp
                openPopUp={more}
                setOpenPopUp={setMore}
                showCloseButton={true}
                title={"Παραπεμπτκό"}
                maxWidth="md"
            >
                {selectedReferral && (
                    <div>
                        <div>
                            <b>Αριθμός Παραπεμπτικού</b>: {selectedReferral.barcode} <br />
                            <b>Κατηγορία Παραπεμπτικού</b>: {selectedReferral.category} <br />
                            <b>Αιτιολογία Παραπεμπτικού</b>: {selectedReferral.reason} <br />
                            <b>Σημειώσεις</b>: {selectedReferral.notes} <br />
                            <b>Κατάσταση Παραπεμπτικού</b>: {selectedReferral.status} <br />
                            <b>Ημερομηνία Λήξης</b>: {selectedReferral.endDate} <br />
                            {Object.keys(selectedReferral.noParticipationCase).length !== 0 && (
                                <div>
                                    <br />
                                    <b>Περιπτώσεις Μηδενικής Συμμετοχής</b>:
                                    {
                                        selectedReferral.noParticipationCase.noParticipationCase
                                            .noParticipationCase
                                    }
                                    <br />
                                    <b>Ημερομηνία Απόφασης</b>:
                                    {selectedReferral.noParticipationCase.date} <br />
                                    <b>Αριθμός Απόφασης</b>:
                                    {selectedReferral.noParticipationCase.number} <br />
                                </div>
                            )}
                        </div>
                        <br />
                        {selectedReferral.diagnosis.length > 0 && (
                            <div>
                                <h6 style={{ marginLeft: "10px", marginBottom: "10px" }}>
                                    Διαγνώσεις Παραπεμπτικού
                                </h6>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {diagnosisColumns.map((column, index) => {
                                                    return (
                                                        <TableCell key={index}>
                                                            <b>{column.name}</b>
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {selectedReferral.diagnosis.map((diaCol, index) => {
                                                return (
                                                    <TableRow key={index}>
                                                        {diagnosisFields.map(
                                                            (field, indexField) => {
                                                                return (
                                                                    <TableCell key={indexField}>
                                                                        {diaCol[field.name]}
                                                                    </TableCell>
                                                                );
                                                            }
                                                        )}
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        )}
                        <br />
                        {selectedReferral.clinicalExaminations.length > 0 && (
                            <div>
                                <h6 style={{ marginLeft: "10px", marginBottom: "10px" }}>
                                    Εξετάσεις
                                </h6>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {referralColumns.map((column, index) => {
                                                    return (
                                                        <TableCell key={index}>
                                                            <b>{column.name}</b>
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {selectedReferral.clinicalExaminations.map(
                                                (clinicalExamination, index) => {
                                                    return (
                                                        <TableRow key={index}>
                                                            {referralFields.map(
                                                                (field, indexField) => {
                                                                    return (
                                                                        <TableCell key={indexField}>
                                                                            {
                                                                                clinicalExamination[
                                                                                    field.name
                                                                                ]
                                                                            }
                                                                        </TableCell>
                                                                    );
                                                                }
                                                            )}
                                                        </TableRow>
                                                    );
                                                }
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        )}
                    </div>
                )}
            </PopUp>
        </div>
    );
}

export default ReferralHistory;
