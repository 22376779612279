import React from "react";
import "./DoctorExecuteExam.css";

import TextField from "@material-ui/core/TextField";

// this is a tab that IDIKA is using
// right now we do not use it, but maybe in the future is useful
const DoctorExecuteExam = (props) => {
    return (
        <div>
            <div className="flexBox">
                <div className="executeForm">
                    <div className="flex">
                        <TextField
                            label="Αριθμός Παραπεμπτικού"
                            id="ReferralNumberSearch"
                            type="text"
                            className="searchInput"
                            name="ReferralNumberSearch"
                        />
                        <button className="btn buttonPrimary">Αναζήτηση</button>
                    </div>
                    <div className="flex">
                        <TextField
                            label="Α.Μ.Κ.Α."
                            id="amkaSearch"
                            type="text"
                            className="searchInput"
                            name="amkaSearch"
                        />
                        <button className="btn buttonPrimary">Αναζήτηση</button>
                    </div>
                </div>
            </div>
            <br />
            <div className="flexBox">
                <button className="btn buttonPrimary">Εκτέλεση Παραπεμπτικού</button>
                <button className="btn buttonPrimary">Μερική Εκτέλεση</button>
                <button className="btn buttonPrimary">Εκτέλεση Νέου Παραπεμπτικού</button>
                <button className="btn buttonPrimary">Εισαγωγή Αποτελεσμάτων</button>
            </div>
        </div>
    );
};

export default DoctorExecuteExam;
