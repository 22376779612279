import React, { useState } from "react";
import { mainTabs } from "./config.js";
import { Tabs, Tab } from "@material-ui/core";
import SearchVisits from "./SeachVisits/SearchVisits.js";
import SearchPrescriptions from "./SearchPrescriptions/SearchPrescriptions.js";
import SearchReferrals from "./SearchReferrals/SearchReferrals.js";

function SearchPrescription({ addTab, tabItem, setTabItem, setSelectedTab, setSelectedTabBttn }) {
    const [selectedSearchTab, setSelectedSearchTab] = useState(0);
    function handleTabs(e, val) {
        setSelectedSearchTab(val);
    }

    const showContent = () => {
        if (selectedSearchTab === 0)
            return (
                <SearchVisits
                    addTab={addTab}
                    tabItem={tabItem}
                    setTabItem={setTabItem}
                    setSelectedTab={setSelectedTab}
                    setSelectedTabBttn={setSelectedTabBttn}
                />
            );
        else if (selectedSearchTab === 1)
            return (
                <SearchPrescriptions
                    addTab={addTab}
                    tabItem={tabItem}
                    setTabItem={setTabItem}
                    setSelectedTab={setSelectedTab}
                    setSelectedTabBttn={setSelectedTabBttn}
                />
            );
        else if (selectedSearchTab === 2)
            return (
                <SearchReferrals
                    addTab={addTab}
                    tabItem={tabItem}
                    setTabItem={setTabItem}
                    setSelectedTab={setSelectedTab}
                    setSelectedTabBttn={setSelectedTabBttn}
                />
            );
        else return null;
    };

    return (
        <div className="flexBoxWrap">
            <div className="flexBox">
                <div className="fullBox">
                    <div className="boxTitle">
                        <h6>Κριτήρια Αναζήτησης</h6>
                    </div>
                    <div className="boxContent">
                        <Tabs
                            value={selectedSearchTab}
                            onChange={handleTabs}
                            indicatorColor="primary"
                            className="tabsBttns"
                        >
                            {mainTabs.map((tab, index) => {
                                return <Tab key={index} {...tab} />;
                            })}
                        </Tabs>
                        {showContent()}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SearchPrescription;
