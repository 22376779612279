const mainColumns = [
    { name: "Αριθμός Συνταγής" },
    { name: "Ημ/νια Έκδοσης Συνταγής" },
    { name: "Ημ/νια Προθεσμίας Εκτέλεσης" },
    { name: "Κατάσταση Συνταγής" },
    { name: "Κωδικός Επίσκεψης" },
    { name: "" },
];

const prescriptionFields = [
    // { name: "id" },
    { name: "startDate" },
    { name: "endDate" },
    { name: "status" },
    { name: "visitId" },
];

export { mainColumns, prescriptionFields };
