import React from "react";

import AnnouncementsResults from "./AnnouncementsResults/AnnouncementsResults.js";

function Announcements({ setAnnouncementsPopUp ,announcements}) {
    
    return (
        <div>
            <AnnouncementsResults announcements={announcements} />    
            <br />
            <div align="center">
                <button
                    type="submit"
                    className="btn buttonPrimary"
                    onClick={() => {
                        setAnnouncementsPopUp(false);
                    }}
                >
                    Συνέχεια
                </button>
            </div>
        </div>
    );
}

export default Announcements;
