import React, { useState, useEffect } from "react";
import PopUp from "../../../../../Utilities/PopUp";
import { Spinner } from "../../../../../Utilities/Utilities.js";
import SearchItem from "./SearchItem/SearchItem";
import MediPlusUserService from "../../../../../../services/MediPlus/MediPlusUserService.js";
import TextField from "@material-ui/core/TextField";
import { patientWithoutActiveInsuranceRedirectLink } from "../../../../../../config";

function NoActiveInsuranceRedirectPopUp({ setNoActiveInsurancesPopUp }) {
    const onYes = () => {
        window.open(patientWithoutActiveInsuranceRedirectLink, "_blank");
        setNoActiveInsurancesPopUp(false);
    };

    const onNo = () => {
        setNoActiveInsurancesPopUp(false);
    };

    return (
        <div className="d-flex flex-column">
            <div>
                Πρέπει να συμπληρωθούν τα στοιχεία ασφάλισης. Παρακαλώ μεταβείτε στην ηλεκτρονική
                συνταγογράφηση.
            </div>
            <div className="mt-3" align="center">
                <button className="btn buttonPrimary" onClick={() => onYes()}>
                    Ναι
                </button>
                <button className="btn buttonDanger" onClick={() => onNo()}>
                    Όχι
                </button>
            </div>
        </div>
    );
}

function SearchPatient({ addTab = null }) {
    const [keyword, setKeyword] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [searchResults, setSearchResults] = useState([]);

    useEffect(() => {
        if (keyword.length < 3) return;
        let mounted = true;
        setError(null);
        setLoading(true);
        MediPlusUserService.getPatients(keyword)
            .then((response) => {
                if (mounted) {
                    setSearchResults(response.data);
                }
            })
            .catch((error) => {
                setError(error.response);
            })
            .finally(() => {
                setLoading(false);
            });
        return () => (mounted = false);
    }, [keyword]);

    const searchPatient = () => {
        if (keyword.length !== 11 && keyword.length !== 20) return;

        let request = {};
        if (keyword.length === 20) request.ekaa = keyword;
        else request.amka = keyword;

        MediPlusUserService.getPatient(request)
            .then(({ data }) => {
                if (data.activeInsurances.activeInsurance.length === 0) {
                    setNoActiveInsurancesPopUp(true);
                } else if (addTab !== null) addTab(data);
            })
            .catch((error) => {
                setError(error.response);
            });
    };

    const [noActiveInsurancesPopUp, setNoActiveInsurancesPopUp] = useState(false);

    return (
        <div className="insurInfoTab" id="insurAmka">
            <div className="flexBase">
                <TextField
                    label="Α.Μ.Κ.Α./Ε.Κ.Α.Α. Ασφαλισμένου"
                    id="amkaInsurSearch"
                    type="text"
                    className="searchInput"
                    name="amkaInsurSearch"
                    value={keyword}
                    onChange={(e) => {
                        setKeyword(e.target.value);
                    }}
                    error={error !== null ? true : false}
                    helperText={
                        error !== null
                            ? error.data.status === 584
                                ? "Δώσατε λάθος ΕΚΑΑ. Προσπαθήστε ξανά."
                                : "Δώσατε λάθος AMKA. Προσπαθήστε ξανά."
                            : null
                    }
                />
                <button
                    className="btn buttonPrimary"
                    style={{ marginLeft: 5 }}
                    onClick={searchPatient}
                >
                    Αναζήτηση
                </button>
            </div>
            <div className="searchResultsHolder">
                <div id="searchResultsWrap">
                    {keyword.length < 3 ? null : loading === true ? (
                        <Spinner align="center" animation="spinner-border" />
                    ) : (
                        searchResults.map((patient, index) => {
                            return (
                                <SearchItem
                                    key={index}
                                    firstname={patient.firstName}
                                    lastname={patient.lastName}
                                    amkaOrEkaa={patient.amka !== null ? patient.amka : patient.ekaa}
                                    address={patient.address}
                                    telephone={patient.telephone}
                                    addTab={addTab}
                                    setError={setError}
                                    setNoActiveInsurancesPopUp={setNoActiveInsurancesPopUp}
                                />
                            );
                        })
                    )}
                </div>
            </div>
            <p className="noMargin">* Συμπληρώνεται σε περίπτωση έμμεσου μέλους</p>
            <PopUp
                openPopUp={noActiveInsurancesPopUp}
                setOpenPopUp={setNoActiveInsurancesPopUp}
                title={"Στοιχεία Ασφάλισης"}
                maxWidth="sm"
                showCloseButton={false}
            >
                <NoActiveInsuranceRedirectPopUp
                    setNoActiveInsurancesPopUp={setNoActiveInsurancesPopUp}
                />
            </PopUp>
        </div>
    );
}

export default SearchPatient;
