const mainColumns = [
    { name: "Κωδικός ICD-10" },
    { name: "Τίτλος" },
    { name: "Περιγραφή" },
    { name: "" },
];

const insertedIcd10Columns = [{ name: "Κωδικός ICD-10" }, { name: "Διάγνωση" }, { name: "" }];

export { mainColumns, insertedIcd10Columns };
