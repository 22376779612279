import React, { useState } from "react";
import ReferencesTable from "./ReferencesTable/ReferencesTable";

function References() {
    // eslint-disable-next-line
    const [otherDoctorReferences, setOtherDoctorReferences] = useState([]);
    // eslint-disable-next-line
    const [references, setReferences] = useState([]);
    return (
        <div>
            <ReferencesTable title={"Παραπομπή από άλλον ιατρό"} references={references} />
            <br />
            <div align="center">
                <button className="btn buttonPrimary">Νέα Παραπομπή</button>
            </div>
            <ReferencesTable title={"Παραπομπές"} references={otherDoctorReferences} />
        </div>
    );
}

export default References;
