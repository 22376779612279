import axios from "axios";
import { baseUrl, getOptions } from "../config.js";

class MediPlusMembershipHistoryService {
    constructor(baseUrl) {
        this.basePath = "/membershiphistories";
        this.baseUrl = `${baseUrl}${this.basePath}`;
    }

    getUserMembershipHistories() {
        return axios(getOptions(`${this.baseUrl}`));
    }
}

export default new MediPlusMembershipHistoryService(baseUrl);
