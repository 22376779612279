import { Alert } from "@material-ui/lab";
import { useState } from "react";
import { locationMap } from "../../config";
import MediPlusUserService from "../../services/MediPlus/MediPlusUserService";
import Terms from "../Terms/Terms";
import { saveJwt } from "../Utilities/JwtUtilities";
import { getCurrentDateAndTime } from "../Utilities/Utilities";
import { findRole } from "./config";

function ConfirmNewTerms({ signInResponse }) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const confirm = (e) => {
        e.preventDefault();
        setLoading(true);
        setError(false);
        MediPlusUserService.updateUserDetailsAllWithToken(
            { acceptedTermsDate: getCurrentDateAndTime() },
            signInResponse.token
        )
            .then(({ data }) => {
                saveJwt(signInResponse.token);
                const role = findRole(signInResponse.authorities);
                sessionStorage.setItem("role", role);
                sessionStorage.setItem(
                    "membershipNotification",
                    signInResponse.membershipNotification
                );
                sessionStorage.setItem("membershipDiffDays", signInResponse.membershipDiffDays);
                window.location = locationMap[role];
            })
            .catch((err) => {
                // console.log(err.response);
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div>
            {error && <Alert severity="error"> Κάτι πήγε στραβά!</Alert>}
            <Terms />
            <div align="center">
                <button className="btn buttonPrimary" disabled={loading} onClick={confirm}>
                    Αποδέχομαι
                </button>
            </div>
        </div>
    );
}

export default ConfirmNewTerms;
