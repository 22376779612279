import React, { useState, useContext, useEffect } from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { steps } from "./config.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { Checkbox, CircularProgress, TextareaAutosize, TextField } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import ArrowBack from "@material-ui/icons/ArrowBack";
import ArrowForward from "@material-ui/icons/ArrowForward";
import IdikaTreatmentProtocolService from "../../../../../../services/MediPlus/Idika/IdikaTreatmentProtocolService.js";
import { PatientContext } from "../../../Esyntagografisi.js";
import PopUp from "../../../../../Utilities/PopUp.js";
import IdikaVisitService from "../../../../../../services/MediPlus/Idika/IdikaVisitService.js";
import { formatMidnightDaytime } from "../../../../../Utilities/Utilities";
import { KeyboardDatePicker } from "@material-ui/pickers";
import "./Protocol.css";

// this file contains all the steps that needs a treatment
// protocol to be created

function TreatmentProtocolDetails({ protocol }) {
    if (protocol === null) return null;
    return (
        <div>
            <div className="flexWrap mt-2 mb-2">
                <p className="infoP alignRight">Τίτλος</p>
                <p className="noMargin">{protocol.treatmentProtocol.title}</p>
            </div>
            <div className="flexWrap mt-2 mb-2">
                <p className="infoP alignRight">MDC</p>
                <p className="noMargin">{protocol.treatmentProtocol.mdc.description}</p>
            </div>
            <div className="flexWrap mt-2 mb-2">
                <p className="infoP alignRight">Περιγραφή</p>
                <p className="noMargin">{protocol.treatmentProtocol.description}</p>
            </div>
            <div className="flexWrap mt-2 mb-2">
                <p className="infoP alignRight">Σχετικό URL</p>
                <p className="noMargin">{protocol.treatmentProtocol.documentationUrl}</p>
            </div>
            <div className="flexWrap mt-2 mb-2">
                <p className="infoP alignRight">Σημειώσεις</p>
                <p className="noMargin">{protocol.treatmentProtocol.notes}</p>
            </div>
            <div className="flexWrap mt-2 mb-2">
                <p className="infoP alignRight">Ημ/νία Ένταξης στο Πρωτόκολλο</p>
                <p className="noMargin">{protocol.treatmentProtocol.creationDate}</p>
            </div>
            <div className="flexWrap mt-2 mb-2">
                <p className="infoP alignRight">Ημ/νία Τελευταίας Συνταγ/σης </p>
                <p className="noMargin">{protocol.lastUsedDate}</p>
            </div>
        </div>
    );
}

function AddNewProtocol({
    protocols,
    setProtocols,
    setAddProtocolPopUp,
    setNewProtocolIsInserted,
}) {
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [treatmentProtocols, setTreatmentProtocols] = useState(null);

    const checkIfProtocolAlreadyExists = (protocol) => {
        for (let p of protocols.treatmentProtocols) {
            if (p.treatmentProtocol.id === protocol.id) return true;
        }
        return false;
    };

    useEffect(() => {
        IdikaTreatmentProtocolService.getTreatmentProtocols()
            .then(({ data }) => {
                let treatProt = data.treatmentProtocols.filter((tp) => {
                    return checkIfProtocolAlreadyExists(tp) === false;
                });
                setTreatmentProtocols({ count: treatProt.length, treatmentProtocols: treatProt });
            })
            .catch((error) => {
                setIsError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
        // eslint-disable-next-line
    }, []);

    const [selectValue, setSelectValue] = useState("");
    const handleSelectChange = (e) => {
        e.preventDefault();
        setSelectValue(e.target.value);
    };

    const addProtocol = (e) => {
        e.preventDefault();
        let newProtocol = JSON.parse(selectValue);
        newProtocol.new = true;
        let newProtocols = [
            ...protocols.treatmentProtocols,
            {
                treatmentProtocol: newProtocol,
                treatmentProtocolICD10s: { treatmentProtocolICD10: [] },
            },
        ];
        setProtocols({ treatmentProtocols: newProtocols, count: newProtocols.length });
        setNewProtocolIsInserted(true);
        setAddProtocolPopUp(false);
    };

    return (
        <div>
            {isLoading ? (
                <CircularProgress />
            ) : isError ? (
                <Alert severity="error">Κάτι πήγε στραβά!</Alert>
            ) : (
                <div>
                    <form className="form-inline">
                        <select
                            className="form-select"
                            value={selectValue}
                            onChange={handleSelectChange}
                        >
                            <option value=""></option>
                            {treatmentProtocols.treatmentProtocols.map(
                                (treatmentProtocol, index) => {
                                    return (
                                        <option
                                            key={index}
                                            value={JSON.stringify(treatmentProtocol)}
                                        >
                                            {treatmentProtocol.title}
                                        </option>
                                    );
                                }
                            )}
                        </select>
                        <button
                            className="btn buttonPrimary"
                            disabled={selectValue === "" ? true : false}
                            onClick={(e) => addProtocol(e)}
                        >
                            Προσθήκη
                        </button>
                    </form>
                    {selectValue !== "" && (
                        <div>
                            <div className="flexWrap mt-2 mb-2">
                                <p className="infoP alignRight">MDC</p>
                                <p className="noMargin">
                                    {JSON.parse(selectValue).mdc.description}
                                </p>
                            </div>
                            <div className="flexWrap mt-2 mb-2">
                                <p className="infoP alignRight">Περιγραφή</p>
                                <p className="noMargin">{JSON.parse(selectValue).notes}</p>
                            </div>
                            <div className="flexWrap mt-2 mb-2">
                                <p className="infoP alignRight">Ημ/νία Ένταξης στο Πρωτόκολλο</p>
                                <p className="noMargin">{JSON.parse(selectValue).creationDate}</p>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

function ProtocolSelection({ setActiveStep, protocolState, setProtocolState }) {
    const columns = [
        { name: "Θεραπευτικό Πρωτοκόλλου" },
        { name: "Ημ/νία Ένταξης στο Πρωτόκολλο" },
        { name: "MDC" },
        { name: "Περιγραφή" },
        { name: "Ημ/νία Τελευταίας Συνταγ/σης" },
        { name: "Ενεργό" },
        { name: "" },
        { name: "" },
        { name: "" },
    ];
    const patient = useContext(PatientContext);

    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        IdikaTreatmentProtocolService.getPatientTreatmentProtocols(patient.amka)
            .then(({ data }) => {
                setData(data);
            })
            .catch(() => {
                setIsError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
        // eslint-disable-next-line
    }, []);

    const trimString = (string) => {
        if (string.length > 40) return string.substring(0, 40) + "...";
        else return string;
    };

    const [selectedProtocol, setSelectedProtocol] = useState(null);

    const [protocolDetailsPopUp, setProtocolDetailsPopUp] = useState(false);
    const onShowDetails = (protocol) => {
        setSelectedProtocol(protocol);
        setProtocolDetailsPopUp(true);
    };

    const [addProtocolPopUp, setAddProtocolPopUp] = useState(false);

    const [newProtocolIsInserted, setNewProtocolIsInserted] = useState(false);

    const onUseProtocol = (protocol) => {
        setProtocolState({ ...protocolState, protocol: protocol });
        setActiveStep(1);
    };

    const deleteTreatmentProtocol = (treatmentProtocol) => {
        let newTreatmentProtocols = data.treatmentProtocols.filter(
            (treatProtocol) =>
                treatProtocol.treatmentProtocol.id !== treatmentProtocol.treatmentProtocol.id
        );
        setData({ ...data, treatmentProtocols: newTreatmentProtocols });
        setNewProtocolIsInserted(false);
    };

    return (
        <div>
            {isLoading ? (
                <CircularProgress />
            ) : isError ? (
                <Alert severity="error">Κάτι πήγε στραβά!</Alert>
            ) : (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((column, index) => {
                                    return (
                                        <TableCell key={index}>
                                            <b>{column.name}</b>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.treatmentProtocols.map((treatmentProtocol, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>
                                            {treatmentProtocol.treatmentProtocol.new === true ? (
                                                <b>
                                                    {`${treatmentProtocol.treatmentProtocol.code} ${treatmentProtocol.treatmentProtocol.title}}`}
                                                </b>
                                            ) : (
                                                `${treatmentProtocol.treatmentProtocol.code} ${treatmentProtocol.treatmentProtocol.title}`
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {treatmentProtocol.treatmentProtocol.new === true ? (
                                                <b>{treatmentProtocol.insertDate}</b>
                                            ) : (
                                                treatmentProtocol.insertDate
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {treatmentProtocol.treatmentProtocol.new === true ? (
                                                <b>
                                                    {trimString(
                                                        treatmentProtocol.treatmentProtocol.mdc
                                                            .description
                                                    )}
                                                </b>
                                            ) : (
                                                trimString(
                                                    treatmentProtocol.treatmentProtocol.mdc
                                                        .description
                                                )
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {treatmentProtocol.treatmentProtocol.new === true ? (
                                                <b>
                                                    {trimString(
                                                        treatmentProtocol.treatmentProtocol.notes
                                                    )}
                                                </b>
                                            ) : (
                                                trimString(
                                                    treatmentProtocol.treatmentProtocol.notes
                                                )
                                            )}
                                        </TableCell>
                                        <TableCell>{treatmentProtocol.lastUsedDate}</TableCell>
                                        <TableCell>
                                            {treatmentProtocol.active && (
                                                <i className="fa fa-check"></i>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            <button
                                                className="btn buttonPrimary"
                                                onClick={() => onShowDetails(treatmentProtocol)}
                                            >
                                                Λεπτομέρειες
                                            </button>
                                        </TableCell>
                                        <TableCell>
                                            <button
                                                className="btn buttonPrimary"
                                                disabled={
                                                    newProtocolIsInserted === true &&
                                                    treatmentProtocol.treatmentProtocol.new !== true
                                                        ? true
                                                        : false
                                                }
                                                onClick={() => onUseProtocol(treatmentProtocol)}
                                            >
                                                Χρήση
                                            </button>
                                        </TableCell>
                                        <TableCell>
                                            {/* {treatmentProtocol.lastUsedDate === "" && (
                                                <button className="btn buttonDanger">
                                                    Διαγραφή
                                                </button>
                                            )} */}
                                            {newProtocolIsInserted === true &&
                                                treatmentProtocol.treatmentProtocol.new ===
                                                    true && (
                                                    <button
                                                        className="btn buttonDanger"
                                                        onClick={() =>
                                                            deleteTreatmentProtocol(
                                                                treatmentProtocol
                                                            )
                                                        }
                                                    >
                                                        Διαγραφή
                                                    </button>
                                                )}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <div className="mt-3 mb-3">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setAddProtocolPopUp(true)}
                    disabled={newProtocolIsInserted === true ? true : false}
                >
                    Προσθηκη νεου
                </Button>
            </div>
            <PopUp
                openPopUp={protocolDetailsPopUp}
                setOpenPopUp={setProtocolDetailsPopUp}
                title={"Λεπτομέρειες Πρωτοκόλλου"}
                maxWidth="md"
            >
                <TreatmentProtocolDetails protocol={selectedProtocol} />
            </PopUp>
            <PopUp
                openPopUp={addProtocolPopUp}
                setOpenPopUp={setAddProtocolPopUp}
                title={"Προσθήκη Νέου Πρωτοκόλλου"}
                maxWidth="md"
            >
                <AddNewProtocol
                    protocols={data}
                    setProtocols={setData}
                    setAddProtocolPopUp={setAddProtocolPopUp}
                    setNewProtocolIsInserted={setNewProtocolIsInserted}
                />
            </PopUp>
        </div>
    );
}

function AddNewIcd10({ protocolState, icd10s, setIcd10s, setAddIcd10PopUp }) {
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [newIcd10s, setNewIcd10s] = useState(null);

    const checkIfIcd10AlreadyExists = (icd10) => {
        for (let i of icd10s) {
            if (i.icd10.id === icd10.id) return true;
        }
        return false;
    };

    useEffect(() => {
        IdikaTreatmentProtocolService.getTreatmentProtocolIcd10s(
            protocolState.protocol.treatmentProtocol.id
        )
            .then(({ data }) => {
                setNewIcd10s(
                    data.filter((icd10) => {
                        return checkIfIcd10AlreadyExists(icd10) === false;
                    })
                );
            })
            .catch((error) => {
                setIsError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
        // eslint-disable-next-line
    }, []);

    const [selectValue, setSelectValue] = useState("");
    const handleSelectChange = (e) => {
        e.preventDefault();
        setSelectValue(e.target.value);
    };

    const addIcd10 = (e) => {
        e.preventDefault();
        setIcd10s([...icd10s, { icd10: JSON.parse(selectValue) }]);
        setAddIcd10PopUp(false);
    };

    return (
        <div>
            {isLoading ? (
                <CircularProgress />
            ) : isError ? (
                <Alert severity="error">Κάτι πήγε στραβά!</Alert>
            ) : (
                <div>
                    {newIcd10s.length === 0 ? (
                        <Alert severity="info">Δεν υπάρχουν άλλες Διαγνώσεις</Alert>
                    ) : (
                        <form className="form-inline">
                            <select
                                className="form-select"
                                value={selectValue}
                                onChange={handleSelectChange}
                            >
                                <option value=""></option>
                                {newIcd10s.map((newIcd10, index) => {
                                    return (
                                        <option key={index} value={JSON.stringify(newIcd10)}>
                                            {newIcd10.title}
                                        </option>
                                    );
                                })}
                            </select>
                            <button
                                className="btn buttonPrimary"
                                disabled={selectValue === "" ? true : false}
                                onClick={(e) => addIcd10(e)}
                            >
                                Προσθήκη
                            </button>
                        </form>
                    )}
                </div>
            )}
        </div>
    );
}

function Diagnoses({ protocolState, setProtocolState }) {
    const columns = [{ name: "ICD-10" }, { name: "" }];

    const [icd10s, setIcd10s] = useState(
        Array.isArray(protocolState.protocol.treatmentProtocolICD10s.treatmentProtocolICD10)
            ? protocolState.protocol.treatmentProtocolICD10s.treatmentProtocolICD10
            : [protocolState.protocol.treatmentProtocolICD10s.treatmentProtocolICD10]
    );

    const onDelete = (index) => {
        setIcd10s(
            icd10s.filter((icd10, idx) => {
                return index !== idx;
            })
        );
    };

    useEffect(() => {
        setProtocolState({
            ...protocolState,
            treatmentProtocolICD10s: { treatmentProtocolICD10: icd10s },
        });
        // eslint-disable-next-line
    }, [icd10s]);

    const [addIcd10PopUp, setAddIcd10PopUp] = useState(false);

    return (
        <div>
            <div className="form-inline">
                <p className="infoP">Θεραπευτικό Πρωτόκολλο</p>
                <h5>{protocolState.protocol.treatmentProtocol.title}</h5>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((column, index) => {
                                return (
                                    <TableCell key={index}>
                                        <b>{column.name}</b>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {icd10s.map((icd10, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell>{icd10.icd10.title}</TableCell>
                                    <TableCell>
                                        <button
                                            className="btn buttonDanger"
                                            onClick={() => onDelete(index)}
                                        >
                                            Διαγραφή
                                        </button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="mt-3 mb-3">
                <Button variant="contained" color="primary" onClick={() => setAddIcd10PopUp(true)}>
                    Προσθηκη κωδικου ICD10
                </Button>
            </div>
            <PopUp
                openPopUp={addIcd10PopUp}
                setOpenPopUp={setAddIcd10PopUp}
                title={"Προσθήκη ICD-10"}
                maxWidth="md"
            >
                <AddNewIcd10
                    protocolState={protocolState}
                    icd10s={icd10s}
                    setIcd10s={setIcd10s}
                    setAddIcd10PopUp={setAddIcd10PopUp}
                />
            </PopUp>
        </div>
    );
}

function DefineParameters({ protocolState, setProtocolState }) {
    const columns = [
        { name: "Παράμετρος" },
        { name: "Προηγούμενη τιμή" },
        { name: "Τιμή Παραμέτρου" },
        { name: "ΑΜΚΑ Διενεργήσαντος Ιατρού" },
        { name: "Ονοματεπώνυμο Διενεργήσαντος Ιατρού" },
        { name: "Ημερ. Μέτρησης" },
        { name: "Μονάδα Μέτρησης / Όρια Τιμών" },
    ];

    const treatmentProtocolId = protocolState.protocol.treatmentProtocol.id;
    const patient = useContext(PatientContext);

    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [parameters, setParameters] = useState(null);
    useEffect(() => {
        IdikaTreatmentProtocolService.getTreatmentProtocolParameters(
            treatmentProtocolId,
            patient.amka
        )
            .then(({ data }) => {
                //if dropdown give it the first value that is set in the dropdown
                for (let param of data.parameters) {
                    if (
                        param.treatmentProtocolParameter &&
                        param.value === "" &&
                        param.protocolParamPool.control === "DROPDOWN"
                    ) {
                        let options = param.protocolParamPool.data.split("#");
                        let value = options[0].split(";")[0];
                        param.value = value;
                    }
                }
                setParameters(data);
                setProtocolState({ ...protocolState, parameters: data });
            })
            .catch((error) => {
                setIsError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
        // eslint-disable-next-line
    }, []);

    const handleParameterChange = (e) => {
        e.preventDefault();
        let { name, value } = e.target;
        let parameters = protocolState.parameters;
        let parameter = parameters.parameters.filter(
            (element) =>
                element.protocolParamPool.control === "TEXT" &&
                element.protocolParamPool.id === name
        )[0];
        parameter.value = value;
        parameters.parameters.filter(
            (element) =>
                element.protocolParamPool.control === "TEXT" &&
                element.protocolParamPool.id === name
        )[0] = parameter;
        setProtocolState({ ...protocolState, parameters: parameters });
    };

    const handleParameterCheckboxChange = (name) => {
        let parameters = protocolState.parameters;
        let parameter = parameters.parameters.filter(
            (element) =>
                element.protocolParamPool.control === "CHECK" &&
                element.protocolParamPool.id === name
        )[0];
        parameter.value = parameter.value === 0 ? 1 : 0;
        parameters.parameters.filter(
            (element) =>
                element.protocolParamPool.control === "CHECK" &&
                element.protocolParamPool.id === name
        )[0] = parameter;
        setProtocolState({ ...protocolState, parameters: parameters });
    };

    const handleDropdownChange = (e) => {
        e.preventDefault();
        let { name, value } = e.target;
        let parameters = protocolState.parameters;
        let parameter = parameters.parameters.filter(
            (element) =>
                element.protocolParamPool.control === "DROPDOWN" &&
                element.protocolParamPool.id === name
        )[0];
        parameter.value = value;
        parameters.parameters.filter(
            (element) =>
                element.protocolParamPool.control === "DROPDOWN" &&
                element.protocolParamPool.id === name
        )[0] = parameter;
        setProtocolState({ ...protocolState, parameters: parameters });
    };

    const determinePrevValue = (parameter) => {
        if (parameter.protocolParamPool.control === "CHECK")
            return parameter.previousValue === 0 ? "_" : <i className="fa fa-check"></i>;
        else return parameter.previousValue;
    };

    const determineParameterInput = (parameter, index) => {
        if (parameter.protocolParamPool.control === "DROPDOWN") {
            let options = parameter.protocolParamPool.data.split("#");
            let opts = options.map((option, index) => {
                return (
                    <option key={index} value={option.split(";")[0]}>
                        {option.split(";")[1]}
                    </option>
                );
            });
            return (
                <select
                    name={parameter.protocolParamPool.id}
                    disabled={parameter.readOnly}
                    value={parameter.value}
                    onChange={handleDropdownChange}
                >
                    {opts}
                </select>
            );
        } else if (parameter.protocolParamPool.control === "CHECK") {
            return (
                <FormControlLabel
                    control={
                        <Checkbox
                            name={parameter.protocolParamPool.id}
                            checked={parameter.value === 0 ? false : true}
                            onChange={() =>
                                handleParameterCheckboxChange(parameter.protocolParamPool.id)
                            }
                            color="primary"
                            disabled={parameter.readOnly}
                        />
                    }
                />
            );
        } else if (parameter.protocolParamPool.control === "TEXT") {
            return (
                <input
                    type={parameter.protocolParamPool.dataType === "NUMBER" ? "number" : "text"}
                    name={parameter.protocolParamPool.id}
                    onChange={handleParameterChange}
                    disabled={parameter.readOnly}
                    value={parameter.value}
                />
            );
        } else return parameter.value;
    };

    const handleDateChange = (name, value) => {
        let parameters = protocolState.parameters;
        let parameter = parameters.parameters[name];
        parameter.measuredDate = formatMidnightDaytime(value);
        parameters.parameters[name] = parameter;
        setProtocolState({ ...protocolState, parameters: parameters });
    };

    const determineMeasuredDate = (parameter, index) => {
        if (parameter.readOnly === true) return null;
        else
            return (
                <KeyboardDatePicker
                    format="dd/MM/yyyy"
                    label="Ημερ. Μέτρησης"
                    value={
                        parameter.measuredDate === "" || parameter.measuredDate === null
                            ? null
                            : parameter.measuredDate.split(" ")[0]
                    }
                    onChange={(date) => {
                        handleDateChange(index.toString(), date);
                    }}
                    placeholder="dd/mm/yyyy"
                    disableToolbar
                    className="datePicker"
                />
            );
    };

    const trimString = (string) => {
        if (string.length > 40) return string.substring(0, 40) + "...";
        else return string;
    };

    const handleAmkaChange = (e) => {
        e.preventDefault();
        let { name, value } = e.target;
        let parameters = protocolState.parameters;
        let parameter = parameters.parameters[name];
        parameter.amka = value;
        parameters.parameters[name] = parameter;
        setProtocolState({ ...protocolState, parameters: parameters });
    };

    return (
        <div>
            {isLoading ? (
                <CircularProgress />
            ) : isError ? (
                <Alert severity="error">Κάτι πήγε στραβά!</Alert>
            ) : (
                <div className="mb-3">
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {columns.map((column, index) => {
                                        return (
                                            <TableCell key={index} align="center">
                                                <b>{column.name}</b>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {parameters.parameters
                                    .filter(
                                        (parameter) =>
                                            !(
                                                parameter.treatmentProtocolParameter ===
                                                    undefined ||
                                                parameter.treatmentProtocolParameter.visible ===
                                                    false
                                            )
                                    )
                                    .map((parameter, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell align="center">
                                                    <div className="d-flex flex-column">
                                                        {parameter.protocolParamPool.label}
                                                        <span style={{ color: "blue" }}>
                                                            {parameter.protocolParamPool.extraInfo}
                                                        </span>
                                                    </div>
                                                </TableCell>
                                                <TableCell align="center">
                                                    {determinePrevValue(parameter)}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {determineParameterInput(parameter, index)}
                                                </TableCell>
                                                <TableCell>
                                                    {parameter.treatmentProtocolParameter
                                                        .amkaIsVisible === true ? (
                                                        <input
                                                            type="text"
                                                            name={index.toString()}
                                                            onChange={handleAmkaChange}
                                                            defaultValue={parameter.amka.toString()}
                                                        />
                                                    ) : null}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {`${parameter.firstName} ${parameter.lastName}`}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {determineMeasuredDate(parameter, index)}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {trimString(
                                                        parameter.protocolParamPool.extraLabel
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            )}
        </div>
    );
}

function DefineParametersContinue({ protocolState, setProtocolState }) {
    const columns = [
        { name: "Παράμετρος" },
        { name: "Προηγούμενη τιμή" },
        { name: "Τιμή Παραμέτρου" },
        { name: "ΑΜΚΑ" },
        { name: "Ονοματεπώνυμο" },
        { name: "Ημερ. Μέτρησης" },
        { name: "Μονάδα Μέτρησης / Όρια Τιμών" },
    ];

    const determinePrevValue = (parameter) => {
        if (parameter.protocolParamPool.control === "CHECK")
            return parameter.previousValue === 0 ? "_" : <i className="fa fa-check"></i>;
        else return parameter.previousValue;
    };

    const determineParameterInput = (parameter) => {
        if (parameter.protocolParamPool.control === "DROPDOWN") {
            let options = parameter.protocolParamPool.data.split("#");
            let opts = options.map((option, index) => {
                return (
                    <option key={index} value={option.split(";")[0]}>
                        {option.split(";")[1]}
                    </option>
                );
            });
            return (
                <select disabled={parameter.readOnly} value={parameter.value}>
                    {opts}
                </select>
            );
        } else if (parameter.protocolParamPool.control === "CHECK")
            return (
                <Checkbox
                    checked={parameter.value === 0 ? false : true}
                    color="primary"
                    disabled={parameter.readOnly}
                />
            );
        else if (parameter.protocolParamPool.control === "TEXT") {
            return (
                <input
                    type={parameter.protocolParamPool.dataType === "NUMBER" ? "number" : "text"}
                    disabled={parameter.readOnly}
                    defaultValue={parameter.value}
                />
            );
        } else return parameter.value;
    };

    const determineMeasuredDate = (parameter) => {
        if (parameter.readOnly === true) return null;
        else
            return (
                <TextField
                    label="Ημερ. Μέτρησης"
                    type="date"
                    name="decisionDate"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            );
    };

    const trimString = (string) => {
        if (string.length > 40) return string.substring(0, 40) + "...";
        else return string;
    };

    return (
        <div className="mb-3">
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((column, index) => {
                                return (
                                    <TableCell key={index} align="center">
                                        <b>{column.name}</b>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {protocolState.postedParameters.parameters
                            .filter(
                                (parameter) =>
                                    !(
                                        parameter.treatmentProtocolParameter === undefined ||
                                        parameter.treatmentProtocolParameter.visible === false
                                    )
                            )
                            .map((parameter, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell align="center">
                                            <div className="d-flex flex-column">
                                                {parameter.protocolParamPool.label}
                                                <span style={{ color: "blue" }}>
                                                    {parameter.protocolParamPool.extraInfo}
                                                </span>
                                            </div>
                                        </TableCell>
                                        <TableCell align="center">
                                            {determinePrevValue(parameter)}
                                        </TableCell>
                                        <TableCell align="center">
                                            {determineParameterInput(parameter)}
                                        </TableCell>
                                        <TableCell>
                                            {parameter.treatmentProtocolParameter.amkaIsVisible ===
                                            true ? (
                                                <input
                                                    type="text"
                                                    defaultValue={parameter.amka.toString()}
                                                    disabled={true}
                                                />
                                            ) : null}
                                        </TableCell>
                                        <TableCell align="center">
                                            {`${parameter.firstName} ${parameter.lastName}`}
                                        </TableCell>
                                        <TableCell align="center">
                                            {determineMeasuredDate(parameter)}
                                        </TableCell>
                                        <TableCell align="center">
                                            {trimString(parameter.protocolParamPool.extraLabel)}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

function NewStep({ protocolState, setProtocolState }) {
    const [value, setValue] = useState("");

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const treatmentProtocolId = protocolState.protocol.treatmentProtocol.id;
    const patient = useContext(PatientContext);

    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [steps, setSteps] = useState(null);
    useEffect(() => {
        IdikaTreatmentProtocolService.getTreatmentProtocolSuggestedSteps(
            treatmentProtocolId,
            patient.amka,
            protocolState.postedParameters
        )
            .then(({ data }) => {
                setSteps(data);
            })
            .catch((error) => {
                setIsError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
        // eslint-disable-next-line
    }, []);

    const [stepNote, setStepNote] = useState("");

    useEffect(() => {
        if (value === "") return;
        let foundStep = steps.steps.find((step) => step.description === value);
        setProtocolState({ ...protocolState, selectedStep: foundStep });
        // eslint-disable-next-line
    }, [value]);

    return (
        <div className="mb-3">
            <div className="form-inline mb-3">
                <p className="infoP">Θεραπευτικό Πρωτόκολλο</p>
                <h5>{protocolState.protocol.treatmentProtocol.title}</h5>
            </div>
            {isLoading ? (
                <CircularProgress />
            ) : isError ? (
                <Alert severity="error">Κάτι πήγε στραβά!</Alert>
            ) : (
                <div>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">* Βήμα Αγωγής</FormLabel>
                        <RadioGroup value={value} onChange={handleChange}>
                            {steps.steps.map((step, index) => {
                                return (
                                    <FormControlLabel
                                        key={index}
                                        value={step.description}
                                        control={<Radio color="primary" />}
                                        label={step.description}
                                        disabled={!step.active}
                                    />
                                );
                            })}
                        </RadioGroup>
                    </FormControl>
                    <div className="flexBase">
                        <p className="infoP">Σχόλιο</p>
                        <TextareaAutosize
                            value={stepNote}
                            onChange={(e) => setStepNote(e.target.value)}
                            rowsMin="6"
                            cols="80"
                            placeholder="(Ελεύθερο Κείμενο)"
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

function ActiveSubstancesSelection({ protocolState, setProtocolState }) {
    const columns = [{ name: "Περιγραφή" }, { name: "Σχόλια" }];
    const columns2 = [{ name: "Κωδικός ATC" }, { name: "Περιγραφή" }, { name: "Περιεκτικότητα" }];

    const treatmentProtocolId = protocolState.protocol.treatmentProtocol.id;
    const patient = useContext(PatientContext);

    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [groups, setGroups] = useState(null);
    const [substances, setSubstances] = useState([]);
    useEffect(() => {
        IdikaTreatmentProtocolService.getTreatmentProtocolActiveSubstanceGroups(
            treatmentProtocolId,
            protocolState.selectedStep.id,
            patient.amka,
            protocolState.postedParameters
        )
            .then(({ data }) => {
                data.groups.sort((a, b) => (a.ordinal > b.ordinal ? 1 : -1));
                data.groups.forEach((element) => {
                    element.protocolGroupSubstances.sort((a, b) =>
                        a.description > b.description ? 1 : -1
                    );
                });
                setGroups(data);
                if (data.groups.length > 0) {
                    setSubstances(
                        data.groups[0].protocolGroupSubstances.map((sub) => {
                            sub.groupId = data.groups[0].id;
                            return sub;
                        })
                    );
                }
                setProtocolState({ ...protocolState, groups: data });
            })
            .catch((error) => {
                setIsError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
        // eslint-disable-next-line
    }, []);

    const [selectedGroupSubstance, setSelectedGroupSubstance] = useState(0);
    const [selectedSubstance, setSelectedSubstance] = useState(0);

    const handleOnClickGroupSubstance = (index) => {
        let newSubstancesArray = [];
        for (let sub of groups.groups[index].protocolGroupSubstances) {
            let foundIndex = selectedSubstances.find((selSub) => selSub.id === sub.id);
            if (foundIndex === undefined) {
                sub.groupId = groups.groups[index].id;
                newSubstancesArray.push(sub);
            }
        }
        if (newSubstancesArray.length === 0) setSelectedSubstance(-1);
        else setSelectedSubstance(0);
        setSubstances(newSubstancesArray);
        setSelectedGroupSubstance(index);
    };

    const [selectedSubstances, setSelectedSubstances] = useState([]);

    const [selectedSubstanceFromSelectedSubstances, setSelectedSubstanceFromSelectedSubstances] =
        useState(-1);

    const addSubstance = (substance) => {
        if (substances.length === 1) setSelectedSubstance(-1);
        else setSelectedSubstance(0);
        let newSelectedSubStancesArray = [...selectedSubstances, substance];
        let newSubStancesArray = substances.filter((sub) => sub.id !== substance.id);
        setSubstances(newSubStancesArray);
        setSelectedSubstances(newSelectedSubStancesArray);
        setSelectedSubstanceFromSelectedSubstances(0);
    };

    const deleteSubstance = (substance) => {
        if (selectedSubstances.length === 1) setSelectedSubstanceFromSelectedSubstances(-1);
        else setSelectedSubstanceFromSelectedSubstances(0);
        let newSelectedSubStancesArray = selectedSubstances.filter(
            (sub) => sub.id !== substance.id
        );
        setSelectedSubstances(newSelectedSubStancesArray);
        if (substance.groupId === groups.groups[selectedGroupSubstance].id) {
            setSubstances([substance, ...substances]);
            setSelectedSubstance(0);
        }
    };

    useEffect(() => {
        setProtocolState({ ...protocolState, selectedSubstances: selectedSubstances });
        // eslint-disable-next-line
    }, [selectedSubstances]);

    return (
        <div>
            {isLoading ? (
                <CircularProgress />
            ) : isError ? (
                <Alert severity="error">Κάτι πήγε στραβά!</Alert>
            ) : (
                <div>
                    <div className="mb-3">
                        <div className="form-inline mb-3">
                            <p className="infoP">Βήμα Αγωγής</p>
                            <h6>{protocolState.selectedStep.description}</h6>
                        </div>
                        <h5>Oμάδες Ουσιών</h5>
                        <TableContainer
                            component={Paper}
                            style={{ maxHeight: "250px", overflowY: "auto" }}
                        >
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column, index) => {
                                            return (
                                                <TableCell key={index}>
                                                    <b>{column.name}</b>
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {groups.groups.map((group, index) => {
                                        return (
                                            <TableRow
                                                key={index}
                                                style={{
                                                    backgroundColor:
                                                        index === selectedGroupSubstance
                                                            ? "#E0E0E0"
                                                            : "white",
                                                }}
                                                onClick={() => handleOnClickGroupSubstance(index)}
                                            >
                                                <TableCell>{group.description}</TableCell>
                                                <TableCell>{group.doctorComments}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className="mt-2 mb-2">{groups.description}</div>

                        <div className="d-flex justify-content-between">
                            <TableContainer
                                component={Paper}
                                style={{ maxHeight: "300px", overflowY: "auto" }}
                            >
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {columns2.map((column, index) => {
                                                return (
                                                    <TableCell key={index}>
                                                        <b>{column.name}</b>
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {substances.map((substance, index) => {
                                            return (
                                                <TableRow
                                                    key={index}
                                                    style={{
                                                        backgroundColor:
                                                            index === selectedSubstance
                                                                ? "#E0E0E0"
                                                                : "white",
                                                    }}
                                                    onClick={() => setSelectedSubstance(index)}
                                                >
                                                    <TableCell>{substance.atcCode}</TableCell>
                                                    <TableCell>{substance.description}</TableCell>
                                                    <TableCell>
                                                        {substance.displayContent}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <div>
                                <IconButton
                                    color="primary"
                                    onClick={() => addSubstance(substances[selectedSubstance])}
                                    disabled={selectedSubstance === -1 ? true : false}
                                >
                                    <ArrowForward />
                                </IconButton>
                                <IconButton
                                    color="primary"
                                    onClick={() =>
                                        deleteSubstance(
                                            selectedSubstances[
                                                selectedSubstanceFromSelectedSubstances
                                            ]
                                        )
                                    }
                                    disabled={
                                        selectedSubstanceFromSelectedSubstances === -1
                                            ? true
                                            : false
                                    }
                                >
                                    <ArrowBack />
                                </IconButton>
                            </div>
                            <TableContainer
                                component={Paper}
                                style={{ maxHeight: "300px", overflowY: "auto" }}
                            >
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {columns2.map((column, index) => {
                                                return (
                                                    <TableCell key={index}>
                                                        <b>{column.name}</b>
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {selectedSubstances.map((substance, index) => {
                                            return (
                                                <TableRow
                                                    key={index}
                                                    style={{
                                                        backgroundColor:
                                                            index ===
                                                            selectedSubstanceFromSelectedSubstances
                                                                ? "#E0E0E0"
                                                                : "white",
                                                    }}
                                                    onClick={() =>
                                                        setSelectedSubstanceFromSelectedSubstances(
                                                            index
                                                        )
                                                    }
                                                >
                                                    <TableCell>{substance.atcCode}</TableCell>
                                                    <TableCell>{substance.description}</TableCell>
                                                    <TableCell>
                                                        {substance.displayContent}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

function ProcessCompletion({
    protocolState,
    addPrescriptionState,
    setAddPrescriptionState,
    tabItem,
}) {
    const columns = [
        { name: "Περιγραφή Ομάδας" },
        { name: "Κωδικός ATC" },
        { name: "Περιγραφή" },
        { name: "Περιεκτικότητα" },
    ];

    const protocol = protocolState.finalTreatmentProtocol.PatientTreatmentProtocol;
    const selectedSubstances =
        protocolState.selectedSubstances.length === 0
            ? protocolState.finalTreatmentProtocol.PatientTreatmentProtocol.canRepeatStep
                  .stepSubstances.stepSubstance
            : protocolState.selectedSubstances;
    const selectedSubstancesFromNewStep =
        protocolState.selectedSubstances.length === 0 ? false : true;
    const step =
        protocolState.selectedSubstances.length === 0
            ? protocolState.finalTreatmentProtocol.PatientTreatmentProtocol.canRepeatStep
            : protocol.steps.step[0];
    const groups = protocolState.groups.groups;
    const getGroupNameByGroupId = (groupId) => {
        let group = groups.find((group) => group.id === groupId);
        return group.description;
    };

    useEffect(() => {
        IdikaVisitService.getTreatmentProtocolSteps(tabItem.visitDetails.id).then(({ data }) => {
            const oldProtocols = addPrescriptionState.insertedTreatmentProtocols.treatmentProtocols;
            const newProtocols = [];
            for (let value of data.treatmentProtocols) {
                const isProtocolAdded = oldProtocols.find(
                    (element) =>
                        value.patientStep.id === element.patientStep.id &&
                        value.visitPastId === element.visitPastId
                );
                if (isProtocolAdded === undefined) {
                    newProtocols.push(value);
                }
            }

            const finalProtocols = newProtocols.concat(oldProtocols);

            const newInsertedTreatmentProtocols = {
                treatmentProtocols: finalProtocols,
                count: finalProtocols.length,
            };

            setAddPrescriptionState({
                ...addPrescriptionState,
                insertedTreatmentProtocols: newInsertedTreatmentProtocols,
            });
        });
        // eslint-disable-next-line
    }, []);

    return (
        <div className="mb-3">
            <div className="d-flex flex-row">
                <div className="mb-3">
                    <div className="form-inline">
                        <p className="infoP">Title</p>
                        <span>{protocol.treatmentProtocol.title}</span>
                    </div>
                    <div className="form-inline">
                        <p className="infoP">Ημ/νία Ένταξης στο Πρωτόκολλο </p>
                        <span>{protocol.insertDate}</span>
                    </div>
                    <div className="form-inline">
                        <p className="infoP">Ημ/νία Τελευταίας Συνταγ/σης </p>
                        <span>{protocol.lastUsedDate}</span>
                    </div>
                    <div className="form-inline">
                        <p className="infoP">Περιγραφή</p>
                        <span>{protocol.treatmentProtocol.description}</span>
                    </div>
                    <div className="form-inline">
                        <p className="infoP">Σημειώσεις</p>
                        <span>{protocol.treatmentProtocol.notes}</span>
                    </div>
                    <div className="form-inline">
                        <p className="infoP">Σχόλιο</p>
                        <span></span>
                    </div>
                </div>
                <div className="mb-3">
                    <div className="form-inline">
                        <p className="infoP">Βήμα Αγωγής </p>
                        <span>{step.step.description}</span>
                    </div>
                    <div className="form-inline">
                        <p className="infoP">Ημ/νία Ένταξης στο Βήμα </p>
                        <span>{step.insertDate}</span>
                    </div>
                    <div className="form-inline">
                        <p className="infoP">Ημ/νία Τελευταίας Συνταγ/σης </p>
                        <span>{step.lastUsedDate}</span>
                    </div>
                </div>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((column, index) => {
                                return (
                                    <TableCell key={index}>
                                        <b>{column.name}</b>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {selectedSubstances.map((selectedSubstance, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell>
                                        {selectedSubstancesFromNewStep === true
                                            ? getGroupNameByGroupId(selectedSubstance.groupId)
                                            : null}
                                    </TableCell>
                                    <TableCell>{selectedSubstance.atcCode}</TableCell>
                                    <TableCell>{selectedSubstance.description}</TableCell>
                                    <TableCell>{selectedSubstance.displayContent}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

function StepActiveSubstances({ step, protocolState }) {
    const columns = ["Περιγραφή Ομάδας", "Κωδικός ATC", "Περιγραφή", "Περιεκτικότητα"];
    return (
        <div>
            <div className="flexBoxInfoWrap">
                <div className="flexColumn">
                    <div className="flexWrap">
                        <p className="infoP alignRight">Τίτλος</p>
                        <p className="noMargin" id="insurdFullname">
                            <b>{step.description}</b>
                        </p>
                    </div>
                    <div className="flexWrap">
                        <p className="infoP alignRight">Ημ/νία Ένταξης στο Πρωτόκολλο</p>
                        <p className="noMargin" id="insurdFullname">
                            {protocolState.protocol.insertDate}
                        </p>
                    </div>
                    <div className="flexWrap">
                        <p className="infoP alignRight">Ημ/νία Τελευταίας Συνταγ/σης</p>
                        <p className="noMargin" id="insurdFullname">
                            {protocolState.protocol.lastUsedDate}
                        </p>
                    </div>
                </div>
                <div className="flexColumn">
                    <div className="flexWrap">
                        <p className="infoP alignRight">Βήμα Αγωγής </p>
                        <p className="noMargin" id="insurdFullname">
                            {step.step.description}
                        </p>
                    </div>
                    <div className="flexWrap">
                        <p className="infoP alignRight">Ημ/νία Ένταξης στο Βήμα </p>
                        <p className="noMargin" id="insurdFullname">
                            {step.insertDate}
                        </p>
                    </div>
                    <div className="flexWrap">
                        <p className="infoP alignRight">Ημ/νία Τελευταίας Συνταγ/σης </p>
                        <p className="noMargin" id="insurdFullname">
                            {step.lastUsedDate}
                        </p>
                    </div>
                </div>
            </div>
            <div className="flexWrap">
                <p className="infoP alignRight">Περιγραφή</p>
                <p className="noMargin" id="insurdFullname">
                    {protocolState.protocol.treatmentProtocol.description}
                </p>
            </div>
            <div className="flexWrap">
                <p className="infoP alignRight">Σημειώσεις</p>
                <p className="noMargin" id="insurdFullname">
                    {protocolState.protocol.treatmentProtocol.notes}
                </p>
            </div>
            <div className="flexWrap">
                <p className="infoP alignRight">Σχόλιο</p>
                <p className="noMargin" id="insurdFullname">
                    {step.comment}
                </p>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((column, index) => {
                                return (
                                    <TableCell key={index}>
                                        <b>{column}</b>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {step.stepSubstances.map((step, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell>{`Ομάδα ${index + 1}`}</TableCell>
                                    <TableCell>{step.stepSubstance.atcCode}</TableCell>
                                    <TableCell>{step.stepSubstance.description}</TableCell>
                                    <TableCell>{step.stepSubstance.displayContent}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

function ChooseStep({ protocolState, setProtocolState, setActiveStep }) {
    const treatmentProtocolId = protocolState.protocol.treatmentProtocol.id;
    const patient = useContext(PatientContext);

    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [steps, setSteps] = useState(null);
    useEffect(() => {
        IdikaTreatmentProtocolService.getTreatmentProtocolPatientSteps(
            treatmentProtocolId,
            patient.amka
        )
            .then(({ data }) => {
                setSteps(data);
            })
            .catch((error) => {
                setIsError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
        // eslint-disable-next-line
    }, []);

    const columns = [
        "Βήμα Αγωγής",
        "Ημ/νία Ένταξης στο Βήμα",
        "Ημ/νία Τελευταίας Συνταγ/σης",
        "Ημερομηνία Επανεκτίμησης",
        "",
        "",
    ];

    const [activeSubstancesPopUp, setActiveSubstancesPopUp] = useState(false);
    const [selectedStep, setSelectedStep] = useState(null);

    const showActiveSubstances = (step) => {
        setSelectedStep(step);
        setActiveSubstancesPopUp(true);
    };

    const [error, setError] = useState(null);
    const repeatStep = (step) => {
        let request = {
            icd10s: protocolState.treatmentProtocolICD10s.treatmentProtocolICD10,
        };
        setError(null);
        IdikaTreatmentProtocolService.repeatStep(
            treatmentProtocolId,
            step.id,
            patient.amka,
            request
        )
            .then(({ data }) => {
                setProtocolState({ ...protocolState, finalTreatmentProtocol: data });
                setActiveStep(7);
            })
            .catch((err) => {
                setError(err.response);
            });
    };

    return (
        <div>
            {isLoading ? (
                <CircularProgress />
            ) : isError ? (
                <Alert severity="error">Κάτι πήγε στραβά!</Alert>
            ) : (
                <div className="mb-3">
                    {error && (
                        <Alert severity="error">
                            {error.data.status === 1020
                                ? "Δεν γίνεται να προστεθεί το ίδιο Θεραπευτικό Πρωτόκολλο παραπάνω από μία φορά"
                                : error.data.status === 586
                                ? "Τα ICD10 δεν ανταποκρίνονται με το Θεραπευτικό Πρωτόκολλο"
                                : "Κάτι πήγε στραβά!"}
                        </Alert>
                    )}
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {columns.map((column, index) => {
                                        return (
                                            <TableCell key={index}>
                                                <b>{column}</b>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {steps.steps.map((step, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>{step.step.description}</TableCell>
                                            <TableCell>{step.insertDate}</TableCell>
                                            <TableCell>{step.lastUsedDate}</TableCell>
                                            <TableCell>{step.expiryDate}</TableCell>
                                            <TableCell>
                                                {step.canRepeatStep && (
                                                    <button
                                                        className="btn buttonPrimary"
                                                        onClick={() => repeatStep(step)}
                                                    >
                                                        Επανάληψη Βήματος
                                                    </button>
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                <button
                                                    className="btn buttonPrimary"
                                                    onClick={() => showActiveSubstances(step)}
                                                >
                                                    Δραστικές Ουσίες
                                                </button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            )}
            <PopUp
                openPopUp={activeSubstancesPopUp}
                setOpenPopUp={setActiveSubstancesPopUp}
                title={"Δραστικές Ουσίες Βήματος"}
                maxWidth="md"
            >
                <StepActiveSubstances step={selectedStep} protocolState={protocolState} />
            </PopUp>
        </div>
    );
}

function getStepContent(
    stepIndex,
    setActiveStep,
    protocolState,
    setProtocolState,
    addPrescriptionState,
    setAddPrescriptionState,
    tabItem
) {
    switch (stepIndex) {
        case 0:
            return (
                <ProtocolSelection
                    setActiveStep={setActiveStep}
                    protocolState={protocolState}
                    setProtocolState={setProtocolState}
                />
            );
        case 1:
            return <Diagnoses protocolState={protocolState} setProtocolState={setProtocolState} />;
        case 2:
            return (
                <ChooseStep
                    protocolState={protocolState}
                    setProtocolState={setProtocolState}
                    setActiveStep={setActiveStep}
                />
            );
        case 3:
            return (
                <DefineParameters
                    protocolState={protocolState}
                    setProtocolState={setProtocolState}
                />
            );
        case 4:
            return (
                <DefineParametersContinue
                    protocolState={protocolState}
                    setProtocolState={setProtocolState}
                />
            );
        case 5:
            return <NewStep protocolState={protocolState} setProtocolState={setProtocolState} />;
        case 6:
            return (
                <ActiveSubstancesSelection
                    protocolState={protocolState}
                    setProtocolState={setProtocolState}
                />
            );
        case 7:
            return (
                <ProcessCompletion
                    protocolState={protocolState}
                    addPrescriptionState={addPrescriptionState}
                    setAddPrescriptionState={setAddPrescriptionState}
                    tabItem={tabItem}
                />
            );
        default:
            return <Alert severity="error">Unknown step index</Alert>;
    }
}

function Protocol({ setOpenPopUp, addPrescriptionState, setAddPrescriptionState, tabItem }) {
    const patient = useContext(PatientContext);
    const [activeStep, setActiveStep] = useState(0);
    const [error, setError] = useState(null);
    const handleNext = () => {
        if (activeStep === 3) {
            let icd10Ids = "";
            for (let icd10 of protocolState.treatmentProtocolICD10s.treatmentProtocolICD10) {
                if (icd10Ids === "") icd10Ids = icd10Ids + icd10.icd10.id;
                else icd10Ids = icd10Ids + ", " + icd10.icd10.id;
            }
            let parameters = protocolState.parameters;
            let icd10Index = parameters.parameters.findIndex(
                (parameter) => parameter.protocolParamPool.id === "ICD10IDS"
            );
            parameters.parameters[icd10Index].value = icd10Ids;
            setError(null);
            IdikaTreatmentProtocolService.addTreatmentProtocolParameters(
                protocolState.protocol.treatmentProtocol.id,
                patient.amka,
                parameters
            )
                .then(({ data }) => {
                    setProtocolState({ ...protocolState, postedParameters: data });
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                })
                .catch((err) => {
                    setError(err);
                });
        } else if (activeStep === 5) {
            setError(null);
            if (Object.keys(protocolState.selectedStep).length === 0) {
                setError({
                    response: {
                        data: { status: 100100100, errors: { error: "Πρέπει να επιλέξετε Βήμα" } },
                    },
                });
                return;
            }
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else if (activeStep === 6) {
            let request = {
                resultKey: protocolState.groups.resultKey,
                parameters: protocolState.postedParameters,
                groupSubstances: protocolState.selectedSubstances.map((substance) => {
                    return { id: substance.id };
                }),
            };
            setError(null);
            IdikaTreatmentProtocolService.getTreatmentProtocolValidation(
                protocolState.protocol.treatmentProtocol.id,
                protocolState.selectedStep.id,
                patient.amka,
                request
            )
                .then(({ data }) => {
                    setValidatedTreatmentProtocol(true);
                })
                .catch((err) => {
                    setError(err);
                });
        } else if (activeStep === 7) {
            setError(null);
            IdikaVisitService.getTreatmentProtocolIcd10s()
                .then(({ data }) => {
                    let newIcd10sToBeInserted = [];
                    for (let icd10 of data.icd10s) {
                        let icd10Found = addPrescriptionState.icd10s.find(
                            (icd) => icd.code === icd10.code
                        );
                        if (icd10Found === undefined) newIcd10sToBeInserted.push(icd10);
                    }
                    setAddPrescriptionState({
                        ...addPrescriptionState,
                        icd10s: [...addPrescriptionState.icd10s, ...newIcd10sToBeInserted],
                    });
                    setOpenPopUp(false);
                })
                .catch((err) => {
                    setError(err.response);
                });
        } else {
            setActiveStep((prevActiveStep) => {
                if (prevActiveStep === 1 && protocolState.protocol.treatmentProtocol.new === true) {
                    return prevActiveStep + 2;
                } else {
                    return prevActiveStep + 1;
                }
            });
        }
    };

    const [validateTreatmentProtocol, setValidatedTreatmentProtocol] = useState(false);
    useEffect(() => {
        if (validateTreatmentProtocol === false) return;
        let request = {
            resultKey: protocolState.groups.resultKey,
            parameters: protocolState.postedParameters,
            groupSubstances: protocolState.selectedSubstances.map((substance) => {
                return { id: substance.id };
            }),
        };
        IdikaTreatmentProtocolService.addTreatmentProtocol(
            protocolState.protocol.treatmentProtocol.id,
            protocolState.selectedStep.id,
            patient.amka,
            request
        )
            .then(({ data }) => {
                setProtocolState({ ...protocolState, finalTreatmentProtocol: data });
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setValidatedTreatmentProtocol(false);
            });
        // eslint-disable-next-line
    }, [validateTreatmentProtocol]);

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const [protocolState, setProtocolState] = useState({
        protocol: null,
        treatmentProtocolICD10s: { treatmentProtocolICD10: [] },
        parameters: { count: 0, parameters: [] },
        postedParameters: { count: 0, parameters: [] },
        selectedStep: {},
        groups: {},
        selectedSubstances: [],
        finalTreatmentProtocol: {},
    });

    const fillParameters = () => {
        let newParameters = { count: protocolState.parameters.count, parameters: [] };
        for (let parameter of protocolState.parameters.parameters) {
            if (parameter.readOnly === true) {
                newParameters.parameters.push(parameter);
            } else {
                if (parameter.previousValue !== "") {
                    parameter.value = parameter.previousValue;
                }
                newParameters.parameters.push(parameter);
            }
        }
        setProtocolState({ ...protocolState, parameters: newParameters });
    };

    return (
        <div>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div>
                {activeStep === steps.length ? (
                    <div>
                        <Alert className="mb-3" severity="success">
                            <Typography component={"span"} variant={"body2"}>
                                Ολοκληρώθηκαν όλα τα βήματα
                            </Typography>
                        </Alert>
                        <Button onClick={handleReset} variant="contained" color="primary">
                            Επιστροφη στην Επιλογη Πρωτοκολλου
                        </Button>
                    </div>
                ) : (
                    <div>
                        <Typography component={"span"} variant={"body2"}>
                            {getStepContent(
                                activeStep,
                                setActiveStep,
                                protocolState,
                                setProtocolState,
                                addPrescriptionState,
                                setAddPrescriptionState,
                                tabItem
                            )}
                        </Typography>
                        <div>
                            {activeStep === 2 && (
                                <Button
                                    className="mr-2"
                                    variant="contained"
                                    color="primary"
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                >
                                    Πισω
                                </Button>
                            )}
                            {activeStep === 3 && (
                                <Button
                                    style={{ marginRight: "5px" }}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => fillParameters()}
                                >
                                    Συμπληρωση Τιμων Παραμετρου με Προηγουμενων
                                </Button>
                            )}
                            {activeStep !== 0 && (
                                <Button
                                    className="float-right"
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                    disabled={
                                        protocolState.treatmentProtocolICD10s.treatmentProtocolICD10
                                            .length === 0
                                            ? true
                                            : false
                                    }
                                >
                                    {activeStep === steps.length - 1
                                        ? "Ολοκληρωση"
                                        : activeStep === 2
                                        ? "Νεο Βημα"
                                        : "Συνεχεια"}
                                </Button>
                            )}
                        </div>
                    </div>
                )}
                <br />
                {error && (
                    <Alert severity="error">
                        {error.response.data.status === 10001 ||
                        error.response.data.status === 100100100 ||
                        error.response.data.status === 9999
                            ? error.response.data.errors.error
                            : "Κάτι πήγε στραβά!"}
                    </Alert>
                )}
            </div>
        </div>
    );
}

export default Protocol;
