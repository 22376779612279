import { getJwt } from "../components/Utilities/JwtUtilities.js";

const basePath = "/api/v1";

const baseUrl = `${basePath}`;

const authHeader = `Bearer ${getJwt()}`;

const getOptions = (baseUrl, endPoint = "", parameters = {}) => {
    return {
        url: `${baseUrl}${endPoint}?` + new URLSearchParams(parameters).toString(),
        method: "GET",
        headers: {
            Authorization: authHeader,
        },
    };
};

const getPdfOptions = (baseUrl, endPoint = "", parameters = {}) => {
    return {
        url: `${baseUrl}${endPoint}?` + new URLSearchParams(parameters).toString(),
        method: "GET",
        headers: {
            Authorization: authHeader,
        },
        responseType: "arraybuffer",
    };
};

const postPdfOptions = (baseUrl, endPoint = "", data = {}) => {
    return {
        url: `${baseUrl}${endPoint}`,
        method: "POST",
        headers: {
            Authorization: authHeader,
        },
        data: data,
        responseType: "arraybuffer",
    };
};

const putOptions = (baseUrl, endPoint = "", data = {}) => {
    return {
        url: `${baseUrl}${endPoint}`,
        method: "PUT",
        headers: {
            Authorization: authHeader,
        },
        data: data,
    };
};

const putOptionsWithToken = (baseUrl, endPoint = "", data = {}, token) => {
    return {
        url: `${baseUrl}${endPoint}`,
        method: "PUT",
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data: data,
    };
};

const postOptions = (baseUrl, endPoint = "", data = {}) => {
    return {
        url: `${baseUrl}${endPoint}`,
        method: "POST",
        headers: {
            Authorization: authHeader,
        },
        data: data,
    };
};

const deleteOptions = (baseUrl, endPoint = "") => {
    return {
        url: `${baseUrl}${endPoint}`,
        method: "DELETE",
        headers: {
            Authorization: authHeader,
        },
    };
};

export {
    baseUrl,
    getOptions,
    getPdfOptions,
    putOptions,
    postOptions,
    deleteOptions,
    postPdfOptions,
    putOptionsWithToken,
};
