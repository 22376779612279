import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import AddNewEvent from "./AddNewEvent/AddNewEvent";
import PopUp from "../../Utilities/PopUp";
import DeleteOrUpdateEvent from "./DeleteOrUpdateEvent/DeleteOrUpdateEvent";
import MediPlusCalendarEventService from "../../../services/MediPlus/MediPlusCalendarEventService.js";
import MediPlusUserService from "../../../services/MediPlus/MediPlusUserService.js";
import { CircularProgress } from "@material-ui/core";
// import { Tooltip } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import elLocale from "@fullcalendar/core/locales/el";
// import { trimString } from "../../Utilities/Utilities";

function Calendar() {
    const plugins = [dayGridPlugin, timeGridPlugin, interactionPlugin];

    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [events, setEvents] = useState([]);
    const [slotDuration, setSlotDuration] = useState(15);

    const handleSlotDurationChange = (e) => {
        e.preventDefault();
        setSlotDuration(parseInt(e.target.value));
        MediPlusUserService.updateUserDetailsAll({
            calendarEventDuration: parseInt(e.target.value),
        })
            .then(({ data }) => {
                // setSlotDuration(e.target.value);
            })
            .catch((err) => {
                // console.log(err.response);
            });
    };

    // Date.prototype.addHours= function(h){
    //     this.setHours(this.getHours()+h);
    //     return this;
    // }

    const addHours = (date) =>{
        const offset = date.getTimezoneOffset()/60;
        date.setHours(date.getHours()-offset);
        return date;
    }; 

    useEffect(() => {
        let mounted = true;

        MediPlusCalendarEventService.getAllCalendarEventsByUserId()
            .then(({ data }) => {
                // console.log(data);
                if (mounted) {
                    for(let i = 0 ; i < data.events.length; i ++){
                        data.events[i].start = addHours(new Date(data.events[i].start));
                        data.events[i].end = addHours(new Date(data.events[i].end));
                    }
                    setEvents(data?.events ?? []);
                    setSlotDuration(data?.calendarEventDuration ?? 15);
                }
            })
            .catch((error) => {
                // console.log(error.response);
                setIsError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });

        return () => {
            mounted = false;
            sessionStorage.removeItem("patientInfoForAppointment");
        };
    }, []);

    const [addNewEventDate, setAddNewEventDate] = useState(new Date());
    const [addNewEventPopUp, setAddNewEventPopUp] = useState(false);

    const addNewEvent = ({ allDay, dateStr }) => {
        let newDate = dateStr;
        if (allDay === false) {
            let tempDate = dateStr.split("T")[0];
            let tempTime = dateStr.split("T")[1].split("+")[0];
            tempTime = tempTime.substring(0, tempTime.length - 3);
            newDate = `${tempDate} ${tempTime}`;
        } else {
            newDate = newDate + " 12:00";
        }
        setAddNewEventDate(newDate);
        setAddNewEventPopUp(true);
    };

    const [selectedEvent, setSelectedEvent] = useState(null);
    const [deleteOrUpdateEventPopUp, setDeleteOrUpdateEventPopUp] = useState(false);

    const deleteOrUpdateEvent = (args) => {
        let id = parseInt(args.event.id);
        let eventFound = events.find((event) => event.id === id);
        if (eventFound !== undefined) {
            setSelectedEvent(eventFound);
            setDeleteOrUpdateEventPopUp(true);
        }
    };

    // const renderEventContent = (eventInfo) => {
    //     return (
    //         <Tooltip title={eventInfo.event.title} placement="top">
    //             <div>
    //                 <span>{eventInfo.timeText}&nbsp;</span>
    //                 <b>{trimString(eventInfo.event.title, 10)}</b>
    //             </div>
    //         </Tooltip>
    //     );
    // };

    return (
        <div>
            {isLoading ? (
                <div align="center">
                    <CircularProgress />
                </div>
            ) : isError ? (
                <Alert severity="error">Κάτι πήγε στραβά!</Alert>
            ) : (
                <div className="mt-3 mb-3">
                    <div className="d-flex justify-content-end form-inline">
                        <h6>Διάρκεια Ραντεβού:</h6>
                        <select
                            className="form-control mr-2 mb-2"
                            value={slotDuration}
                            onChange={handleSlotDurationChange}
                        >
                            <option value={15}>15</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                        </select>
                    </div>
                    <FullCalendar
                        initialView="timeGridWeek"
                        headerToolbar={{
                            left: "today,prev,next",
                            center: "title",
                            right: "dayGridMonth,timeGridWeek,timeGridDay",
                        }}
                        plugins={plugins}
                        events={events}
                        views={{
                            dayGridMonth: {
                                titleFormat: { year: "numeric", month: "2-digit", day: "2-digit" },
                            },
                            timeGridWeek: {
                                titleFormat: { year: "numeric", month: "2-digit", day: "2-digit" },
                            },
                            timeGridDay: {
                                titleFormat: { year: "numeric", month: "2-digit", day: "2-digit" },
                            },
                            allDay: false,
                        }}
                        dateClick={addNewEvent}
                        eventClick={deleteOrUpdateEvent}
                        allDaySlot={false}
                        locale={elLocale}
                        slotDuration={`00:${slotDuration}`}
                        displayEventEnd={false}
                        timeZone= 'local'
                        // eventContent={(args) => renderEventContent(args)}
                    />
                    <PopUp
                        openPopUp={addNewEventPopUp}
                        setOpenPopUp={setAddNewEventPopUp}
                        title={"Νέο Ραντεβού"}
                        maxWidth="sm"
                    >
                        <AddNewEvent
                            events={events}
                            setEvents={setEvents}
                            setAddNewEventPopUp={setAddNewEventPopUp}
                            addNewEventDate={addNewEventDate}
                            slotDuration={`00:${slotDuration}`}
                        />
                    </PopUp>
                    <PopUp
                        openPopUp={deleteOrUpdateEventPopUp}
                        setOpenPopUp={setDeleteOrUpdateEventPopUp}
                        title={"Ενημέρωση Ραντεβού"}
                        maxWidth="sm"
                    >
                        <DeleteOrUpdateEvent
                            events={events}
                            setEvents={setEvents}
                            setDeleteOrUpdateEventPopUp={setDeleteOrUpdateEventPopUp}
                            selectedEvent={selectedEvent}
                        />
                    </PopUp>
                </div>
            )}
        </div>
    );
}

export default Calendar;
