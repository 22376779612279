import axios from "axios";
import { baseUrl, getOptions, postOptions } from "../../config.js";

class IdikaTreatmentProtocolService {
    constructor(baseUrl) {
        this.basePath = "/idika/treatmentprotocols";
        this.baseUrl = `${baseUrl}${this.basePath}`;
    }

    getPatientTreatmentProtocols(amkaOrEkaa) {
        return axios(getOptions(this.baseUrl, `/patient/${amkaOrEkaa}`));
    }

    getTreatmentProtocols() {
        return axios(getOptions(this.baseUrl));
    }

    getTreatmentProtocolIcd10s(treatmentprotocolΙd) {
        return axios(getOptions(this.baseUrl, `/${treatmentprotocolΙd}/icd10`));
    }

    getTreatmentProtocolPatientSteps(treatmentprotocolΙd, amkaOrEkaa) {
        return axios(
            getOptions(this.baseUrl, `/${treatmentprotocolΙd}/patient/${amkaOrEkaa}/patientSteps`)
        );
    }

    getTreatmentProtocolParameters(treatmentprotocolΙd, amkaOrEkaa) {
        return axios(
            getOptions(this.baseUrl, `/${treatmentprotocolΙd}/patient/${amkaOrEkaa}/parameters`)
        );
    }

    addTreatmentProtocolParameters(treatmentprotocolΙd, amkaOrEkaa, parameters) {
        return axios(
            postOptions(
                this.baseUrl,
                `/${treatmentprotocolΙd}/patient/${amkaOrEkaa}/parameters`,
                parameters
            )
        );
    }

    getTreatmentProtocolSuggestedSteps(treatmentprotocolΙd, amkaOrEkaa, parameters) {
        return axios(
            postOptions(
                this.baseUrl,
                `/${treatmentprotocolΙd}/patient/${amkaOrEkaa}/suggestedSteps`,
                parameters
            )
        );
    }

    getTreatmentProtocolActiveSubstanceGroups(treatmentprotocolΙd, stepId, amkaOrEkaa, parameters) {
        return axios(
            postOptions(
                this.baseUrl,
                `/${treatmentprotocolΙd}/step/${stepId}/patient/${amkaOrEkaa}/activeSubstanceGroups`,
                parameters
            )
        );
    }

    getTreatmentProtocolValidation(treatmentprotocolΙd, stepId, amkaOrEkaa, parameters) {
        return axios(
            postOptions(
                this.baseUrl,
                `/${treatmentprotocolΙd}/step/${stepId}/patient/${amkaOrEkaa}/validate`,
                parameters
            )
        );
    }

    addTreatmentProtocol(treatmentprotocolΙd, stepId, amkaOrEkaa, parameters) {
        return axios(
            postOptions(
                this.baseUrl,
                `/${treatmentprotocolΙd}/step/${stepId}/patient/${amkaOrEkaa}`,
                parameters
            )
        );
    }

    repeatStep(treatmentprotocolΙd, stepId, amkaOrEkaa, parameters) {
        return axios(
            postOptions(
                this.baseUrl,
                `/${treatmentprotocolΙd}/patient/${amkaOrEkaa}/repeatStep/${stepId}`,
                parameters
            )
        );
    }
}

export default new IdikaTreatmentProtocolService(baseUrl);
