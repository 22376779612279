import React from "react";
import "./InsuredDetails.css";
import TextField from "@material-ui/core/TextField";
import SearchPatient from "./SearchPatient/SearchPatient.js";
import InsuredInformation from "./InsuredInformation/InsuredInformation.js";

// if we are in a dynamic tab we have to display patient's data
// otherwise we display the search patient buttonDanger
function InsuredDetails({
    addTab = null,
    patientData = null,
    visitDetails,
    setVisitDetails,
    tabIndex,
    tabItem,
    setTabItem,
    setSelectedTab,
}) {
    return (
        <div className="section">
            {addTab !== null && patientData === null ? (
                <div>
                    <div className="boxTitle">
                        <h6>Στοιχεία Ασφαλισμένου</h6>
                    </div>
                    <div className="boxContent">
                        <div className="insurInfo">
                            <p className="infoP noMargin">Αναζήτηση με:</p>
                            <div className="flex">
                                <SearchPatient addTab={addTab} />
                                <div className="insurInfoTab displayNone" id="insurEkaa">
                                    <TextField
                                        label="Ε.Κ.Α.Α."
                                        id="ekaaInsurSearch"
                                        type="text"
                                        className="searchInput"
                                        name="ekaaInsurSearch"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <InsuredInformation
                    patientData={patientData}
                    visitDetails={visitDetails}
                    setVisitDetails={setVisitDetails}
                    tabIndex={tabIndex}
                    tabItem={tabItem}
                    setTabItem={setTabItem}
                    setSelectedTab={setSelectedTab}
                />
            )}
        </div>
    );
}

export default InsuredDetails;
