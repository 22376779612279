import React, { useState, useEffect } from "react";
import "./PatientMedicineHistory.css";

import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Alert from "@material-ui/lab/Alert";
import { KeyboardDatePicker } from "@material-ui/pickers";

import Prescriptions from "./Prescriptions/Prescriptions.js";
import Referrals from "./Referrals/Referrals.js";
import PatientInformation from "./PatientInformation/PatientInformation.js";
import PopUp from "../../../Utilities/PopUp.js";
import { formatMaterialUiDate, getCurrentDate, Spinner } from "../../../Utilities/Utilities.js";
import Reminder from "./Reminder.js";
import IdikaPatientService from "../../../../services/MediPlus/Idika/IdikaPatientService.js";

import SearchPatient from "./SearchPatient/SearchPatient.js";

import axios from "axios";

const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
}));

const responseInitialState = {
    patientData: null,
    patientBiometrics: null,
    patientIntolerances: null,
    patientProtocolParams: null,
};

const checkIfAllFieldsAreNull = (object) => {
    for (const property in object) {
        if (object[property] !== null) return false;
    }
    return true;
};

function PatientMedicineHistory({
    addTab,
    tabItem,
    setTabItem,
    setSelectedTab,
    setSelectedTabBttn,
}) {
    const [state, setState] = useState({
        from: getCurrentDate(),
        to: getCurrentDate(),
        amkaOrEkaa: tabItem.patientData !== null ? tabItem.patientData.amka.toString() : "",
        patientsConsent: false,
        personalInfoPatient: false,
        biometricElementsPatient: false,
        intolerancesAndProtocolParams: false,
    });

    const handleOnChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setState({ ...state, [name]: value });
    };

    const [response, setResponse] = useState(responseInitialState);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const [openPopUp, setOpenPopUp] = useState(false);
    const onSubmit = (e) => {
        e.preventDefault();
        if (Number.isNaN(Date.parse(state.from)) || Number.isNaN(Date.parse(state.to))) {
            setError({ data: { dates: "Οι ημερομηνίες δεν είναι έγκυρες" }, status: 410 });
            return;
        }
        if (state.patientsConsent === true) setOpenPopUp(true);
        else setFetch(true);
    };

    const classes = useStyles();

    const [fetch, setFetch] = useState(false);
    useEffect(() => {
        if (fetch === true) {
            setPrescriptionPage(1);
            setReferralPage(1);
            let request = {};
            if (state.amkaOrEkaa.length === 20) request.ekaa = state.amkaOrEkaa;
            else request.amka = state.amkaOrEkaa;

            let axiosRequests = [];
            axiosRequests.push(
                IdikaPatientService.getPrescriptions(
                    {
                        from: state.from,
                        to: state.to,
                        patientsConsent: state.patientsConsent,
                        size: 2,
                        page: 0,
                    },
                    state.amkaOrEkaa
                )
            );
            axiosRequests.push(
                IdikaPatientService.getReferrals(
                    {
                        from: state.from,
                        to: state.to,
                        patientsConsent: state.patientsConsent,
                        size: 2,
                        page: 0,
                    },
                    state.amkaOrEkaa
                )
            );

            let moreRequest = {};
            if (state.personalInfoPatient === true) {
                moreRequest.personalInfoPatient = axiosRequests.length;
                axiosRequests.push(IdikaPatientService.getPatient(request));
            }
            if (state.biometricElementsPatient === true) {
                moreRequest.biometricElementsPatient = axiosRequests.length;
                axiosRequests.push(IdikaPatientService.getPatientBiovalues(request));
            }
            if (state.intolerancesAndProtocolParams === true) {
                moreRequest.intolerancesAndProtocolParams = axiosRequests.length;
                axiosRequests.push(
                    IdikaPatientService.getMedicineHistoryProtocolParams(
                        { patientsConsent: true },
                        state.amkaOrEkaa
                    )
                );
                axiosRequests.push(
                    IdikaPatientService.getMedicineHistoryIntolerances(
                        { patientsConsent: true },
                        state.amkaOrEkaa
                    )
                );
            }
            setError({});
            setPrescriptions(null);
            setReferrals(null);
            setResponse(responseInitialState);
            setLoading(true);
            axios
                .all(axiosRequests)
                .then(
                    axios.spread((...responses) => {
                        const newState = {
                            patientData: null,
                            patientBiometrics: null,
                            patientIntolerances: null,
                            patientProtocolParams: null,
                        };
                        if (state.personalInfoPatient === true)
                            newState.patientData =
                                responses[moreRequest.personalInfoPatient].data.Patient;
                        if (state.biometricElementsPatient === true)
                            newState.patientBiometrics =
                                responses[moreRequest.biometricElementsPatient].data;
                        if (state.intolerancesAndProtocolParams === true) {
                            newState.patientProtocolParams =
                                responses[moreRequest.intolerancesAndProtocolParams].data;
                            newState.patientIntolerances =
                                responses[moreRequest.intolerancesAndProtocolParams + 1].data;
                        }
                        setPrescriptions(responses[0].data);
                        setReferrals(responses[1].data);
                        setResponse(newState);
                    })
                )
                .catch((errors) => {
                    setError(errors.response.data);
                })
                .finally(() => {
                    setLoading(false);
                    setFetch(false);
                });
        }
        // eslint-disable-next-line
    }, [fetch]);

    const [prescriptions, setPrescriptions] = useState(null);
    const [prescriptionPage, setPrescriptionPage] = useState(1);
    const handlePrescriptionPageChange = (event, value) => {
        setPrescriptionPage(value);
    };

    useEffect(() => {
        if (state.amkaOrEkaa.length === 11 || state.amkaOrEkaa.length === 20) {
            IdikaPatientService.getPrescriptions(
                {
                    from: state.from,
                    to: state.to,
                    patientsConsent: state.patientsConsent,
                    size: 2,
                    page: prescriptionPage - 1,
                },
                state.amkaOrEkaa
            ).then((response) => {
                setPrescriptions(response.data);
            });
        }
        // eslint-disable-next-line
    }, [prescriptionPage]);

    const [referralPage, setReferralPage] = useState(1);
    const handleReferralPageChange = (event, value) => {
        setReferralPage(value);
    };
    const [referrals, setReferrals] = useState(null);
    useEffect(() => {
        if (state.amkaOrEkaa.length === 11 || state.amkaOrEkaa.length === 20) {
            IdikaPatientService.getReferrals(
                {
                    from: state.from,
                    to: state.to,
                    patientsConsent: state.patientsConsent,
                    size: 2,
                    page: referralPage - 1,
                },
                state.amkaOrEkaa
            ).then((response) => {
                setReferrals(response.data);
            });
        }
        // eslint-disable-next-line
    }, [referralPage]);

    return (
        <div className="flexBoxWrap">
            <div className="flexBox">
                <div className="fullBox">
                    <div className="boxTitle">
                        <h6> Αναζήτηση Ιστορικού </h6>
                    </div>
                    <div>
                        {error.status !== undefined ? (
                            <Alert severity="error">
                                {error.status === 3002
                                    ? `Δεν υπάρχει ασθενής με Α.Μ.Κ.Α./Ε.Κ.Α.Α.: ${
                                          error.errors.error.split("Invalid patient's amka:")[1]
                                      }`
                                    : error.status === 439
                                    ? `Δεν υπάρχει ασθενής με Α.Μ.Κ.Α./Ε.Κ.Α.Α.: ${
                                          error.errors.error.split(
                                              "There is no patient with amka:"
                                          )[1]
                                      }`
                                    : error.status === 510
                                    ? `Η Ημερομηνία 'Από' δεν πρέπει να είναι μεγαλύτερη από την Ημερομηνία 'Εώς'`
                                    : error.status === 410
                                    ? error.data.dates
                                    : error.status === 400
                                    ? "Κάτι πήγε στραβά"
                                    : null}
                            </Alert>
                        ) : null}
                    </div>
                    <form onSubmit={onSubmit}>
                        <div className="boxContent">
                            <div>
                                <div className="flex">
                                    <div className="flex">
                                        <KeyboardDatePicker
                                            format="dd/MM/yyyy"
                                            label="Από"
                                            value={state.from}
                                            onChange={(date) => {
                                                setState({
                                                    ...state,
                                                    from: formatMaterialUiDate(date),
                                                });
                                            }}
                                            className={classes.textField}
                                            disableToolbar
                                        />
                                    </div>
                                </div>
                                <div className="flex">
                                    <KeyboardDatePicker
                                        format="dd/MM/yyyy"
                                        label="Έως"
                                        value={state.to}
                                        onChange={(date) => {
                                            setState({
                                                ...state,
                                                to: formatMaterialUiDate(date),
                                            });
                                        }}
                                        className={classes.textField}
                                        disableToolbar
                                    />
                                </div>
                                <SearchPatient
                                    name="amkaOrEkaa"
                                    value={state.amkaOrEkaa}
                                    onChange={handleOnChange}
                                    error={error}
                                    state={state}
                                    setState={setState}
                                    setFetch={setFetch}
                                    patientExists={tabItem.patientData !== null ? true : false}
                                />
                                <br />
                                {/* {response.patientData !== null && (
                                    <div>
                                        Ονοματεπώνυμο Ασθενούς:
                                        <br />
                                        {`${response.patientData.firstName} ${response.patientData.lastName}`}
                                        <br />
                                        <br />
                                    </div>
                                )} */}
                                <div className="flex">
                                    <div className="flex">
                                        {loading === true ? (
                                            <Spinner align="center" animation="spinner-border" />
                                        ) : (
                                            <button className="btn buttonPrimary">Αναζήτηση</button>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="flex">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={state.patientsConsent}
                                            name="patientsConsent"
                                            onChange={() => {
                                                setState({
                                                    ...state,
                                                    patientsConsent: !state.patientsConsent,
                                                });
                                            }}
                                            color="primary"
                                        />
                                    }
                                    label="Να προβληθεί το ΠΛΗΡΕΣ ιστορικό, από όλους τους παρόχους υπηρεσιών υγείας"
                                />
                            </div>
                            <div className="flex">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={state.personalInfoPatient}
                                            name="personalInfoPatient"
                                            onChange={() => {
                                                setState({
                                                    ...state,
                                                    personalInfoPatient: !state.personalInfoPatient,
                                                });
                                            }}
                                            color="primary"
                                        />
                                    }
                                    label="Εχω την συγκατάθεση του ασθενή να δω προσωπικές πληροφορίες"
                                />
                            </div>
                            <div className="flex">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={state.biometricElementsPatient}
                                            name="biometricElementsPatient"
                                            onChange={() => {
                                                setState({
                                                    ...state,
                                                    biometricElementsPatient:
                                                        !state.biometricElementsPatient,
                                                });
                                            }}
                                            color="primary"
                                        />
                                    }
                                    label="Εχω την συγκατάθεση του ασθενή να δω τα βιομετρικά του στοιχεία"
                                />
                            </div>
                            <div className="flex">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={state.intolerancesAndProtocolParams}
                                            name="intolerancesAndProtocolParams"
                                            onChange={() => {
                                                setState({
                                                    ...state,
                                                    intolerancesAndProtocolParams:
                                                        !state.intolerancesAndProtocolParams,
                                                });
                                            }}
                                            color="primary"
                                        />
                                    }
                                    label="Να προβληθεί το ΠΛΗΡΕΣ ιστορικό, από όλους τους παρόχους υπηρεσιών υγείας"
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Prescriptions
                prescriptions={prescriptions}
                page={prescriptionPage}
                handlePageChange={handlePrescriptionPageChange}
                addTab={addTab}
                tabItem={tabItem}
                setTabItem={setTabItem}
                setSelectedTab={setSelectedTab}
                setSelectedTabBttn={setSelectedTabBttn}
                amkaOrEkaa={state.amkaOrEkaa}
                patientsConsent={state.patientsConsent}
            />
            <Referrals
                referrals={referrals}
                page={referralPage}
                handlePageChange={handleReferralPageChange}
                addTab={addTab}
                tabItem={tabItem}
                setTabItem={setTabItem}
                setSelectedTab={setSelectedTab}
                setSelectedTabBttn={setSelectedTabBttn}
                amkaOrEkaa={state.amkaOrEkaa}
                patientsConsent={state.patientsConsent}
            />
            {checkIfAllFieldsAreNull(response) === false ? (
                <PatientInformation
                    personalInfoPatient={state.personalInfoPatient}
                    biometricElementsPatient={state.biometricElementsPatient}
                    patientData={response.patientData}
                    patientBiometrics={response.patientBiometrics}
                    patientIntolerances={response.patientIntolerances}
                    patientProtocolParams={response.patientProtocolParams}
                />
            ) : null}
            <PopUp
                openPopUp={openPopUp}
                setOpenPopUp={setOpenPopUp}
                title={"Σημαντική Υπενθύμιση!"}
                showCloseButton={false}
            >
                <Reminder
                    setOpenPopUp={setOpenPopUp}
                    state={state}
                    setState={setState}
                    setFetch={setFetch}
                />
            </PopUp>
        </div>
    );
}

export default PatientMedicineHistory;
