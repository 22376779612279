import { useEffect, useState } from "react";
import PopUp from "../../Utilities/PopUp";
import CompanyManagement from "./CompanyManagement";
import MediPlusUserService from "../../../services/MediPlus/MediPlusUserService";
import { Spinner } from "../../Utilities/Utilities.js";
import Alert from "@material-ui/lab/Alert";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { FormatListBulleted } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";


function Company() {
    const [companies, setCompanies] = useState([]);
    const [companyPopUp, setCompanyPopUp] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [companyName,setCompanyName] = useState(null);
    const [defaultValues,setDefaultValues] = useState([]);
    const [editCompany,setEditCompany] = useState(FormatListBulleted);


const useStyles = makeStyles(() => ({
    root: {
        width: "40%",
        backgroundColor:"white",
        textAlign: "center",
        alignContent:"center",
        alignItems: "center",
        padding:"10px",
        paddingTop: "0px",
        border: "5px white solid",
        borderRadius: "5px",
        marginBottom: "10px",
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)"
      },
      listItem: {
        textAlign:"center",
        
      },
}));

const classes = useStyles();

    const handleClick = () => {
        clearValues();
        setEditCompany(false);
        setCompanyPopUp(true);
    };

    const handleListClick = (index) => {
        clearValues();
        setCompanyName(companies[index].companyName);
        setDefaultValues(companies[index].producers);
        setEditCompany(true);
        setCompanyPopUp(true);
    };

    const clearValues = () => {
        setCompanyName(null);
        setDefaultValues([]);
    };

    useEffect(() => {
        MediPlusUserService.getCompanies()
            .then((response) => {
                setCompanies(response.data);
                setLoading(false);
            })
            .catch((error) => {
                setError(error.response.data);
                setLoading(false);
            });
    }, [setCompanies,companyPopUp]);

    const data = companies.map((company, index) => 
        <ListItem button key ={index} onClick = {() => handleListClick(index)} divider>
            <ListItemText primary= {company.companyName} className={classes.listItem} />
        </ListItem>
    );

    if (error !== null) return <Alert severity="error"> Κάτι πήγε στραβά! </Alert>;
    else if (loading === true) return <Spinner align="center" animation="spinner-border" />;

    return (
        <div align="center">
            <div >
                <h4>Εταιρείες</h4>
            <List
                className={classes.root}
                component="nav"
                aria-labelledby="nested-list-subheader"
                
            
            >
                {data}
            </List>
            </div>
            
            <div align="center">
                <button type="submit" className="btn buttonPrimary" onClick={handleClick}>
                    Προσθήκη Εταιρείας
                </button>
            </div>
            {companyPopUp === true ? (
                <PopUp openPopUp={companyPopUp} setOpenPopUp={setCompanyPopUp}>
                    <CompanyManagement
                        setCompanies={setCompanies}
                        setCompanyPopUp={setCompanyPopUp}
                        companyName = {companyName}
                        defaultValues = {defaultValues}
                        editCompany = {editCompany}
                    />
                </PopUp>
            ) : null}
        </div>
    );
}

export default Company;
