import axios from "axios";
import { baseUrl, getOptions} from "../config.js";

class MediPlusBannerService {
    constructor(baseUrl) {
        this.basePath = "/banners";
        this.baseUrl = `${baseUrl}${this.basePath}`;
    }

    getBannerByActiveSubstance(data) {
        return axios(getOptions(this.baseUrl,"/activeSubstance",data));
    }

    getBannerByIcd(data) {
        return axios(getOptions(this.baseUrl,"/icd",data));
    }

}

export default new MediPlusBannerService(baseUrl);