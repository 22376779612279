import { useEffect, useState } from "react";

import MediPlusUserService from "../../../services/MediPlus/MediPlusUserService";
import "../../SignUp/SignUp.css";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Spinner } from "../../Utilities/Utilities.js";
import Alert from "@material-ui/lab/Alert";



function AssignUsers({ data, rowIndex, columns, users, setUsers,doctors,agents,type}){

    const [doctorsList,setDoctorsList] = useState({});
    const [agentsList,setAgentsList] = useState({});
    const [defaultDoctors,setDefaultDoctors] = useState({});
    const [defaultAgents,setDefaultAgents] = useState({});

    const [loading,setLoading] = useState(true);
    const [alert,setAlert] = useState(null);
    const [error,setError] = useState(null);

    let adminId = rowIndex;
    

    const handleDoctorChange = (event, value) => {
        setDoctorsList(value);
    };

    const handlePhAgentChange = (event, value) => {
        setAgentsList(value);
    };

    useEffect(() => {

        let request = {
            adminId: adminId
        }

        MediPlusUserService.getAssignedUsers(request)
        .then((response) => {
            setDefaultDoctors(response.data[1]);
            setDefaultAgents(response.data[0]);
           
        })
        .catch((error) => {
            setError(error.response.data.errors);
        })
        .finally(() => {
            setLoading(false);
            
        });
        
    }, [adminId]);



    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        
        //if no change has been made the list will appear empty so we take the old list
        let request = {
            "doctors": doctorsList.length === undefined ? defaultDoctors : doctorsList,
            "agents": agentsList.length === undefined ? defaultAgents : agentsList,
            "adminId" : adminId
        };

        MediPlusUserService.assignUsers(request)
        .then((response) => {
            setAlert("Η ανάθεση ηταν επιτυχής");
            setDefaultDoctors(request.doctors);
            setDefaultAgents(request.agents);
        })
        .catch((error) => {
            setError(error.response.data.errors);
        })
        .finally(() => {
            setLoading(false);
            
        });

        
         


    };

    if (error !== null) return <Alert severity="error"> Κάτι πήγε στραβά! </Alert>;
    else if (loading === true) return <Spinner align="center" animation="spinner-border" />;




    return (   
        <div className="authForm">
            <h4>ΑΝΑΘΕΣΗ ΧΡΗΣΤΩΝ </h4>
            {alert && <Alert severity="success">{alert}</Alert>}
            <form onSubmit={onSubmit} className="mb-3">
                <div className="d-flex justify-content-around">
                    <Autocomplete
                        onChange={handleDoctorChange}
                        defaultValue={doctors.filter(function(obj) {
                            return defaultDoctors.some(function(obj2) {
                                return obj.id === obj2.id;
                            });
                        })}
                        multiple
                        id="tags-outlined"
                        options={doctors}
                        getOptionLabel={(option) =>option.id + " " + option.firstName + " " + option.lastName + " -- " + option.specialty + " -- " + option.email}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Ανάθεση Γιατρών"
                                placeholder="Ανάθεση Γιατρών"
                            />
                        )}
                        style={{ width: "500px" }}
                    />
                    
                </div>
                <br />
                <div className="d-flex justify-content-around">
                <Autocomplete
                        multiple
                        id="tags-outlined"
                        options={agents}
                        onChange={handlePhAgentChange}
                        defaultValue={agents.filter(function(obj) {
                            return defaultAgents.some(function(obj2) {
                                return obj.id === obj2.id;
                            });
                        })}
                        getOptionLabel={(option) =>option.id + " " + option.firstName + " " + option.lastName + " -- " + option.company + " -- " + option.email}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Ανάθεση Φαρμακευτικών Αντιπροσώπων"
                                placeholder="Ανάθεση Φαρμακευτικών Αντιπροσώπων"
                            />
                        )}
                        style={{ width: "500px" }}
                    />
                </div>
                <br/>
                <div align="center">
                    <button type="submit" className="btn buttonPrimary">
                       ΑΠΟΘΗΚΕΥΣΗ
                    </button>
                </div>
            </form>
        </div>

);



}

export default AssignUsers;