import React, { useState, useEffect, useContext } from "react";
// import Checkbox from "@material-ui/core/Checkbox";
import { mainColumns } from "./config.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IdikaDoctorService from "../../../../../../services/MediPlus/Idika/IdikaDoctorService.js";
import Alert from "@material-ui/lab/Alert";
import { PatientContext } from "../../../Esyntagografisi.js";
import { CircularProgress } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

// function compareExamResultDescriptions(a, b) {
//     if (a.description < b.description) return -1;
//     if (a.description > b.description) return 1;
//     return 0;
// }

function MedicalExams({ examinationGroupId, addOrRemoveReferral,state,setState}) {
    const patient = useContext(PatientContext);
    const [keyword, setKeyword] = useState("");
    // const [checked, setChecked] = useState(false);
    // const handleChange = (event) => {
    //     setChecked(event.target.checked);
    // };

    const [examGroups, setExamGroups] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const [fetch, setFetch] = useState(true);
    //we use this state to check if some of the exams are already in use
    const [synchronizeWithState, setSynchronizeWithState] = useState(false);

    //we use this to inform if no examinations found
    const [noResults,setNoResults] = useState(false);



    const decideAction = (exam,index) =>{
        let exams = examGroups.exams;
        const currentExam = exams[index]
        currentExam.inserted = !currentExam.inserted;
        exams[index] = currentExam;
        
        if(!currentExam.inserted){
            addOrRemoveReferral(exam,"remove",index);
        }else{
            addOrRemoveReferral(exam,"add",index);
        }

        setExamGroups({...examGroups, exams:exams});
        
    }

    useEffect(()=>{
        if(synchronizeWithState && examGroups !== null){
            let tempExams = examGroups.exams;
            for(let referral of state.referrals){
                for(let i = 0; i <tempExams.length; i++){
                    if(String(tempExams[i].id) === referral.code){
                        tempExams[i].inserted = true;
                    }
                }
            }
            setExamGroups({...examGroups,exams:tempExams});
            setSynchronizeWithState(false);
        }
    },[examGroups,state.referrals,synchronizeWithState]);

    useEffect(() => {
        if (fetch === false) return;
        setError(null);
        IdikaDoctorService.getAllSpecialtyExaminationsPricesFilteredBySocialInsuranceId(
            { examinationGroupId, keyword },
            patient.activeInsurances.activeInsurance[patient.selectedActiveInsurance]
                .socialInsurance.parentSocialInsuranceId
        )
            .then(({ data }) => {
                const temp = [];
                for(let tem of data.exams){
                    tem = {...tem,"inserted":false}
                    temp.push(tem);
                }
                let newData = {"exams":temp,"count":data.count}
                setExamGroups(newData);
                setNoResults(newData.exams.length === 0);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
                setSynchronizeWithState(true);
                setFetch(false);
            });
        // eslint-disable-next-line
    }, [fetch]);

    return (
        <div className="form-inline m-2">
            
            <h1 className="infoP">Λέξεις - Κλειδιά </h1>
            
            <input type="text" value={keyword} onChange={(e) => setKeyword(e.target.value)} />
            {/* <h1 className="infoP">Μόνο Προληπτικές Εξετάσεις </h1> */}
            {/* <Checkbox checked={checked} onChange={handleChange} color="primary" /> */}
            <button className="btn buttonPrimary" onClick={() => setFetch(true)}>
                Αναζήτηση
            </button>
            {loading ? (
                <CircularProgress />
            ) : error ? (
                <Alert severity="error">Κάτι πήγε στραβά!</Alert>
            ) : (
                <TableContainer component={Paper}>
                    {noResults &&   <Alert severity="info">Δεν βρέθηκαν αποτελέσματα </Alert>}
                    <Table>
                        <TableHead>
                            <TableRow>
                                {mainColumns.map((column, index) => {
                                    return (
                                        <TableCell key={index}>
                                            <b>{column.name}</b>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {examGroups.exams.map((exam,index) => {
                                return (
                                    <TableRow key={exam.id}>
                                        <TableCell>{exam.description}</TableCell>
                                        <TableCell>{exam.keyWords}</TableCell>
                                        <TableCell>{exam.price}</TableCell>
                                        {/* <TableCell>
                                            <input type="number" />
                                        </TableCell> */}
                                        {/* <TableCell>_</TableCell> */}
                                        <TableCell>
                                            {!exam.inserted ? (<button
                                                className="btn buttonPrimary"
                                                onClick={() => { decideAction(exam,index);} }
                                            >
                                                Προσθήκη
                                            </button>) : (<DeleteIcon
                                                            onClick={() =>
                                                                {decideAction(exam,index);}
                                                                
                                                            }
                                                            color="secondary"
                                                            style={{ cursor: "pointer" }}
                                                        />)}
                                            
                                            
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </div>
    );
}

export default MedicalExams;
