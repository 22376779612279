const mainColumns = [
    { name: "Όνομα" },
    { name: "Επώνυμο" },
    { name: "Α.Μ.Κ.Α./E.K.A.A." },
    { name: "Φύλο" },
    { name: "Ημ/νία Γέννησης" },
    { name: "Οδός/Αριθμός" },
    { name: "Τ.Κ." },
    { name: "Πόλη" },
    { name: "Νομός" },
    { name: "Τηλέφωνο" },
    { name: "Email" },
];

const patientFields = [
    { name: "firstName" },
    { name: "lastName" },
    { name: "amka" },
    { name: "sex" },
    { name: "birthDate" },
    { name: "address" },
    { name: "postalCode" },
    { name: "city" },
    { name: "county" },
    { name: "telephone" },
    { name: "email" },
];

export { mainColumns, patientFields };
