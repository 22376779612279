import React, { useState } from "react";
import MediPlusUserService from "../../../../../services/MediPlus/MediPlusUserService.js";
import { Spinner } from "../../../../Utilities/Utilities.js";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
        marginBottom: "30px",
    },
}));

function IdikaCredentialsForm({ errorMessage, setOpenPopUp, setModalResult }) {
    const classes = useStyles();
    const [state, setState] = useState({
        userNameIdika: "",
        passwordIdika: "",
    });
    const [error, setError] = useState(errorMessage);
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setState({ ...state, [name]: value });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        MediPlusUserService.updateUserDetails(state)
            .then((response) => {
                setLoading(false);
                setOpenPopUp(false);
                setModalResult("success");
            })
            .catch((error) => {
                setError(error.response.data.errors.error);
                setLoading(false);
            });
    };

    return (
        <div>
            <div align="center">
                <div className={classes.root}>
                    <Alert severity="error">
                        {error === "Wrong IDIKA credentials"
                            ? "Δώσατε λάθος ΗΔΙΚΑ στοιχεία! Σας παρακαλώ προσπαθήστε ξανά."
                            : error === "No IDIKA credentials are provided"
                            ? "Δεν έχετε εισάγει τα ΗΔΙΚΑ στοιχεία σας!"
                            : "Σας Παρακαλώ ενημερώστε τα ΗΔΙΚΑ στοιχεία σας!"}
                    </Alert>
                </div>
            </div>
            <form onSubmit={onSubmit}>
                <div className="form-group">
                    <label>ΗΔΙΚΑ username</label>
                    <input
                        type="text"
                        className="form-control"
                        name="userNameIdika"
                        value={state.userNameIdika}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>ΗΔΙΚΑ password</label>
                    <input
                        type="password"
                        className="form-control"
                        name="passwordIdika"
                        value={state.passwordIdika}
                        onChange={handleChange}
                        required
                    />
                </div>
                {loading === true ? (
                    <Spinner align="center" animation="spinner-border" />
                ) : (
                    <div align="center">
                        <button className="btn buttonPrimary">Υποβολή</button>
                    </div>
                )}
            </form>
        </div>
    );
}

export default IdikaCredentialsForm;
