import {useState } from "react";
import { mainAdminTabs} from "./config";
import { Redirect } from "react-router";
import { getJwt } from "../Utilities/JwtUtilities";
import { Tabs, Tab, AppBar, Toolbar } from "@material-ui/core";
import UserManagement from "./UserManagement/UserManagement";
import SignUp from "../SignUp/SignUp";
import Settings from "../Doctor/Management/Settings/Settings";

function Admin() {
    const [selectedTab, setSelectedTab] = useState(0);
    const handleTabs = (e, value) => {
        setSelectedTab(value);
    };

    const signOut = () => {
        sessionStorage.clear();
        window.location = "/";
    };

    const showContent = () => {
        if (selectedTab === 0) return <UserManagement type="user" />;
        else if (selectedTab === 1) return <UserManagement type="phAgent" />;
        else if (selectedTab === 2) return <SignUp admin={false} phAgent ={true}/>;
        else if (selectedTab === 3) return <Settings admin={true} />;
        else return null;
    };

    const token = getJwt();
    if (token === null) return <Redirect to="/" />;

    return (
        <div className="bodyWrap">
            <div className="mainHeaderAdmin mb-5">
                <AppBar position="static" className="mainTabs">
                    <Toolbar>
                        <img
                            className="mr-2"
                            height="40px"
                            src="/images/MediPlusLogoNoPadding.png"
                            alt="MediPlusLogoNoPadding"
                        />
                        <Tabs
                            className="mainTabs"
                            value={selectedTab}
                            onChange={handleTabs}
                            variant="scrollable"
                            scrollButtons="auto"
                            indicatorColor="secondary"
                        >
                            {mainAdminTabs.map((tab, index) => {
                                return <Tab key={index} {...tab} />;
                            })}
                        </Tabs>
                        <button className="btn buttonPrimary HeaderButton" onClick={signOut}>
                            <i className="fa fa-sign-out"></i>
                            Αποσύνδεση
                        </button>
                    </Toolbar>
                </AppBar>
            </div>
            {showContent()}
        </div>
    );
}

export default Admin;
