function MembershipNotification({ setMembershipEndDatePopUp, numberOfDatesUntilMembershipExpire }) {
    const removeMembershipEndDateFromSession = (e) => {
        e.preventDefault();
        sessionStorage.removeItem("membershipNotification");
        sessionStorage.removeItem("membershipDiffDays");
        setMembershipEndDatePopUp(false);
    };

    return (
        <div>
            <h6>Η συνδρομή σας λήγει σε {numberOfDatesUntilMembershipExpire} μέρες!</h6>
            <div align="center">
                <button className="btn buttonPrimary" onClick={removeMembershipEndDateFromSession}>
                    Συνέχεια
                </button>
            </div>
        </div>
    );
}

export default MembershipNotification;
