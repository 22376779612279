import React, { useState, useEffect } from "react";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import IdikaDoctorService from "../../../../../services/MediPlus/Idika/IdikaDoctorService.js";
import { Spinner } from "../../../../Utilities/Utilities.js";
import PopUp from "../../../../Utilities/PopUp.js";
import NewFavoriteExam from "./NewFavoriteExam/NewFavoriteExam.js";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import Pagination from "@material-ui/lab/Pagination";

import { createContext } from "react";
export const FavoriteExamsContext = createContext(null);

function FavoriteExams() {
    const [exams, setExams] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);

    const [popUp, setPopUp] = useState(false);

    const pageDivider = 10;
    const [pagination, setPagination] = useState({
        page: 1,
        totalPages: 1,
    });

    const handlePageChange = (event, value) => {
        setPagination({ ...pagination, page: value });
    };

    useEffect(() => {
        if (isOpen === true && exams === null) {
            IdikaDoctorService.getFavoriteExamsAll()
                .then((response) => {
                    setExams(response.data.exams);
                    let newPage = pagination.page;
                    if (
                        (pagination.page - 1) * pageDivider === response.data.exams.length &&
                        pagination.page !== 1
                    ) {
                        newPage = newPage - 1;
                    }
                    setPagination({
                        page: newPage,
                        totalPages: Math.ceil(response.data.exams.length / pageDivider),
                    });
                    setLoading(false);
                })
                .catch((error) => {
                    setError(error);
                    setLoading(false);
                });
        }
        // eslint-disable-next-line
    }, [isOpen, exams]);

    const addOrRemoveExam = (e) => {
        e.preventDefault();
        const examId = e.target.id;
        setLoading(true);
        IdikaDoctorService.addOrRemoveFavoriteExam(examId)
            .then((response) => {
                setExams(null);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    };

    const shownExams = [];
    if (exams !== null) {
        for (let i = (pagination.page - 1) * pageDivider; i < pagination.page * pageDivider; i++) {
            if (i >= exams.length) break;
            shownExams.push(exams[i]);
        }
    }

    return (
        <div className="flexBox">
            <Accordion className="doctorAccordion">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    onClick={() => {
                        setIsOpen(!isOpen);
                    }}
                >
                    <h6>Αγαπημένες Εξετάσεις</h6>
                </AccordionSummary>
                {error !== null ? (
                    <Alert severity="error"> Κάτι πήγε στραβά! </Alert>
                ) : loading === true ? (
                    <Spinner align="center" animation="spinner-border" />
                ) : (
                    <div>
                        <div align="center">
                            <button className="btn buttonPrimary" onClick={() => setPopUp(true)}>
                                Εισαγωγή
                            </button>
                            <br />
                            <br />
                        </div>
                        <AccordionDetails>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <b>ID</b>
                                            </TableCell>
                                            <TableCell>
                                                <b>Κατηγορία Εξετάσεων</b>
                                            </TableCell>
                                            <TableCell>
                                                <b>Εξέταση</b>
                                            </TableCell>
                                            <TableCell>
                                                <b>Λέξεις-Κλειδιά</b>
                                            </TableCell>
                                            <TableCell>
                                                <b>Δράση</b>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {shownExams.map((exam) => {
                                            return (
                                                <TableRow key={exam.id}>
                                                    <TableCell component="th" scope="row">
                                                        {exam.id}
                                                    </TableCell>
                                                    <TableCell>{exam.category}</TableCell>
                                                    <TableCell>{exam.exam}</TableCell>
                                                    <TableCell>{exam.keyWords}</TableCell>
                                                    <TableCell>
                                                        <button
                                                            className="btn buttonDanger"
                                                            id={exam.id}
                                                            onClick={addOrRemoveExam}
                                                        >
                                                            Διαγραφή
                                                        </button>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                                <Pagination
                                    count={pagination.totalPages}
                                    page={pagination.page}
                                    onChange={handlePageChange}
                                />
                            </TableContainer>
                        </AccordionDetails>
                    </div>
                )}
            </Accordion>
            <PopUp
                openPopUp={popUp}
                setOpenPopUp={setPopUp}
                showCloseButton={true}
                title={"Αναζήτηση Εξέτασεων"}
                className="bigModal"
            >
                <FavoriteExamsContext.Provider value={{ favoriteExams: exams, addOrRemoveExam }}>
                    <NewFavoriteExam />
                </FavoriteExamsContext.Provider>
            </PopUp>
        </div>
    );
}

export default FavoriteExams;
