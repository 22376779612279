import axios from "axios";
import { baseUrl, getOptions, postOptions, putOptions, deleteOptions } from "../config.js";

class MediPlusCalendarEventService {
    constructor(baseUrl) {
        this.basePath = "/calendarEvents";
        this.baseUrl = `${baseUrl}${this.basePath}`;
    }

    getAllCalendarEventsByUserId() {
        return axios(getOptions(this.baseUrl));
    }

    addCalendarEvent(calendarEvent) {
        return axios(postOptions(this.baseUrl, "", calendarEvent));
    }

    updateCalendarEvent(calendarEventId, calendarEvent) {
        return axios(putOptions(this.baseUrl, `/${calendarEventId}`, calendarEvent));
    }

    deleteCalendarEvent(calendarEventId) {
        return axios(deleteOptions(this.baseUrl, `/${calendarEventId}`));
    }
}

export default new MediPlusCalendarEventService(baseUrl);
