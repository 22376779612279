import React from "react";

import { mainColumns, patientFields } from "./config.js";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

function PatientInfo({ patientData }) {
    return (
        <div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {mainColumns.map((column, index) => {
                                return (
                                    <TableCell key={index}>
                                        <b>
                                            {column.name !== "Α.Μ.Κ.Α./E.K.A.A."
                                                ? column.name
                                                : patientData.amka.length === 20
                                                ? "E.K.A.A."
                                                : "A.M.K.A."}
                                        </b>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            {patientFields.map((column, index) => {
                                return (
                                    <TableCell key={index}>
                                        {column.name !== "sex"
                                            ? patientData[column.name]
                                            : patientData[column.name]["name"]}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default PatientInfo;
