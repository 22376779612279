import { useEffect, useState , useContext } from "react";
import Terms from "../../../Terms/Terms";
import PopUp from "../../../Utilities/PopUp";
import { CircularProgress, Container, Divider, Grid, Switch, TextField } from "@material-ui/core";
import MediPlusUserService from "../../../../services/MediPlus/MediPlusUserService.js";
import { Alert } from "@material-ui/lab";
import { TabContext } from "../../Doctor";

function Settings({ admin }) {
    const [state, setState] = useState({});
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        MediPlusUserService.getUserByAuthToken()
            .then(({ data }) => {
                // console.log(data);
                setState(data);
            })
            .catch((err) => {
                // console.log(err.response);
                setError(err.response);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return (
        <div>
            {loading ? (
                <CircularProgress />
            ) : error ? (
                <Alert severity="error">Κάτι πήγε στραβά!</Alert>
            ) : (
                <Container>
                    {!admin && <UserInfo inputState={state} />}
                    {!admin && <Divider />}
                    <ResetPassword />
                    {!admin && <Divider />}
                    {!admin && <UserDetails inputState={state.userDetails} />}
                </Container>
            )}
        </div>
    );
}

function UserInfo({ inputState }) {
    const [state, setState] = useState(inputState);

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setState({ ...state, [name]: value });
    };

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const onSubmit = (e) => {
        e.preventDefault();

        let request = {
            firstName: state.firstName,
            lastName: state.lastName,
            userName: state.userName,
            email: state.email,
            address: state.address,
            city: state.city,
            specialty: state.specialty,
            phoneNumber: state.phoneNumber,
        };
        setLoading(true);
        setError(false);
        setSuccess(false);
        MediPlusUserService.updateUser(request)
            .then(({ data }) => {
                // console.log(data);
                setSuccess(true);
            })
            .catch((err) => {
                // console.log(err.response);
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className="mb-5 mt-5">
            {error && (
                <Alert className="mb-3" severity="error">
                    Κάτι πήγε στραβά!
                </Alert>
            )}
            {success && (
                <Alert className="mb-3" severity="success">
                    Τα στοιχεία ενημερώθηκαν με επιτυχία!
                </Alert>
            )}
            <h6 className="mb-3">Στοιχεία Χρήστη</h6>
            <form onSubmit={onSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <TextField
                            className="formInputUIFull"
                            variant="outlined"
                            label="Όνομα"
                            name="firstName"
                            value={state.firstName}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            className="formInputUIFull"
                            variant="outlined"
                            label="Επώνυμο"
                            name="lastName"
                            value={state.lastName}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            className="formInputUIFull"
                            variant="outlined"
                            label="Όνομα χρήστη"
                            name="userName"
                            value={state.userName}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            className="formInputUIFull"
                            variant="outlined"
                            type="email"
                            label="Email"
                            name="email"
                            value={state.email}
                            onChange={handleChange}
                            required
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            className="formInputUIFull"
                            variant="outlined"
                            type="text"
                            label="Διεύθυνση"
                            name="address"
                            value={state.address}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            className="formInputUIFull"
                            variant="outlined"
                            type="text"
                            label="Πόλη"
                            name="city"
                            value={state.city}
                            onChange={handleChange}
                            required
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            className="formInputUIFull"
                            variant="outlined"
                            type="text"
                            label="Ειδικότητα"
                            name="specialty"
                            value={state.specialty}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            className="formInputUIFull"
                            variant="outlined"
                            type="text"
                            label="Αριθμός Τηλεφώνου"
                            name="phoneNumber"
                            value={state.phoneNumber}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                </Grid>
                <button className="btn buttonPrimary mt-3" disabled={loading}>
                    Ενημέρωση Στοιχείων
                </button>
            </form>
        </div>
    );
}

function ResetPassword() {
    const [state, setState] = useState({
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
    });

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setState({ ...state, [name]: value });
    };

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const [success, setSuccess] = useState(false);
    const onSubmit = (e) => {
        e.preventDefault();
        // console.log(state);

        if (state.newPassword !== state.confirmNewPassword) {
            setError({ newPassword: "Οι νέοι κωδικού δεν ταιριάζουν!" });
            return;
        }

        let request = {
            oldPassword: state.oldPassword,
            newPassword: state.newPassword,
        };
        setLoading(true);
        setError({});
        setSuccess(false);
        MediPlusUserService.updatePassword(request)
            .then(({ data }) => {
                setSuccess(true);
            })
            .catch((err) => {
                // console.log(err.response);
                setError(err.response);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className="mb-5 mt-5">
            {Object.keys(error).length !== 0 && (
                <Alert className="mb-3" severity="error">
                    {error.newPassword !== undefined
                        ? error.newPassword
                        : error.data.status === 400 && error.data.errors.oldPassword !== undefined
                        ? "Ο παλιός Κωδικός είναι Λάθος!"
                        : "Κάτι πήγε στραβά!"}
                </Alert>
            )}
            {success && (
                <Alert className="mb-3" severity="success">
                    Ο κωδικός ενημερώθηκε με επιτυχία!
                </Alert>
            )}
            <h6 className="mb-3">Αλλαγή Κωδικού</h6>
            <form onSubmit={onSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <TextField
                            className="formInputUIFull"
                            variant="outlined"
                            type="password"
                            label="Παλιός Κωδικός"
                            name="oldPassword"
                            value={state.oldPassword}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            className="formInputUIFull"
                            variant="outlined"
                            type="password"
                            label="Νέος Κωδικός"
                            name="newPassword"
                            value={state.newPassword}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            className="formInputUIFull"
                            variant="outlined"
                            type="password"
                            label="Επιβεβαίωση Νέου Κωδικού"
                            name="confirmNewPassword"
                            value={state.confirmNewPassword}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                </Grid>
                <button className="btn buttonPrimary mt-3" disabled={loading}>
                    Ενημέρωση Κωδικού
                </button>
            </form>
        </div>
    );
}

function UserDetails({ inputState }) {
    const [state, setState] = useState(inputState);

    const {setAcceptBannerTerms} = useContext(TabContext);
    // const [error, setError] = useState(false);
    // const [loading, setLoading] = useState(true);

    const handleSwitchChange = (event) => {
        let { name, checked } = event.target;
        let request = {};
        request[name] = checked;
        setAcceptBannerTerms(checked);
        MediPlusUserService.updateUserDetailsAll(request).then(({ data }) => {
            setState(data.userDetails);
        });
        // .catch((err) => {
        //     console.log(err.response);
        //     setError(err.response);
        // })
        // .finally(() => {
        //     setLoading(false);
        // });
    };

    const [termsPopUp, setTermsPopUp] = useState(false);

    const [updatesInfoAlert, setUpdatedInfoAlert] = useState(false);
    useEffect(() => {
        if (state.acceptReceivingUpdatesViaApp === true) return;
        setUpdatedInfoAlert(true);
        let timer = setTimeout(() => setUpdatedInfoAlert(false), 2000);
        return () => {
            clearTimeout(timer);
            setUpdatedInfoAlert(false);
        };
    }, [state.acceptReceivingUpdatesViaApp]);

    useEffect(() => {
        if (state.acceptReceivingUpdatesViaContactDetails === true) return;
        setUpdatedInfoAlert(true);
        let timer = setTimeout(() => setUpdatedInfoAlert(false), 2000);
        return () => {
            clearTimeout(timer);
            setUpdatedInfoAlert(false);
        };
    }, [state.acceptReceivingUpdatesViaContactDetails]);

    return (
        <div className="mb-5 mt-5">
            <div>
                {updatesInfoAlert && (
                    <Alert className="mb-3" severity="info">
                        Δε θα μπορείτε να ενημερωθείτε για τυχόν ενημερώσεις του Mediplus.
                    </Alert>
                )}
                <div className="d-flex flex-row form-inline">
                    <Switch checked={true} name="acceptTerms" color="primary" disabled />
                    <div>Διάβασα και Αποδέχομαι τους όρους χρήσης της εφαρμογής Mediplus </div>
                    <div className="form-inline">
                        [
                        <div
                            style={{
                                color: "blue",
                                cursor: "pointer",
                                textDecoration: "underline",
                            }}
                            onClick={() => setTermsPopUp(true)}
                        >
                            Όροι
                        </div>
                        ]
                    </div>
                </div>
                <div className="d-flex flex-row form-inline">
                    <Switch
                        checked={state.acceptReceivingUpdatesViaContactDetails}
                        onChange={handleSwitchChange}
                        name="acceptReceivingUpdatesViaContactDetails"
                        color="primary"
                    />
                    <div>
                        Αποδέχομαι τη λήψη ενημερώσεων και προτάσεων μέσω των στοιχείων επικοινωνίας
                        που έχω δηλώσει
                    </div>
                </div>
                <div className="d-flex flex-row form-inline">
                    <Switch
                        checked={state.acceptReceivingUpdatesViaApp}
                        onChange={handleSwitchChange}
                        name="acceptReceivingUpdatesViaApp"
                        color="primary"
                    />
                    <div>
                        Αποδέχομαι τη λήψη ενημερώσεων και προτάσεων μέσω της εφαρμογής Mediplus
                    </div>
                </div>
            </div>
            <PopUp
                openPopUp={termsPopUp}
                setOpenPopUp={setTermsPopUp}
                title={"Όροι MediPlus"}
                maxWidth="md"
            >
                <Terms />
            </PopUp>
        </div>
    );
}

export default Settings;
