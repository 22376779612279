import React, { useEffect, useState, useContext } from "react";
import PopUp from "../../../../../Utilities/PopUp";
import Buttons from "./Buttons/Buttons";
import { Button } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { formatDate } from "../../../../../Utilities/Utilities";
import { TabContext } from "../../../../Doctor";

function Insurances({ patientData, tabItem, setTabItem, setShowInsurances }) {
    const columns = ["Ασφ. Φορέας", "Άμεσος/Έμμεσος", "Α.Μ.Α.", "Ημ/νία Λήξης Ασφαλ. Ικανότητας"];

    const handleOnClick = (e) => {
        let newPatientData = patientData;
        newPatientData.selectActiveInsurance = false;
        setTabItem({ ...tabItem, patientData: newPatientData });
        setShowInsurances(false);
    };

    const handleSocialInsuranceChange = (index) => {
        let newPatientData = patientData;
        newPatientData.selectedActiveInsurance = index;
        setTabItem({ ...tabItem, patientData: newPatientData });
    };

    return (
        <div align="center">
            <TableContainer className="mb-3" component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((column, index) => {
                                return (
                                    <TableCell key={index}>
                                        <b>{column}</b>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {patientData.activeInsurances.activeInsurance.map(
                            (activeInsurance, index) => {
                                return (
                                    <TableRow
                                        key={index}
                                        style={{
                                            cursor: "pointer",
                                            backgroundColor:
                                                patientData.selectedActiveInsurance === index
                                                    ? "lightgrey"
                                                    : "white",
                                        }}
                                        onClick={() => handleSocialInsuranceChange(index)}
                                    >
                                        <TableCell>
                                            {activeInsurance.socialInsurance.shortName}
                                        </TableCell>
                                        <TableCell>{activeInsurance.memberType.name}</TableCell>
                                        <TableCell>{activeInsurance.ama}</TableCell>
                                        <TableCell>
                                            {formatDate(activeInsurance.expiryDate)}
                                        </TableCell>
                                    </TableRow>
                                );
                            }
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button variant="contained" color="primary" onClick={handleOnClick}>
                OK
            </Button>
        </div>
    );
}

function InsurancePatientInfo({ socialInsurancePatientInfo, setShowSocialInsurancePatientInfo }) {
    return (
        <div align="center">
            <div className="mb-3">{socialInsurancePatientInfo}</div>
            <Button
                variant="contained"
                color="primary"
                onClick={() => setShowSocialInsurancePatientInfo(false)}
            >
                OK
            </Button>
        </div>
    );
}


function InsuredInformation({
    patientData,
    visitDetails,
    setVisitDetails,
    tabIndex,
    tabItem,
    setTabItem,
    setSelectedTab,
}) {
    const { covidCheck,setCovidCheck} = useContext(TabContext);
    const [patient, setPatient] = useState(patientData);
    const [showInsurances, setShowInsurances] = useState(
        patientData.selectActiveInsurance === true ? true : false
    );

    const [showSocialInsurancePatientInfo, setShowSocialInsurancePatientInfo] = useState(false);
    const [socialInsurancePatientInfo, setSocialInsurancePatientInfo] = useState("");

    const [covidPopUp,setCovidPopUp] = useState(false);

    useEffect(() =>{
        if(covidCheck){
            if (patientData.covidVaccineMessage && patientData.covidVaccineMessage.length > 0) {
                setCovidPopUp(true);
            }
            setCovidCheck(false);

        }
    },[covidCheck,patientData,setCovidCheck]);


    useEffect(() => {
        let patientData = tabItem.patientData;
        let activeInsurances = tabItem.patientData.activeInsurances.activeInsurance;
        let activeInsurance = activeInsurances[patientData.selectedActiveInsurance];
        if (
            patientData.selectActiveInsurance === true ||
            patientData.showSocialInsurancePatientInfo === false ||
            showInsurances === true
        )
            return;
        if (activeInsurance.socialInsurance.patientInfoMsg !== "") {
            setSocialInsurancePatientInfo(activeInsurance.socialInsurance.patientInfoMsg);
            patientData.showSocialInsurancePatientInfo = false;
            setTabItem({ ...tabItem, patientData });
            setShowSocialInsurancePatientInfo(true);
        }
        // eslint-disable-next-line

    }, [
        patientData.selectActiveInsurance,
        patientData.showSocialInsurancePatientInfo,
        showInsurances,setTabItem,tabItem
    ]);

    return (
        <div>
            <div className="boxTitle">
                <h6>Στοιχεία Ασφαλισμένου</h6>
            </div>
            <div className="boxContent">
                <div className="insurInfo">
                    <div className="flexBoxInfoWrap">
                        <div className="flexColumn">
                            <div className="flexWrap">
                                <p className="infoP alignRight">Ονοματεπώνυμο</p>
                                <p className="noMargin" id="insurdFullname">
                                    {patient.firstName} {patient.lastName}
                                </p>
                            </div>
                            <div className="flexWrap">
                                <p className="infoP alignRight">
                                    {patient.amka.length === 20 ? "Ε.Κ.Α.Α." : "Α.Μ.Κ.Α."}
                                </p>
                                <p className="noMargin" id="insuredAmka">
                                    {patient.amka}
                                </p>
                            </div>
                            <div className="flexWrap">
                                <p className="infoP alignRight">Τηλέφωνο</p>
                                <p className="noMargin" id="insuredPhone">
                                    {patient.telephone}
                                </p>
                            </div>
                            <div className="flexWrap">
                                <p className="infoP alignRight">Οδός/Αριθμός</p>
                                <p className="noMargin" id="insuredAddress">
                                    {patient.address}
                                </p>
                            </div>
                            <div className="flexWrap">
                                <p className="infoP alignRight">Πόλη</p>
                                <p className="noMargin" id="insuredTown">
                                    {patient.city}
                                </p>
                            </div>
                            <div className="flexWrap">
                                <p className="infoP alignRight">Τ.Κ.</p>
                                <p className="noMargin" id="insuredTk">
                                    {patient.postalCode}
                                </p>
                            </div>
                        </div>
                        <div className="flexColumn">
                            <div className="flexWrap">
                                <p className="infoP alignRight">Ημ/νία Γέννησης</p>
                                <p className="noMargin" id="insuredBirthdate">
                                    {formatDate(patient.birthDate)}
                                </p>
                            </div>
                            <div className="flexWrap">
                                <p className="infoP alignRight">Φύλο</p>
                                <p className="noMargin" id="insuredSex">
                                    {patient.sex.name}
                                </p>
                            </div>
                            <div className="flexWrap">
                                <p className="infoP alignRight">Φορέας Κοινωνικής Ασφάλισης</p>
                                <p className="noMargin" id="insuredSocialInsurance">
                                    {
                                        patient.activeInsurances.activeInsurance[
                                            patient.selectedActiveInsurance
                                        ].socialInsurance.shortName
                                    }
                                </p>
                            </div>
                            {patient.amka.length === 20 ? (
                                <div className="flexWrap">
                                    <p className="infoP alignRight">Χώρα</p>
                                    <p className="noMargin" id="insuredDirectIndirect">
                                        {patient.country.name} {patient.country.codeNum}
                                    </p>
                                </div>
                            ) : (
                                <div className="flexWrap">
                                    <p className="infoP alignRight">Άμεσος/Έμμεσος</p>
                                    <p className="noMargin" id="insuredDirectIndirect">
                                        {
                                            patient.activeInsurances.activeInsurance[
                                                patient.selectedActiveInsurance
                                            ].memberType.name
                                        }
                                    </p>
                                </div>
                            )}
                            <div className="flexWrap">
                                <p className="infoP alignRight">Αριθμός Μητρώου</p>
                                <p className="noMargin" id="insuredRegistrationNumber">
                                    {
                                        patient.activeInsurances.activeInsurance[
                                            patient.selectedActiveInsurance
                                        ].ama
                                    }
                                </p>
                            </div>
                            <div className="flexWrap">
                                <p className="infoP alignRight">Ημ/νία Λήξης Ασφαλ. Ικανότητας</p>
                                <p className="noMargin" id="insuredExpirationInsuranceDate">
                                    {formatDate(
                                        patient.activeInsurances.activeInsurance[
                                            patient.selectedActiveInsurance
                                        ].expiryDate
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                    <Buttons
                        patient={patient}
                        setPatient={setPatient}
                        visitDetails={visitDetails}
                        setVisitDetails={setVisitDetails}
                        tabIndex={tabIndex}
                        tabItem={tabItem}
                        setTabItem={setTabItem}
                        setSelectedTab={setSelectedTab}
                    />
                </div>
            </div>
            <PopUp
                openPopUp={showInsurances}
                setOpenPopUp={setShowInsurances}
                title={"Επιλογή Ασφαλιστικού Φορέα"}
                showCloseButton={false}
            >
                <Insurances
                    patientData={patientData}
                    tabItem={tabItem}
                    setTabItem={setTabItem}
                    setShowInsurances={setShowInsurances}
                />
            </PopUp>
            <PopUp
                openPopUp={showSocialInsurancePatientInfo}
                setOpenPopUp={setShowSocialInsurancePatientInfo}
                title={"Προειδοποίηση"}
            >
                <InsurancePatientInfo
                    socialInsurancePatientInfo={socialInsurancePatientInfo}
                    setShowSocialInsurancePatientInfo={setShowSocialInsurancePatientInfo}
                />
            </PopUp>
            <PopUp
                openPopUp={covidPopUp}
                setOpenPopUp={setCovidPopUp}
                title={"Προειδοποίηση Covid-19"}
                maxWidth="sm"
                showCloseButton={false}
            >
                <Covid19Info setCovidPopUp={setCovidPopUp} />
            </PopUp>
        </div>
    );
}

function Covid19Info({ setCovidPopUp }) {
    const openCovidLink = () => {
        window.open(
            "https://eps.e-prescription.gr/auth/realms/e-prescription/protocol/openid-connect/auth?response_type=code&scope=profile+mobile+email&client_id=vacreg&redirect_uri=https%3A%2F%2Femvolio.gov.gr%2Fpharmacist%2Feprescriptionlogin"
        );
    };

    return (
        <div align="center">
            <div className="mb-3">
                <p>
                    Εάν ο πολίτης δεν είναι εμβολιασμένος έναντι της νόσου COVID-19 ,ως
                    Θεράπων/Προσωπικός/Οικογενειακός ιατρός παρακαλούμε ενημερώστε τον και συζητήστε
                    μαζί του για τα οφέλη του εμβολιασμού. Μπορείτε να προγραμματίστε ραντεβού στον
                    πολίτη στο παρακάτω link:
                </p>

                <Button variant="contained" color="primary" size="small" onClick={openCovidLink}>
                    Προγραμματισμός Ραντεβού
                </Button>
            </div>
            
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => setCovidPopUp(false)}
                >
                    OK
                </Button>
                
           
        </div>
    );
}

export default InsuredInformation;
