import React, { useState } from "react";

import { mainColumns } from "./config.js";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import MediPlusVisitReasonService from "../../../../../../../services/MediPlus/MediPlusVisitReasonService.js";

function DeleteVisitReason({ visitReasons, setVisitReasons }) {
    const [error, setError] = useState({});
    const [successAlert, setSuccessAlert] = useState(false);

    const dltVisitReason = (id) => {
        setError({});
        setSuccessAlert(false);
        MediPlusVisitReasonService.deleteVisitReason(id)
            .then(() => {
                setVisitReasons(visitReasons.filter((vr) => vr.id !== id));
                setSuccessAlert(true);
            })
            .catch((error) => {
                setError(error);
            });
    };

    return (
        <div>
            {Object.keys(error).length !== 0 && (
                <div>
                    <Alert severity="error">Κάτι πήγε στραβά!</Alert>
                    <br />
                </div>
            )}
            {successAlert === true && (
                <div>
                    <Alert severity="success">Η Διαγραφή έγινε με επιτυχία!</Alert>
                    <br />
                </div>
            )}
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {mainColumns.map((column, index) => {
                                return (
                                    <TableCell key={index}>
                                        <b>{column.name}</b>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {visitReasons.map((visitReason) => {
                            return (
                                <TableRow key={visitReason.id}>
                                    <TableCell>{visitReason.id}</TableCell>
                                    <TableCell>{visitReason.reason}</TableCell>
                                    <TableCell>{visitReason.createdDate}</TableCell>
                                    <TableCell>
                                        <button
                                            className="btn buttonDanger"
                                            onClick={() => dltVisitReason(visitReason.id)}
                                        >
                                            Διαγραφή
                                        </button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default DeleteVisitReason;
