const mainColumns = [
    { name: "Όνομα" },
    { name: "Αριθμός Φαρμάκων" },
    { name: "Ημ/νία Δημιουργίας" },
    { name: "" },
];

const medicineColumns = [
    { name: "Προτείνεται η χορήγηση γενόσημου σκευάσματος" },
    { name: "Θεραπευτικό Πρωτόκολλο" },
    { name: "Δραστική Ουσία" },
    { name: "Μονάδα Δόσης" },
    { name: "Ποσό Δόσης" },
    { name: "Συχνότητα" },
    { name: "Ημέρες" },
    { name: "Ποσότητα" },
    { name: "Ποσό Συμμετοχής" },
];

const diagnosisColumns = [{ name: "Κωδικός ICD-10" }, { name: "Διάγνωση" }];

export { mainColumns, medicineColumns, diagnosisColumns };
