const mainColumns = [
    { name: "Προτείνεται η χορήγηση γενόσημου σκευάσματος" },
    { name: "Θεραπευτικό Πρωτόκολλο" },
    { name: "Δραστική Ουσία" },
    { name: "Μονάδα Δόσης" },
    { name: "Ποσό Δόσης" },
    { name: "Συχνότητα" },
    { name: "Ημέρες" },
    { name: "Ποσότητα" },
    { name: "Υπόλοιπο" },
    { name: "Ποσοστό Συμμετοχής" },
    { name: "Συμμετοχή Ασφ/νου" },
    { name: "Διαφορά Ασφ/νου" },
];

const galenicColumns = [
    { name: "Ονομασία" },
    { name: "Φαρμ/κή Μορφή" },
    { name: "Μονάδα Μέτρησης" },
    { name: "Συνολική Ποσότητα" },
    { name: "Μονάδα Δόσης" },
    { name: "Ποσό Δόσης" },
    { name: "Συχνότητα" },
    { name: "Ημέρες" },
    { name: "% Συμμετοχή" },
    { name: "Τιμή" },
    { name: "Συμμετοχή Ασφ/νου" },
    { name: "Διαφορά Ασφ/νου" },
    { name: "Σχόλια" },
    { name: "" },
];

const galenicIngredientsColumns = [
    { name: "Τύπος" },
    { name: "Περιγραφή" },
    { name: "Μονάδα Μέτρησης" },
    { name: "Ποσότητα Χρήσης" },
    { name: "% Συμμετοχή" },
    { name: "Τιμή Μονάδας" },
    { name: "Τιμή Αποζ. Ασφ." },
    { name: "Τιμή" },
    { name: "Συμμετοχή Ασφ/νου" },
    { name: "Διαφορά Ασφ/νου" },
    { name: "Σχόλια" },
];

export { mainColumns, galenicColumns, galenicIngredientsColumns };
