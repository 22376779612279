import React, { useState } from "react";
import { mainColumns, prescriptionFields } from "./config.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IdikaPrescriptionService from "../../../../../../services/MediPlus/Idika/IdikaPrescriptionService.js";
import Alert from "@material-ui/lab/Alert";

function Prescriptions({ prescriptions, tabItem, setTabItem, setSelectedTabBttn }) {
    const [error, setError] = useState(null);
    const [prescriptionId, setPrescriptionId] = useState(-1);

    if (prescriptions === undefined) return null;

    function onIdClick(prescriptionId) {
        setError(null);
        setPrescriptionId(prescriptionId);
        IdikaPrescriptionService.getPrescription(prescriptionId)
            .then(({ data }) => {
                setTabItem({ ...tabItem, subTab: 7, viewPrescription: data });
                setSelectedTabBttn(7);
            })
            .catch((error) => {
                setError(error);
                setPrescriptionId(-1);
            });
    }

    function onPrescriptiuonProcess(prescriptionId) {
        setError(null);
        setPrescriptionId(prescriptionId);
        IdikaPrescriptionService.getPrescription(prescriptionId)
            .then(({ data }) => {
                setTabItem({ ...tabItem, subTab: 2, addPrescription: data.objectForRepeat });
                setSelectedTabBttn(2);
            })
            .catch((error) => {
                setError(error);
                setPrescriptionId(-1);
            });
    }

    return (
        <div>
            {error && <Alert severity="error">Κάτι πήγε στραβά!</Alert>}
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {mainColumns.map((column, index) => {
                                return (
                                    <TableCell key={index}>
                                        <b>{column.name}</b>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {prescriptions.map((prescription, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell>
                                        <button
                                            onClick={() => onIdClick(prescription.id)}
                                            className="btn buttonPrimary"
                                            disabled={prescription.id === prescriptionId}
                                        >
                                            {prescription.id === prescriptionId ? (
                                                <div className="form-inline">
                                                    <i className="fa fa-spinner fa-pulse mr-3"></i>
                                                    Άντληση....
                                                </div>
                                            ) : (
                                                prescription.id
                                            )}
                                        </button>
                                    </TableCell>
                                    {prescriptionFields.map((field, indexField) => {
                                        return (
                                            <TableCell key={indexField}>
                                                {prescription[field.name]}
                                            </TableCell>
                                        );
                                    })}
                                    <TableCell>
                                        {prescription.status === "ΠΡΟΣΩΡΙΝΑ ΣΥΝΤΑΓΟΓΡΑΦΗΜΕΝΗ" && (
                                            <button
                                                className="btn buttonPrimary"
                                                onClick={() =>
                                                    onPrescriptiuonProcess(prescription.id)
                                                }
                                                disabled={prescription.id === prescriptionId}
                                            >
                                                Επεξεργασία
                                            </button>
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default Prescriptions;
