import { toolbar } from "./config.js";
import parser from "html-react-parser";
import { Alert } from "@material-ui/lab";
import { useEffect, useState, useContext } from "react";
import { CircularProgress } from "@material-ui/core";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import MediPlusCustomPrescriptionService from "../../../../../../../../services/MediPlus/MediPlusCustomPrescriptionService";
import MediPlusCustomPrescriptionHistoryService from "../../../../../../../../services/MediPlus/MediPlusCustomPrescriptionHistoryService.js";
import MediPlusPdfService from "../../../../../../../../services/MediPlus/MediPlusPdfService.js";
import { PatientContext } from "../../../../../Esyntagografisi.js";

function CustomPrescription({body,setBody}) {
    const patient = useContext(PatientContext);

    const [header, setHeader] = useState("");
    const [footer, setFooter] = useState("");
    const [image, setImage] = useState(null);
    const [imagePosition, setImagePosition] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [saveInfo, setSaveInfo] = useState(false);
    const [saveError, setSaveError] = useState(false);
    const [emptyError, setEmptyError] = useState(false);

    useEffect(() => {
        MediPlusCustomPrescriptionService.getUserDefaultCustomPrescription()
            .then(({ data }) => {
                if (data.header !== undefined) setHeader(data.header);
                if (data.footer !== undefined) setFooter(data.footer);
                setImage(data.image);
                setImagePosition(data.customPrescriptionImagePosition);
            })
            .catch((err) => {
                // console.log(err.response);
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);


    const generatePdf = (header, body, footer) => {
        if(body !== ""){
            MediPlusPdfService.getCustomPrescription({
                header,
                body,
                footer,
                imageBytes: image?.bytes ?? null,
                imagePosition: imagePosition?.name ?? null,
            }).then(({ data }) => {
                let file = new Blob([data], { type: "application/pdf" });
                let fileURL = URL.createObjectURL(file);
                saveCustomPrescription(body, patient.id, patient.amka);
                setEmptyError(false)
                window.open(fileURL);
            });
        }else{
            setEmptyError(true);
        }
        
    };

    const saveCustomPrescription = (body, id, amka) => {
        MediPlusCustomPrescriptionHistoryService.addCustomPrescriptionHistory({
            body,
            id,
            amka,
        })
            .then(({ data }) => {
                setSaveInfo(true);
            })
            .catch((err) => {
                // console.log(err.response);
                setSaveError(true);
            });
    };

    return (
        <div>
            {saveInfo ? (
                <Alert severity="success">Επιτυχής καταχώρηση σημειώματος!</Alert>
            ) : saveError ? (
                <Alert severity="error">Σφάλμα κατα την καταχώρηση σημειώματος!</Alert>
            ) : loading ? (
                <CircularProgress />
            ) : error ? (
                <Alert severity="error">Κάτι πήγε στραβά!</Alert>
            ) : (
                <div>
                    {emptyError && <Alert severity="error">Δεν επιτρέπεται η δημιουργία κενού σημειώματος!</Alert>}
                    {header !== "" && (
                        <div className="mb-5">
                            <h6>Αρχή Κείμενου</h6>
                            {parser(header)}
                        </div>
                    )}

                    <div className="mb-5">
                        <h6>Ελέυθερο Κείμενο</h6>
                        <CKEditor
                            editor={ClassicEditor}
                            config={{
                                toolbar,
                            }}
                            data={body}
                            onChange={(event, editor) => setBody(editor.getData())}
                        />
                    </div>

                    {footer !== "" && (
                        <div className="mb-5">
                            <h6>Τέλος Κείμενου</h6>

                            {parser(footer)}
                        </div>
                    )}
                    {image && (
                        <div>
                            <h6>
                                Logo <span>({imagePosition.name})</span>
                            </h6>
                            <div className="form-inline">
                                <img
                                    style={{
                                        height: "50px",
                                        margin: "10px",
                                    }}
                                    alt="img"
                                    src={`data:image/jpeg;base64,${image.bytes}`}
                                />
                            </div>
                        </div>
                    )}
                    <div align="center">
                        <button
                            className="btn buttonPrimary"
                            onClick={() => generatePdf(header, body, footer)}
                        >
                            Δημιουργία PDF
                        </button>
                    </div>
                </div>
            )}

        </div>
    );
}

export default CustomPrescription;
