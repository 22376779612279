import React, { useState } from "react";
import { mainColumns } from "./config.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";
import Alert from "@material-ui/lab/Alert";
import IdikaPrescriptionService from "../../../../../../services/MediPlus/Idika/IdikaPrescriptionService.js";
import { formatDate, showButtonBasedOnDate } from "../../../../../Utilities/Utilities.js";
import { initialAddReferralState } from "../../../../Doctor.js";

function PrescriptionResults({
    prescriptions = null,
    state,
    setState,
    setFetch,
    addTab,
    tabItem,
    setTabItem,
    setSelectedTab,
    setSelectedTabBttn,
}) {
    const [error, setError] = useState(null);

    if (prescriptions === null) return null;

    const handlePageChange = (e, value) => {
        e.preventDefault();
        setState({ ...state, page: value });
        setFetch(true);
    };

    const onPrescriptionFetch = (prescriptionId) => {
        setError(null);
        IdikaPrescriptionService.getPrescription(prescriptionId)
            .then(({ data }) => {
                if (tabItem.patientData === null) {
                    let tabId = addTab(
                        data.patientData,
                        {
                            id: -1,
                            startDate: "",
                            reason: "",
                            comment: "",
                            icpc2s: [],
                            active: false,
                            status: "",
                            prescriptions: [],
                            referrals: [],
                        },
                        7,
                        data
                    );
                    setSelectedTab(tabId);
                } else {
                    setTabItem({ ...tabItem, subTab: 7, viewPrescription: data });
                    setSelectedTabBttn(7);
                }
            })
            .catch(({ response }) => {
                setError(response);
            });
    };

    const onEdit = (prescription) => {
        IdikaPrescriptionService.getPrescriptionForEdit(prescription.barcode)
            .then(({ data }) => {
                data.objectForRepeat.id = prescription.barcode.toString();
                if (tabItem.patientData === null) {
                    let tabId = addTab(
                        data.visit.patientData,
                        data.visit.visitDetails,
                        2,
                        {},
                        {},
                        initialAddReferralState,
                        data.objectForRepeat,
                        "prescription"
                    );
                    setSelectedTab(tabId);
                } else {
                    setTabItem({ ...tabItem, subTab: 2, addPrescription: data.objectForRepeat });
                    setSelectedTabBttn(2);
                }
            })
            .catch((err) => {
                setError(err.response);
            });
    };

    return (
        <div>
            {prescriptions.count === 0 ? (
                <Alert severity="info">Δεν βρέθηκαν συνταγές!</Alert>
            ) : (
                <div>
                    {error && (
                        <div>
                            <Alert severity="error">Κάτι πήγε στραβά!</Alert>
                            <br />
                        </div>
                    )}
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {mainColumns.map((column, index) => {
                                        return (
                                            <TableCell key={index}>
                                                <b>{column.name}</b>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {prescriptions.prescriptions.map((prescription) => {
                                    return (
                                        <TableRow key={prescription.barcode}>
                                            <TableCell>
                                                <button
                                                    className="btn buttonPrimary"
                                                    onClick={() =>
                                                        onPrescriptionFetch(prescription.barcode)
                                                    }
                                                >
                                                    {prescription.barcode}
                                                </button>
                                            </TableCell>
                                            <TableCell>{prescription.patientInfo.amka}</TableCell>
                                            <TableCell>
                                                {prescription.patientInfo.lastName}
                                            </TableCell>
                                            <TableCell>
                                                {prescription.patientInfo.firstName}
                                            </TableCell>
                                            <TableCell>
                                                {prescription.patientInfo.socialInsuranceShortName}
                                            </TableCell>
                                            <TableCell>
                                                {formatDate(prescription.issueDate)}
                                            </TableCell>
                                            <TableCell>{prescription.status.status}</TableCell>
                                            <TableCell>
                                                {prescription.status.status ===
                                                    "ΣΥΝΤΑΓΟΓΡΑΦΗΜΕΝΗ" &&
                                                    showButtonBasedOnDate(
                                                        prescription.issueDate
                                                    ) && (
                                                        <button
                                                            className="btn buttonPrimary"
                                                            onClick={() => onEdit(prescription)}
                                                        >
                                                            Επεξεργασία
                                                        </button>
                                                    )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        <br />
                        <Pagination
                            count={prescriptions.totalPages}
                            page={state.page}
                            onChange={handlePageChange}
                        />
                    </TableContainer>
                </div>
            )}
        </div>
    );
}

export default PrescriptionResults;
