import axios from "axios";
import {
    baseUrl,
    getOptions,
    getPdfOptions,
    putOptions,
    postOptions,
    deleteOptions,
} from "../../config.js";

class IdikaVisitService {
    constructor(baseUrl) {
        this.basePath = "/idika/visits";
        this.baseUrl = `${baseUrl}${this.basePath}`;
    }

    getVisit(visitId) {
        return axios(getOptions(this.baseUrl, `/${visitId}`));
    }

    getVisits(parameters) {
        return axios(getOptions(this.baseUrl, "", parameters));
    }

    getVisitsWithContent(parameters) {
        return axios(getOptions(this.baseUrl, "/withcontent", parameters));
    }

    getActiveVisit() {
        return axios(getOptions(this.baseUrl, "/active"));
    }

    createNewVisit(data) {
        return axios(postOptions(this.baseUrl, "", data));
    }

    updateVisit(visitId, data) {
        return axios(putOptions(this.baseUrl, `/${visitId}`, data));
    }

    cancelVisit(visitId) {
        return axios(putOptions(this.baseUrl, `/${visitId}/cancel`));
    }

    closeVisit(visitId, inQuota,state) {
        return axios(putOptions(this.baseUrl, `/${visitId}/close?inQuota=${inQuota}`,state));
    }

    reopenVisit(visitId) {
        return axios(putOptions(this.baseUrl, `/${visitId}/reopen`));
    }

    printVisit(visitId) {
        return axios(getPdfOptions(this.baseUrl, `/${visitId}/print`));
    }

    printAllVisitReferrals(visitId) {
        return axios(getPdfOptions(this.baseUrl, `/voucher/${visitId}/print`));
    }

    getTreatmentProtocolSteps(visitId) {
        return axios(getOptions(this.baseUrl, `/${visitId}/treatmentprotocol/steps`));
    }

    deleteTreatmentProtocolStep(visitId, stepId) {
        return axios(deleteOptions(this.baseUrl, `/${visitId}/treatmentprotocol/steps/${stepId}`));
    }

    getTreatmentProtocolIcd10s() {
        return axios(getOptions(this.baseUrl, `/treatmentprotocols/icd10s`));
    }
}

export default new IdikaVisitService(baseUrl);
