import React from "react";
import "./Header.css";
import { useHistory } from "react-router-dom";
import { getJwt } from "../Utilities/JwtUtilities.js";

function Header() {
    const history = useHistory();
    const token = getJwt();

    const signOut = () => {
        sessionStorage.clear();
        window.location = "/";
    };

    return (
        <nav className="navbar navbar-dark">
            <a className="navbar-brand" href={token === null ? "/" : "#/"}>
                <img
                    className={token === null ? "pointer" : "default"}
                    src="/images/MediPlusLogoNoPadding.png"
                    height="40px"
                    alt="MediPlusLogoNoPadding"
                />
            </a>
            {token === null ? (
                <div id="headerButtons">
                    <button
                        className="btn buttonPrimary HeaderButton"
                        onClick={() => {
                            history.push("/SignIn");
                        }}
                    >
                        <i className="fa fa-sign-in"></i>
                        Σύνδεση
                    </button>
                    <button
                        className="btn buttonPrimary HeaderButton"
                        onClick={() => {
                            history.push("/SignUp");
                        }}
                    >
                        <i className="fa fa-user-plus"></i>
                        Εγγραφή
                    </button>
                </div>
            ) : (
                <div>
                    <button className="btn buttonPrimary HeaderButton" onClick={signOut}>
                        <i className="fa fa-sign-out"></i>
                        Αποσύνδεση
                    </button>
                </div>
            )}
        </nav>
    );
}

export default Header;
