import React, { useState } from "react";
import { Spinner } from "../../../../../../../../Utilities/Utilities";
import Alert from "@material-ui/lab/Alert";
import MediPlusCommentService from "../../../../../../../../../services/MediPlus/MediPlusCommentService";

function AddComment({ commentId, commentContent, patientId, comments, setComments }) {
    const [comment, setComment] = useState(commentContent);
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const [successAlert, setSuccessAlert] = useState(false);

    const onSubmit = (e) => {
        e.preventDefault();

        setError({});
        setLoading(true);
        setSuccessAlert(false);
        MediPlusCommentService.updateComment(commentId, patientId, { comment: comment })
            .then(({ data }) => {
                let content = comments.content.map((cmt) => (cmt.id !== commentId ? cmt : data));
                setComments({ ...comments, content: content });
                setSuccessAlert(true);
            })
            .catch((error) => {
                setError(error.response);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div>
            {Object.keys(error).length !== 0 && (
                <div>
                    <Alert severity="error">Κάτι πήγε στραβά!</Alert>
                    <br />
                </div>
            )}
            {successAlert === true && (
                <div>
                    <Alert severity="success">Η Ενημέρωση έγινε με επιτυχία!</Alert>
                    <br />
                </div>
            )}
            <form onSubmit={onSubmit}>
                <div className="form-group">
                    <label>Σχόλιο *</label>
                    <input
                        type="text"
                        className="form-control"
                        name="comment"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        required
                    />
                </div>
                <div align="center">
                    {loading === true ? (
                        <Spinner align="center" animation="spinner-border" />
                    ) : (
                        <div>
                            <button className="btn buttonPrimary">Αποθήκευση</button>
                        </div>
                    )}
                </div>
            </form>
        </div>
    );
}

export default AddComment;
