import { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Container from "@material-ui/core/Container";
import { ckeditorToolbar } from "../../../config";
import MediPlusTermsService from "../../../services/MediPlus/MediPlusTermsService";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";

function ApplicationTerms() {
    const [content, setContent] = useState("");
    const [loadingTerms, setLoadingTerms] = useState(true);
    const [errorTerms, setErrorTerms] = useState(false);
    useEffect(() => {
        MediPlusTermsService.getLatestCreatedTerms()
            .then(({ data }) => {
                setContent(data.content);
            })
            .catch((err) => {
                // console.log(err.response);
                setErrorTerms(true);
            })
            .finally(() => {
                setLoadingTerms(false);
            });
    }, []);

    const [success, setSuccess] = useState(false);
    const [saveError, setSaveError] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const onSave = (e) => {
        e.preventDefault();

        setSaveLoading(true);
        setSaveError(false);
        setSuccess(false);
        MediPlusTermsService.addTerms(content)
            .then(({ data }) => {
                setSuccess(true);
            })
            .catch((err) => {
                // console.log(err.response);
                setSaveError(true);
            })
            .finally(() => {
                setSaveLoading(false);
            });
    };

    return (
        <div>
            {loadingTerms ? (
                <CircularProgress />
            ) : errorTerms ? (
                <Alert severity="error">Κάτι πήγε στραβά!</Alert>
            ) : (
                <Container>
                    {success && (
                        <Alert severity="success" className="mb-3">
                            Οι όροι της εφαρμογής αποθηκεύτηκαν με επιτυχία!
                        </Alert>
                    )}
                    {saveError && <Alert severity="error">Κάτι πήγε στραβά!</Alert>}
                    <CKEditor
                        editor={ClassicEditor}
                        config={{
                            toolbar: ckeditorToolbar,
                        }}
                        data={content}
                        onChange={(event, editor) => setContent(editor.getData())}
                    />
                    <div align="center" className="mt-5">
                        <button
                            className="btn buttonPrimary"
                            onClick={onSave}
                            disabled={saveLoading}
                        >
                            Αποθήκευση
                        </button>
                    </div>
                </Container>
            )}
        </div>
    );
}

export default ApplicationTerms;
