import React, { useState, useEffect } from "react";

import { mainColumns } from "./config.js";
import PopUp from "../../../../../../Utilities/PopUp.js";
import { Spinner } from "../../../../../../Utilities/Utilities.js";
import IdikaPatientService from "../../../../../../../services/MediPlus/Idika/IdikaPatientService.js";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import AddBiometric from "./AddBiometric/AddBiometric.js";
import EditBiometric from "./EditBiometric/EditBiometric.js";

function PatientBiometrics({ patientData }) {
    const [biometrics, setBiometrics] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    useEffect(() => {
        let request = {};
        if (patientData.amka.length === 20) request.ekaa = patientData.amka;
        else request.amka = patientData.amka;
        IdikaPatientService.getPatientBiovalues(request)
            .then(({ data }) => {
                setBiometrics(data);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    }, [patientData]);

    const [openPopUp, setOpenPopUp] = useState(false);
    const [editPopUp, setEditPopUp] = useState(false);

    const [currentBiometric, setCurrentBiometric] = useState({});
    const onEdit = (biometric) => {
        setCurrentBiometric({
            ...biometric,
        });
        setEditPopUp(true);
    };

    if (error !== null) return <Alert severity="error">Κάτι πήγε στραβά!</Alert>;
    else if (loading === true) return <Spinner align="center" animation="spinner-border" />;

    return (
        <div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {mainColumns.map((column, index) => {
                                return (
                                    <TableCell key={index}>
                                        <b>{column.name}</b>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {biometrics &&
                            biometrics.biovalues.map((biometric, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>
                                            {biometric.biometricsPool.description}
                                        </TableCell>
                                        <TableCell>{biometric.bioValue}</TableCell>
                                        <TableCell>{biometric.biometricsPool.mesUnit}</TableCell>
                                        <TableCell>{biometric.dateInserted}</TableCell>
                                        <TableCell>
                                            <button
                                                className="btn buttonPrimary"
                                                onClick={() => onEdit(biometric)}
                                            >
                                                Επεξεργασία
                                            </button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            <div align="center">
                <button className="btn buttonPrimary" onClick={() => setOpenPopUp(true)}>
                    Προσθήκη
                </button>
            </div>
            <PopUp
                openPopUp={openPopUp}
                setOpenPopUp={setOpenPopUp}
                title={"Προσθήκη Βιομετρικού Στοιχείου"}
                maxWidth="sm"
            >
                <AddBiometric
                    amkaOrEkaa={patientData.amka}
                    patientBiovalues={biometrics}
                    setPatientBiovalues={setBiometrics}
                    setOpenPopUp={setOpenPopUp}
                />
            </PopUp>
            <PopUp
                openPopUp={editPopUp}
                setOpenPopUp={setEditPopUp}
                title={"Επεξεργασία Βιομετρικού Στοιχείου"}
                maxWidth="sm"
            >
                <EditBiometric
                    amkaOrEkaa={patientData.amka}
                    biometric={currentBiometric}
                    biometrics={biometrics}
                    setBiometrics={setBiometrics}
                />
            </PopUp>
        </div>
    );
}

export default PatientBiometrics;
