import { useState, useEffect } from "react";
import MediPlusTermsService from "../../services/MediPlus/MediPlusTermsService";
import parser from "html-react-parser";
import { CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

function Terms() {
    const [content, setContent] = useState("");
    const [loadingTerms, setLoadingTerms] = useState(true);
    const [errorTerms, setErrorTerms] = useState(false);
    useEffect(() => {
        MediPlusTermsService.getLatestCreatedTerms()
            .then(({ data }) => {
                setContent(data.content);
            })
            .catch((err) => {
                // console.log(err.response);
                setErrorTerms(true);
            })
            .finally(() => {
                setLoadingTerms(false);
            });
    }, []);

    return (
        <div>
            {loadingTerms ? (
                <CircularProgress />
            ) : errorTerms ? (
                <Alert severity="error">Κάτι πήγε στραβά!</Alert>
            ) : (
                <div>{parser(content)}</div>
            )}
        </div>
    );
}

export default Terms;
