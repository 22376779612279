import { makeStyles } from "@material-ui/core";
import { locationMap } from "../../config.js";

const useStyles = makeStyles({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        margin: 0,
        padding: 0,
    },
    h2: {
        margin: "20px 0",
    },
    h4: {
        margin: "20px 0",
    },
});

// this component is displayed if the user tries to access an unknown route
function Error() {
    const classes = useStyles();

    const redirectToHome = () => {
        window.location = locationMap[sessionStorage.getItem("role")];
    };

    return (
        <div className={classes.root}>
            <h2 className={classes.h2}>Ουπς! Η σελίδα δεν βρέθηκε.</h2>
            <img src="/images/404.png" alt="404" />
            <h4 className={classes.h4}>Δεν μπορούμε να βρούμε την σελίδα που ψάχνετε.</h4>
            <button className="btn buttonPrimary" onClick={redirectToHome}>
                Επιστροφή στην Αρχική
            </button>
        </div>
    );
}

export default Error;
