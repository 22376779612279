import React, { useState } from "react";
import { mainColumns } from "./config.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";
import Alert from "@material-ui/lab/Alert";
import IdikaReferralService from "../../../../../../services/MediPlus/Idika/IdikaReferralService.js";
import { formatDate, showButtonBasedOnDate } from "../../../../../Utilities/Utilities.js";
import { addPrescriptionInitialState } from "../../../../Doctor.js";

function ReferralsResults({
    referrals = null,
    state,
    setState,
    setFetch,
    addTab,
    tabItem,
    setTabItem,
    setSelectedTab,
    setSelectedTabBttn,
}) {
    const [error, setError] = useState(null);

    if (referrals === null) return null;

    const handlePageChange = (e, value) => {
        e.preventDefault();
        setState({ ...state, page: value });
        setFetch(true);
    };

    const onReferralFetch = (referralId) => {
        setError(null);
        IdikaReferralService.getReferral(referralId)
            .then(({ data }) => {
                if (tabItem.patientData === null) {
                    let tabId = addTab(
                        data.patientData,
                        {
                            id: -1,
                            startDate: "",
                            reason: "",
                            comment: "",
                            icpc2s: [],
                            active: false,
                            status: "",
                            prescriptions: [],
                            referrals: [],
                        },
                        8,
                        {},
                        data
                    );
                    setSelectedTab(tabId);
                } else {
                    setTabItem({ ...tabItem, subTab: 8, viewReferral: data });
                    setSelectedTabBttn(8);
                }
            })
            .catch(({ response }) => {
                setError(response);
            });
    };

    const onEdit = (referral) => {
        IdikaReferralService.getReferralForEdit(referral.barcode, { convert: true })
            .then(({ data }) => {
                if (tabItem.patientData === null) {
                    let tabId = addTab(
                        data.visit.patientData,
                        data.visit.visitDetails,
                        3,
                        {},
                        {},
                        data,
                        addPrescriptionInitialState,
                        "referral"
                    );
                    setSelectedTab(tabId);
                } else {
                    setTabItem({ ...tabItem, subTab: 3, addReferral: data });
                    setSelectedTabBttn(3);
                }
            })
            .catch(({ response }) => {
                setError(response);
            });
    };

    return (
        <div>
            {referrals.count === 0 ? (
                <Alert severity="info">Δεν βρέθηκαν παραπεμπτικά!</Alert>
            ) : (
                <div>
                    {error && (
                        <div>
                            <Alert severity="error">Κάτι πήγε στραβά!</Alert>
                            <br />
                        </div>
                    )}
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {mainColumns.map((column, index) => {
                                        return (
                                            <TableCell key={index}>
                                                <b>{column.name}</b>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {referrals.referrals.map((referral) => {
                                    return (
                                        <TableRow key={referral.barcode}>
                                            <TableCell>
                                                <button
                                                    className="btn buttonPrimary"
                                                    onClick={() =>
                                                        onReferralFetch(referral.barcode)
                                                    }
                                                >
                                                    {referral.barcode}
                                                </button>
                                            </TableCell>
                                            <TableCell>{referral.examinationGroup.name}</TableCell>
                                            <TableCell>{referral.patientInfo.amka}</TableCell>
                                            <TableCell>{referral.patientInfo.lastName}</TableCell>
                                            <TableCell>{referral.patientInfo.firstName}</TableCell>
                                            <TableCell>
                                                {referral.patientInfo.socialInsuranceShortName}
                                            </TableCell>
                                            <TableCell>{formatDate(referral.issueDate)}</TableCell>
                                            <TableCell>{referral.status.status}</TableCell>
                                            <TableCell>
                                                {referral.status.status === "ΣΥΝΤΑΓΟΓΡΑΦΗΜΕΝΟ" &&
                                                    showButtonBasedOnDate(referral.issueDate) && (
                                                        <button
                                                            className="btn buttonPrimary"
                                                            onClick={() => onEdit(referral)}
                                                        >
                                                            Επεξεργασία
                                                        </button>
                                                    )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        <br />
                        <Pagination
                            count={referrals.totalPages}
                            page={state.page}
                            onChange={handlePageChange}
                        />
                    </TableContainer>
                </div>
            )}
        </div>
    );
}

export default ReferralsResults;
