import axios from "axios";
import { baseUrl, postOptions } from "../config.js";

class MediPlusTermsService {
    constructor(baseUrl) {
        this.basePath = "/terms";
        this.baseUrl = `${baseUrl}${this.basePath}`;
    }

    getLatestCreatedTerms() {
        return axios.get(`${this.baseUrl}/latest`);
    }

    addTerms(content) {
        return axios(postOptions(this.baseUrl, "", { content }));
    }
}

export default new MediPlusTermsService(baseUrl);
