import React, { useState, createContext, useEffect } from "react";
import { Redirect } from "react-router-dom";

import { Tabs, Tab, AppBar, Toolbar } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

import "./Doctor.css";
import { mainTabs } from "./config.js";
import { getJwt } from "../Utilities/JwtUtilities.js";
import Esyntagografisi from "./Esyntagografisi/Esyntagografisi.js";
import Statistics from "./Statistics/Statistics";
import Calendar from "./Calendar/Calendar";
import Management from "./Management/Management";
import IdikaVisitService from "../../services/MediPlus/Idika/IdikaVisitService";
import MembershipNotification from "./MembershipNotification";
import PopUp from "../Utilities/PopUp";
import Banner from "react-js-banner";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import IconButton from "@material-ui/core/IconButton";
import MediPlusUserService from "../../services/MediPlus/MediPlusUserService";
import { bannerDisplayTime } from "../../config";
import { Button } from "@material-ui/core";

export const DoctorContext = createContext(null);
export const TabContext = createContext(null);
export const UnitIdContext = createContext(null);

export const initialAddReferralState = {
    barcode: "",
    reason: "",
    comment: "",
    prescriptionTypeId: "1",
    examinationGroupId: "",
    noParticipationCase: '{"needsDecision":false}',
    decisionDate: "",
    decisionNumber: "",
    needsDecision: false,
    referrals: [],
    diagnoses: [],
};

export const addPrescriptionInitialState = {
    id: "",
    patientAmka: "",
    visitId: "",

    prescriptionTypeId: "1",
    repeatId: "1",
    interval: "",
    otherDoctorOpinion: false,
    otherDoctorOpinionAmka: "",
    otherDoctorOpinionDate: "",
    chronicDisease: false,

    insertedTreatmentProtocols: { count: 0, treatmentProtocols: [] },

    icd10s: [],
    diagnosisComment: "",

    medicines: [],
    prescriptionBasedOnCommercialNameId: false,
    prescriptionBasedOnCommercialNameReasonId: "-1",
    prescriptionBasedOnCommercialNameComment: "",
    noPartitionCaseId: "0",
    beneficialEKAS: false,
    monthlyRecipe: false,
    twoMonthRecipe: false,
    medicineOnedose: false,
    galenicPrescription: false,
    desensitizationVaccine: false,

    prescriptionNotes: "",

    // this field is used for holding all treatment protocols
    allTreatmentProtocols: { count: 0, treatmentProtocols: [] },
};

export const visitDetailsInitialState = {
    id: -1,
    startDate: "",
    reason: "",
    comment: "",
    icpc2s: [],
    active: false,
    status: "",
    prescriptions: [],
    referrals: [],
};

function Doctor() {
    const [doctorData, setDoctorData] = useState(
        sessionStorage.getItem("mediPlusDoctorData") === null
            ? {
                  id: -1,
                  firstname: "",
                  lastname: "",
                  specialty: "",
                  amka: "",
                  etaaRegNo: "",
              }
            : JSON.parse(sessionStorage.getItem("mediPlusDoctorData"))
    );
    const [tabList, setTabList] = useState(mainTabs);
    const [selectedUnit, setSelectedUnit] = useState("");

    const [showBanner, setShowBanner] = useState(false);
    const [banner, setBanner] = useState();
    //we use this to show id based banner after diagnose pop up is closed
    const [openDiagnosePopUp, setOpenDiagnosePopUp] = useState(false);
    const [acceptBannerTerms, setAcceptBannerTerms] = useState(false);

    const [covidCheck,setCovidCheck] = useState(true);

    const [tabAlertPopUp, setTabAlertPopUp] = useState(false);

    useEffect(() => {
        document.addEventListener('click', function(e) { if(document.activeElement.toString() === '[object HTMLButtonElement]'){ document.activeElement.blur(); } });
    }, []);

    useEffect(() => {
        if (showBanner) {
            setTimeout(() => {
                setShowBanner(false);
            }, bannerDisplayTime);
        }
    }, [showBanner, setShowBanner]);

    useEffect(() => {
        MediPlusUserService.getUserByAuthToken()
            .then(({ data }) => {
                setAcceptBannerTerms(data.userDetails.acceptReceivingUpdatesViaApp);
            })
            .catch((err) => {
                //console.log(err.response);
            });
    }, []);

    const addTab = (
        patientData = null,
        visitDetails = visitDetailsInitialState,
        subTab = 1,
        viewPrescription = {},
        viewReferral = {},
        addReferral = initialAddReferralState,
        addPrescription = addPrescriptionInitialState,
        editReferralOrPrescription = null
    ) => {
        visitDetails.prescriptionsToBeRegistered = [];
        visitDetails.referralsToBeRegistered = [];
        let id = tabList[tabList.length - 1].id + 1;
        let infoArray = [];
        if (patientData !== null) {
            if (patientData.message !== "") infoArray.push(patientData.message);
            if (patientData.info !== "") infoArray.push(patientData.info);
            patientData.showSocialInsurancePatientInfo = true;
        }
        let infoPopUp = infoArray.length === 0 ? false : true;
        setTabList([
            ...tabList,
            {
                key: id,
                id: id,
                label: `${patientData.lastName} ${patientData.firstName}`,
                patientData: patientData,
                visitDetails: visitDetails,
                subTab: subTab,
                viewPrescription: viewPrescription,
                viewReferral: viewReferral,
                addReferral: addReferral,
                addPrescription: addPrescription,
                infoArray: infoArray,
                infoPopUp: infoPopUp,
                editReferralOrPrescription,
                component: <Esyntagografisi key={id} patientData={patientData} />,
            },
        ]);
        setSelectedTab(id);
        return id;
    };

    // if the user is not at the current tab
    // or the current tab contains an active visit
    // the user can not close/delete it
    const deleteTab = (e) => {
        e.stopPropagation();

        let tabId = parseInt(e.target.id);
        if (tabList.length <= 4 || tabId !== selectedTab) {
            return;
        }

        let tabIdIndex = 0;
        let tabs = tabList.filter((value, index) => {
            if (value.id === tabId) {
                tabIdIndex = index;
            }
            return value.id !== tabId;
        });

        if (tabList[tabIdIndex].visitDetails.active === true) return;

        if (tabIdIndex === 4 && tabList.length > 5) {
            setSelectedTab(tabList[tabIdIndex + 1].id);
        } else if (tabList.length === 5) {
            setSelectedTab(0);
        } else {
            setSelectedTab(tabList[tabIdIndex - 1].id);
        }
        setCovidCheck(true);
        setTabList(tabs);
    };

    const findTabIndexByTabId = (tabId) => {
        for (let tabIndex in tabList) {
            if (tabList[tabIndex].id === tabId) return tabIndex;
        }
    };

    const [selectedTab, setSelectedTab] = useState(0);
    const [tempTabValue,setTempTabValue] = useState();
    const handleTabs = (e, value) => {
        const currentSubTab = tabList[findTabIndexByTabId(selectedTab)].subTab;
        if(selectedTab > 3 && (currentSubTab === 2 || currentSubTab === 3)){
            setTabAlertPopUp(true);
            setTempTabValue(value);
        }else{
            setSelectedTab(value);
        }
        
    };

    const forceChangeTab = () =>{
        setTabAlertPopUp(false);
        setSelectedTab(tempTabValue);
    }

    const signOut = () => {
        sessionStorage.clear();
        window.location = "/";
    };

    const [unitPopUp, setUnitPopUp] = useState(false);

    useEffect(() => {
        IdikaVisitService.getActiveVisit()
            .then(({ data }) => {
                addTab(data.patientData, data.visitDetails);
            })
            .catch((error) => {
                //console.log(error.response);
            })
            .finally(() => {
                if (
                    sessionStorage.getItem("unitId") === null &&
                    sessionStorage.getItem("mediPlusDoctorData") !== null
                ) {
                    setUnitPopUp(true);
                }
            });
        // eslint-disable-next-line
    }, []);

    const [numberOfDatesUntilMembershipExpire, setNumberOfDatesUntilMembershipExpire] = useState(0);
    const [membershipEndDatePopUp, setMembershipEndDatePopUp] = useState(false);
    useEffect(() => {
        let membershipNotification = sessionStorage.getItem("membershipNotification");
        if (membershipNotification === null) return;
        else if (membershipNotification === "true") {
            setNumberOfDatesUntilMembershipExpire(sessionStorage.getItem("membershipDiffDays"));
            setMembershipEndDatePopUp(true);
        } else {
            sessionStorage.removeItem("membershipNotification");
            sessionStorage.removeItem("membershipDiffDays");
        }
    }, []);

    const token = getJwt();
    if (token === null) return <Redirect to="/SignIn" />;

    return (
        <div className="bodyWrap">
            {showBanner && (
                <Banner
                    showBanner={showBanner && !openDiagnosePopUp && acceptBannerTerms}
                    css={{
                        height: "100px",
                        backgroundColor: "transparent",
                        fontFamily: "arial",
                        position: "fixed",
                        top: "0",
                        zIndex: "210",
                        display: "flex",
                    }}
                >
                    <div id="bannerContainer" style={{position:"relative",width:"100%",left:"-10px"}}>
                        <IconButton
                            aria-label="delete"
                            style={{position:"absolute",top:"20px"}}
                            onClick={() => {
                                setShowBanner(false);
                            }}
                        >
                            <HighlightOffIcon />
                        </IconButton>
                        {banner}
                    </div>
                </Banner>
            )}
            <div className="mainHeader">
                <AppBar position="static" className="mainTabs">
                    <Toolbar>
                        <img
                            className="mr-2"
                            height="40px"
                            src="/images/MediPlusLogoNoPadding.png"
                            alt="MediPlusLogoNoPadding"
                        />
                        <Tabs
                            className="mainTabs"
                            value={selectedTab}
                            onChange={handleTabs}
                            variant="scrollable"
                            scrollButtons="auto"
                            indicatorColor="secondary"
                        >
                            {tabList.map((tab, index) => {
                                if (tab.label !== null)
                                    return (
                                        <Tab
                                            key={index}
                                            value={tab.id}
                                            label={tab.label}
                                            icon={
                                                tab.id > 3 ? (
                                                    <Tooltip
                                                        placement="top"
                                                        title={
                                                            tab.visitDetails.active
                                                                ? "Η Επίσκεψη είναι ανοιχτή! Για να κλείσετε την καρτέλα θα πρέπει πρώτα να Κλείσετε/Ακυρώσετε την Επίσκεψη."
                                                                : "Η καρτέλα μπορεί να κλείσει μόνο εάν βρίσκεστε στην συγκεκριμένη καρτέλα."
                                                        }
                                                    >
                                                        <div
                                                            id={tab.id}
                                                            onClick={deleteTab}
                                                            className="btn btnPrimary"
                                                            style={{
                                                                margin: "0px",
                                                                padding: "5px 10px 1px 10px",
                                                                height: "30px",
                                                                border: tab.visitDetails.active
                                                                    ? "1px solid #3f50b5"
                                                                    : "1px solid #f44336",
                                                                color: tab.visitDetails.active
                                                                    ? "#3f50b5"
                                                                    : "#f44336",
                                                            }}
                                                        >
                                                            <i
                                                                id={tab.id}
                                                                onClick={deleteTab}
                                                                className="fa fa-times-circle"
                                                            ></i>
                                                        </div>
                                                    </Tooltip>
                                                ) : (
                                                    tab.icon
                                                )
                                            }
                                            className="mytab"
                                        />
                                    );
                                else return null;
                            })}
                        </Tabs>
                        <button className="btn buttonPrimary HeaderButton" onClick={signOut}>
                            <i className="fa fa-sign-out"></i>
                            Αποσύνδεση
                        </button>
                    </Toolbar>
                </AppBar>
            </div>
            <DoctorContext.Provider value={{ doctorData, setDoctorData, unitPopUp, setUnitPopUp }}>
                <UnitIdContext.Provider value={{ selectedUnit, setSelectedUnit }}>
                    <TabContext.Provider
                        value={{
                            selectedTab,
                            setSelectedTab,
                            findTabIndexByTabId,
                            tabList,
                            setTabList,
                            addTab,
                            setShowBanner,
                            setBanner,
                            openDiagnosePopUp,
                            setOpenDiagnosePopUp,
                            setAcceptBannerTerms,
                            covidCheck,
                            setCovidCheck
                        }}
                    >
                        {selectedTab === 0 && <Esyntagografisi addTab={addTab} />}
                        {selectedTab === 1 && <Statistics />}
                        {selectedTab === 2 && <Calendar />}
                        {selectedTab === 3 && <Management />}
                        {selectedTab > 3 && tabList[findTabIndexByTabId(selectedTab)].component}
                    </TabContext.Provider>
                </UnitIdContext.Provider>
            </DoctorContext.Provider>
            <PopUp
                openPopUp={membershipEndDatePopUp}
                setOpenPopUp={setMembershipEndDatePopUp}
                title={"Υπενθύμιση Συνδρομής"}
                maxWidth="sm"
                showCloseButton={false}
            >
                <MembershipNotification
                    setMembershipEndDatePopUp={setMembershipEndDatePopUp}
                    numberOfDatesUntilMembershipExpire={numberOfDatesUntilMembershipExpire}
                />
            </PopUp>
            <PopUp
                openPopUp={tabAlertPopUp}
                setOpenPopUp={setTabAlertPopUp}
                title={"Προειδοποίηση"}
                maxWidth="sm"
            >
                <div className="mb-3" style={{textAlign:"justify"}}>
                    <p>
                        Αυτή η σελίδα περιέχει δεδομένα που δεν έχουν οριστικοποιηθεί. Θέλετε να
                        πραγματοποιήσετε πλοήγηση; Πατήστε "ΟΚ" για να συνεχίσετε ή "Άκυρο" για να
                        ακυρώσετε τη λειτουργία πλοήγησης.
                    </p>
                </div>
                <div align="center" >
                <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={forceChangeTab}
                        style={{marginRight:"5px"}}
                    >
                        ΟΚ
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => setTabAlertPopUp(false)}
                    >
                        ΑΚΥΡΟ
                    </Button>
                </div>
                    
                
            </PopUp>
        </div>
    );
}

export default Doctor;
