import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/App";

// redux
// import { Provider } from "react-redux";
// import store from "./redux/store";

ReactDOM.render(
    // <Provider store={store}>
    //     <App />
    // </Provider>,
    <App />,
    document.getElementById("root")
);
