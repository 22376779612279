import React, { useState, useEffect, useContext } from "react";

import { mainColumns } from "./config.js";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { TabContext } from "../../../../Doctor.js";
import PopUp from "../../../../../Utilities/PopUp.js";
import SearchIcpc2 from "./SearchIcpc2/SearchIcpc2.js";

function Icpc2() {
    const { selectedTab, findTabIndexByTabId, tabList, setTabList } = useContext(TabContext);
    const tabIndex = findTabIndexByTabId(selectedTab);
    const [tabItem, setTabItem] = useState(tabList[tabIndex]);
    const [icpc2s, setIcpc2s] = useState(tabList[tabIndex] !== undefined
        ? tabList[tabIndex].visitDetails.icpc2s
        : []);
    
    const [searchIcpc2PopUp, setSearchIcpc2PopUp] = useState(false);

    const onDelete = (code) => {
        setIcpc2s(icpc2s.filter((icpc2) => icpc2.code !== code));
    };

    useEffect(() => {
        if (tabItem !== undefined) {
            let visitDetails = tabItem.visitDetails
            visitDetails.icpc2s = icpc2s
            setTabItem({ ...tabItem, visitDetails: visitDetails });
        }
        // eslint-disable-next-line
    }, [icpc2s]);

    useEffect(() => {
        if (tabItem !== undefined) {
            let newTabList = [...tabList];
            newTabList[tabIndex] = tabItem;
            setTabList(newTabList);
        }
        // eslint-disable-next-line
    }, [tabItem]);

    return (
        <div>
            <div align="right">
                <button
                    className="btn buttonPrimary"
                    onClick={() => {
                        setSearchIcpc2PopUp(true);
                    }}
                >
                    Εισαγωγή
                </button>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {mainColumns.map((column, index) => {
                                return (
                                    <TableCell key={index}>
                                        <b>{column.name}</b>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {icpc2s.map((icpc2) => {
                            return (
                                <TableRow key={icpc2.code}>
                                    <TableCell>{icpc2.code}</TableCell>
                                    <TableCell>{icpc2.title}</TableCell>
                                    <TableCell>
                                        <button
                                            className="btn buttonDanger"
                                            onClick={() => onDelete(icpc2.code, icpc2.title)}
                                        >
                                            Διαγραφή
                                        </button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <PopUp
                openPopUp={searchIcpc2PopUp}
                setOpenPopUp={setSearchIcpc2PopUp}
                showCloseButton={true}
                title={"Αναζήτηση ICPC2"}
            >
                <SearchIcpc2 insertedIcpc2s={icpc2s} setinsertedIcpc2s={setIcpc2s} />
            </PopUp>
        </div>
    );
}

export default Icpc2;
