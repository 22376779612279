import React from "react";
import { Button } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { formatDate } from "../../../../../../../Utilities/Utilities";

function Insurances({ patientData, tabItem, setTabItem, setShowInsurances }) {
    const columns = ["Ασφ. Φορέας", "Άμεσος/Έμμεσος", "Α.Μ.Α.", "Ημ/νία Λήξης Ασφαλ. Ικανότητας"];

    const handleOnClick = (e) => {
        let newPatientData = patientData;
        newPatientData.selectActiveInsurance = false;
        newPatientData.showSocialInsurancePatientInfo = true;
        setTabItem({ ...tabItem, patientData: newPatientData });
        setShowInsurances(false);
    };

    const handleSocialInsuranceChange = (index) => {
        let newPatientData = patientData;
        newPatientData.selectedActiveInsurance = index;
        setTabItem({ ...tabItem, patientData: newPatientData });
    };

    return (
        <div align="center">
            <TableContainer className="mb-3" component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((column, index) => {
                                return (
                                    <TableCell key={index}>
                                        <b>{column}</b>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {patientData.activeInsurances.activeInsurance.map(
                            (activeInsurance, index) => {
                                return (
                                    <TableRow
                                        key={index}
                                        style={{
                                            cursor: "pointer",
                                            backgroundColor:
                                                patientData.selectedActiveInsurance === index
                                                    ? "lightgrey"
                                                    : "white",
                                        }}
                                        onClick={() => handleSocialInsuranceChange(index)}
                                    >
                                        <TableCell>
                                            {activeInsurance.socialInsurance.shortName}
                                        </TableCell>
                                        <TableCell>{activeInsurance.memberType.name}</TableCell>
                                        <TableCell>{activeInsurance.ama}</TableCell>
                                        <TableCell>
                                            {formatDate(activeInsurance.expiryDate)}
                                        </TableCell>
                                    </TableRow>
                                );
                            }
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button variant="contained" color="primary" onClick={handleOnClick}>
                OK
            </Button>
        </div>
    );
}

export default Insurances;
