import { columns } from "./config.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useEffect, useState, useContext } from "react";
import PopUp from "../../../../../../../Utilities/PopUp.js";
import parser from "html-react-parser";
import { CircularProgress} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import MediPlusCustomPrescriptionHistoryService from "../../../../../../../../services/MediPlus/MediPlusCustomPrescriptionHistoryService.js";
import { PatientContext } from "../../../../../Esyntagografisi.js";
import { formatMaterialUiDate } from "../../../../../../../Utilities/Utilities.js";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ckeditorToolbar } from "../../../../../../../../config.js";

function CustomPrescriptionHistory({ setBody,setCustomPrescription }) {
    const patient = useContext(PatientContext);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [history, setHistory] = useState({});
    const [isEmpty, setIsEmpty] = useState(false);

    const [selectedCustomPrescription, setSelectedCustomPrescription] = useState(null);
    const [viewCustomPrescription, setViewCustomPrescription] = useState(false);

    const onView = (customPrescription) => {
        setSelectedCustomPrescription(customPrescription.body);
        setViewCustomPrescription(true);
    };

    const repeatCustomPrescription = (customPrescription) => {
        setBody(customPrescription.body);
        setCustomPrescription(true);
    };

    useEffect(() => {
        MediPlusCustomPrescriptionHistoryService.getUserPatientCustomPrescriptions(patient.amka)
            .then(({ data }) => {
                setHistory(data.customPrescriptionsHistory);
                if (data.customPrescriptionsHistory.length === 0) {
                    setIsEmpty(true);
                }
            })
            .catch((err) => {
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [patient.amka]);

    return (
        <div className="section mb-5">
            <div className="boxContent">
                {loading ? (
                    <CircularProgress />
                ) : error ? (
                    <Alert severity="error">Κάτι πήγε στραβά!</Alert>
                ) : isEmpty ? (
                    <Alert severity="info">Ιστορικό ιατρικών σημειωμάτων ασθενή κενό </Alert>
                ) : (
                    <div>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column, index) => {
                                            return (
                                                <TableCell key={index}>
                                                    <b>{column.name}</b>
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {history.map((customPrescription, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <div
                                                        style={{
                                                            whiteSpace: "nowrap",
                                                            width: "250px",
                                                            display: "block",
                                                            overflow: "hidden",
                                                        }}
                                                    >
                                                        {parser(customPrescription.body || "")}
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    {parser(
                                                        formatMaterialUiDate(
                                                            new Date(customPrescription.createdDate)
                                                        ) || ""
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    <button
                                                        className="btn buttonPrimary"
                                                        onClick={() => onView(customPrescription)}
                                                    >
                                                        Προβολή
                                                    </button>
                                                    <button
                                                        className="btn buttonPrimary"
                                                        onClick={() =>
                                                            repeatCustomPrescription(
                                                                customPrescription
                                                            )
                                                        }
                                                    >
                                                        Επανάληψη
                                                    </button>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                )}
            </div>
            <PopUp
                openPopUp={viewCustomPrescription}
                setOpenPopUp={setViewCustomPrescription}
                showCloseButton={true}
                title={"Σώμα Κειμένου"}
                maxWidth="lg"
            >
                <CKEditor
                    editor={ClassicEditor}
                    config={{
                        toolbar: ckeditorToolbar,
                    }}
                    data={selectedCustomPrescription}
                    disabled
                />
            </PopUp>
        </div>
    );
}

export default CustomPrescriptionHistory;
