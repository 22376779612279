import React, { useEffect, useRef, useState } from "react";
import "./SignUp.css";
import TextField from "@material-ui/core/TextField";
import MediPlusAuthService from "../../services/MediPlus/MediPlusAuthService.js";
import { getCurrentDateAndTime, Spinner } from "../Utilities/Utilities.js";

import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { getJwt } from "../Utilities/JwtUtilities";
import { Redirect } from "react-router";
import { Switch } from "@material-ui/core";
import ReCAPTCHA from "react-google-recaptcha";
import { locationMap, RECAPTCHA_SITE_KEY } from "../../config";
import PopUp from "../Utilities/PopUp.js";
import Terms from "../Terms/Terms.js";
import MediPlusUserService from "../../services/MediPlus/MediPlusUserService";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles({
    root: {
        width: "80%",
        marginTop: "10px",
        marginBottom: "10px",
    },
});

function SignUp({ admin, phAgent }) {
    const classes = useStyles();
    const [state, setState] = useState({
        firstName: "",
        lastName: "",
        userName: "",
        email: "",
        address: "",
        city: "",
        specialty:  "",
        phoneNumber: "",
        password: "",
        confirmPassword: "",
        acceptedTermsDate: admin || phAgent ? getCurrentDateAndTime() : "",
        acceptTerms: admin || phAgent ? true : false,
        acceptReceivingUpdatesViaContactDetails: true,
        acceptReceivingUpdatesViaApp: true,
        level: admin || phAgent ? "" : 0,
        company:""
    });
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(null);
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        if (phAgent) {
            MediPlusUserService.getCompanies()
                .then((response) => {
                    setCompanies(response.data);
                })
                .catch((error) => {
                    setError(error.response.data);
                });
        }
    }, [phAgent]);

    const companiesData = companies.map((company, index) => (
        <MenuItem key={index} value={company.companyName}>
            {company.companyName}
        </MenuItem>
    ));

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setState({ ...state, [name]: value });
    };

    const handleSwitchChange = (event) => {
        let newState = { ...state, [event.target.name]: event.target.checked };
        if (event.target.name === "acceptTerms" && event.target.checked === true) {
            newState["acceptedTermsDate"] = getCurrentDateAndTime();
        }
        setState(newState);
    };

    const [reCaptchaToken, setReCaptchaToken] = useState("");
    const [signUpRequest, setSignUpRequest] = useState(false);
    // every time the user clicks the submit button we have to get the recaptcha token first
    const onSubmit = async (e) => {
        e.preventDefault();

        const reCaptchaTokenResponse = await reCaptchaRef.current
            .executeAsync()
            .then((value) => {
                return value;
            })
            .catch((err) => {
                // console.log(err);
            });
        setReCaptchaToken(reCaptchaTokenResponse);
        reCaptchaRef.current.reset();

        setSignUpRequest(true);
    };

    useEffect(() => {
        if (signUpRequest === false) return;
        setError({});
        setAlert(null);
        if (state.password !== state.confirmPassword) {
            setError({ passwords: "Οι Κωδικοί δεν ταιριάζουν" });
            setSignUpRequest(false);
            return;
        } else if (state.phoneNumber.length !== 10) {
            setError({ phoneNumber: "Ο Αριθμός Τηλεφώνου είναι λάθος" });
            setSignUpRequest(false);
            return;
        } else if (state.acceptTerms === false || state.acceptedTermsDate === "") {
            setError({ acceptTerms: "Πρέπει να αποδεχτείτε τους όρους του MediPlus" });
            setSignUpRequest(false);
            return;
        }
        setLoading(true);
        let request = { ...state, reCaptchaToken };

        if (admin === true) {
            request.specialty= "Διαχειριστής";
            MediPlusUserService.newAdmin(request)
                .then((response) => {
                    // console.log(response);
                    setAlert("Ο λογαριασμός δημιουργήθηκε!");
                })
                .catch((error) => {
                    // console.log(error.response);
                    setError(error.response.data.errors);
                })
                .finally(() => {
                    setSignUpRequest(false);
                    setLoading(false);
                });
        } else if(phAgent === true) {
            request.specialty= "Φαρμακευτικός Αντιπρόσωπος";
            MediPlusUserService.newPhAgent(request)
                .then((response) => {
                    // console.log(response);
                    setAlert("Ο χρηστης δημιουργήθηκε με επιτυχια!");
                })
                .catch((error) => {
                    // console.log(error.response);
                    setError(error.response.data.errors);
                })
                .finally(() => {
                    setSignUpRequest(false);
                    setLoading(false);
                });
        }else {
            MediPlusAuthService.signUp(request)
                .then((response) => {
                    // console.log(response);
                    setAlert("Θα ειδοποιηθείτε όταν ολοκληρωθεί η εγγραφή σας!");
                })
                .catch((error) => {
                    // console.log(error.response);
                    setError(error.response.data.errors);
                })
                .finally(() => {
                    setSignUpRequest(false);
                    setLoading(false);
                });

        }
    }, [signUpRequest,state, reCaptchaToken, admin, phAgent]);

    const reCaptchaRef = useRef();

    const [termsPopUp, setTermsPopUp] = useState(false);

    const [updatesInfoAlert, setUpdatedInfoAlert] = useState(false);
    useEffect(() => {
        if (state.acceptReceivingUpdatesViaApp === true) return;
        setUpdatedInfoAlert(true);
        let timer = setTimeout(() => setUpdatedInfoAlert(false), 2000);
        return () => {
            clearTimeout(timer);
            setUpdatedInfoAlert(false);
        };
    }, [state.acceptReceivingUpdatesViaApp]);

    useEffect(() => {
        if (state.acceptReceivingUpdatesViaContactDetails === true) return;
        setUpdatedInfoAlert(true);
        let timer = setTimeout(() => setUpdatedInfoAlert(false), 2000);
        return () => {
            clearTimeout(timer);
            setUpdatedInfoAlert(false);
        };
    }, [state.acceptReceivingUpdatesViaContactDetails]);

    if (admin !== true && phAgent !== true) {
        const token = getJwt();
        if (token !== null) return <Redirect to={locationMap[sessionStorage.getItem("role")]} />;
    }

    

    

    return (
        <div className="signUpForm bodyWrap">
            <div className="signUpBody">
                <div className="flexBox">
                    <div className="fullBox">
                        <div className="authForm">
                            <h4>
                                {" "}
                                ΕΓΓΡΑΦΗ{" "}
                                {admin
                                    ? "ΝΕΟΥ ΔΙΑΧΕΙΡΙΣΤΗ"
                                    : phAgent
                                    ? "ΝΕΟΥ ΦΑΜΡΑΚΕΥΤΙΚΟΥ ΑΝΤΙΠΡΟΣΩΠΟΥ"
                                    : ""}{" "}
                            </h4>
                            <div align="center">
                                <div className={classes.root}>
                                    {Object.keys(error).length !== 0 && (
                                        <Alert severity="error">
                                            {error.passwords !== undefined
                                                ? "Οι Κωδικοί δεν ταιριάζουν!"
                                                : error.username !== undefined
                                                ? "Το Όνομα χρήστη(Username) υπάρχει ήδη!"
                                                : error.email !== undefined
                                                ? "Το Email υπάρχει ήδη!"
                                                : error.phoneNumber !== undefined
                                                ? error.phoneNumber
                                                : error.acceptTerms !== undefined
                                                ? error.acceptTerms
                                                : null}
                                        </Alert>
                                    )}
                                    {alert && <Alert severity="success">{alert}</Alert>}
                                    {updatesInfoAlert && (
                                        <Alert severity="info">
                                            Δε θα μπορείτε να ενημερωθείτε για τυχόν ενημερώσεις του
                                            Mediplus.
                                        </Alert>
                                    )}
                                </div>
                            </div>
                            <form onSubmit={onSubmit} className="mb-3">
                                <div className="d-flex justify-content-around">
                                    <TextField
                                        className="formInputUI mr-2 ml-2"
                                        label="Όνομα"
                                        name="firstName"
                                        value={state.firstName}
                                        onChange={handleChange}
                                        required
                                    />
                                    <TextField
                                        className="formInputUI mr-2 ml-2"
                                        label="Επώνυμο"
                                        name="lastName"
                                        value={state.lastName}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="d-flex justify-content-around">
                                    <TextField
                                        className="formInputUI mr-2 ml-2"
                                        label="Όνομα χρήστη"
                                        name="userName"
                                        value={state.userName}
                                        onChange={handleChange}
                                        error={error.username !== undefined ? true : false}
                                        required
                                    />
                                    <TextField
                                        className="formInputUI mr-2 ml-2"
                                        type="email"
                                        label="Email"
                                        name="email"
                                        value={state.email}
                                        onChange={handleChange}
                                        error={error.email !== undefined ? true : false}
                                        required
                                    />
                                </div>
                                <div className="d-flex justify-content-around">
                                    <TextField
                                        className="formInputUI mr-2 ml-2"
                                        type="password"
                                        label="Κωδικός"
                                        name="password"
                                        value={state.password}
                                        onChange={handleChange}
                                        error={error.passwords !== undefined ? true : false}
                                        required
                                    />
                                    <TextField
                                        className="formInputUI mr-2 ml-2"
                                        type="password"
                                        label="Επιβεβαίωση κωδικού"
                                        name="confirmPassword"
                                        value={state.confirmPassword}
                                        onChange={handleChange}
                                        error={error.passwords !== undefined ? true : false}
                                        required
                                    />
                                </div>
                                <div className="d-flex justify-content-around">
                                    {phAgent && (
                                        <FormControl className="formInputUI mr-2 ml-2">
                                            <InputLabel >
                                                Επίπεδο
                                            </InputLabel>
                                            <Select
                                                value={state.level}
                                                onChange={handleChange}
                                                displayEmpty
                                                name="level"
                                                required
                                            >
                                                <MenuItem value="1">
                                                    <em>Επίπεδο 1</em>
                                                </MenuItem>
                                                <MenuItem value={2}>Επίπεδο 2</MenuItem>
                                            </Select>
                                        </FormControl>
                                    )}
                                    {phAgent && (
                                        <FormControl className="formInputUI mr-2 ml-2">
                                            <InputLabel >
                                                Εταιρεία
                                            </InputLabel>
                                            <Select
                                                value={state.company}
                                                onChange={handleChange}
                                                displayEmpty
                                                name="company"
                                                required
                                            >
                                                {companiesData}
                                            </Select>
                                        </FormControl>
                                    )}
                                </div>
                                <div className="d-flex justify-content-around">
                                    <TextField
                                        className="formInputUI mr-2 ml-2"
                                        type="text"
                                        label="Διεύθυνση"
                                        name="address"
                                        value={state.address}
                                        onChange={handleChange}
                                        required
                                    />
                                    <TextField
                                        className="formInputUI mr-2 ml-2"
                                        type="text"
                                        label="Πόλη"
                                        name="city"
                                        value={state.city}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="d-flex justify-content-around">
                                    {!admin && !phAgent && (
                                        <TextField
                                            className="formInputUI mr-2 ml-2"
                                            type="text"
                                            label="Ειδικότητα"
                                            name="specialty"
                                            value={state.specialty}
                                            onChange={handleChange}
                                            required
                                        />
                                    )}
                                    <TextField
                                        className="formInputUI mr-2 ml-2"
                                        type="text"
                                        label="Αριθμός Τηλεφώνου"
                                        name="phoneNumber"
                                        value={state.phoneNumber}
                                        onChange={handleChange}
                                        error={error.phoneNumber !== undefined ? true : false}
                                        required
                                    />
                                </div>
                                {!admin && !phAgent && (
                                    <div>
                                        <div className="d-flex flex-row form-inline">
                                            <Switch
                                                checked={state.acceptTerms}
                                                onChange={handleSwitchChange}
                                                name="acceptTerms"
                                                color="primary"
                                            />
                                            <div>
                                                Διάβασα και Αποδέχομαι τους όρους χρήσης της
                                                εφαρμογής Mediplus{" "}
                                            </div>
                                            <div className="form-inline">
                                                [
                                                <div
                                                    style={{
                                                        color: "blue",
                                                        cursor: "pointer",
                                                        textDecoration: "underline",
                                                    }}
                                                    onClick={() => setTermsPopUp(true)}
                                                >
                                                    Όροι
                                                </div>
                                                ]
                                            </div>
                                        </div>
                                        <div className="d-flex flex-row form-inline">
                                            <Switch
                                                checked={
                                                    state.acceptReceivingUpdatesViaContactDetails
                                                }
                                                onChange={handleSwitchChange}
                                                name="acceptReceivingUpdatesViaContactDetails"
                                                color="primary"
                                            />
                                            <div>
                                                Αποδέχομαι τη λήψη ενημερώσεων και προτάσεων μέσω
                                                των στοιχείων επικοινωνίας που έχω δηλώσει
                                            </div>
                                        </div>
                                        <div className="d-flex flex-row form-inline">
                                            <Switch
                                                checked={state.acceptReceivingUpdatesViaApp}
                                                onChange={handleSwitchChange}
                                                name="acceptReceivingUpdatesViaApp"
                                                color="primary"
                                            />
                                            <div>
                                                Αποδέχομαι τη λήψη ενημερώσεων και προτάσεων μέσω
                                                της εφαρμογής Mediplus
                                            </div>
                                        </div>
                                        <br />
                                        <div className="mb-3">
                                            <span>
                                                Μπορείτε να αλλάξετε τις επιλογές σας μέσα από την
                                                εφαρμογή Mediplus(από το μενού Διαχείριση)
                                            </span>
                                        </div>
                                    </div>
                                )}
                                <ReCAPTCHA
                                    sitekey={RECAPTCHA_SITE_KEY}
                                    size="invisible"
                                    ref={reCaptchaRef}
                                />
                                {loading === true ? (
                                    <Spinner align="center" animation="spinner-border" />
                                ) : (
                                    <button className="btn buttonPrimary">Εγγραφή</button>
                                )}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <PopUp
                openPopUp={termsPopUp}
                setOpenPopUp={setTermsPopUp}
                title={"Όροι MediPlus"}
                maxWidth="md"
            >
                <Terms />
            </PopUp>
        </div>
    );
}

export default SignUp;
