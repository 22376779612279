import React, { useState } from "react";
import { diagnosisColumns, mainColumns, medicineColumns } from "./config.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import PopUp from "../../../../../Utilities/PopUp";
import Alert from "@material-ui/lab/Alert";
import { ShowDetailsContent } from "../../InsuredDetails/InsuredInformation/Buttons/FavoritePrescriptions/FavoritePrescriptions.js";

function ShowHistoryDetailsContent({ prescriptionDetails }) {
    console.log(prescriptionDetails);
    return (
        <div>
            <div>
                <b>Όνομα: </b> {prescriptionDetails.name}
            </div>
            <div className="mb-3">
                <b>Ημ/νία Δημιουργίας: </b> {prescriptionDetails.createdDate}
            </div>
            <div className="mb-3">
                <h6 style={{ marginLeft: "10px", marginBottom: "10px" }}>Διαγνώσεις Συνταγής</h6>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {diagnosisColumns.map((column, index) => {
                                    return (
                                        <TableCell key={index}>
                                            <b>{column.name}</b>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {prescriptionDetails.icd10s.map((innerDiagnosis, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{innerDiagnosis.code}</TableCell>
                                        <TableCell>{innerDiagnosis.title}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div className="mb-3">
                <h6 style={{ marginLeft: "10px", marginBottom: "10px" }}>Φάρμακα</h6>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {medicineColumns.map((column, index) => {
                                    return (
                                        <TableCell key={index}>
                                            <b>{column.name}</b>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {prescriptionDetails.medicines.map((medicine, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>
                                            {medicine.request.genericMedicine === true && (
                                                <i className="fa fa-check"></i>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {medicine.request.treatmentProtocolDescription}
                                        </TableCell>
                                        <TableCell>
                                            {`${medicine.commercialName} ${medicine.formCode} ${medicine.content}  ${medicine.packageForm}`}
                                        </TableCell>
                                        <TableCell>
                                            {medicine.request.activeSubstanceName}
                                        </TableCell>
                                        <TableCell>{medicine.request.doseQuantity.unit}</TableCell>
                                        <TableCell>{medicine.request.doseQuantity.value}</TableCell>
                                        <TableCell>{medicine.request.effectiveTime.name}</TableCell>
                                        <TableCell>{medicine.request.rateQuantity.value}</TableCell>
                                        <TableCell>{medicine.request.quantity}</TableCell>
                                        <TableCell>
                                            {medicine.request.percentageParticipation}%
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
}

function PrescriptionsToBeRegistered({
    prescriptionsToBeRegistered,
    setPrescriptionsToBeRegistered,
    tabItem,
    setTabItem,
}) {
    const [prescriptionDetails, setPrescriptionDetails] = useState({});
    const [popUpHistory, setPopUpHistory] = useState(false);
    const [popUpFavorite, setPopUpFavorite] = useState(false);
    const onShowHistoryDetails = (prescription) => {
        setPrescriptionDetails(prescription);

        if (prescription.favoritePrescriptionId !== undefined) setPopUpFavorite(true);
        else setPopUpHistory(true);
    };

    const onDelete = (prescription, prescriptionIndex) => {
        let newPrescriptionsToBeRegistered = prescriptionsToBeRegistered.filter(
            (presc, index) => index !== prescriptionIndex
        );
        setPrescriptionsToBeRegistered(newPrescriptionsToBeRegistered);
        setTabItem({
            ...tabItem,
            visitDetails: {
                ...tabItem.visitDetails,
                prescriptionsToBeRegistered: newPrescriptionsToBeRegistered,
            },
        });
    };

    return (
        <div>
            {prescriptionsToBeRegistered.length === 0 ? (
                <Alert severity="info">Δεν υπάρχουν Συνταγές προς Καταχώρηση</Alert>
            ) : (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {mainColumns.map((column, index) => {
                                    return (
                                        <TableCell key={index}>
                                            <b>{column.name}</b>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {prescriptionsToBeRegistered.map((prescription, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{prescription.name}</TableCell>
                                        <TableCell>{prescription.medicines.length}</TableCell>
                                        <TableCell>{prescription.createdDate}</TableCell>
                                        <TableCell>
                                            <button
                                                className="btn buttonPrimary small-padding"
                                                onClick={() => onShowHistoryDetails(prescription)}
                                            >
                                                <i className="fa fa-search"></i>
                                                Λεπτομέρειες
                                            </button>
                                            <button
                                                className="btn buttonDanger small-padding"
                                                onClick={() => onDelete(prescription, index)}
                                            >
                                                <i className="fa fa-trash"></i>
                                                Διαγραφή
                                            </button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <PopUp
                openPopUp={popUpHistory}
                setOpenPopUp={setPopUpHistory}
                showCloseButton={true}
                title={"Λεπτομέρειες Αγαπημένου Πακέτου Συνταγών"}
                maxWidth="xl"
            >
                <ShowHistoryDetailsContent prescriptionDetails={prescriptionDetails} />
            </PopUp>
            <PopUp
                openPopUp={popUpFavorite}
                setOpenPopUp={setPopUpFavorite}
                showCloseButton={true}
                title={"Λεπτομέρειες Αγαπημένου Πακέτου Συνταγών"}
                maxWidth="xl"
            >
                <ShowDetailsContent prescriptionDetails={prescriptionDetails} />
            </PopUp>
        </div>
    );
}

export default PrescriptionsToBeRegistered;
