const mainTabs = [
    { label: "Εξετασεις" },
    { label: "Αποτελεσματα Εξετασεων", disabled: true },
    { label: "Εξαιρεσεις", disabled: true },
];

const referralColumns = [
    { name: "Εξέταση" },
    { name: "Διάγνωση" },
    { name: "Λόγος παραπομπής" },
    { name: "Σχόλια" },
    { name: "Ημ/νία Εκτέλεσης" },
];

const referralFields = [
    { name: "title" },
    { name: "diagnosisCode" },
    { name: "reason" },
    { name: "comment" },
    { name: "executionDate" },
];

export { mainTabs, referralColumns, referralFields };
