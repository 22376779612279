import ReceiptIcon from "@material-ui/icons/Receipt";
import BarChartIcon from "@material-ui/icons/BarChart";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import PersonIcon from "@material-ui/icons/Person";

const mainTabs = [
    {
        key: 0,
        id: 0,
        label: "ΣΥΝΤΑΓΟΓΡΑΦΗΣΗ",
        icon: <ReceiptIcon />,
        subTab: 1,
        patientData: null,
        viewPrescription: {},
        viewReferral: {},
        visitDetails: {
            id: -1,
            status: "",
            prescriptionsToBeRegistered: [],
            referralsToBeRegistered: [],
        },
        infoPopUp: false,
        editReferralOrPrescription: null,
    },
    {
        key: 1,
        id: 1,
        label: "ΣΤΑΤΙΣΤΙΚΑ",
        icon: <BarChartIcon />,
    },
    {
        key: 2,
        id: 2,
        label: "ΗΜΕΡΟΛΟΓΙΟ",
        icon: <CalendarTodayIcon />,
    },
    {
        key: 3,
        id: 3,
        label: "ΔΙΑΧΕΙΡΙΣΗ",
        icon: <PersonIcon />,
    },
];

export { mainTabs };
