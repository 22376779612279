import React, { useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Doctor from "./Doctor/Doctor.js";
import Header from "./Header/Header.js";
import Homepage from "./Homepage/Homepage.js";
import SignIn from "./SignIn/SignIn";
import SignUp from "./SignUp/SignUp";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import ChangePassword from "./ChangePassword/ChangePassword";
import Error from "./Error/Error";
import Footer from "./Footer/Footer.js";
import { getJwt, removeJwt } from "./Utilities/JwtUtilities.js";
import PopUp from "./Utilities/PopUp";

import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Alert from "@material-ui/lab/Alert";

import theme from "../theme";
import { makeStyles, ThemeProvider } from "@material-ui/core";

import { QueryClient, QueryClientProvider } from "react-query";
import Admin from "./Admin/Admin.js";
import SuperAdmin from "./Admin/SuperAdmin.js";
import axios from "axios";
import PharmaceuticalAgent from "./PharmaceuticalAgent/PharmaceuticalAgent.js";

// create a client for react-query
const queryClient = new QueryClient();

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
    },
    footer: {
        marginTop: "auto",
    },
}));

function App() {
    const [openPopUp, setOpenPopUp] = useState(false);

    axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
        if(err.response.status === 401 || err.response.data.message === "401 Unauthorized"){
            removeJwt();
            setOpenPopUp(true);
        }

        if(err.response.data.errors.error === "Bad credentials"){
            setOpenPopUp(false);
        }

        return Promise.reject(err);
    });

    const classes = useStyles();

    const token = getJwt();

    return (
        <div className="App">
            <QueryClientProvider client={queryClient}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <ThemeProvider theme={theme}>
                        <BrowserRouter>
                            <div className={classes.root}>
                                {token === null ? <Header /> : null}
                                <Switch>
                                    <Route exact path="/" component={Homepage} />
                                    <Route exact path="/Doctor" component={Doctor} />
                                    <Route exact path="/Admin" component={Admin} />
                                    <Route exact path="/SuperAdmin" component={SuperAdmin} />
                                    <Route exact path="/SignIn" component={SignIn} />
                                    <Route exact path="/SignUp" component={SignUp} />
                                    <Route exact path="/PharmaceuticalAgent" component={PharmaceuticalAgent} />
                                    <Route path="/PharmaceuticalAgent/:printId" children={<PharmaceuticalAgent />} />
                                    <Route
                                        exact
                                        path="/ForgotPassword"
                                        component={ForgotPassword}
                                    />
                                    <Route
                                        exact
                                        path="/ChangePassword"
                                        component={ChangePassword}
                                    />
                                    <Route path="*" component={Error} />
                                </Switch>
                                <div className={classes.footer}>
                                    <Footer />
                                </div>
                            </div>
                        </BrowserRouter>
                    </ThemeProvider>
                </MuiPickersUtilsProvider>
            </QueryClientProvider>
            <PopUp
                openPopUp={openPopUp}
                setOpenPopUp={setOpenPopUp}
                title={"Λήξη Σύνδεσης"}
                maxWidth="md"
                maxHeight="md"
            >
                <Alert severity="info">Η σύνδεση σας έληξε! Παρακαλώ συνδεθείτε ξανά.</Alert>
            </PopUp>
        </div>
    );
}

export default App;
