import React from "react";

import { mainColumns } from "./config.js";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

function ReferencesTable({ title, references = [] }) {
    return (
        <div>
            <h6>
                <b>{title}</b>
            </h6>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {mainColumns.map((column, index) => {
                                return (
                                    <TableCell key={index}>
                                        <b>{column.name}</b>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody></TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default ReferencesTable;
