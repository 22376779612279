import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import PrintIcon from "@material-ui/icons/Print";
import { withStyles } from "@material-ui/core/styles";

const defaultToolbarStyles = {
  iconButton: {
    
  },
};

class MedicineTotalsToolbar extends React.Component {
  
  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Tooltip title={"Υποβολή και PDF"}>
          <IconButton className={classes.iconButton} onClick={this.props.handleClick}>
            <PrintIcon className={classes.deleteIcon} />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    );
  }

}

export default withStyles(defaultToolbarStyles, { name: "MedicineTotalsToolbar" }) (MedicineTotalsToolbar);