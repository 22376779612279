import axios from "axios";
import { baseUrl, getOptions } from "../config.js";

class MediPlusEPrescriptionHomePageService {
    constructor(baseUrl) {
        this.basePath = "/ePrescriptionHomePage/links";
        this.baseUrl = `${baseUrl}${this.basePath}`;
    }

    getLinks() {
        return axios(getOptions(`${this.baseUrl}`));
    }
}

export default new MediPlusEPrescriptionHomePageService(baseUrl);
