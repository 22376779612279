import React, { useState } from "react";
import { mainColumns } from "./config.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import PopUp from "../../../../../Utilities/PopUp";
import Alert from "@material-ui/lab/Alert";

function ShowDetailsContent({ examDetails }) {
    const diagnosisColumns = ["Κωδικός ICD-10", "Διάγνωση"];
    const examinationColumns = ["Εξέταση", "Διάγνωση", "Λόγος παραπομπής", "Σχόλια"];

    const diagnoses = JSON.parse(examDetails.diagnoses);
    const referrals = JSON.parse(examDetails.referrals);

    return (
        <div>
            <div>
                <b>Όνομα: </b> {examDetails.name}
            </div>
            <div>
                <b>Αριθμός Εξετάσεων: </b> {examDetails.numberOfReferrals}
            </div>
            <div>
                <b>Τύπος Συνταγής: </b> {examDetails.prescriptionType.nameForExamPrescription}
            </div>
            <div>
                <b>Κατηγορία Εξετάσεων: </b> {examDetails.examinationGroup}
            </div>
            <div>
                <b>Λόγος: </b> {examDetails.reason}
            </div>
            <div>
                <b>Σχόλια: </b> {examDetails.comment}
            </div>
            <br />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {diagnosisColumns.map((column, index) => {
                                return (
                                    <TableCell key={index}>
                                        <b>{column}</b>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {diagnoses.map((diagnosis, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell>{diagnosis.code}</TableCell>
                                    <TableCell>{diagnosis.title}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {examinationColumns.map((column, index) => {
                                return (
                                    <TableCell key={index}>
                                        <b>{column}</b>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {referrals.map((referral, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell>{referral.code}</TableCell>
                                    <TableCell>{referral.displayName}</TableCell>
                                    <TableCell>{referral.diagnosisCode}</TableCell>
                                    <TableCell>{referral.comment}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
function ReferralsToBeRegistered({
    referralsToBeRegistered,
    setReferralsToBeRegistered,
    tabItem,
    setTabItem,
}) {
    const [examDetails, setExamDetails] = useState({});
    const [popUp, setPopUp] = useState(false);
    const onShowDetails = (exam) => {
        setExamDetails(exam);
        setPopUp(true);
    };

    const onDelete = (exam, examIndex) => {
        let newReferralsToBeRegistered = referralsToBeRegistered.filter(
            (referral, index) => index !== examIndex
        );
        setReferralsToBeRegistered(newReferralsToBeRegistered);
        setTabItem({
            ...tabItem,
            visitDetails: {
                ...tabItem.visitDetails,
                referralsToBeRegistered: newReferralsToBeRegistered,
            },
        });
    };

    return (
        <div>
            {referralsToBeRegistered.length === 0 ? (
                <Alert severity="info">Δεν υπάρχουν Παραπεμπτικά προς Καταχώρηση</Alert>
            ) : (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {mainColumns.map((column, index) => {
                                    return (
                                        <TableCell key={index}>
                                            <b>{column.name}</b>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {referralsToBeRegistered.map((exam, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{exam.name}</TableCell>
                                        <TableCell>{exam.numberOfReferrals}</TableCell>
                                        <TableCell>{exam.examinationGroup}</TableCell>
                                        <TableCell>
                                            <button
                                                className="btn buttonPrimary small-padding"
                                                onClick={() => onShowDetails(exam)}
                                            >
                                                <i className="fa fa-search"></i>
                                                Λεπτομέρειες
                                            </button>
                                            <button
                                                className="btn buttonDanger small-padding"
                                                onClick={() => onDelete(exam, index)}
                                            >
                                                <i className="fa fa-trash"></i>
                                                Διαγραφή
                                            </button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <PopUp
                openPopUp={popUp}
                setOpenPopUp={setPopUp}
                showCloseButton={true}
                title={"Λεπτομέρειες Αγαπημένου Πακέτου Εξετάσεων"}
            >
                <ShowDetailsContent examDetails={examDetails} />
            </PopUp>
        </div>
    );
}

export default ReferralsToBeRegistered;
