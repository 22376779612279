import React, { useState, useEffect } from "react";
import { mainColumns } from "./config.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import { Spinner } from "../../../../../../../Utilities/Utilities.js";
import IdikaMasterDataService from "../../../../../../../../services/MediPlus/Idika/IdikaMasterDataService.js";
import IdikaPatientService from "../../../../../../../../services/MediPlus/Idika/IdikaPatientService.js";

function AddBiometric({ amkaOrEkaa, patientBiovalues, setPatientBiovalues, setOpenPopUp }) {
    const [biometrics, setBiometrics] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const checkIfBiometricExists = (bioId) => {
        for (let pBio of patientBiovalues.biovalues) {
            if (bioId === pBio.biometricsPool.id) return true;
        }
        return false;
    };

    const restBiometrics = (data) => {
        let biometrics = [];
        for (let bio of data) {
            if (checkIfBiometricExists(bio.id) === false) biometrics.push(bio);
        }
        return biometrics;
    };

    useEffect(() => {
        IdikaMasterDataService.getBiometrics()
            .then(({ data }) => {
                setBiometrics(restBiometrics(data.biometrics));
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
        // eslint-disable-next-line
    }, []);

    const [state, setState] = useState({
        bioValue: "",
        biometricsPool: { id: "" },
    });

    const handleSelect = (e) => {
        e.preventDefault();
        let value = e.target.value;
        let holdBio = { id: "" };
        for (let bio of biometrics) {
            if (bio.id === value) {
                holdBio = bio;
                break;
            }
        }
        setState({ ...state, biometricsPool: holdBio });
    };

    const onSubmit = (e) => {
        e.preventDefault();

        let request = {
            Collection: {
                item: {
                    ...state,
                },
            },
        };

        IdikaPatientService.saveOrAddBiovalues(request, amkaOrEkaa)
            .then(({ data }) => {
                let newBiometrics = patientBiovalues;
                newBiometrics.biovalues.push(data.biovalues[0]);
                setPatientBiovalues(newBiometrics);
                setOpenPopUp(false);
            })
            .catch((error) => {
                setError(error);
            });
    };

    if (error !== null) return <Alert severity="error">Κάτι πήγε στραβά!</Alert>;
    else if (loading === true) return <Spinner align="center" animation="spinner-border" />;

    return (
        <div>
            {biometrics.length !== 0 ? (
                <form onSubmit={onSubmit}>
                    <div className="form-group">
                        <label>Βιομετρικό</label>
                        <select className="form-control" onChange={handleSelect} value={state.id}>
                            <option value="">{""}</option>
                            {biometrics.map((biometric) => (
                                <option key={biometric.id} value={biometric.id}>
                                    {biometric.description}
                                </option>
                            ))}
                        </select>
                    </div>
                    {state.biometricsPool.id !== "" ? (
                        <div>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {mainColumns.map((column, index) => {
                                                return (
                                                    <TableCell key={index}>
                                                        <b>{column.name}</b>
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                {state.biometricsPool.description}
                                            </TableCell>
                                            <TableCell>
                                                <input
                                                    type={
                                                        state.biometricsPool.datatype === "NUM"
                                                            ? "number"
                                                            : "text"
                                                    }
                                                    className="form-control"
                                                    value={state.bioValue}
                                                    onChange={(e) =>
                                                        setState({
                                                            ...state,
                                                            bioValue: e.target.value,
                                                        })
                                                    }
                                                    required
                                                />
                                            </TableCell>
                                            <TableCell>{state.biometricsPool.mesUnit}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <br />
                            <div align="center">
                                <button className="btn buttonPrimary">Προσθήκη</button>
                            </div>
                        </div>
                    ) : null}
                </form>
            ) : (
                <Alert severity="info">
                    Έχουν προστεθεί όλα τα Βιομετρικά Στοιχεία στον Ασθενή
                </Alert>
            )}
        </div>
    );
}

export default AddBiometric;
