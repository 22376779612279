import React, { useState, useEffect, useContext } from "react";
import "./Prescription.css";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { getCurrentDateGreekFormat, Spinner } from "../../../../Utilities/Utilities";
import Alert from "@material-ui/lab/Alert";
import IdikaMasterDataService from "../../../../../services/MediPlus/Idika/IdikaMasterDataService";
import IdikaPrescriptionService from "../../../../../services/MediPlus/Idika/IdikaPrescriptionService";
import axios from "axios";
import Tooltip from "@material-ui/core/Tooltip";
import { TextField } from "@material-ui/core";
import { DoctorContext } from "../../../Doctor.js";

function PrescriptionDetails({ addPrescriptionState, setAddPrescriptionState }) {
    const doctor = useContext(DoctorContext);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [prescriptionRepeats, setPrescriptionRepeats] = useState(null);
    const [prescriptionTypes, setPrescriptionTypes] = useState(null);
    useEffect(() => {
        const requestPrescriptionRepeats = IdikaMasterDataService.getPrescriptionRepeats();
        const requestPrescriptionTypes = IdikaMasterDataService.getPrescriptionTypes();
        const requestPrescriptionIntervals = IdikaMasterDataService.getPrescriptionIntervals();

        axios
            .all([
                requestPrescriptionRepeats,
                requestPrescriptionTypes,
                requestPrescriptionIntervals,
            ])
            .then(
                axios.spread((...responses) => {
                    let responsePrescriptionRepeats = responses[0].data;
                    responsePrescriptionRepeats.prescriptionRepeats =
                        responsePrescriptionRepeats.prescriptionRepeats.sort((a, b) =>
                            a.id > b.id ? 1 : -1
                        );
                    let responsePrescriptionIntervals = responses[2].data;
                    responsePrescriptionIntervals.prescriptionIntervals =
                        responsePrescriptionIntervals.prescriptionIntervals.sort((a, b) =>
                            a.id > b.id ? 1 : -1
                        );
                    responsePrescriptionRepeats.prescriptionRepeats.forEach((element) => {
                        if (element.id === 1) {
                            element.intervals = [];
                        } else if (element.id === 2) {
                            element.intervals =
                                responsePrescriptionIntervals.prescriptionIntervals.filter(
                                    (interval) => interval.intervalDays === 60
                                );
                        } else if (element.id === 3) {
                            element.intervals = responsePrescriptionIntervals.prescriptionIntervals;
                        } else {
                            element.intervals =
                                responsePrescriptionIntervals.prescriptionIntervals.filter(
                                    (interval) =>
                                        interval.intervalDays === 28 || interval.intervalDays === 30
                                );
                        }
                    });
                    setPrescriptionRepeats(responsePrescriptionRepeats);
                    setPrescriptionTypes(responses[1].data);
                })
            )
            .catch((errors) => {
                setError(errors);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const [opinionError, setOpinionError] = useState({});
    const [doctorIsValid, setDoctorIsValid] = useState(false);
    const checkIfDoctorAmkaIsValid = () => {
        if (
            addPrescriptionState.otherDoctorOpinionAmka.length < 11 ||
            addPrescriptionState.otherDoctorOpinionAmka.length > 11
        ) {
            setOpinionError({
                doctorError: `Λάθος ΑΜΚΑ`,
            });
            return;
        }
        if (addPrescriptionState.otherDoctorOpinionAmka === doctor.doctorData.amka.toString()) {
            setOpinionError({
                doctorError: `Το ΑΜΚΑ του γιατρού γνωμάτευσης πρέπει να είναι διαφορετικό από το δικό σας`,
            });
            return;
        }
        setOpinionError({});
        IdikaPrescriptionService.getCopyDoctor(addPrescriptionState.otherDoctorOpinionAmka)
            .then(({ data }) => {
                setDoctorIsValid(true);
            })
            .catch((err) => {
                let errorAmka = addPrescriptionState.otherDoctorOpinionAmka;
                setOpinionError({ doctorError: `Δεν βρέθηκε ιατρός με ΑΜΚΑ: ${errorAmka}` });
            });
    };

    const handlePrescriptionTypeChange = (e) => {
        setAddPrescriptionState({
            ...addPrescriptionState,
            prescriptionTypeId: e.target.value,
        });
    };

    const handleRepeatChange = (e) => {
        let repeats = prescriptionRepeats.prescriptionRepeats.find(
            (element) => element.id.toString() === e.target.value
        );
        if (e.target.value > 1) {
            setAddPrescriptionState({
                ...addPrescriptionState,
                repeatId: e.target.value,
                interval: repeats.intervals[0].id,
                chronicDisease: true,
            });
        } else {
            setAddPrescriptionState({
                ...addPrescriptionState,
                repeatId: e.target.value,
                interval: "",
                chronicDisease: false,
            });
        }
    };

    const handleIntervalChange = (e) => {
        setAddPrescriptionState({
            ...addPrescriptionState,
            interval: e.target.value,
        });
    };

    return (
        <div className="fullBox">
            <div className="boxTitle">
                <h6>Συνταγή</h6>
            </div>
            {loading ? (
                <Spinner align="center" animation="spinner-border" />
            ) : error ? (
                <Alert severity="error">Κάτι πήγε στραβά!</Alert>
            ) : (
                <div className="boxContent">
                    <div>
                        <div className="prescriptionFlex">
                            <div className="flex">
                                <p className="infoP alignRight">* Τύπος Συνταγής</p>
                                <select
                                    value={addPrescriptionState.prescriptionTypeId}
                                    onChange={handlePrescriptionTypeChange}
                                    disabled={
                                        addPrescriptionState.medicines.length > 0 ? true : false
                                    }
                                >
                                    {prescriptionTypes.prescriptionTypes
                                        .slice(1)
                                        .map((prescriptionType) => {
                                            return (
                                                <option
                                                    key={prescriptionType.id}
                                                    value={prescriptionType.id}
                                                >
                                                    {prescriptionType.nameForPrescription}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>
                            <div className="flex">
                                <p className="infoP alignRight">* Επαναλήψεις</p>
                                <Tooltip
                                    placement="top"
                                    title="Σε επαναλαμβανόμενη συνταγή δεν είναι δυνατόν να προστεθούν αναλώσιμα"
                                >
                                    <select
                                        value={addPrescriptionState.repeatId}
                                        onChange={handleRepeatChange}
                                        disabled={
                                            addPrescriptionState.twoMonthRecipe === true ||
                                            addPrescriptionState.medicines.length > 0
                                                ? true
                                                : false
                                        }
                                    >
                                        {prescriptionRepeats.prescriptionRepeats.map(
                                            (prescriptionRepeat) => {
                                                return (
                                                    <option
                                                        key={prescriptionRepeat.id}
                                                        value={prescriptionRepeat.id}
                                                    >
                                                        {prescriptionRepeat.name}
                                                    </option>
                                                );
                                            }
                                        )}
                                    </select>
                                </Tooltip>
                            </div>
                            <div className="flex">
                                <p className="infoP alignRight">Περίοδος Επανάληψης</p>
                                <select
                                    value={addPrescriptionState.interval}
                                    onChange={handleIntervalChange}
                                    disabled={
                                        addPrescriptionState.twoMonthRecipe === true ||
                                        addPrescriptionState.repeatId === "1" ||
                                        addPrescriptionState.medicines.length > 0
                                            ? true
                                            : false
                                    }
                                >
                                    {prescriptionRepeats.prescriptionRepeats
                                        .find(
                                            (element) =>
                                                element.id.toString() ===
                                                addPrescriptionState.repeatId
                                        )
                                        .intervals.map((prescriptionInterval) => {
                                            return (
                                                <option
                                                    key={prescriptionInterval.id}
                                                    value={prescriptionInterval.id}
                                                >
                                                    {prescriptionInterval.intervalDescription}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>
                            <div className="flex">
                                <p className="infoP alignRight">Ημ/νία Έκδοσης Συνταγής</p>
                                <p className="noMargin" id="">
                                    {getCurrentDateGreekFormat()}
                                </p>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div>
                        <div className="prescriptionFlex">
                            <Tooltip
                                placement="top"
                                title="Το πεδίο δεν τροποποιείται εάν έχουν προστεθεί θεραπείες στη συνταγή"
                            >
                                <div className="flex">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={addPrescriptionState.chronicDisease}
                                                onChange={() =>
                                                    setAddPrescriptionState({
                                                        ...addPrescriptionState,
                                                        chronicDisease:
                                                            !addPrescriptionState.chronicDisease,
                                                    })
                                                }
                                                name="chronicDisease"
                                                color="primary"
                                            />
                                        }
                                        label="Χρόνια Πάθηση"
                                        disabled={
                                            addPrescriptionState.monthlyRecipe ||
                                            addPrescriptionState.twoMonthRecipe ||
                                            addPrescriptionState.medicines.length > 0
                                                ? true
                                                : false
                                        }
                                    />
                                </div>
                            </Tooltip>
                            <div className="flex">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={addPrescriptionState.otherDoctorOpinion}
                                            onChange={() =>
                                                setAddPrescriptionState({
                                                    ...addPrescriptionState,
                                                    otherDoctorOpinion:
                                                        !addPrescriptionState.otherDoctorOpinion,
                                                })
                                            }
                                            name="recipeIssueDate"
                                            color="primary"
                                        />
                                    }
                                    label="Γνωμάτευση"
                                />
                            </div>
                            {addPrescriptionState.otherDoctorOpinion && (
                                <div className="flex">
                                    <TextField
                                        label="ΑΜΚΑ Ιατρού Γνωμάτευσης"
                                        variant="outlined"
                                        size="small"
                                        value={addPrescriptionState.otherDoctorOpinionAmka}
                                        onChange={(e) =>
                                            setAddPrescriptionState({
                                                ...addPrescriptionState,
                                                otherDoctorOpinionAmka: e.target.value,
                                            })
                                        }
                                        error={
                                            Object.keys(opinionError).length === 0 ? false : true
                                        }
                                        helperText={
                                            Object.keys(opinionError).length === 0
                                                ? null
                                                : opinionError.doctorError
                                        }
                                    />
                                    <button
                                        className="btn buttonPrimary"
                                        onClick={checkIfDoctorAmkaIsValid}
                                    >
                                        Αναζήτηση
                                    </button>
                                </div>
                            )}
                            {addPrescriptionState.otherDoctorOpinion && doctorIsValid && (
                                <div className="flex ml-3">
                                    <TextField
                                        label="Ημερ. Μέτρησης"
                                        type="date"
                                        value={addPrescriptionState.otherDoctorOpinionDate}
                                        onChange={(e) =>
                                            setAddPrescriptionState({
                                                ...addPrescriptionState,
                                                otherDoctorOpinionDate: e.target.value,
                                            })
                                        }
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default PrescriptionDetails;
