import React, { useState, useContext, useEffect } from "react";

import DoctorInfo from "../DoctorVisit/DoctorInfo/DoctorInfo";
import InsuredDetails from "../AddPrescription/InsuredDetails/InsuredDetails";
import ReferralDetails from "./ReferralDetails/ReferralDetails";
import Examinations from "./Examinations/Examinations";
import IdikaReferralService from "../../../../services/MediPlus/Idika/IdikaReferralService";
import PopUp from "../../../Utilities/PopUp";
import { PatientContext } from "../Esyntagografisi.js";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import IdikaMasterDataService from "../../../../services/MediPlus/Idika/IdikaMasterDataService";
import {
    Checkbox,
    CircularProgress,
    Paper,
    Table,
    TableCell,
    TableContainer,
    TableRow,
    TableBody,
    TableHead,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
        flexGrow: 1,
    },
}));

const initialReferralState = {
    barcode: "",
    reason: "",
    comment: "",
    prescriptionTypeId: "1",
    examinationGroupId: "",
    noParticipationCase: '{"needsDecision":false}',
    decisionDate: "",
    decisionNumber: "",
    needsDecision: false,
    referrals: [],
    diagnoses: [],
};

const DoctorAddExamination = ({ visitId, tabItem, setTabItem, setSelectedTabBttn }) => {
    const doctorData = JSON.parse(sessionStorage.getItem("mediPlusDoctorData"));
    const patientData = useContext(PatientContext);

    const [state, setState] = useState(tabItem.addReferral);
    const handleOnChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setState({ ...state, [name]: value });
        setTabItem({ ...tabItem, addReferral: state });
    };
    const [errorPopUp, setErrorPopUp] = useState(false);
    const [errors, setErrors] = useState([]);

    const [overridePopUp, setOverridePopUp] = useState(false);
    const [overrides, setOverrides] = useState([]);
    const [requestExtraInfo, setRequestExtraInfo] = useState({});

    const isValidSubmission = () => {
        let onSubmitErrors = [];
        if (state.examinationGroupId === "")
            onSubmitErrors.push("Πρέπει να συμπληρώσετε την Κατηγορία Εξετάσεων");
        if (state.reason === "")
            onSubmitErrors.push("Πρέπει να συμπληρώσετε την Αιτιολογία Παραπεμπτικού");
        let noParticipationCase = JSON.parse(state.noParticipationCase);
        if (noParticipationCase.needsDecision === true) {
            if (state.decisionDate === "")
                onSubmitErrors.push("Πρέπει να συμπληρώσετε την Ημ/νία Απόφασης");
            if (state.decisionNumber === "")
                onSubmitErrors.push("Πρέπει να συμπληρώσετε τον Αριθμό Απόφασης");
        }
        if (state.diagnoses.length === 0)
            onSubmitErrors.push("Το παραπεμπτικό θα πρέπει να έχει τουλάχιστον μία διάγνωση");
        if (state.referrals.length === 0)
            onSubmitErrors.push("Το παραπεμπτικό θα πρέπει να έχει τουλάχιστον μία εξέταση");
        else {
            let diagnosisCopy = [...state.diagnoses];
            for (let referral of state.referrals) {
                diagnosisCopy = diagnosisCopy.filter(
                    (diagnosis) => diagnosis.code !== referral.diagnosisCode
                );
                if (referral.diagnosisCode === "") {
                    onSubmitErrors.push(
                        "Κάθε εξέταση πρέπει να συσχετιστεί με συγκεκριμένη ICD10 διάγνωση"
                    );
                    break;
                } else if (referral.relationType === "") {
                    onSubmitErrors.push("Κάθε εξέταση πρέπει να έχει και έναν Λόγο Παραπομπής");
                    break;
                }
            }
            if (diagnosisCopy.length !== 0)
                onSubmitErrors.push(
                    "Κάθε ICD10 διάγνωση πρέπει να συσχετιστεί με μία τουλάχιστον εξέταση"
                );
        }
        if (Object.keys(onSubmitErrors).length !== 0) return onSubmitErrors;
        else return null;
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setErrors([]);

        let onSubmitErrors = isValidSubmission();
        if (onSubmitErrors !== null) {
            setErrors(onSubmitErrors);
            setErrorPopUp(true);
            return;
        }

        let request = {
            ...state,
            doctorId: doctorData.id.toString(),
            visitId: visitId.toString(),
            decisionDate: state.decisionDate.replaceAll("-", ""),
            patientAmka: patientData.amka.toString(),
        };
        setRequestExtraInfo({
            doctorId: doctorData.id.toString(),
            visitId: visitId.toString(),
            decisionDate: state.decisionDate.replaceAll("-", ""),
            patientAmka: patientData.amka.toString(),
        });
        IdikaReferralService.createReferral(request)
            .then(({ data }) => {
                setState(initialReferralState);
                setTabItem({
                    ...tabItem,
                    viewReferral: data,
                    addReferral: initialReferralState,
                    subTab: 8,
                });
                setSelectedTabBttn(8);
            })
            .catch((error) => {
                onSubmitErrors = [];
                if (error.response.data.status === 6130) {
                    onSubmitErrors.push("Λάθος Ημερομηνία Απόφασης");
                }
                if (error.response.data.status === 6036) {
                    setOverrides(JSON.parse(error.response.data.errors.error));
                    setOverridePopUp(true);
                    return;
                } else onSubmitErrors.push("Κάτι πήγε στραβά!");
                setErrors(onSubmitErrors);
                setErrorPopUp(true);
            });
    };

    const [tempSuccessPopUp, setTempSuccessPopUp] = useState(false);
    const [tempErrorPopUp, setTempErrorPopUp] = useState(false);
    const [tempErrors, setTempErrors] = useState([]);
    const onTempSubmit = (e) => {
        e.preventDefault();

        let onSubmitErrors = [];
        if (state.examinationGroupId === "")
            onSubmitErrors.push("Πρέπει να συμπληρώσετε την Κατηγορία Εξετάσεων");
        if (state.reason === "")
            onSubmitErrors.push("Πρέπει να συμπληρώσετε την Αιτιολογία Παραπεμπτικού");
        if (Object.keys(onSubmitErrors).length !== 0) {
            setTempErrors(onSubmitErrors);
            setTempErrorPopUp(true);
            return;
        }

        let request = {
            ...state,
            doctorId: doctorData.id.toString(),
            visitId: visitId.toString(),
            decisionDate: state.decisionDate.replaceAll("-", ""),
            patientAmka: patientData.amka.toString(),
        };

        IdikaReferralService.createTempReferral(request)
            .then(({ data }) => {
                setTempSuccessPopUp(true);
                setState(initialReferralState);
                setTabItem({
                    ...tabItem,
                    viewReferral: data,
                    addReferral: initialReferralState,
                    subTab: 8,
                });
                setSelectedTabBttn(8);
            })
            .catch((error) => {
                // console.log(error.response);
            });
    };

    const classes = useStyles();

    return (
        <div className="flexBoxWrap">
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={5}>
                        <DoctorInfo
                            firstname={doctorData.firstname}
                            lastname={doctorData.lastname}
                            specialty={doctorData.specialty}
                            amka={doctorData.amka}
                            etaaRegNo={doctorData.etaaRegNo}
                        />
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <InsuredDetails />
                    </Grid>
                </Grid>
            </div>
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={5}>
                        <ReferralDetails
                            state={state}
                            setState={setState}
                            handleOnChange={handleOnChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <Examinations
                            state={state}
                            setState={setState}
                            isValidSubmission={isValidSubmission}
                        />
                    </Grid>
                </Grid>
            </div>
            <br />
            <div className="flexBox">
                <button className="btn buttonPrimary" onClick={onTempSubmit}>
                    Προσωρινή Καταχώρηση
                </button>
                <button className="btn buttonPrimary" onClick={onSubmit}>
                    Καταχώρηση
                </button>
                <button
                    className="btn buttonPrimary"
                    onClick={() => setState(initialReferralState)}
                >
                    Καθαρισμός
                </button>
            </div>
            <PopUp openPopUp={errorPopUp} setOpenPopUp={setErrorPopUp} title={"Σφάλμα"}>
                <div>
                    <ul>
                        {errors.map((error, index) => {
                            return <li key={index}>{error}</li>;
                        })}
                    </ul>
                    <div align="center">
                        <button className="btn buttonPrimary" onClick={() => setErrorPopUp(false)}>
                            OK
                        </button>
                    </div>
                </div>
            </PopUp>
            <PopUp openPopUp={tempErrorPopUp} setOpenPopUp={setTempErrorPopUp} title={"Σφάλμα"}>
                <div>
                    <ul>
                        {tempErrors.map((error, index) => {
                            return <li key={index}>{error}</li>;
                        })}
                    </ul>
                    <div align="center">
                        <button
                            className="btn buttonPrimary"
                            onClick={() => setTempErrorPopUp(false)}
                        >
                            OK
                        </button>
                    </div>
                </div>
            </PopUp>
            <PopUp
                openPopUp={tempSuccessPopUp}
                setOpenPopUp={setTempSuccessPopUp}
                title={"Επιβεβαίωση"}
                maxWidth="sm"
            >
                <div align="center">
                    <p style={{ fontSize: "larger", color: "black" }}>
                        Η προσωρινή καταχώρηση ολοκληρώθηκε με επιτυχία
                    </p>
                    <br />
                    <button
                        className="btn buttonPrimary"
                        onClick={() => setTempSuccessPopUp(false)}
                    >
                        OK
                    </button>
                </div>
            </PopUp>
            <PopUp
                openPopUp={overridePopUp}
                setOpenPopUp={setOverridePopUp}
                showCloseButton={true}
                title={"Προσοχή!"}
                maxWidth="lg"
            >
                <Override
                    addExaminationState={state}
                    setAddExaminationState={setState}
                    overrides={overrides}
                    tabItem={tabItem}
                    setTabItem={setTabItem}
                    setSelectedTabBttn={setSelectedTabBttn}
                    extraInfo={requestExtraInfo}
                />
            </PopUp>
        </div>
    );
};

function Override({
    addExaminationState,
    // setAddExaminationState,
    overrides,
    tabItem,
    setTabItem,
    setSelectedTabBttn,
    extraInfo,
}) {
    const mainColumns = [
        "Αποδοχή",
        "Διάγνωση",
        "Εξέταση",
        "Εξαίρεση",
        "Περιγραφή υπέρβασης κανόνα",
        "Αιτιολόγηση",
    ];

    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [reason, setReason] = useState(
        overrides.map((override) => {
            let reasonState = {};
            reasonState.reason = "";
            reasonState.accept = false;
            return reasonState;
        })
    );

    const [state, setState] = useState(
        overrides.map((override) => {
            let overrideState = { ...override };
            overrideState.accept = false;
            overrideState.reason = "";
            overrideState.code = "";
            overrideState.exception = "";
            overrideState.examination = "";
            return overrideState;
        })
    );

    useEffect(() => {
        setIsLoading(true);
        IdikaMasterDataService.getOverrideTypes()
            .then(({ data }) => {
                const examinationOverrides = data.Page.contents.item;
                var tempState = state;
                for (var i = 0; i < tempState.length; i++) {
                    //exception
                    tempState[i].exception = examinationOverrides.find(
                        (element) => element.id === tempState[i].overrideTypeId
                    ).description;
                    if (tempState[i].examinationId) {
                        tempState[i].examination = addExaminationState.referrals.find(
                            (element) => element.code === tempState[i].examinationId.toString()
                        ).displayName;
                    }
                }

                setState(tempState);

                setIsLoading(false);
            })
            .catch((error) => {
                setIsError(true);
                setIsLoading(false);
            });
    }, [addExaminationState, overrides, state]);

    useEffect(() => {
        var icd10s = [];
        for (var i = 0; i < overrides.length; i++) {
            if (overrides[i].icd10Id !== undefined) {
                icd10s.push(overrides[i].icd10Id);
            }
        }

        IdikaMasterDataService.getIcd10Code({ icd10s: icd10s })
            .then(({ data }) => {
                var tempState = state;
                for (var i = 0; i < tempState.length; i++) {
                    if (tempState[i].icd10Id) {
                        tempState[i].code = data.icds.find(
                            (element) => element.id === tempState[i].icd10Id
                        ).info.code;
                    }
                }

                setState(tempState);
            })
            .catch((error) => {
                setIsError(true);
                // setIsLoading(false);
            });
    }, []);

    const [filledComponentsError, setFilledComponentsError] = useState(false);
    const [overrideLoading, setOverrideLoading] = useState(false);
    const onSubmit = () => {
        let tempState = state;
        for (let i = 0; i < tempState.length; i++) {
            tempState[i].reason = reason[i].reason;
            tempState[i].accept = reason[i].accept;
        }

        if (tempState.find((s) => s.accept === false || s.reason.length === 0) !== undefined) {
            setFilledComponentsError(true);
            return;
        }
        setFilledComponentsError(false);
        let referralRequests = addExaminationState.referrals;
        for (let override of tempState) {
            for (let index in referralRequests) {
                if (String(override.lineNumber) === referralRequests[index].code) {
                    referralRequests[index].override = override;
                }
            }
        }
        let request = {
            ...addExaminationState,
            ...extraInfo,
            referrals: referralRequests,
        };

        setOverrideLoading(true);
        IdikaReferralService.createReferral(request)
            .then(({ data }) => {
                //setState(initialReferralState);
                setTabItem({
                    ...tabItem,
                    viewReferral: data,
                    addReferral: initialReferralState,
                    subTab: 8,
                });
                setSelectedTabBttn(8);
            })
            .catch((error) => {
                //console.log(error.response);
            })
            .finally(() => {
                setOverrideLoading(false);
            });
    };

    const handleStateAcceptChange = (e) => {
        let { name, checked } = e.target;
        let newState = reason;
        newState[parseInt(name)].accept = checked;
        setReason([...newState]);
        // let newState = state;
        // newState[parseInt(name)].accept = checked;
        // setState([...newState]);
    };

    const handleStateReasonChange = (e) => {
        let { name, value } = e.target;
        let newState = reason;
        newState[parseInt(name)].reason = value;
        setReason([...newState]);
    };

    return (
        <div>
            Για να ολοκληρωθεί η καταχώρηση παραπεμπτικού πρέπει να γίνει αποδοχή των εξαιρέσεων και
            τεκμηρίωση για κάθε εξαίρεση.
            {isLoading ? (
                <CircularProgress />
            ) : isError ? (
                <Alert severity="error">Κάτι πήγε στραβά!</Alert>
            ) : (
                <div>
                    {filledComponentsError && (
                        <Alert className="mt-3" severity="error">
                            Παρακαλώ επιλέξτε όλα τα πλαίσια αποδοχής και αιτιολόγησης εξαιρέσεων,
                            προκειμένου να ολοκληρώσετε την καταχώρηση
                        </Alert>
                    )}
                    <TableContainer component={Paper} className="mt-3 mb-3">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {mainColumns.map((column, index) => {
                                        return (
                                            <TableCell key={index}>
                                                <b>{column}</b>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {state.map((override, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <Checkbox
                                                    name={index.toString()}
                                                    color="primary"
                                                    checked={reason[index].accept}
                                                    onChange={handleStateAcceptChange}
                                                />
                                            </TableCell>
                                            <TableCell>{override.code}</TableCell>
                                            <TableCell>{override.examination}</TableCell>
                                            <TableCell>{override.exception}</TableCell>
                                            <TableCell>{override.violationDetails}</TableCell>
                                            <TableCell>
                                                <textarea
                                                    name={index.toString()}
                                                    rows="5"
                                                    cols="40"
                                                    value={reason[index].reason}
                                                    onChange={handleStateReasonChange}
                                                    required
                                                />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div align="center">
                        <button
                            className="btn buttonPrimary"
                            onClick={onSubmit}
                            disabled={overrideLoading}
                        >
                            OK
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default DoctorAddExamination;
