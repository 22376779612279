import React from "react";
import "./Footer.css";

function Footer() {
    return (
        <footer className="page-footer">
            <div className="footer-copyright text-center py-3">
                © {new Date().getFullYear()} Copyright:
                <a href="http://plusit.gr/" target="_blank" rel="noreferrer">
                    {` Plus IT`}
                </a>
            </div>
        </footer>
    );
}

export default Footer;
