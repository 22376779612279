import { CircularProgress, Grid, Tooltip } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useEffect, useRef, useState } from "react";
import MediPlusImageService from "../../../services/MediPlus/MediPlusImageService";

// careful do not upload huge images
// the size should be small because these images
// are used to customize doctor's custom prescription
function CustomPrescription() {
    const [image, setImage] = useState(null);
    const [preview, setPreview] = useState(null);
    const fileInputRef = useRef();

    useEffect(() => {
        if (image) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
            };
            reader.readAsDataURL(image);
        } else {
            setPreview(null);
        }
    }, [image]);

    const [sizeError, setSizeError] = useState("");
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file.size > 60000) {
            setSizeError("Το μέγεθος της εικόνας είναι μεγαλύτερο από το επιτρεπτό.");
            setImage(null);
            return;
        }
        setSizeError("");
        if (file && file.type.substr(0, 5) === "image") {
            setImage(file);
        } else {
            setImage(null);
        }
    };

    const uploadImage = (event) => {
        event.preventDefault();
        fileInputRef.current.click();
    };

    const [saveError, setSaveError] = useState(null);
    const [saveLoading, setSaveLoading] = useState(false);
    const onSave = (event) => {
        event.preventDefault();
        const uploadImageData = new FormData();
        uploadImageData.append("imageFile", image, image.name);
        setSaveError(null);
        setSaveLoading(true);
        MediPlusImageService.uploadImage(uploadImageData)
            .then(({ data }) => {
                setImages([...images, data]);
            })
            .catch((err) => {
                // console.log(err.response);
                setSaveError(err.response);
            })
            .finally(() => {
                setSaveLoading(false);
            });
    };

    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    useEffect(() => {
        MediPlusImageService.getAllImages()
            .then(({ data }) => {
                setImages(data);
            })
            .catch((err) => {
                // console.log(err.response);
                setError(err.response);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return (
        <div>
            {loading ? (
                <div align="center">
                    <CircularProgress />
                </div>
            ) : error ? (
                <Alert severity="error">Κάτι πήγε στραβά!</Alert>
            ) : (
                <form className="d-flex flex-column justify-content-between">
                    {sizeError && (
                        <Alert className="mb-3" severity="info">
                            {sizeError}
                        </Alert>
                    )}
                    {saveError && (
                        <Alert className="mb-3" severity="error">
                            Κάτι πήγε στραβά!
                        </Alert>
                    )}
                    <button
                        className="btn buttonPrimary align-self-center mb-5"
                        onClick={uploadImage}
                    >
                        Προσθήκη Εικόνας
                    </button>
                    {preview && (
                        <div className="d-flex flex-column justify-content-between">
                            <Tooltip title="Κάντε κλικ στην φωτογραφία για να την διαγράψετε">
                                <img
                                    className="align-self-center mb-2"
                                    src={preview}
                                    style={{ height: "200px", cursor: "pointer" }}
                                    alt="img"
                                    onClick={() => {
                                        setImage(null);
                                    }}
                                />
                            </Tooltip>
                            <div className="align-self-center">
                                <button
                                    className="btn buttonPrimary mb-5"
                                    onClick={onSave}
                                    disabled={saveLoading}
                                >
                                    Αποθήκευση Εικόνας
                                </button>
                            </div>
                        </div>
                    )}
                    <input
                        type="file"
                        style={{ display: "none" }}
                        ref={fileInputRef}
                        accept="image/*"
                        onChange={handleFileChange}
                    />
                    <Grid container className="mb-5">
                        {images.map((storedImage) => {
                            return (
                                <Grid item xs={3} align="center" key={storedImage.id}>
                                    <div align="center">
                                        <img
                                            style={{ height: "150px", margin: "10px" }}
                                            alt="img"
                                            src={`data:image/jpeg;base64,${storedImage.bytes}`}
                                        />
                                    </div>
                                </Grid>
                            );
                        })}
                    </Grid>
                </form>
            )}
        </div>
    );
}

export default CustomPrescription;
