const mainTabs = [
    {
        className: "tabBttns",
        label: "Αρχική",
        disabled: false,
    },
    {
        className: "tabBttns",
        label: "Επίσκεψη",
        disabled: false,
    },
    {
        className: "tabBttns",
        label: "Καταχώρηση Συνταγής Φαρμάκων",
        disabled: true,
    },
    {
        className: "tabBttns",
        label: "Καταχώρηση Παραπεμπτικού",
        disabled: true,
    },
    {
        className: "tabBttns",
        label: "Εκτέλεση Παραπεμπτικού",
        disabled: true,
    },
    {
        className: "tabBttns",
        label: "Ιστορικό Ασθενή",
        disabled: false,
    },
    {
        className: "tabBttns",
        label: "Αναζητήσεις",
        disabled: false,
    },
    {
        className: "tabBttns",
        label: "Θεώρηση Συνταγής",
        disabled: true,
    },
    {
        className: "tabBttns",
        label: "Θεώρηση Παραπεμπτικού",
        disabled: true,
    },
    {
        className: "tabBttns",
        label: "Τα Στοιχεία μου",
        disabled: false,
    },
];

export { mainTabs };
