import React, { useEffect, useState } from "react";

import { mainColumns } from "./config.js";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";

import { Spinner } from "../../../../../../Utilities/Utilities.js";
import Alert from "@material-ui/lab/Alert";
import IdikaMasterDataService from "../../../../../../../services/MediPlus/Idika/IdikaMasterDataService.js";

function SearchIcpc2({ insertedIcpc2s, setinsertedIcpc2s }) {
    const [keyword, setKeyword] = useState("");
    const [icpc2s, setIcpc2s] = useState(null);
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const [successAlert, setSuccessAlert] = useState(false);
    const [infoAlert, setInfoAlert] = useState(null);

    const onSubmit = (e) => {
        e.preventDefault();
        setFetch(true);
    };

    const [pagination, setPagination] = useState({
        page: 1,
        totalPages: 0,
    });

    const handlePageChange = (event, value) => {
        setPagination({ ...pagination, page: value });
        setFetch(true);
    };

    const [fetch, setFetch] = useState(false);
    useEffect(() => {
        if (fetch === false) return;
        setInfoAlert(null);
        setSuccessAlert(false);
        setError({});
        setLoading(true);
        IdikaMasterDataService.getIcpc2s({ keyword: keyword, size: 7, page: pagination.page - 1 })
            .then(({ data }) => {
                let page = 1;
                if (data.count === 0) setInfoAlert("Δεν βρέθηκαν icpc2s!");
                else page = pagination.page;
                setIcpc2s(data);
                setPagination({
                    page: page,
                    totalPages: data.totalPages,
                });
                setLoading(false);
            })
            .catch((error) => {
                setError(error.response.data);
                setLoading(false);
            });
        setFetch(false);
        // eslint-disable-next-line
    }, [fetch]);

    const onAdd = ({ code, title }) => {
        setinsertedIcpc2s([...insertedIcpc2s, { code, title }]);
        setSuccessAlert(`To ICPC2 '${title}' προστέθηκε με επιτυχία!`);
    };

    const onDelete = (code, title) => {
        setinsertedIcpc2s(insertedIcpc2s.filter((icpc2) => icpc2.code !== code));
        setSuccessAlert(`To ICPC2 '${title}' διαγράφθηκε με επιτυχία!`);
    };

    const checkIfIcpc2IsAdded = (code) => {
        return insertedIcpc2s.find((icpc2) => {
            return icpc2.code === code;
        });
    };

    return (
        <div>
            {Object.keys(error).length !== 0 && (
                <div>
                    <Alert severity="error">Kάτι πήγε στραβά!</Alert>
                    <br />
                </div>
            )}
            {infoAlert && (
                <div>
                    <Alert severity="info">{infoAlert}</Alert>
                    <br />
                </div>
            )}
            {successAlert && (
                <div>
                    <Alert severity="success">{successAlert}</Alert>
                    <br />
                </div>
            )}
            <form onSubmit={onSubmit}>
                <div className="form-group">
                    <label>Κωδικός/Tίτλος *</label>
                    <input
                        type="text"
                        className="form-control"
                        name="keyword"
                        value={keyword}
                        onChange={(e) => setKeyword(e.target.value)}
                        required
                    />
                </div>
                {loading === true ? (
                    <Spinner align="center" animation="spinner-border" />
                ) : (
                    <div align="center">
                        {loading === true ? (
                            <Spinner align="center" animation="spinner-border" />
                        ) : (
                            <div>
                                <button className="btn buttonPrimary">Αναζήτηση</button>
                            </div>
                        )}
                    </div>
                )}
            </form>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {mainColumns.map((column, index) => {
                                return (
                                    <TableCell key={index}>
                                        <b>{column.name}</b>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {icpc2s !== null &&
                            icpc2s.icpc2s.map((icpc2) => {
                                return (
                                    <TableRow key={icpc2.code}>
                                        <TableCell>{icpc2.code}</TableCell>
                                        <TableCell>{icpc2.title}</TableCell>
                                        <TableCell>
                                            {checkIfIcpc2IsAdded(icpc2.code) ? (
                                                <button
                                                    className="btn buttonDanger"
                                                    onClick={() =>
                                                        onDelete(icpc2.code, icpc2.title)
                                                    }
                                                >
                                                    Διαγραφή
                                                </button>
                                            ) : (
                                                <button
                                                    className="btn buttonPrimary"
                                                    onClick={() =>
                                                        onAdd({
                                                            code: icpc2.code,
                                                            title: icpc2.title,
                                                        })
                                                    }
                                                >
                                                    Προσθήκη
                                                </button>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination
                count={pagination.totalPages}
                page={pagination.page}
                onChange={handlePageChange}
            />
        </div>
    );
}

export default SearchIcpc2;
