import axios from "axios";
import { baseUrl, postOptions } from "../config.js";

class MediPlusResetPasswordService {
    constructor(baseUrl) {
        this.basePath = "/resetPassword";
        this.baseUrl = `${baseUrl}${this.basePath}`;
    }

    createTokenAndSendEmail(email) {
        return axios(postOptions(this.baseUrl, "", { email }));
    }

    changePasswordWithToken(data) {
        return axios(postOptions(this.baseUrl, "/changePassword", data));
    }
}

export default new MediPlusResetPasswordService(baseUrl);
