import React, { useEffect, useState, useContext } from "react";
import "./DoctorInfo.css";
import IdikaDoctorService from "../../../../../services/MediPlus/Idika/IdikaDoctorService.js";
import { UnitIdContext } from "../../../Doctor";

function DoctorInfo({ firstname, lastname, specialty, amka, etaaRegNo }) {
    const [userUnitLimits, setUserUnitLimits] = useState({
        actualCost: "",
        canPrescribe: "",
        canPrescribeWithCommercialName: true,
        eoppyCostLimit: "",
        notUpdPharCost: "",
        totalNoInnValue: 0,
        totalPercentage: 0,
        totalValue: "",
    });

    const { selectedUnit } = useContext(UnitIdContext);

    // this useEffect is called everytime we switch tabs
    // because we want the data to be updated all the time
    useEffect(() => {
        let mounted = true;
        if (selectedUnit === "") return null;
        IdikaDoctorService.getUserUnitLimits(selectedUnit)
            .then(({ data }) => {
                if (mounted) {
                    setUserUnitLimits(data.UserLimits);
                }
            })
            .catch((error) => {
                // console.log(error);
            });
        return () => {
            mounted = false;
        };
    }, [selectedUnit]);

    return (
        <div className="section">
            <div className="boxTitle">
                <h6>Στοιχεία Ιατρού</h6>
            </div>
            <div className="boxContent">
                <div className="docInfo">
                    <div className="docInfoItem">
                        <p className="infoP">Ονοματεπώνυμο</p>
                        <p id="DocName">{firstname + " " + lastname}</p>
                    </div>
                    <div className="docInfoItem">
                        <p className="infoP">Ειδικότητα</p>
                        <p id="docSpecialty">{specialty}</p>
                    </div>
                    <div className="docInfoItem">
                        <p className="infoP">Α.Μ.Κ.Α.</p>
                        <p id="docAMKA">{amka}</p>
                    </div>
                    <div className="docInfoItem">
                        <p className="infoP">Α.Μ.Ε.Τ.Α.Α.</p>
                        <p id="docAMETAA">{etaaRegNo}</p>
                    </div>
                </div>
                <hr />
                <div className="docInfoPrice">
                    <div className="flex">
                        <p className="infoP">
                            Συνολική αξία συνταγογραφημένων σκευασμάτων με εμπορική ονομασία:
                        </p>
                        <p>
                            <span id="medPrice">{userUnitLimits.totalNoInnValue}</span> €
                        </p>
                    </div>
                    <div className="flex">
                        <p className="infoP">
                            Ποσοστό αξίας συνταγογραφημένων σκευασμάτων με εμπορική ονομασία επί του
                            συνόλου:
                        </p>
                        <p>
                            <span id="medPricePerc">{Math.round((userUnitLimits.totalPercentage * 100 + Number.EPSILON)*100)/100}</span> %
                        </p>
                    </div>
                </div>
                <p>
                    Το ποσοστό της συνολικής αξίας των συνταγογραφημένων σκευασμάτων με εμπορική
                    ονομασία δεν θα πρέπει να υπερβαίνει το 15% της συνολικής αξίας των
                    συνταγογραφημένων σκευασμάτων σε ετήσια βάση.
                </p>
                <p className="mt-2">
                    Όριο μηνιαίας φαρμ. δαπάνης ΕΟΠΥΥ ανά ασφ/νο: {userUnitLimits.eoppyCostLimit !== "" ? userUnitLimits.eoppyCostLimit : " " } €. Τρέχουσα μηνιαία φαρμ. δαπάνη
                    ανά ασφ/νο: {userUnitLimits.actualCost !== "" ? userUnitLimits.actualCost : " " } €
                </p>
            </div>
        </div>
    );
}

export default DoctorInfo;
