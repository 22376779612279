import React, { useState, useEffect } from "react";
import "./DoctorInformation.css";
import IdikaDoctorService from "../../../../services/MediPlus/Idika/IdikaDoctorService.js";
import { Spinner } from "../../../Utilities/Utilities.js";
import DoctorInfo from "./DoctorInfo/DoctorInfo.js";
import DoctorUnits from "./DoctorUnits/DoctorUnits.js";
import FavoriteExams from "./FavoriteExams/FavoriteExams.js";
import FavoriteExamPackage from "./FavoriteExamPackage/FavoriteExamPackage.js";
import FavoritePrescriptionPackage from "./FavoritePrescriptionPackage/FavoritePrescriptionPackage.js";

import Alert from "@material-ui/lab/Alert";

function DoctorInformation() {
    const [doctorData, setDoctorData] = useState({
        firstname: "",
        lastname: "",
        specialty: "",
        amka: "",
        etaaRegNo: "",
    });
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        IdikaDoctorService.getMe()
            .then((response) => {
                const data = response.data.User;
                setDoctorData(data);
                setLoading(false);
            })
            .catch((error) => {
                setError(error.response.data);
                setLoading(false);
            });
    }, []);

    if (error !== null) return <Alert severity="error"> Κάτι πήγε στραβά! </Alert>;
    else if (loading === true) return <Spinner align="center" animation="spinner-border" />;

    return (
        <div className="flexBoxWrap" styles={{ width: "90%" }}>
            <DoctorInfo doctorData={doctorData} />
            <DoctorUnits />
            <FavoriteExams />
            <FavoriteExamPackage />
            <FavoritePrescriptionPackage />
        </div>
    );
}

export default DoctorInformation;
