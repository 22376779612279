import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { prescriptionColumns } from "./config";

function Prescriptions({ prescriptions = [] }) {
    // console.log(prescriptions);
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        {prescriptionColumns.map((column, index) => {
                            return (
                                <TableCell key={index}>
                                    <b>{column.name}</b>
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {prescriptions.map((prescription) => {
                        return (
                            <TableRow key={prescription.id}>
                                <TableCell>{prescription.id}</TableCell>
                                <TableCell>{prescription.startDate}</TableCell>
                                <TableCell>{prescription.endDate}</TableCell>
                                <TableCell>{prescription.status}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
export default Prescriptions;
