import React from "react";

function Reminder({ setOpenPopUp, state, setState, setFetch }) {
    return (
        <div>
            Επιλέξατε να προσπελάσετε το ΠΛΗΡΕΣ ΙΣΤΟΡΙΚΟ του ασθενή. Για να συνεχίσετε ΠΡΕΠΕΙ
            ΑΠΑΡΑΙΤΗΤΑ ΝΑ ΕΧΕΤΕ ΤΗΝ ΣΥΓΚΑΤΑΘΕΣΗ ΤΟΥ ΑΣΘΕΝΗ και η ενέργειά σας αυτή ΘΑ ΚΑΤΑΓΡΑΦΕΙ ΑΠΟ
            ΤΟ ΣΥΣΤΗΜΑ. Επιθυμείτε να συνεχίσετε; (Εάν όχι, θα δείτε μόνο το ιστορικό που σας
            αφορά).
            <br />
            <br />
            <div align="center">
                <button
                    className="btn buttonPrimary"
                    onClick={() => {
                        setOpenPopUp(false);
                        setFetch(true);
                    }}
                >
                    Ναι, έχω τη συγκατάθεση του ασθενή και θέλω να προχωρήσω.
                </button>
                <br />
                <br />
                <button
                    className="btn buttonDanger"
                    onClick={() => {
                        setState({
                            ...state,
                            patientsConsent: !state.patientsConsent,
                        });
                        setOpenPopUp(false);
                        setFetch(true);
                    }}
                >
                    Όχι, δεν επιθυμώ να δω το πλήρες ιστορικό.
                </button>
            </div>
        </div>
    );
}

export default Reminder;
