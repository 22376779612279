import React, { useState } from "react";

import { mainColumns, referralFields } from "./config.js";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import Pagination from "@material-ui/lab/Pagination";
import IdikaReferralService from "../../../../../services/MediPlus/Idika/IdikaReferralService.js";
import IdikaPatientService from "../../../../../services/MediPlus/Idika/IdikaPatientService.js";

function Referrals({
    referrals = null,
    page = 0,
    handlePageChange = null,
    addTab,
    tabItem,
    setTabItem,
    setSelectedTab,
    setSelectedTabBttn,
    amkaOrEkaa,
    patientsConsent,
}) {
    const [error, setError] = useState(null);

    if (referrals === null) return null;

    function onIdClick(referralId) {
        IdikaReferralService.getReferral(referralId)
            .then(({ data }) => {
                if (tabItem.patientData === null) {
                    let tabId = addTab(
                        data.patientData,
                        {
                            id: -1,
                            startDate: "",
                            reason: "",
                            comment: "",
                            icpc2s: [],
                            active: false,
                            status: "",
                            prescriptions: [],
                            referrals: [],
                        },
                        8,
                        {},
                        data
                    );
                    setSelectedTab(tabId);
                } else {
                    setTabItem({ ...tabItem, subTab: 8, viewReferral: data });
                    setSelectedTabBttn(8);
                }
            })
            .catch(({ response }) => {
                if (response.data.status === 512) {
                    IdikaPatientService.getMedicineHistoryFullReferralFromAnotherDoctor(
                        amkaOrEkaa,
                        referralId,
                        { patientsConsent: [patientsConsent] }
                    )
                        .then(({ data }) => {
                            if (tabItem.patientData === null) {
                                let tabId = addTab(
                                    data.patientData,
                                    {
                                        id: -1,
                                        startDate: "",
                                        reason: "",
                                        comment: "",
                                        icpc2s: [],
                                        active: false,
                                        status: "",
                                        prescriptions: [],
                                        referrals: [],
                                    },
                                    8,
                                    {},
                                    data
                                );
                                setSelectedTab(tabId);
                            } else {
                                setTabItem({ ...tabItem, subTab: 8, viewReferral: data });
                                setSelectedTabBttn(8);
                            }
                        })
                        .catch((error) => {
                            setError(error);
                        });
                } else {
                    setError(response);
                }
            });
    }

    return (
        <div className="flexBoxWrap">
            <div className="flexBox">
                <div className="fullBox">
                    <div className="boxTitle">
                        <h6> Παραπεμπτικά </h6>
                    </div>
                    {error && <Alert severity="error"> Κάτι πήγε στραβά!</Alert>}
                    {referrals.referrals.length === 0 ? (
                        <Alert severity="info"> Δε βρέθηκαν παραπεμπτικά.</Alert>
                    ) : (
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {mainColumns.map((column, index) => {
                                            return (
                                                <TableCell key={index}>
                                                    <b>{column.name}</b>
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {referrals.referrals.map((referral, index) => {
                                        return (
                                            <TableRow key={index}>
                                                {referralFields.map((field, index2) => {
                                                    return (
                                                        <TableCell key={index2}>
                                                            {field.name !== "id" ? (
                                                                referral[field.name]
                                                            ) : (
                                                                <button
                                                                    onClick={() =>
                                                                        onIdClick(
                                                                            referral[field.name]
                                                                        )
                                                                    }
                                                                    className="btn buttonPrimary"
                                                                >
                                                                    {referral[field.name]}
                                                                </button>
                                                            )}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                            <br />
                            <Pagination
                                count={referrals.totalPages}
                                page={page}
                                onChange={handlePageChange}
                            />
                        </TableContainer>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Referrals;
