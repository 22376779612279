export function Spinner({ align, animation }) {
    return (
        <div align={align}>
            <div className={animation} role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    );
}

export function getCurrentDate() {
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    if (month < 10) month = "0" + month;
    if (day < 10) day = "0" + day;

    return `${year}-${month}-${day}`;
}

export function getCurrentDateAndTime() {
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();

    if (month < 10) month = "0" + month;
    if (day < 10) day = "0" + day;
    if (hours < 10) hours = "0" + hours;
    if (minutes < 10) minutes = "0" + minutes;
    if (seconds < 10) seconds = "0" + seconds;

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function getCurrentDateGreekFormat() {
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    if (month < 10) month = "0" + month;
    if (day < 10) day = "0" + day;

    return `${day}/${month}/${year}`;
}

export function formatMaterialUiDate(date) {
    if (date === null) return null;
    let year = date.getFullYear().toString();
    let month = (parseInt(date.getMonth()) + 1).toString();
    let day = date.getDate().toString();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    let finalDate = year + "-" + month + "-" + day;
    return finalDate;
}

export function formatMaterialUiDateTime(date) {
    if (date === null) return null;
    let year = date.getFullYear().toString();
    let month = (parseInt(date.getMonth()) + 1).toString();
    let day = date.getDate().toString();
    let hours = date.getHours().toString();
    let minutes = date.getMinutes().toString();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    if (hours.length < 2) hours = "0" + hours;
    if (minutes.length < 2) minutes = "0" + minutes;

    let finalDate = year + "-" + month + "-" + day + " " + hours + ":" + minutes;
    return finalDate;
}

export function formatMidnightDaytime(date) {
    if (date === null) return null;
    let year = date.getFullYear().toString();
    let month = (parseInt(date.getMonth()) + 1).toString();
    let day = date.getDate().toString();
    let hours="00";
    let minutes="00";
    let seconds ="00";

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    let finalDate = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
    return finalDate;
}

export function formatDate(date) {
    if (date === null) return null;
    const dateArray = date.split(" ")[0].split("-");
    return `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;
}

export function formatDateAndTime(date) {
    if (date === null || date === "") return null;
    const dateArray = date.split(" ")[0].split("-");
    const time = date.split(" ")[1];
    return `${dateArray[2]}/${dateArray[1]}/${dateArray[0]} ${time}`;
}

export function trimString(string, length) {
    if (string.length <= length) return string;
    return string.substring(0, length) + "...";
}

export function showButtonBasedOnDate(date) {
    if (date.split(" ")[0] === getCurrentDate()) return true;
    return false;
}
