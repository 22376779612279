const mainTabs = [{ label: "Συνταγές" }, { label: "Παραπεμπτικά" }];

const prescriptionColumns = [
    { name: "Αριθμός Συνταγής" },
    { name: "Ημ/νία Έκδοσης Συνταγής" },
    { name: "Ημ/νία Πρoθεσμίας Εκτέλεσης" },
    { name: "Κατάσταση Συνταγής" },
];

const referralColumns = [
    { name: "Αριθμός Παραπεμπτικού" },
    { name: "Ημ/νία Έκδοσης Παραπεμπτικού" },
    { name: "Ημ/νία Πρoθεσμίας Εκτέλεσης" },
    { name: "Κατάσταση Παραπεμπτικού" },
];

export { mainTabs, prescriptionColumns, referralColumns };
