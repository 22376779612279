import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { formatMaterialUiDate, getCurrentDate } from "../../../../Utilities/Utilities";
import ReferralsResults from "./ReferralsResults/ReferralsResults";
import IdikaReferralService from "../../../../../services/MediPlus/Idika/IdikaReferralService";
import { KeyboardDatePicker } from "@material-ui/pickers";
import SearchPatient from "../SearchPatient/SearchPatient";
import { Alert } from "@material-ui/lab";

function SearchReferrals({ addTab, tabItem, setTabItem, setSelectedTab, setSelectedTabBttn }) {
    const [state, setState] = useState({
        page: 1,
        size: 2,
        from: getCurrentDate(),
        to: getCurrentDate(),
        amkaOrEkaa: tabItem.patientData !== null ? tabItem.patientData.amka.toString() : "",
        barcode: "",
    });

    const handleOnChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setState({ ...state, [name]: value });
    };

    const [error, setError] = useState({});
    const onSubmit = (e) => {
        e.preventDefault();
        if (Number.isNaN(Date.parse(state.from)) || Number.isNaN(Date.parse(state.to))) {
            setError({ data: { dates: "Οι ημερομηνίες δεν είναι έγκυρες" }, status: 410 });
            return;
        }
        setError({});
        setState({ ...state, page: 1 });
        setFetch(true);
    };

    const [referrals, setReferrals] = useState(null);
    const [fetch, setFetch] = useState(false);
    useEffect(() => {
        if (fetch === false) return;
        let request = { ...state, page: state.page - 1 };
        if (state.amkaOrEkaa !== "") {
            if (state.amkaOrEkaa.length === 20) request.ekaa = state.amkaOrEkaa;
            else if (state.amkaOrEkaa.length === 11) request.amka = state.amkaOrEkaa;
            else {
                setFetch(false);
                return;
            }
        }
        IdikaReferralService.getReferrals(request)
            .then(({ data }) => {
                setReferrals(data);
            })
            .catch((err) => {
                setError(err.response);
            })
            .finally(() => {
                setFetch(false);
            });
        // eslint-disable-next-line
    }, [fetch]);

    return (
        <div className="tabContent">
            {error.status !== undefined ? (
                <Alert severity="error">
                    {error.status === 3002
                        ? `Δεν υπάρχει ασθενής με Α.Μ.Κ.Α./Ε.Κ.Α.Α.: ${
                              error.errors.error.split("Invalid patient's amka:")[1]
                          }`
                        : error.status === 439
                        ? `Δεν υπάρχει ασθενής με Α.Μ.Κ.Α./Ε.Κ.Α.Α.: ${
                              error.errors.error.split("There is no patient with amka:")[1]
                          }`
                        : error.status === 510
                        ? `Η Ημερομηνία 'Από' δεν πρέπει να είναι μεγαλύτερη από την Ημερομηνία 'Εώς'`
                        : error.status === 410
                        ? error.data.dates
                        : null}
                </Alert>
            ) : null}
            <form onSubmit={onSubmit}>
                <div className="flex">
                    <TextField
                        label="Αριθμός Παραπεμπτικού"
                        id="numberSearchReferral"
                        type="text"
                        name="barcode"
                        value={state.barcode}
                        onChange={handleOnChange}
                        className="searchInput"
                        style={{ width: "50%" }}
                    />
                </div>
                <div className="flex">
                    <SearchPatient
                        name="amkaOrEkaa"
                        value={state.amkaOrEkaa}
                        onChange={handleOnChange}
                        state={state}
                        setState={setState}
                        tabItem={tabItem}
                    />
                </div>
                <div className="flex">
                    <KeyboardDatePicker
                        format="dd/MM/yyyy"
                        label="Από"
                        value={state.from}
                        onChange={(date) => {
                            setState({
                                ...state,
                                from: formatMaterialUiDate(date),
                            });
                        }}
                        disableToolbar
                    />
                </div>
                <div className="flex">
                    <KeyboardDatePicker
                        format="dd/MM/yyyy"
                        label="Έως"
                        value={state.to}
                        onChange={(date) => {
                            setState({
                                ...state,
                                to: formatMaterialUiDate(date),
                            });
                        }}
                        disableToolbar
                    />
                </div>
                <br />
                <div className="flex">
                    <button className="btn buttonPrimary">Αναζήτηση</button>
                </div>
            </form>
            <br />
            <ReferralsResults
                referrals={referrals}
                state={state}
                setState={setState}
                setFetch={setFetch}
                addTab={addTab}
                tabItem={tabItem}
                setTabItem={setTabItem}
                setSelectedTab={setSelectedTab}
                setSelectedTabBttn={setSelectedTabBttn}
            />
        </div>
    );
}

export default SearchReferrals;
