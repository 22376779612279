import { useState } from "react";
import { makeStyles, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import MediPlusResetPasswordService from "../../services/MediPlus/MediPlusResetPasswordService";
import { getJwt } from "../Utilities/JwtUtilities";
import { Redirect } from "react-router";
import { locationMap } from "../../config";

const useStyles = makeStyles(() => ({
    root: {
        width: "80%",
        marginTop: "10px",
        marginBottom: "10px",
    },
}));

function ForgotPassword() {
    const classes = useStyles();
    const [email, setEmail] = useState("");
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const onSubmit = (e) => {
        e.preventDefault();
        setError(null);
        setLoading(true);
        setSuccess(false);
        MediPlusResetPasswordService.createTokenAndSendEmail(email)
            .then(({ data }) => {
                setSuccess(true);
            })
            .catch((err) => {
                // console.log(err);
                setError(err.response);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const token = getJwt();
    if (token !== null) return <Redirect to={locationMap[sessionStorage.getItem("role")]} />;

    return (
        <div className="loginForm bodyWrap">
            <div className="loginBody">
                <div className="flexBox">
                    <div className="fullBox">
                        <div className="authForm">
                            <h4> ΕΠΑΝΑΦΟΡΑ ΚΩΔΙΚΟΥ ΠΡΟΣΒΑΣΗΣ </h4>
                            <div align="center" className="mb-3">
                                <div className={classes.root}>
                                    {error !== null ? (
                                        <Alert severity="error">
                                            {error?.status === 400
                                                ? "Τα στοιχεία που δώσατε δεν είναι σωστά!"
                                                : "Κάτι πήγε στραβά!"}
                                        </Alert>
                                    ) : null}
                                    {success && (
                                        <Alert severity="success">Σας έχει σταλθεί email!</Alert>
                                    )}
                                </div>
                            </div>
                            <form onSubmit={onSubmit}>
                                <TextField
                                    className="formInputUI mb-5"
                                    label="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    error={error !== null ? true : false}
                                    type="email"
                                    required
                                />
                                <button className="btn buttonPrimary" disabled={loading}>
                                    Αποστολή Email
                                </button>
                                <br />
                                <br />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgotPassword;
