import axios from "axios";
import { baseUrl, getOptions, postOptions, putOptions, deleteOptions } from "../config.js";

class MediPlusCustomPrescriptionService {
    constructor(baseUrl) {
        this.basePath = "/customprescriptions";
        this.baseUrl = `${baseUrl}${this.basePath}`;
    }

    getCustomPrescriptions() {
        return axios(getOptions(`${this.baseUrl}`));
    }

    getUserDefaultCustomPrescription() {
        return axios(getOptions(`${this.baseUrl}`, "/default"));
    }

    addCustomPrescription(customPrescription) {
        return axios(postOptions(this.baseUrl, "", customPrescription));
    }

    updateCustomPrescription(id, customPrescription) {
        return axios(putOptions(this.baseUrl, `/${id}`, customPrescription));
    }

    deleteCustomPrescription(id) {
        return axios(deleteOptions(this.baseUrl, `/${id}`));
    }
}

export default new MediPlusCustomPrescriptionService(baseUrl);
