import React, { useState } from "react";

import { Spinner } from "../../../../../../../../Utilities/Utilities";
import Alert from "@material-ui/lab/Alert";
import MediPlusCommentService from "../../../../../../../../../services/MediPlus/MediPlusCommentService";

function DeleteComment({
    commentId,
    commentContent,
    patientId,
    comments,
    setComments,
    setFetch,
    page,
    setPage,
}) {
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const [successAlert, setSuccessAlert] = useState(false);

    const onClick = (e) => {
        e.preventDefault();

        setError({});
        setLoading(true);
        setSuccessAlert(false);
        MediPlusCommentService.deleteComment(commentId, patientId)
            .then(() => {
                let content = comments.content.filter((cmt) => cmt.id !== commentId);
                if (content.length === 0 && page !== 1) setPage(page - 1);
                setComments({ ...comments, content: content });
                setSuccessAlert(true);
                setFetch(true);
                setLoading(false);
            })
            .catch((error) => {
                setError(error.response.data);
                setLoading(false);
            });
    };

    return (
        <div>
            {Object.keys(error).length !== 0 && (
                <div>
                    <Alert severity="error">Κάτι πήγε στραβά!</Alert>
                    <br />
                </div>
            )}
            {successAlert === true && (
                <div>
                    <Alert severity="success">Η Διαγραφή έγινε με επιτυχία!</Alert>
                    <br />
                </div>
            )}
            {successAlert === false && (
                <div>
                    <div>
                        Είστε σίγουρος\η ότι θέλετε να διαγράψετε το σχόλιο `{commentContent}`;
                    </div>
                    <div align="center">
                        <br />
                        {loading === true ? (
                            <Spinner align="center" animation="spinner-border" />
                        ) : (
                            <div>
                                <button className="btn buttonDanger" onClick={onClick}>
                                    Διαγραφή
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default DeleteComment;
