import React, { useState, useEffect } from "react";

import TextField from "@material-ui/core/TextField";

import { Spinner } from "../../../../Utilities/Utilities.js";
import MediPlusUserService from "../../../../../services/MediPlus/MediPlusUserService.js";
import SearchItem from "./SearchItem/SearchItem.js";

function SearchPatient({ name, value, onChange, error, state, setState, setFetch, patientExists }) {
    const [loading, setLoading] = useState(false);
    const [searchResults, setSearchResults] = useState([]);

    useEffect(() => {
        if (value.length === 11 || value.length === 20) {
            setSearchResults([]);
        } else if (value.length >= 3) {
            setLoading(true);
            MediPlusUserService.getPatients(value).then((response) => {
                setSearchResults(response.data);
                setLoading(false);
            });
        }
        // eslint-disable-next-line
    }, [value]);

    return (
        <div style={{ width: "50%" }}>
            <TextField
                label="ΑΜΚΑ/EKAA Ασθενή"
                id="amkaPatientSearch"
                type="text"
                className="searchInput"
                name={name}
                value={value}
                onChange={onChange}
                error={
                    error.status !== undefined && (error.status === 3002 || error.status === 439)
                        ? true
                        : false
                }
                disabled={patientExists}
                required
            />
            <div>
                {value.length < 3 ? null : loading === true ? (
                    <Spinner align="center" animation="spinner-border" />
                ) : (
                    searchResults.map((patient, index) => {
                        return (
                            <SearchItem
                                key={index}
                                firstname={patient.firstName}
                                lastname={patient.lastName}
                                amkaOrEkaa={patient.amka !== null ? patient.amka : patient.ekaa}
                                address={patient.address}
                                telephone={patient.telephone}
                                state={state}
                                setState={setState}
                                setFetch={setFetch}
                            />
                        );
                    })
                )}
            </div>
        </div>
    );
}

export default SearchPatient;
