import React, { useState } from "react";
import { mainColumns } from "./config.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import IdikaPatientService from "../../../../../../../../services/MediPlus/Idika/IdikaPatientService.js";
import { Spinner } from "../../../../../../../Utilities/Utilities.js";

function EditBiometric({ amkaOrEkaa, biometric, biometrics, setBiometrics }) {
    const [bioValue, setBioValue] = useState(biometric.bioValue);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [successAlert, setSuccessAlert] = useState(false);

    const onSubmit = (e) => {
        e.preventDefault();

        let request = {
            Collection: {
                item: {
                    biometricsPool: { id: biometric.biometricsPool.id },
                    bioValue: bioValue,
                },
            },
        };

        setError(null);
        setSuccessAlert(false);
        setLoading(true);
        IdikaPatientService.saveOrAddBiovalues(request, amkaOrEkaa)
            .then(({ data }) => {
                const bioIndex = biometrics.biovalues.findIndex(
                    (bio) => bio.biometricsPool.id === biometric.biometricsPool.id
                );
                let newBiometrics = biometrics;
                newBiometrics.biovalues[bioIndex] = data.biovalues[0];
                setBiometrics(newBiometrics);
                setSuccessAlert(true);

                setLoading(false);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    };

    return (
        <form onSubmit={onSubmit}>
            {error && (
                <div>
                    <Alert severity="error">Κάτι πήγε στραβά!</Alert>
                    <br />
                </div>
            )}
            {successAlert === true && (
                <div>
                    <Alert severity="success">Η Ενημέρωση έγινε με επιτυχία!</Alert>
                    <br />
                </div>
            )}
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {mainColumns.map((column, index) => {
                                return (
                                    <TableCell key={index}>
                                        <b>{column.name}</b>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>{biometric.biometricsPool.description}</TableCell>
                            <TableCell>
                                <input
                                    type={
                                        biometric.biometricsPool.datatype === "NUM"
                                            ? "number"
                                            : "text"
                                    }
                                    className="form-control"
                                    value={bioValue}
                                    onChange={(e) => setBioValue(e.target.value)}
                                    required
                                />
                            </TableCell>
                            <TableCell>{biometric.biometricsPool.mesUnit}</TableCell>
                            <TableCell>{biometric.dateInserted}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            <div align="center">
                {loading === true ? (
                    <Spinner align="center" animation="spinner-border" />
                ) : (
                    <button className="btn buttonPrimary">Αποθήκευση</button>
                )}
            </div>
        </form>
    );
}

export default EditBiometric;
