const mainColumns = [
    { name: "Αριθμός Εξέτασης" },
    { name: "Ημ/νια  Έκδοσης" },
    { name: "Κατάσταση" },
    { name: "" },
];

const medicineColumns = [
    { name: "Προτείνεται η χορήγηση γενόσημου σκευάσματος" },
    { name: "Θεραπευτικό Πρωτόκολλο" },
    { name: "Εμπορική Ονομασία" },
    { name: "Δραστική Ουσία" },
    { name: "Μονάδα Δόσης" },
    { name: "Ποσό Δόσης" },
    { name: "Συχνότητα" },
    { name: "Ημέρες" },
    { name: "Συνταγογραφημένη Ποσότητα" },
    { name: "Εκτελεσμένη Ποσότητα" },
    { name: "Ποσό Συμμετοχής" },
];

const galenicColumns = [
    { name: "Ονομασία" },
    { name: "Φαρμ/κή Μορφή" },
    { name: "Μονάδα Μέτρησης" },
    { name: "Συνολική Ποσότητα" },
    { name: "Μονάδα Δόσης" },
    { name: "Ποσό Δόσης" },
    { name: "Συχνότητα" },
    { name: "Ημέρες" },
    { name: "% Συμμετοχή" },
    { name: "Τιμή" },
    { name: "Συμμετοχή Ασφ/νου" },
    { name: "Διαφορά Ασφ/νου" },
    { name: "Σχόλια" },
    { name: "" },
];

const diagnosisColumns = [{ name: "Κωδικός ICD-10" }, { name: "Διάγνωση" }];

export { mainColumns, medicineColumns, diagnosisColumns, galenicColumns };
