const mainColumns = [
    { name: "Αριθμός Παραπεμπτικού" },
    { name: "Κατηγορία Εξετάσεων" },
    { name: "Α.Μ.Κ.Α./E.K.A.A." },
    { name: "Επώνυμο" },
    { name: "Όνομα" },
    { name: "Φορέας Κοινωνικής Ασφάλισης" },
    { name: "Ημ/νία Έκδοσης Παραπεμπτικού" },
    { name: "Κατάσταση Παραπεμπτικού" },
    { name: "" },
];

export { mainColumns };
