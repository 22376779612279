import React, { useState, useContext } from "react";
import { mainColumns } from "./config.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";
import Alert from "@material-ui/lab/Alert";
import { TabContext } from "../../../../Doctor.js";
import IdikaVisitService from "../../../../../../services/MediPlus/Idika/IdikaVisitService.js";
import PopUp from "../../../../../Utilities/PopUp.js";
import CancelVisitPopUp from "./CancelVisitPopUp/CancelVisitPopUp.js";
import { formatDateAndTime, showButtonBasedOnDate } from "../../../../../Utilities/Utilities.js";

function VisitResults({ visits = null, setVisits, state, setState, setFetch }) {
    const { setSelectedTab, addTab } = useContext(TabContext);
    const [error, setError] = useState(null);
    const [cancelPopUp, setCancelPopUp] = useState(false);
    const [cancelVisitId, setCancelVisitId] = useState(-1);
    const onCancel = (visitId) => {
        setCancelVisitId(visitId);
        setCancelPopUp(true);
    };

    if (visits === null) return null;

    const handlePageChange = (e, value) => {
        e.preventDefault();
        setState({ ...state, page: value });
        setFetch(true);
    };

    const onVisitFetch = (visitId) => {
        setError(null);
        IdikaVisitService.getVisit(visitId)
            .then(({ data }) => {
                let tabId = addTab(data.patientData, data.visitDetails);
                setSelectedTab(tabId);
            })
            .catch((error) => {
                setError(error);
            });
    };

    return (
        <div>
            {visits.count === 0 ? (
                <Alert severity="info">Δεν υπάρχουν επισκέψεις!</Alert>
            ) : (
                <div>
                    {error && (
                        <div>
                            <Alert severity="error">Κάτι πήγε στραβά!</Alert>
                            <br />
                        </div>
                    )}
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {mainColumns.map((column, index) => {
                                        return (
                                            <TableCell key={index}>
                                                <b>{column.name}</b>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {visits.visits.map((visit) => {
                                    return (
                                        <TableRow key={visit.id}>
                                            <TableCell>
                                                <button
                                                    className="btn buttonPrimary"
                                                    onClick={() => onVisitFetch(visit.id)}
                                                >
                                                    {visit.id}
                                                </button>
                                            </TableCell>
                                            <TableCell>
                                                {(visit.status.status === "Συνταγογραφημένη" ||
                                                    visit.status.status === "Ολοκληρωμένη") &&
                                                    showButtonBasedOnDate(visit.startDate) && (
                                                        <button
                                                            className="btn buttonPrimary"
                                                            onClick={() => onCancel(visit.id)}
                                                        >
                                                            Ακύρωση
                                                        </button>
                                                    )}
                                            </TableCell>
                                            <TableCell>{visit.patientInfo.amka}</TableCell>
                                            <TableCell>{visit.patientInfo.lastName}</TableCell>
                                            <TableCell>{visit.patientInfo.firstName}</TableCell>
                                            <TableCell>
                                                {visit.patientInfo.socialInsuranceShortName}
                                            </TableCell>
                                            <TableCell>
                                                {formatDateAndTime(visit.startDate)}
                                            </TableCell>
                                            <TableCell>
                                                {formatDateAndTime(visit.endDate)}
                                            </TableCell>
                                            <TableCell>{visit.status.status}</TableCell>
                                            <TableCell>
                                                {/* {visit.inQuota === false
                                                    ? "Δεν είναι Χρεωμένη στο Όριο"
                                                    : "Είναι χρεωμένη στο Όριο"} */}
                                            </TableCell>
                                            <TableCell>
                                                {/* <button className="btn buttonPrimary">
                                                    Χρέωση στο όριο επισκέψεων
                                                </button> */}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        <br />
                        <Pagination
                            count={visits.totalPages}
                            page={state.page}
                            onChange={handlePageChange}
                        />
                    </TableContainer>
                </div>
            )}
            <PopUp
                openPopUp={cancelPopUp}
                setOpenPopUp={setCancelPopUp}
                title={"Ακύρωση Επίσκεψης"}
                maxWidth="md"
            >
                <CancelVisitPopUp
                    cancelVisitId={cancelVisitId}
                    visits={visits}
                    setVisits={setVisits}
                    setCancelPopUp={setCancelPopUp}
                />
            </PopUp>
        </div>
    );
}

export default VisitResults;
