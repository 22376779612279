import { useQuery } from "react-query";
import { CircularProgress, Container, Divider, Grid } from "@material-ui/core";
import MediPlusAppContactDetailsService from "../../../../services/MediPlus/MediPlusAppContactDetailsService";
import { Alert } from "@material-ui/lab";

function Contact() {
    const { isLoading, isError, data } = useQuery("contact", () =>
        MediPlusAppContactDetailsService.getAllAppContactDetails().then(({ data }) => data)
    );

    return (
        <div>
            {isLoading ? (
                <CircularProgress />
            ) : isError ? (
                <Alert severity="error">Κάτι πήγε στραβά!</Alert>
            ) : (
                <Container maxWidth="sm">
                    {data.map((contact) => {
                        return (
                            <div key={contact.id}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <h5>{contact.name}</h5>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <h6>{contact.value}</h6>
                                    </Grid>
                                </Grid>
                                <Divider className="mb-2" />
                            </div>
                        );
                    })}
                </Container>
            )}
        </div>
    );
}

export default Contact;
