import React from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";

export default function PopUp({
    title,
    children,
    openPopUp,
    setOpenPopUp,
    showCloseButton = true,
    setModalResult = null,
    maxWidth = "md",
    fullWidth = true,
}) {
    return (
        <Dialog open={openPopUp} maxWidth={maxWidth} fullWidth={fullWidth}>
            <DialogTitle>
                {title}
                {showCloseButton === true ? (
                    <button
                        type="button"
                        className="close"
                        aria-label="Close"
                        onClick={() => {
                            setOpenPopUp(false);
                            if (setModalResult !== null) setModalResult("failure");
                        }}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                ) : null}
            </DialogTitle>
            <DialogContent> {children} </DialogContent>
        </Dialog>
    );
}
