import React from "react";

function DoctorInfo({ doctorData }) {
    return (
        <div className="flexBox">
            <div className="fullBox">
            <div className="boxTitle"> <h6>Γενικά στοιχεία ιατρού</h6></div>
            <div className="boxContent">
               
                <div className="flexBoxInfoWrap">
                    <div className="flexColumn">
                        <div className="flexWrap">
                            <p className="infoP alignRight">Επώνυμο</p>
                            <p className="noMargin" id="doctorLastname">
                                {doctorData.name.lastname}
                            </p>
                        </div>
                        <div className="flexWrap">
                            <p className="infoP alignRight">Όνομα</p>
                            <p className="noMargin" id="doctorFirstname">
                                {doctorData.name.firstname}
                            </p>
                        </div>
                        <div className="flexWrap">
                            <p className="infoP alignRight">Πατρώνυμο</p>
                            <p className="noMargin" id="doctorFathername">
                                {doctorData.name.fathername}
                            </p>
                        </div>
                        <div className="flexWrap">
                            <p className="infoP alignRight">Μητρώνυμο</p>
                            <p className="noMargin" id="doctorMothername">
                                {doctorData.name.mothername}
                            </p>
                        </div>
                    </div>
                    <div className="flexColumn">
                        <div className="flexWrap">
                            <p className="infoP alignRight">Α.Μ.Κ.Α.</p>
                            <p className="noMargin" id="doctorAmka">
                                {doctorData.amka}
                            </p>
                        </div>
                        <div className="flexWrap">
                            <p className="infoP alignRight">Α.Φ.Μ.</p>
                            <p className="noMargin" id="doctorAfm">
                                {doctorData.taxRegistryNo}
                            </p>
                        </div>
                        <div className="flexWrap">
                            <p className="infoP alignRight">Α.Μ.Ε.Τ.Α.Α.</p>
                            <p className="noMargin" id="doctorAmetaa">
                                {doctorData.etaaRegNo}
                            </p>
                        </div>
                        <div className="flexWrap">
                            <p className="infoP alignRight">Αριθμός Αδείας</p>
                            <p className="noMargin" id="doctorLicenseNumber">
                                {doctorData.licenceNo}
                            </p>
                        </div>
                    </div>
                    <div className="flexColumn">
                        <div className="flexWrap">
                            <p className="infoP alignRight">Κινητό</p>
                            <p className="noMargin" id="doctorMobile">
                                {doctorData.mobile}
                            </p>
                        </div>
                        <div className="flexWrap">
                            <p className="infoP alignRight">Φύλο</p>
                            <p className="noMargin" id="doctorSex">
                                {doctorData.sex.name}
                            </p>
                        </div>
                        <div className="flexWrap">
                            <p className="infoP alignRight">Ημ/νία Γέννησης</p>
                            <p className="noMargin" id="doctorBirthDate">
                                {doctorData.birthDate}
                            </p>
                        </div>
                        <div className="flexWrap">
                            <p className="infoP alignRight">Ειδικότητα</p>
                            <p className="noMargin" id="doctorSpecialty">
                                {doctorData.doctorSpecialty.name}
                            </p>
                        </div>
                    </div>
                    <div className="flexColumn">
                        <div className="flexWrap">
                            <p className="infoP alignRight">Ιατρικός Σύλλογος</p>
                            <p className="noMargin" id="doctorMedicalAssociation">
                                {doctorData.doctorUnion.name}
                            </p>
                        </div>
                        <div className="flexWrap">
                            <p className="infoP alignRight">Α.Μ. στον Ιατρικό Σύλλογο</p>
                            <p className="noMargin" id="doctorAM">
                                {doctorData.doctorUnionRegNo}
                            </p>
                        </div>
                        <div className="flexWrap">
                            <p className="infoP alignRight">Ημ/νία εγγραφής στο Μητρώο Ε.Τ.Α.Α.</p>
                            <p className="noMargin" id="doctorRegisterEtaaDate">
                                {/* Ημ/νία εγγραφής στο Μητρώο Ε.Τ.Α.Α. */}
                            </p>
                        </div>
                        <div className="flexWrap">
                            <p className="infoP alignRight">Αριθμός ADSL σύνδεσης</p>
                            <p className="noMargin" id="doctorAdslNumber">
                                {/* Αριθμός ADSL σύνδεσης */}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    );
}

export default DoctorInfo;
