import { useEffect, useState } from "react";
import { columns } from "./config.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import MediPlusMembershipHistoryService from "../../../../services/MediPlus/MediPlusMembershipHistoryService.js";
import { formatDateAndTime } from "../../../Utilities/Utilities.js";

function MembershipHistory() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        let mounted = true;
        MediPlusMembershipHistoryService.getUserMembershipHistories()
            .then(({ data }) => {
                if (mounted) {
                    setData(data);
                }
            })
            .catch((err) => {
                // console.log(err.response);
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
        return () => (mounted = false);
    }, []);

    return (
        <div className="section mb-5">
            <div className="boxTitle">
                <h6>Ιστορικό Συνδρομών</h6>
            </div>
            <div className="boxContent">
                {loading ? (
                    <CircularProgress />
                ) : error ? (
                    <Alert severity="error">Κάτι πήγε στραβά!</Alert>
                ) : (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {columns.map((column, index) => {
                                        return (
                                            <TableCell key={index}>
                                                <b>{column.name}</b>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((membershipHistory) => {
                                    return (
                                        <TableRow key={membershipHistory.id}>
                                            <TableCell>
                                                {formatDateAndTime(
                                                    membershipHistory.prevMembershipEndDate
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {formatDateAndTime(
                                                    membershipHistory.newMembershipEndDate
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </div>
        </div>
    );
}

export default MembershipHistory;
