import axios from "axios";
import { baseUrl } from "../config.js";

class MediPlusAuthService {
    constructor(baseUrl) {
        this.basePath = "/auth";
        this.baseUrl = `${baseUrl}${this.basePath}`;
    }

    signIn(credentials) {
        return axios.post(`${this.baseUrl}/signIn`, credentials);
    }

    signUp(user) {
        return axios.post(`${this.baseUrl}/signUp`, user);
    }
}

export default new MediPlusAuthService(baseUrl);
