import React, { useState } from "react";

import { Tabs, Tab } from "@material-ui/core";

import { mainTabs } from "./config.js";
import PatientInfo from "./PatientInfo/PatientInfo.js";
import PatientBiometrics from "./PatientBiometrics/PatientBiometrics.js";
import PatientIntolerances from "./PatientIntolerances/PatientIntolerances.js";
import PatientProtocolParams from "./PatientProtocolParams/PatientProtocolParams.js";

function PatientInformation({
    patientData,
    patientBiometrics,
    patientIntolerances,
    patientProtocolParams,
}) {
    let shownTabs = [];
    if (patientData !== null)
        shownTabs.push({
            tabDetails: mainTabs[0],
            component: <PatientInfo patientData={patientData} />,
        });
    if (patientBiometrics !== null)
        shownTabs.push({
            tabDetails: mainTabs[1],
            component: <PatientBiometrics biometrics={patientBiometrics} />,
        });
    if (patientProtocolParams !== null)
        shownTabs.push({
            tabDetails: mainTabs[2],
            component: <PatientProtocolParams protocolParams={patientProtocolParams} />,
        });
    if (patientIntolerances !== null)
        shownTabs.push({
            tabDetails: mainTabs[3],
            component: <PatientIntolerances intolerances={patientIntolerances} />,
        });

    const [selectedTabBttn, setSelectedTabBttn] = useState(0);
    const handleTabsBttns = (e, val) => {
        setSelectedTabBttn(val);
    };

    const showContent = () => {
        return shownTabs[selectedTabBttn].component;
    };

    return (
        <div className="flexBoxWrap">
            <div className="flexBox">
                <div className="fullBox">
                    <div className="boxTitle">
                        <h6> Πληροφορίες Ασθενή </h6>
                    </div>
                    <Tabs
                        value={selectedTabBttn}
                        onChange={handleTabsBttns}
                        indicatorColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        className="tabsBttns"
                    >
                        {shownTabs.map((tab) => {
                            return <Tab {...tab.tabDetails} />;
                        })}
                    </Tabs>
                    {showContent()}
                </div>
            </div>
        </div>
    );
}

export default PatientInformation;
