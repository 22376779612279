import React, { useState, useEffect, useContext } from "react";
import IdikaDoctorService from "../../../../../services/MediPlus/Idika/IdikaDoctorService.js";
import IdikaOthersService from "../../../../../services/MediPlus/Idika/IdikaOthersService.js";
import { Spinner } from "../../../../Utilities/Utilities.js";
import Alert from "@material-ui/lab/Alert";
import { UnitIdContext } from "../../../Doctor.js";

function Unit({ setUnitPopUp, setAnnouncementsPopUp, announcements,  setAnnouncements }) {
    const [units, setUnits] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const { selectedUnit, setSelectedUnit } = useContext(UnitIdContext);

    useEffect(() => {
        IdikaDoctorService.getUnits()
            .then((response) => {
                const data = response.data.List.contents.item;
                setUnits(data);
                setSelectedUnit(data[0].id);
                setLoading(false);
            })
            .catch((error) => {
                setError(error.response.data);
                setLoading(false);
            });
        // eslint-disable-next-line
    }, []);

    //get announcements
    useEffect(() => {
        IdikaOthersService.getAllAnnouncements()
            .then((response) => {
                setAnnouncements(response.data);          
            })
            .catch((error) => {
                setError(error.response.data);
            });
    }, [setAnnouncements]);

    if (error !== null) return <Alert severity="error"> Κάτι πήγε στραβά! </Alert>;
    else if (loading === true) return <Spinner align="center" animation="spinner-border" />;

    const onSubmit = (e) => {
        e.preventDefault();
        sessionStorage.setItem("unitId", selectedUnit.toString());
        let found = units.find((unit) => unit.id === selectedUnit);
        if (found !== undefined) sessionStorage.setItem("unit", JSON.stringify(found));
        setUnitPopUp(false);
        //if there are any announcemenets open pop up
        if(announcements.announcements.length !== 0){
            setAnnouncementsPopUp(true);
        }  
    };

    return (
        <div>
            Παρακαλώ επιλέξτε από την παρακάτω λίστα τη Μονάδα από την οποία θα συνταγογραφήσετε και
            πατήστε Επιλογή: <br /> <br />
            <form onSubmit={onSubmit}>
                <select
                    className="form-control"
                    value={selectedUnit}
                    onChange={(e) => setSelectedUnit(e.target.value)}
                >
                    {units.map((unit, index) => {
                        return (
                            <option
                                key={index}
                                value={unit.id}
                            >{`${unit.healthCareUnit.description} ${unit.description}`}</option>
                        );
                    })}
                </select>
                <br />
                <div align="center">
                    <button type="submit" className="btn buttonPrimary">
                        Επιλογή
                    </button>
                </div>
            </form>
        </div>
    );
}

export default Unit;
