import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

function CustomMenu({ name, menuItems }) {
    const [anchorEl, setAnchorEl] = useState(null);

    function handleClick(event) {
        if (anchorEl !== event.currentTarget) {
            setAnchorEl(event.currentTarget);
        }
    }

    function handleClose() {
        setAnchorEl(null);
    }

    return (
        <div>
            <Button
                aria-owns={anchorEl ? "simple-menu" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                onMouseOver={handleClick}
                variant="contained"
                color="primary"
            >
                {name}
            </Button>
            <Menu
                // elevation={0}
                // getContentAnchorEl={null}
                // anchorOrigin={{
                //     vertical: "bottom",
                //     horizontal: "center",
                // }}
                // transformOrigin={{
                //     vertical: "top",
                //     horizontal: "center",
                // }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{ onMouseLeave: handleClose }}
            >
                {menuItems.map((menuItem, index) => {
                    return (
                        <MenuItem
                            key={index}
                            onClick={menuItem.onClick !== null ? menuItem.onClick : handleClose}
                        >
                            {menuItem.name}
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
}

export default CustomMenu;
