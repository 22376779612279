const mainColumns = [
    { name: "Αριθμός Παραπεμπτικού" },
    { name: "Ημ/νια Έκδοσης Παραπεμπτικού" },
    { name: "Ημ/νια Προθεσμίας Εκτέλεσης" },
    { name: "Κατάσταση Παραπεμπτικού" },
    { name: "Κωδικός Επίσκεψης" },
    { name: "" },
];

const referralFields = [
    // { name: "id" },
    { name: "startDate" },
    { name: "endDate" },
    { name: "status" },
    { name: "visitId" },
];

export { mainColumns, referralFields };
