import { useEffect, useState } from "react";
import { Grid, TextField } from "@material-ui/core";
//import QrReader from "react-qr-scanner";
import QrReader from "react-camera-qr";
import MediPlusPharmaceuticalAgentService from "../../services/MediPlus/MediPlusPharmaceuticalAgentService";
import MUIDataTable from "mui-datatables";
import { medicineTotalsTblColumns } from "../Doctor/Statistics/config";
import { materialUiDataTableOptions } from "../../config";
import { Alert } from "@material-ui/lab";
import "./PharmaceuticalAgent.css";
import {Link} from "react-router-dom";

function SearchStatisticsPrint({ id }) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [barcode, setBarcode] = useState();
    const [barcodeToShow, setBarcodeToShow] = useState();
    const [state, setState] = useState({ delay: 500, result: "No result" });
    const [scanPopUp, setScanPopUp] = useState(false);
    const [statistics, setStatistics] = useState({});
    const [doctorName, setDoctorName] = useState();
    const [searchParams, setSearchParams] = useState();
    const [filter, setFilter] = useState({});
    const [nothingToShow,setNothingToShow] = useState(false);

    //for agent's mobile view

    const [columnsToUse, setColumnsToUse] = useState([]);

    useEffect(() => {
        setLoading(true);

        if (id !== undefined) {
            setColumnsToUse([]);
            MediPlusPharmaceuticalAgentService.getSubmittedPrintoutByQrCode(id)
                .then(({ data }) => {
                    if (data.data.length !== 0) {
                        setNothingToShow(false);
                        const columns = Object.keys(data.data[0]);
                        for (let column of medicineTotalsTblColumns) {
                            for (let filterCol of columns) {
                                if (column.name === filterCol) {
                                    setColumnsToUse((columnsToUse) => [...columnsToUse, column]);
                                }
                            }
                        }
                        setDoctorName(data.doctorName);
                        setStatistics(data.data);
                        setSearchParams(data.searchParams);
                        let filters = "";
                        let filterArray = data.searchParams.filters;
                        for (let i = 0; i < filterArray.length; i++) {
                            if (filterArray[i][0] !== undefined) {
                                filters +=
                                    medicineTotalsTblColumns[i].label +
                                    ": " +
                                    filterArray[i][0] +
                                    " ";
                            }
                        }
                        setFilter(filters);
                    }else{
                        clearState();
                        setNothingToShow(true);

                    }
                })
                .catch((err) => {
                    //console.log(err);
                    setError(err.response);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, [id]);

    const clearState = () =>{
        setDoctorName();
        setSearchParams("");
        setFilter("");
    };

    const handleScan = (data) => {
        if (data !== null) {
            if (data.includes("https://mediplus.itml.gr/PharmaceuticalAgent/")) {
                setState({ ...state, result: data });
                setBarcode(data);

                const index = data.lastIndexOf("/");
                setBarcodeToShow(data.slice(index + 1));

                setScanPopUp(false);
            }
        }
    };

    const handleError = (err) => {
        console.log(err);
    };

    const handleChange = (e) => {
        e.preventDefault();
        setBarcode(e.target.value);
        const index = e.target.value.lastIndexOf("/");
        setBarcodeToShow(e.target.value.slice(index + 1));
    };

    const barcodeSearchHandler = (e) => {
        e.preventDefault();
        setBarcodeToShow("");
        setScanPopUp(true);
    };

    const closeScaneer = (e) => {
        e.preventDefault();
        setBarcodeToShow("");
        setScanPopUp(false);
    };

    const previewStyle = {
        height: 240,
        width: 320,
    };

    return (
        <div id="searchStatistics">
            {error.status !== undefined ? (
                <Alert severity="error">{error.status === 410 ? error.data.dates : null}</Alert>
            ) : null}
            <form>
                <Grid container direction="column" alignItems="center" spacing={2}>
                    <h3 style={{ marginBottom: "15px" }}>Αναζήτηση Εκτύπωσης</h3>
                    {scanPopUp && (
                        <Grid item xs={12} style={{ marginBottom: "50px" }}>
                            <QrReader
                                delay={state.delay}
                                style={previewStyle}
                                onError={handleError}
                                onScan={handleScan}
                            />
                        </Grid>
                    )}
                    <Grid item xs={6}>
                        {!scanPopUp && (
                            <TextField
                                className="formInputUIFull"
                                variant="outlined"
                                label={barcode === "" ? "Εισάγετε Barcode" : ""}
                                name="barcode"
                                value={barcodeToShow}
                                onChange={handleChange}
                                required
                            />
                        )}
                    </Grid>

                    <Grid item xs={6}>
                        <Link to={barcodeToShow === undefined ?`/PharmaceuticalAgent` : `/PharmaceuticalAgent/${barcodeToShow}`}>
                            <button className="btn buttonPrimary mt-3">
                                Αναζήτηση Εκτύπωσης
                            </button>
                        </Link>
                    </Grid>

                    <Grid item xs={6}>
                        <button
                            className="btn buttonPrimary mt-3"
                            //disabled={loading}
                            onClick={!scanPopUp ? barcodeSearchHandler : closeScaneer}
                        >
                            {!scanPopUp ? "Αναζήτηση με Barcode" : "Κλείσιμο Scanner"}
                        </button>
                    </Grid>

                    
                </Grid>
            </form>

            <br />

            {nothingToShow && (
                <Alert severity="info">Δεν υπάρχουν φάρμακα που να αντιστοιχούν στην εταιρεία σας</Alert>
            ) }

            {doctorName !== undefined && loading === false && (
                <MUIDataTable
                    className="mb-5"
                        title={
                            "Σύνολα Φαρμάκων " +
                            " " +
                            doctorName +
                            " " +
                            "Ημ/νία Εκτύπωσης: " +
                            searchParams.pdfExportTimestamp +
                            " " +
                            searchParams.dateType +
                            " " +
                            " Απο: " +
                            searchParams.from +
                            " " +
                            " Μέχρι: " +
                            searchParams.to +
                            " " +
                            " " +
                            filter
                        }
                    
                    data={statistics}
                    columns={columnsToUse}
                    options={materialUiDataTableOptions}
                    style={{ width: "300px" }}
                />
            )}
        </div>
    );
}

export default SearchStatisticsPrint;
