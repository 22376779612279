import React, { useState } from "react";
import { mainColumns } from "./config.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import FavoriteExams from "./FavoriteExams/FavoriteExams.js";
import PopUp from "../../../../Utilities/PopUp.js";
import MedicalExams from "./MedicalExams/MedicalExams.js";
import IdikaMasterDataService from "../../../../../services/MediPlus/Idika/IdikaMasterDataService.js";
import { useQuery } from "react-query";
import Alert from "@material-ui/lab/Alert";
import { Spinner } from "../../../../Utilities/Utilities.js";
import MediPlusFavoriteExamPackageService from "../../../../../services/MediPlus/MediPlusFavoriteExamPackageService.js";
import DeleteIcon from "@material-ui/icons/Delete";

function Examinations({ state, setState, isValidSubmission }) {
    const [favoriteExamsPopUp, setFavoriteExamsPopUp] = useState(false);
    const [medicalExamsPopUp, setMedicalExamsPopUp] = useState(false);

    const addOrRemoveReferral = (referral, action, index = 0) => {
        if (action === "remove") {
            let newReferralsArray = state.referrals.filter(
                (selectedReferral, selectedReferralIndex) => {
                    //if -> method was called from Examinations ,else -> method was called from MedicalExams
                    if(referral.id === undefined){
                        return selectedReferralIndex !== index;
                    }else{
                        return selectedReferral.code !== String(referral.id) ;
                    }
                }
            );
            setState({ ...state, referrals: newReferralsArray });
        } else {
            let arrayObject = {
                code:
                    referral.examination !== undefined
                        ? referral.examination.id.toString()
                        : referral.id.toString(),
                displayName: referral.description,
                relationType: "",
                diagnosisCode: "",
                // not used
                comment: "",
                // debugging field
                // ...referral,
            };
            let newReferralsArray = [...state.referrals, arrayObject];
            setState({ ...state, referrals: newReferralsArray });
        }
    };

    const { isLoading, isError, data } = useQuery("relationTypes", () =>
        IdikaMasterDataService.getRelationTypes().then(({ data }) => data)
    );

    const handleCommentChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let newReferralObject = state.referrals[name];
        newReferralObject.comment = value;
        let newReferralsArray = [...state.referrals];
        newReferralsArray[name] = newReferralObject;
        setState({ ...state, referrals: newReferralsArray });
    };

    const handleRelationTypeChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let newReferralObject = state.referrals[name];
        newReferralObject.relationType = value;
        let newReferralsArray = [...state.referrals];
        newReferralsArray[name] = newReferralObject;
        setState({ ...state, referrals: newReferralsArray });
    };

    const handleDiagnosisChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let newReferralObject = state.referrals[name];
        newReferralObject.diagnosisCode = value;
        let newReferralsArray = [...state.referrals];
        newReferralsArray[name] = newReferralObject;
        setState({ ...state, referrals: newReferralsArray });
    };

    const [addFavoriteExamPackagePopUp, setAddFavoriteExamPackagePopUp] = useState(false);

    return (
        <div className="section">
            <div className="boxTitle">
                <h6>Εξετάσεις</h6>
            </div>
            {isLoading ? (
                <Spinner align="center" animation="spinner-border" />
            ) : isError ? (
                <Alert severity="error">Κάτι πήγε στραβά!</Alert>
            ) : (
                <div className="boxContent">
                    <div className="flexBox">
                        <button
                            className="btn buttonPrimary"
                            onClick={() => setMedicalExamsPopUp(true)}
                            disabled={
                                state.examinationGroupId === "" || state.reason === ""
                                    ? true
                                    : false
                            }
                        >
                            Ιατρικές Εξετάσεις
                        </button>
                        <button
                            className="btn buttonPrimary"
                            onClick={() => setFavoriteExamsPopUp(true)}
                            disabled={
                                state.examinationGroupId === "" || state.reason === ""
                                    ? true
                                    : false
                            }
                        >
                            Αγαπημένες Εξετάσεις
                        </button>
                        <button
                            className="btn buttonPrimary"
                            onClick={() => setState({ ...state, referrals: [] })}
                        >
                            Καθαρισμός Εξετάσεων
                        </button>
                        <button
                            className="btn buttonPrimary"
                            onClick={() => setAddFavoriteExamPackagePopUp(true)}
                            disabled={isValidSubmission() !== null ? true : false}
                        >
                            Προσθήκη στα Αγαπημένα Πακέτα Εξετάσεων
                        </button>
                    </div>
                    <br />
                    <div className="flexBox">
                        {state.referrals.length === 0 ? null : (
                            <TableContainer component={Paper}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            {mainColumns.map((column, index) => {
                                                return (
                                                    <TableCell key={index}>
                                                        <b>{column.name}</b>
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {state.referrals.map((referral, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell>{referral.displayName}</TableCell>
                                                    <TableCell>
                                                        <select
                                                            name={index}
                                                            onChange={handleDiagnosisChange}
                                                            value={
                                                                state.referrals[index].diagnosisCode
                                                            }
                                                        >
                                                            <option value=""></option>
                                                            {state.diagnoses.map((diagnosis) => {
                                                                return (
                                                                    <option
                                                                        key={diagnosis.code}
                                                                        value={diagnosis.code}
                                                                    >
                                                                        {diagnosis.code}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </TableCell>
                                                    <TableCell>
                                                        <select
                                                            name={index}
                                                            onChange={handleRelationTypeChange}
                                                            value={
                                                                state.referrals[index].relationType
                                                            }
                                                        >
                                                            <option value=""></option>
                                                            {data.relationTypes.map(
                                                                (relationType) => {
                                                                    return (
                                                                        <option
                                                                            key={relationType.id}
                                                                            value={relationType.id}
                                                                        >
                                                                            {relationType.type}
                                                                        </option>
                                                                    );
                                                                }
                                                            )}
                                                        </select>
                                                    </TableCell>
                                                    <TableCell>
                                                        <input
                                                            type="text"
                                                            name={index}
                                                            onChange={handleCommentChange}
                                                            value={state.referrals[index].comment}
                                                            style={{ width: "100%" }}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <DeleteIcon
                                                            onClick={() =>
                                                                addOrRemoveReferral(
                                                                    referral,
                                                                    "remove",
                                                                    index
                                                                )
                                                            }
                                                            color="secondary"
                                                            style={{ cursor: "pointer" }}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </div>
                </div>
            )}
            <PopUp
                openPopUp={medicalExamsPopUp}
                setOpenPopUp={setMedicalExamsPopUp}
                showCloseButton={true}
                title={"Αναζήτηση Εξέτασεων"}
            >
                <MedicalExams
                    examinationGroupId={state.examinationGroupId}
                    addOrRemoveReferral={addOrRemoveReferral}
                    state={state}
                    setState={setState}
                />
            </PopUp>
            <PopUp
                openPopUp={favoriteExamsPopUp}
                setOpenPopUp={setFavoriteExamsPopUp}
                showCloseButton={true}
                title={"Αναζήτηση Εξέτασεων"}
            >
                <FavoriteExams
                    examinationGroupId={state.examinationGroupId}
                    addOrRemoveReferral={addOrRemoveReferral}
                />
            </PopUp>
            <PopUp
                openPopUp={addFavoriteExamPackagePopUp}
                setOpenPopUp={setAddFavoriteExamPackagePopUp}
                showCloseButton={true}
                title={"Προσθήκη Αγαπημένου Πακέτου Εξετάσεων"}
            >
                <NewFavoriteExamPackage state={state} />
            </PopUp>
        </div>
    );
}

function NewFavoriteExamPackage({ state }) {
    const [successAlert, setSuccessAlert] = useState(false);
    const [error, setError] = useState(null);
    const [existsError,setExistsError] = useState(false);
    const [name, setName] = useState("");

    const onSubmit = (e) => {
        e.preventDefault();
        setSuccessAlert(false);
        setError(null);
        let request = {
            ...state,
            name: name,
            numberOfReferrals: state.referrals.length,
            referrals: JSON.stringify(state.referrals),
            diagnoses: JSON.stringify(state.diagnoses),
        };
        MediPlusFavoriteExamPackageService.addFavoriteExamPackage(request)
            .then(({ data }) => {
                if(data === "package exists"){
                    setExistsError(true)
                }else{
                    setSuccessAlert(true);
                    setExistsError(false);
                }
            })
            .catch((error) => {
                setError(error);
            });
    };

    return (
        <div>
            {error && (
                <div>
                    <Alert severity="error">Κάτι πήγε στραβά!</Alert>
                    <br />
                </div>
            )}
            {existsError && (
                <div>
                    <Alert severity="info">Υπάρχει ήδη πακέτο με όνομα {name}</Alert>
                    <br />
                </div>
            )}
            {successAlert && (
                <div>
                    <Alert severity="success">Το Πακέτο προστέθηκε με επιτυχία!</Alert>
                    <br />
                </div>
            )}
            <form onSubmit={onSubmit}>
                <span>Όνομα Αγαπημένου Πακέτου Εξέτασης *</span>
                <div align="center">
                    <input
                        type="text"
                        className="form-control"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                    {!successAlert &&  (
                        <button type="submit" className="btn buttonPrimary">
                            Προσθήκη
                        </button>
                    )}
                </div>
            </form>
        </div>
    );
}

export default Examinations;
