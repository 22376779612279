import { saveAs } from "file-saver";
import { write, utils } from "xlsx";

const patientWithoutActiveInsuranceRedirectLink = "https://www.e-prescription.gr/test/faces/Login";

const locationMap = { ROLE_ADMIN: "/Admin", ROLE_USER: "/Doctor",ROLE_SUPER_ADMIN: "/SuperAdmin",ROLE_PH_AGENT: "/PharmaceuticalAgent"};

const bannerDisplayTime = 3000;

const effectiveTimes = [
    { value: 1, unit: "d", name: "1 φορά την ημέρα" },
    { value: 12, unit: "h", name: "2 φορές την ημέρα" },
    { value: 8, unit: "h", name: "3 φορές την ημέρα" },
    { value: 6, unit: "h", name: "4 φορές την ημέρα" },
    { value: 1, unit: "wk", name: "1 φορά την εβδομάδα" },
    { value: 4, unit: "d", name: "2 φορές την εβδομάδα" },
    { value: 2, unit: "d", name: "3 φορές την εβδομάδα" },
    { value: 1, unit: "once", name: "εφάπαξ" },
    { value: 1, unit: "pain", name: "επί πόνου" },
    { value: 2, unit: "wk", name: "κάθε 2 εβδομάδες" },
    { value: 1, unit: "dyspnea", name: "επί δύσπνοιας" },
];

const findEffectiveTimes = (effectiveTime) => {
    for (let eT of effectiveTimes) {
        if (eT.value === effectiveTime.value && eT.unit === effectiveTime.unit) return eT.name;
    }
    return null;
};

const RECAPTCHA_SITE_KEY = "6Lfx75QaAAAAABfBzlad0vc_WboT0WcIyAByEF5a";

const ckeditorToolbar = [
    "heading",
    "|",
    "bold",
    "italic",
    "blockQuote",
    "numberedList",
    "bulletedList",
    "insertTable",
    "tableColumn",
    "tableRow",
    "mergeTableCells",
    "|",
    "undo",
    "redo",
];

const materialUiDataTableOptions = {
    fixedHeader:true,
    tableBodyHeight: String(window.innerHeight -150),
    responsive:"vertical",
    selectableRows: "none",
    page: 0,
    textLabels: {
        body: {
            noMatch: "Λυπούμαστε, δεν βρέθηκαν αντίστοιχες εγγραφές",
            toolTip: "Ταξινόμηση",
            columnHeaderTooltip: (column) => `Ταξινόμηση για ${column.label}`,
        },
        pagination: {
            rowsPerPage: "Αποτελέσματα ανά Σελίδα:",
            displayRows: "από",
        },
        filter: {
            all: "Όλα",
            title: "Φίλτρα",
            reset: "Επαναφορά",
        },
        toolbar: {
            search: "Αναζήτηση",
            downloadCsv: "Κατεβάστε σε xlsx",
            print: "Εκτύπωση",
            viewColumns: "Προβολή Στηλών",
            filterTable: "Πίνακας φίλτρου",
        },
    },
    onDownload: (buildHead, buildBody, columns, values) => {
        const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const json = values.reduce((result, val) => {
            const temp = {};
            val.data.map((v, idx) => {
                return (temp[columns[idx].name] = v);
            });
            result.push(temp);
            return result;
        }, []);
        const fileName = `users`;
        const ws = utils.json_to_sheet(json);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        saveAs(data, fileName + fileExtension);
        return false;
    },
    downloadOptions: {
        filterOptions: {
            useDisplayedColumnsOnly: true,
            useDisplayedRowsOnly: true,
        },
    },
};

export {
    patientWithoutActiveInsuranceRedirectLink,
    locationMap,
    findEffectiveTimes,
    RECAPTCHA_SITE_KEY,
    ckeditorToolbar,
    materialUiDataTableOptions,
    bannerDisplayTime
};
