const mainColumns = [
    { name: "Αριθμός Συνταγής" },
    { name: "Ημ/νία Έκδοσης Συνταγής" },
    { name: "Περιγραφή" },
    { name: "Συνταγογραφημένη Ποσότητα" },
    { name: "Εκτελεσμένη Ποσότητα" },
    { name: "Κατάσταση Συνταγής" },
];

export { mainColumns };
