const mainColumns = [
    { name: "Αριθμός Παραπεμπτικού" },
    { name: "Κατηγορία Εξετάσεων" },
    { name: "Ημ/νία Έκδοσης Παραπεμπτικού" },
    { name: "Σημειώσεις" },
    { name: "Φορέας Κοινωνικής Ασφάλισης" },
    { name: "Κατάσταση Παραπεμπτικού" },
];

const referralFields = [
    { name: "id" },
    { name: "examinationCategory" },
    { name: "prescribedDate" },
    { name: "notes" },
    { name: "insuranceInstitution" },
    { name: "referralStatus" },
];

export { mainColumns, referralFields };
