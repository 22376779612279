import React, { useState } from "react";
import { mainColumns } from "./config.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import Pagination from "@material-ui/lab/Pagination";
import IdikaPrescriptionService from "../../../../../services/MediPlus/Idika/IdikaPrescriptionService.js";
import IdikaPatientService from "../../../../../services/MediPlus/Idika/IdikaPatientService.js";

function Prescriptions({
    prescriptions = null,
    page = 0,
    handlePageChange = null,
    addTab,
    tabItem,
    setTabItem,
    setSelectedTab,
    setSelectedTabBttn,
    amkaOrEkaa,
    patientsConsent,
}) {
    const [error, setError] = useState(null);

    if (prescriptions === null) return null;

    function onIdClick(medicineId) {
        setError(null);
        IdikaPrescriptionService.getPrescription(medicineId)
            .then(({ data }) => {
                if (tabItem.patientData === null) {
                    let tabId = addTab(
                        data.patientData,
                        {
                            id: -1,
                            startDate: "",
                            reason: "",
                            comment: "",
                            icpc2s: [],
                            active: false,
                            status: "",
                            prescriptions: [],
                            referrals: [],
                        },
                        7,
                        data
                    );
                    setSelectedTab(tabId);
                } else {
                    setTabItem({ ...tabItem, subTab: 7, viewPrescription: data });
                    setSelectedTabBttn(7);
                }
            })
            .catch((error) => {
                if (error.response.data.status === 511) {
                    IdikaPatientService.getMedicineHistoryFullPrescriptionFromAnotherDoctor(
                        amkaOrEkaa,
                        medicineId,
                        { patientsConsent: [patientsConsent] }
                    )
                        .then(({ data }) => {
                            if (tabItem.patientData === null) {
                                let tabId = addTab(
                                    data.patientData,
                                    {
                                        id: -1,
                                        startDate: "",
                                        reason: "",
                                        comment: "",
                                        icpc2s: [],
                                        active: false,
                                        status: "",
                                        prescriptions: [],
                                        referrals: [],
                                    },
                                    7,
                                    data
                                );
                                setSelectedTab(tabId);
                            } else {
                                setTabItem({ ...tabItem, subTab: 7, viewPrescription: data });
                                setSelectedTabBttn(7);
                            }
                        })
                        .catch((error) => {
                            setError("error");
                            setError(error);
                        });
                } else {
                    setError(error);
                }
            });
    }

    return (
        <div className="flexBoxWrap">
            <div className="flexBox">
                <div className="fullBox">
                    <div className="boxTitle">
                        <h6> Συνταγές </h6>
                    </div>
                    {error && <Alert severity="error"> Κάτι πήγε στραβά!</Alert>}
                    {prescriptions.count === 0 ? (
                        <Alert severity="info"> Δε βρέθηκαν Συνταγές. </Alert>
                    ) : (
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {mainColumns.map((column, index) => {
                                            return (
                                                <TableCell key={index}>
                                                    <b>{column.name}</b>
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {prescriptions.medicines.map((medicine, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <button
                                                        onClick={() => onIdClick(medicine.id)}
                                                        className="btn buttonPrimary"
                                                    >
                                                        {medicine.id}
                                                    </button>
                                                </TableCell>
                                                <TableCell>{medicine.prescribedDate}</TableCell>
                                                <TableCell>{medicine.name}</TableCell>
                                                <TableCell>{medicine.prescribedQuantity}</TableCell>
                                                <TableCell>
                                                    {medicine.prescribedQuantity -
                                                        medicine.remainder}
                                                </TableCell>
                                                <TableCell>{medicine.prescriptionStatus}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                            <br />
                            <Pagination
                                count={prescriptions.totalPages}
                                page={page}
                                onChange={handlePageChange}
                            />
                        </TableContainer>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Prescriptions;
