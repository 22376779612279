import React, { useState } from "react";
import { mainTabs } from "./config";
import { Tab, Tabs } from "@material-ui/core";
import CustomPrescriptions from "./CustomPrescriptions/CustomPrescriptions";
import MembershipHistory from "./MembershipHistory/MembershipHistory";
import "./Management.css";
import Settings from "./Settings/Settings";
import Contact from "./Contact/Contact";

function Management() {
    const [selectedTabBttn, setSelectedTabBttn] = useState(0);
    const handleTabsBttns = (e, val) => {
        setSelectedTabBttn(val);
    };

    const showContent = () => {
        if (selectedTabBttn === 0) return <MembershipHistory />;
        else if (selectedTabBttn === 1) return <CustomPrescriptions />;
        else if (selectedTabBttn === 2) return <Settings />;
        else if (selectedTabBttn === 3) return <Contact />;
        else return null;
    };

    return (
        <div>
            <Tabs
                value={selectedTabBttn}
                onChange={handleTabsBttns}
                indicatorColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                className="tabsBttns stickyTabs innerTabs mb-5"
            >
                {mainTabs.map((tab, index) => {
                    return <Tab key={index} {...tab} />;
                })}
            </Tabs>
            {showContent()}
        </div>
    );
}

export default Management;
