import axios from "axios";
import { baseUrl, getOptions} from "../config.js";

class MediPlusPharmaceuticalAgentService{
    constructor(baseUrl) {
        this.basePath = "/phagent";
        this.baseUrl = `${baseUrl}${this.basePath}`;
    }

    getSubmittedPrintoutByQrCode(qrCode) {
        return axios(getOptions(this.baseUrl, `/${qrCode}`));
    }

}

export default new MediPlusPharmaceuticalAgentService(baseUrl);