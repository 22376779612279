import React, { useState } from "react";
import { mainTabs, referralColumns, referralFields } from "./config.js";
import { Tabs, Tab } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const Exams = ({ referral }) => {
    return (
        <div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {referralColumns.map((column, index) => {
                                return (
                                    <TableCell key={index}>
                                        <b>{column.name}</b>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {referral.clinicalExaminations.map((clinicalExamination, index) => {
                            return (
                                <TableRow key={index}>
                                    {referralFields.map((field, indexField) => {
                                        return (
                                            <TableCell key={indexField}>
                                                {clinicalExamination[field.name]}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

function ExaminationInfo({ referral }) {
    const [selectedTab, setSelectedTab] = useState(0);
    function handleTabs(e, val) {
        setSelectedTab(val);
    }

    const showContent = () => {
        if (selectedTab === 0) return <Exams referral={referral} />;
        else if (selectedTab === 1) return <div>2</div>;
        else if (selectedTab === 2) return <div>3</div>;
        else return null;
    };

    return (
        <div className="section">
            <Tabs value={selectedTab} onChange={handleTabs}>
                {mainTabs.map((tab, index) => {
                    return <Tab key={index} {...tab} />;
                })}
            </Tabs>
            {showContent()}
        </div>
    );
}

export default ExaminationInfo;
