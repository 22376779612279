import React, { useState, useEffect } from "react";
import IdikaVisitService from "../../../../../../../../services/MediPlus/Idika/IdikaVisitService";
import { Spinner } from "../../../../../../../Utilities/Utilities";
import Alert from "@material-ui/lab/Alert";
import { mainColumns, icpc2Columns } from "./config.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import PopUp from "../../../../../../../Utilities/PopUp";
import Pagination from "@material-ui/lab/Pagination";

function VisitHistory({ patient, visitDetails, setVisitDetails, tabIndex, tabItem, setTabItem }) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [page, setPage] = useState(1);
    const handleChange = (event, value) => {
        setPage(value);
    };

    useEffect(() => {
        let request = {
            page: page - 1,
            size: 5,
        };
        if (patient.amka.length === 20) request.ekaa = patient.amka;
        else request.amka = patient.amka;
        IdikaVisitService.getVisitsWithContent(request)
            .then(({ data }) => {
                setData(data);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [patient.amka, page]);

    const [more, setMore] = useState(false);
    const [selectedVisit, setSelectedVisit] = useState(null);

    const handleMore = (visitDetails) => {
        setSelectedVisit(visitDetails);
        setMore(true);
    };

    const onRepeat = (inputVisitDetails) => {
        let newVisitDetails = {
            ...visitDetails,
            reason: inputVisitDetails.reason,
            comment: inputVisitDetails.comment,
            icpc2s: inputVisitDetails.icpc2s,
        };
        setVisitDetails(newVisitDetails);
    };

    return (
        <div>
            {loading ? (
                <Spinner align="center" animation="spinner-border" />
            ) : error ? (
                <Alert severity="error">Κάτι πήγε στραβά!</Alert>
            ) : data.count === 0 ? (
                <Alert severity="info">
                    {`Δεν υπάρχουν παλαιότερες Επισκέψεις για τον ασθενή '${patient.lastName}
                    ${patient.firstName}'`}
                </Alert>
            ) : (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {mainColumns.map((column, index) => {
                                    return (
                                        <TableCell key={index}>
                                            <b>{column.name}</b>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.visits.map((visit, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{visit.visitDetails.id}</TableCell>
                                        <TableCell>{visit.visitDetails.reason}</TableCell>
                                        <TableCell>{visit.visitDetails.startDate}</TableCell>
                                        <TableCell>{visit.visitDetails.status}</TableCell>
                                        <TableCell>
                                            {visitDetails.status === "" ? (
                                                <button
                                                    className="btn buttonPrimary small-padding"
                                                    onClick={() => onRepeat(visit.visitDetails)}
                                                >
                                                    <i className="fa fa-repeat"></i>
                                                    Επανάληψη
                                                </button>
                                            ) : null}
                                            <button
                                                className="btn buttonPrimary small-padding"
                                                onClick={() => handleMore(visit.visitDetails)}
                                            >
                                                <i className="fa fa-search"></i>
                                                Λεπτομέρειες
                                            </button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                    <Pagination count={data.totalPages} page={page} onChange={handleChange} />
                </TableContainer>
            )}
            <PopUp
                openPopUp={more}
                setOpenPopUp={setMore}
                showCloseButton={true}
                title={"Επίσκεψη"}
                maxWidth="md"
            >
                {selectedVisit && (
                    <div>
                        <div>
                            <b>Κατάσταση Επίσκεψης</b>: {selectedVisit.status} <br />
                            <b>Έναρξη Επίσκεψης</b>: {selectedVisit.startDate} <br />
                            <b>Τέλος Επίσκεψης</b>: {selectedVisit.endDate} <br /> <br />
                            <b>Λόγος Επίσκεψης</b>: {selectedVisit.reason} <br />
                            <b>Σχόλια/Παρατηρήσεις</b>: {selectedVisit.comment} <br /> <br />
                        </div>
                        {selectedVisit.icpc2s.length > 0 && (
                            <div>
                                <h6 style={{ marginLeft: "10px", marginBottom: "10px" }}>ICPC2s</h6>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {icpc2Columns.map((column, index) => {
                                                    return (
                                                        <TableCell key={index}>
                                                            <b>{column.name}</b>
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {selectedVisit.icpc2s.map((icpc2) => {
                                                return (
                                                    <TableRow key={icpc2.code}>
                                                        <TableCell>{icpc2.code}</TableCell>
                                                        <TableCell>{icpc2.title}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        )}
                    </div>
                )}
            </PopUp>
        </div>
    );
}

export default VisitHistory;
