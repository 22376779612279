import React, { useEffect, useState } from "react";
import { mainColumns } from "./config.js";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PopUp from "../../../../Utilities/PopUp.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";
import MediPlusFavoriteExamPackageService from "../../../../../services/MediPlus/MediPlusFavoriteExamPackageService.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ReceiptIcon from "@material-ui/icons/Receipt";

function ShowDetailsContent({ examDetails }) {
    const diagnosisColumns = ["Κωδικός ICD-10", "Διάγνωση"];
    const examinationColumns = ["Εξέταση", "Διάγνωση", "Λόγος παραπομπής", "Σχόλια"];

    const diagnoses = JSON.parse(examDetails.diagnoses);
    const referrals = JSON.parse(examDetails.referrals);

    return (
        <div>
            <div>
                <b>Όνομα: </b> {examDetails.name}
            </div>
            <div>
                <b>Αριθμός Εξετάσεων: </b> {examDetails.numberOfReferrals}
            </div>
            <div>
                <b>Ημ/νία Δημιουργίας: </b> {examDetails.createdDate}
            </div>
            <div>
                <b>Ημ/νία Επεξεργασίας: </b> {examDetails.updatedDate}
            </div>
            <div>
                <b>Τύπος Συνταγής: </b> {examDetails.prescriptionType}
            </div>
            <div>
                <b>Κατηγορία Εξετάσεων: </b> {examDetails.examinationGroup}
            </div>
            <div>
                <b>Λόγος: </b> {examDetails.reason}
            </div>
            <div>
                <b>Σχόλια: </b> {examDetails.comment}
            </div>
            <br />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {diagnosisColumns.map((column, index) => {
                                return (
                                    <TableCell key={index}>
                                        <b>{column}</b>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {diagnoses.map((diagnosis, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell>{diagnosis.code}</TableCell>
                                    <TableCell>{diagnosis.title}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {examinationColumns.map((column, index) => {
                                return (
                                    <TableCell key={index}>
                                        <b>{column}</b>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {referrals.map((referral, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell>{referral.code}</TableCell>
                                    <TableCell>{referral.displayName}</TableCell>
                                    <TableCell>{referral.diagnosisCode}</TableCell>
                                    <TableCell>{referral.comment}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

function EditExam({ examDetails, inputData, setData, setEditPopUp }) {
    const [name, setName] = useState(examDetails.name);
    const [error, setError] = useState(null);
    const onSubmit = (e) => {
        e.preventDefault();
        let request = { ...examDetails, name: name };
        MediPlusFavoriteExamPackageService.updateFavoriteExamPackage(examDetails.id, request)
            .then(({ data }) => {
                let content = inputData.content.map((exam) => {
                    return exam.id !== examDetails.id ? exam : request;
                });
                setData({ ...inputData, content: content });
                setEditPopUp(false);
            })
            .catch((error) => {
                setError(error);
            });
    };

    return (
        <div>
            {error && (
                <div>
                    <Alert severity="error">Κάτι πήγε στραβά!</Alert>
                    <br />
                </div>
            )}
            <form onSubmit={onSubmit}>
                <span>Όνομα Αγαπημένου Πακέτου Εξέτασης *</span>
                <div align="center">
                    <input
                        type="text"
                        className="form-control"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                    <button type="submit" className="btn buttonPrimary">
                        Αποθήκευση
                    </button>
                </div>
            </form>
        </div>
    );
}

function DeleteExam({ examDetails, inputData, setData, setDeletePopUp }) {
    const [error, setError] = useState(null);
    const onClick = (e) => {
        e.preventDefault();
        MediPlusFavoriteExamPackageService.deleteFavoriteExamPackage(examDetails.id)
            .then(({ data }) => {
                let content = inputData.content.filter((exam) => exam.id !== examDetails.id);
                setData({ ...inputData, content: content });
                setDeletePopUp(false);
            })
            .catch((error) => {
                setError(error);
            });
    };

    return (
        <div>
            {error && (
                <div>
                    <Alert severity="error">Κάτι πήγε στραβά!</Alert>
                    <br />
                </div>
            )}
            <div align="center">
                <h5>
                    Είστε σίγουρος ότι θέλετε να διαγράψετε τo Πακέτο Εξετάσεων `{examDetails.name}
                    `;
                </h5>
                <button className="btn buttonDanger" onClick={onClick}>
                    Διαγραφή
                </button>
            </div>
        </div>
    );
}

function FavoriteExamPackage() {
    const [isOpen, setIsOpen] = useState(false);
    const [page, setPage] = useState(1);
    const handlePageChange = (event, value) => {
        setPage(value);
    };
    const [isLoading, setIsLoading] = useState(true);
    const [isFetching, setIsFetching] = useState(true);
    const [isError, setIsError] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        setIsFetching(true);
        MediPlusFavoriteExamPackageService.getFavoriteExamPackagesWithTypeAndGroup({
            page: page - 1,
            size: 5,
        })
            .then(({ data }) => {
                setData(data);
                setIsFetching(false);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsError(true);
            });
    }, [page]);

    const [examDetails, setExamDetails] = useState({});
    const [popUp, setPopUp] = useState(false);
    const onShowDetails = (exam) => {
        setExamDetails(exam);
        setPopUp(true);
    };

    const [deletePopUp, setDeletePopUp] = useState(false);
    const onDelete = (exam) => {
        setExamDetails(exam);
        setDeletePopUp(true);
    };

    const [editPopUp, setEditPopUp] = useState(false);
    const onEdit = (exam) => {
        setExamDetails(exam);
        setEditPopUp(true);
    };

    return (
        <div className="flexBox">
            <Accordion className="doctorAccordion">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    onClick={() => {
                        setIsOpen(!isOpen);
                    }}
                >
                    <h6>Αγαπημένα Πακέτα Εξετάσεων</h6>
                </AccordionSummary>
                <AccordionDetails>
                    <TableContainer component={Paper}>
                        {isLoading ? (
                            <CircularProgress className="m-2" />
                        ) : isError ? (
                            <Alert severity="error">Κάτι πήγε στραβά!</Alert>
                        ) : (
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {mainColumns.map((column, index) => {
                                            return (
                                                <TableCell key={index}>
                                                    <b>{column.name}</b>
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.content.map((exam, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>{exam.name}</TableCell>
                                                <TableCell>{exam.numberOfReferrals}</TableCell>
                                                <TableCell>{exam.createdDate}</TableCell>
                                                <TableCell>
                                                    <ReceiptIcon
                                                        style={{ cursor: "pointer" }}
                                                        color="primary"
                                                        onClick={() => onShowDetails(exam)}
                                                    />
                                                    <EditIcon
                                                        style={{ cursor: "pointer" }}
                                                        color="primary"
                                                        onClick={() => onEdit(exam)}
                                                    />
                                                    <DeleteIcon
                                                        style={{ cursor: "pointer" }}
                                                        color="secondary"
                                                        onClick={() => onDelete(exam)}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        )}
                        {data !== null && (
                            <div className="form-inline">
                                <Pagination
                                    count={data !== null ? data.totalPages : 0}
                                    page={page}
                                    onChange={handlePageChange}
                                />
                                {isFetching ? <span> Φόρτωση...</span> : null}
                            </div>
                        )}
                    </TableContainer>
                </AccordionDetails>
            </Accordion>
            <PopUp
                openPopUp={popUp}
                setOpenPopUp={setPopUp}
                showCloseButton={true}
                title={"Λεπτομέρειες Αγαπημένου Πακέτου Εξετάσεων"}
            >
                <ShowDetailsContent examDetails={examDetails} />
            </PopUp>
            <PopUp
                openPopUp={editPopUp}
                setOpenPopUp={setEditPopUp}
                showCloseButton={true}
                title={"Επεξεργασία Αγαπημένου Πακέτου Συνταγών"}
            >
                <EditExam
                    examDetails={examDetails}
                    inputData={data}
                    setData={setData}
                    setEditPopUp={setEditPopUp}
                />
            </PopUp>
            <PopUp
                openPopUp={deletePopUp}
                setOpenPopUp={setDeletePopUp}
                showCloseButton={true}
                title={"Διαγραφή Αγαπημένου Πακέτου Συνταγών"}
            >
                <DeleteExam
                    examDetails={examDetails}
                    inputData={data}
                    setData={setData}
                    setDeletePopUp={setDeletePopUp}
                />
            </PopUp>
        </div>
    );
}

export default FavoriteExamPackage;
