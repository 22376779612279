import React, { useState } from "react";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { formatMaterialUiDateTime } from "../../../Utilities/Utilities";
import MediPlusCalendarEventService from "../../../../services/MediPlus/MediPlusCalendarEventService";
import { Alert } from "@material-ui/lab";

function AddNewEvent({ events, setEvents, setAddNewEventPopUp, addNewEventDate, slotDuration }) {
    const [start, setStart] = useState(new Date(addNewEventDate));
    const [end, setEnd] = useState(
        new Date(
            new Date(addNewEventDate).setMinutes(
                new Date(addNewEventDate).getMinutes() + parseInt(slotDuration.split(":")[1])
            )
        )
    );
    const [title, setTitle] = useState(
        sessionStorage.getItem("patientInfoForAppointment") !== null
            ? sessionStorage.getItem("patientInfoForAppointment")
            : ""
    );

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const onSubmit = (e) => {
        e.preventDefault();
        let newEvent = {
            start: formatMaterialUiDateTime(start),
            end: formatMaterialUiDateTime(end),
            title: title,
        };
        for (let event of events) {
            if (
                (start > event.start && start < event.end) ||
                (end > event.start && end < event.end) ||
                (start < event.start && end > event.end)
            ) {
                setIsError(true);
                setErrorMessage("Υπάρχει άλλο ραντεβού εκείνη την μέρα και ώρα");
                return;
            }
        }
        setIsError(false);
        setErrorMessage("");
        setIsLoading(true);
        MediPlusCalendarEventService.addCalendarEvent(newEvent)
            .then(({ data }) => {
                setEvents([...events, data]);
                setAddNewEventPopUp(false);
            })
            .catch((err) => {
                setIsError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <div>
            {isError && (
                <Alert severity="error">
                    {errorMessage !== "" ? errorMessage : "Κάτι πήγε στραβά!"}
                </Alert>
            )}
            <form onSubmit={onSubmit}>
                <div align="center">
                    <KeyboardDateTimePicker
                        className="mr-2"
                        variant="inline"
                        ampm={false}
                        label="Αρχή"
                        value={start}
                        onChange={setStart}
                        format="dd/MM/yyyy HH:mm"
                    />
                    <KeyboardDateTimePicker
                        variant="inline"
                        ampm={false}
                        label="Τέλος"
                        value={end}
                        onChange={setEnd}
                        format="dd/MM/yyyy HH:mm"
                    />
                    <textarea
                        rows="10"
                        cols="72"
                        placeholder="Όνομα και Σχόλια Ραντεβού"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                    />
                    <button type="submit" className="btn buttonPrimary" disabled={isLoading}>
                        Δημιουργία Ραντεβού
                    </button>
                </div>
            </form>
        </div>
    );
}

export default AddNewEvent;
