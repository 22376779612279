import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import MediPlusUserService from "../../../../../services/MediPlus/MediPlusUserService";
import SearchItem from "./SearchItem/SearchItem";
import { Spinner } from "../../../../Utilities/Utilities";

function SearchPatient({ name, value, onChange, state, setState, tabItem }) {
    const [loading, setLoading] = useState(false);
    const [searchResults, setSearchResults] = useState([]);

    useEffect(() => {
        if (value.length === 11 || value.length === 20) {
            setSearchResults([]);
        } else if (value.length >= 3) {
            setLoading(true);
            MediPlusUserService.getPatients(value).then(({ data }) => {
                setSearchResults(data);
                setLoading(false);
            });
        }
        // eslint-disable-next-line
    }, [value]);

    return (
        <div style={{ width: "50%" }}>
            <TextField
                label="Α.Μ.Κ.Α./Ε.Κ.Α.Α. Ασφαλισμένου"
                id="amkaPatientSearch"
                type="text"
                className="searchInput"
                name={name}
                value={value}
                onChange={onChange}
                disabled={tabItem.patientData !== null ? true : false}
            />
            <div>
                {value.length < 3 ? null : loading === true ? (
                    <Spinner align="center" animation="spinner-border" />
                ) : (
                    searchResults.map((patient, index) => {
                        return (
                            <SearchItem
                                key={index}
                                firstname={patient.firstName}
                                lastname={patient.lastName}
                                amkaOrEkaa={patient.amka !== null ? patient.amka : patient.ekaa}
                                address={patient.address}
                                telephone={patient.telephone}
                                state={state}
                                setState={setState}
                            />
                        );
                    })
                )}
            </div>
        </div>
    );
}

export default SearchPatient;
