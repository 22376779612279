import React, { useContext } from "react";
import IdikaVisitService from "../../../../../../services/MediPlus/Idika/IdikaVisitService.js";
import { TabContext } from "../../../../Doctor.js";

function ActiveVisit({ activeVisitId, setActiveVisitPopUp }) {
    const { setSelectedTab, tabList, addTab } = useContext(TabContext);

    const fetchActiveVisit = () => {
        IdikaVisitService.getActiveVisit().then(({ data }) => {
            let tabId = addTab(data.patientData, data.visitDetails);
            setActiveVisitPopUp(false);
            setSelectedTab(tabId);
        });
    };

    const handleOnClick = () => {
        for (let tabIndex in tabList) {
            if (tabList[tabIndex].visitDetails !== undefined) {
                if (tabList[tabIndex].visitDetails.id === activeVisitId) {
                    setActiveVisitPopUp(false);
                    setSelectedTab(tabList[tabIndex].id);
                    return;
                }
            }
        }
        // otherwise fetch active visit
        fetchActiveVisit();
    };

    return (
        <div>
            <div>
                Υπάρχει ήδη μία Ανοιχτή Eπίσκεψη! Θα πρέπει πρώτα να την Κλείσετε/Ακυρώσετε για να
                μπορέσετε να ανοίξετε μια νέα Επίσκεψη. Θέλετε να μεταφερθείτε στην Ανοιχτή
                Επίσκεψη;
            </div>
            <br />
            <div align="center">
                <button className="btn buttonPrimary" onClick={handleOnClick}>
                    Ναι
                </button>
            </div>
        </div>
    );
}

export default ActiveVisit;
