import axios from "axios";
import { baseUrl, getOptions, getPdfOptions, postOptions, putOptions } from "../../config.js";

class IdikaPrescriptionService {
    constructor(baseUrl) {
        this.basePath = "/idika/prescriptions";
        this.baseUrl = `${baseUrl}${this.basePath}`;
    }

    getPrescriptions(parameters) {
        return axios(getOptions(this.baseUrl, "", parameters));
    }

    getExecutedOrPendingPrescriptions(parameters) {
        return axios(getOptions(this.baseUrl, `/patient/${parameters.amkaOrEkaa}/${parameters.sociId}/medicine/${parameters.medBarcode}/history/all`));
    }

    getPrescription(barcode) {
        return axios(getOptions(this.baseUrl, `/${barcode}`));
    }

    getPrescriptionForEdit(barcode) {
        return axios(getOptions(this.baseUrl, `/${barcode}/edit`));
    }

    printPrescription(barcode) {
        return axios(getPdfOptions(this.baseUrl, `/${barcode}/print`));
    }

    getCopyDoctor(amka) {
        return axios(getOptions(this.baseUrl, `/copy/doctor/${amka}`));
    }

    createNewPrescription(data) {
        return axios(postOptions(this.baseUrl, "", data));
    }

    sendNoPaperPrescription(barcode) {
        return axios(postOptions(this.baseUrl,`/nopaper/${barcode}/send`));
    }

    repeatPrescriptionWithTreatmentProtocol(data) {
        return axios(postOptions(this.baseUrl, "/repeatPrescriptionWithTreatmentProtocol", data));
    }

    cancelPrescription(barcode) {
        return axios(putOptions(this.baseUrl, `/${barcode}/cancel`));
    }

    createTempPrescription(data) {
        return axios(postOptions(this.baseUrl, "/temp", data));
    }
    
}

export default new IdikaPrescriptionService(baseUrl);
