import { useEffect, useState } from "react";
import MediPlusAppContactDetailsService from "../../../services/MediPlus/MediPlusAppContactDetailsService";
import { columns } from "./config";
import { Alert } from "@material-ui/lab";
import {
    TableCell,
    TableContainer,
    TableRow,
    TableBody,
    TableHead,
    TextField,
} from "@material-ui/core";
import { CircularProgress, Paper, Table } from "@material-ui/core";
import PopUp from "../../Utilities/PopUp";

function AppContactDetails() {
    const [appContactDetails, setAppContactDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    useEffect(() => {
        MediPlusAppContactDetailsService.getAllAppContactDetails()
            .then(({ data }) => {
                setAppContactDetails(data);
            })
            .catch((err) => {
                setIsError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const deleteAppContactDetails = (id) => {
        MediPlusAppContactDetailsService.deleteAppContactDetails(id)
            .then(() => {
                setAppContactDetails(
                    appContactDetails.filter((appContactDetail) => appContactDetail.id !== id)
                );
            })
            .catch((err) => {
                // console.log(err.response);
            });
    };

    const [newAppContactDetailPopUp, setNewAppContactDetailPopUp] = useState(false);
    const [editAppContactDetail, setEditAppContactDetail] = useState(false);
    const [selectedAppContactDetail, setSelectedAppContactDetail] = useState(null);

    const handleAppContactDetailEdit = (appContactDetail) => {
        setSelectedAppContactDetail(appContactDetail);
        setEditAppContactDetail(true);
    };

    return (
        <div>
            {isLoading ? (
                <CircularProgress />
            ) : isError ? (
                <Alert severity="error">Κάτι πήγε στραβά!</Alert>
            ) : (
                <div>
                    <div className="mb-5" align="center">
                        <button
                            className="btn buttonPrimary"
                            onClick={() => setNewAppContactDetailPopUp(true)}
                        >
                            Εισαγωγή
                        </button>
                    </div>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {columns.map((column, index) => {
                                        return (
                                            <TableCell key={index}>
                                                <b>{column.name}</b>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {appContactDetails.map((appContactDetail) => {
                                    return (
                                        <TableRow key={appContactDetail.id}>
                                            <TableCell>{appContactDetail.name}</TableCell>
                                            <TableCell>{appContactDetail.value}</TableCell>
                                            <TableCell>
                                                <button
                                                    className="btn buttonPrimary"
                                                    onClick={() =>
                                                        handleAppContactDetailEdit(appContactDetail)
                                                    }
                                                >
                                                    Επεξεργασία
                                                </button>
                                            </TableCell>
                                            <TableCell>
                                                <button
                                                    className="btn buttonDanger"
                                                    onClick={() =>
                                                        deleteAppContactDetails(appContactDetail.id)
                                                    }
                                                >
                                                    Διαγραφή
                                                </button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <PopUp
                        openPopUp={newAppContactDetailPopUp}
                        setOpenPopUp={setNewAppContactDetailPopUp}
                        title={"Νέο Στοιχείο Πληροφορίας Επικοινωνιας για την εφαρμογή"}
                        maxWidth="md"
                    >
                        <NewAppContactDetail
                            appContactDetails={appContactDetails}
                            setAppContactDetails={setAppContactDetails}
                            setNewAppContactDetailPopUp={setNewAppContactDetailPopUp}
                        />
                    </PopUp>
                    <PopUp
                        openPopUp={editAppContactDetail}
                        setOpenPopUp={setEditAppContactDetail}
                        title={"Επεξεργασίας Πληροφορίας Επικοινωνιας της εφαρμογής"}
                        maxWidth="md"
                    >
                        <EditAppContactDetail
                            selectedAppContactDetail={selectedAppContactDetail}
                            appContactDetails={appContactDetails}
                            setAppContactDetails={setAppContactDetails}
                            setEditAppContactDetail={setEditAppContactDetail}
                        />
                    </PopUp>
                </div>
            )}
        </div>
    );
}

function NewAppContactDetail({
    appContactDetails,
    setAppContactDetails,
    setNewAppContactDetailPopUp,
}) {
    const [state, setState] = useState({
        name: "",
        value: "",
    });

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setState({ ...state, [name]: value });
    };

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const onSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        setIsError(false);
        MediPlusAppContactDetailsService.addAppContactDetails(state)
            .then(({ data }) => {
                setAppContactDetails([...appContactDetails, data]);
                setNewAppContactDetailPopUp(false);
            })
            .catch((err) => {
                // console.log(err.response);
                setIsError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <div>
            {isError && <Alert severity="error">Κάτι πήγε στραβά!</Alert>}
            <form className="d-flex flex-column" onSubmit={onSubmit}>
                <TextField
                    label="Όνομα"
                    variant="outlined"
                    onChange={handleChange}
                    name="name"
                    value={state.name}
                    required
                />
                <TextField
                    label="Τιμή"
                    variant="outlined"
                    onChange={handleChange}
                    name="value"
                    value={state.value}
                    required
                />
                <button className="btn buttonPrimary" disabled={isLoading}>
                    Αποθήκευση
                </button>
            </form>
        </div>
    );
}

function EditAppContactDetail({
    selectedAppContactDetail,
    appContactDetails,
    setAppContactDetails,
    setEditAppContactDetail,
}) {
    const [state, setState] = useState({
        name: selectedAppContactDetail.name,
        value: selectedAppContactDetail.value,
    });

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setState({ ...state, [name]: value });
    };

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const onSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        setIsError(false);
        MediPlusAppContactDetailsService.updateAppContactDetails(selectedAppContactDetail.id, state)
            .then(({ data }) => {
                let newAppContactDetails = appContactDetails;
                let index = newAppContactDetails.findIndex(
                    (appContactDetail) => appContactDetail.id === selectedAppContactDetail.id
                );
                newAppContactDetails[index] = data;
                setAppContactDetails([...newAppContactDetails]);
                setEditAppContactDetail(false);
            })
            .catch((err) => {
                // console.log(err.response);
                setIsError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <div>
            {isError && <Alert severity="error">Κάτι πήγε στραβά!</Alert>}
            <form className="d-flex flex-column" onSubmit={onSubmit}>
                <TextField
                    label="Όνομα"
                    variant="outlined"
                    onChange={handleChange}
                    name="name"
                    value={state.name}
                    required
                />
                <TextField
                    label="Τιμή"
                    variant="outlined"
                    onChange={handleChange}
                    name="value"
                    value={state.value}
                    required
                />
                <button className="btn buttonPrimary" disabled={isLoading}>
                    Αποθήκευση
                </button>
            </form>
        </div>
    );
}

export default AppContactDetails;
