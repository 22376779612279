import React from "react";
import { mainColumns } from "./config.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

function PatientIntolerances({ intolerances }) {
    return (
        <div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {mainColumns.map((column, index) => {
                                return (
                                    <TableCell key={index}>
                                        <b>{column.name}</b>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {intolerances.intolerances.map((intolerance, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell>{intolerance.treatmentProtocol}</TableCell>
                                    <TableCell>{intolerance.activeSubstance}</TableCell>
                                    <TableCell>{intolerance.intolerance}</TableCell>
                                    <TableCell>{intolerance.remarks}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default PatientIntolerances;
