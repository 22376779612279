import axios from "axios";
import { baseUrl, getOptions, postOptions, putOptions, deleteOptions } from "../config.js";

class MediPlusAppContactDetailsService {
    constructor(baseUrl) {
        this.basePath = "/appContactDetails";
        this.baseUrl = `${baseUrl}${this.basePath}`;
    }

    getAllAppContactDetails() {
        return axios(getOptions(this.baseUrl));
    }

    addAppContactDetails(appContactDetails) {
        return axios(postOptions(this.baseUrl, "", appContactDetails));
    }

    updateAppContactDetails(appContactDetailsId, appContactDetails) {
        return axios(putOptions(this.baseUrl, `/${appContactDetailsId}`, appContactDetails));
    }

    deleteAppContactDetails(appContactDetailsId) {
        return axios(deleteOptions(this.baseUrl, `/${appContactDetailsId}`));
    }
}

export default new MediPlusAppContactDetailsService(baseUrl);
