import React, { useState, useEffect } from "react";

import { Spinner } from "../../../../../Utilities/Utilities.js";
import IdikaMasterDataService from "../../../../../../services/MediPlus/Idika/IdikaMasterDataService.js";
import IdikaDoctorService from "../../../../../../services/MediPlus/Idika/IdikaDoctorService.js";
import ExamResults from "./ExamResults.js";

import Alert from "@material-ui/lab/Alert";
import Pagination from "@material-ui/lab/Pagination";

function compareExamGroupNames(a, b) {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
}

function compareExamResultDescriptions(a, b) {
    if (a.description < b.description) return -1;
    if (a.description > b.description) return 1;
    return 0;
}

function NewFavoriteExam() {
    const [state, setState] = useState({
        examinationGroupId: 0,
        keyword: "",
        checkbox: false,
    });
    const [examGroups, setExamGroups] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (examGroups === null) {
            IdikaMasterDataService.getExaminationGroups()
                .then(({ data }) => {
                    data.examinationGroups.sort(compareExamGroupNames);
                    setExamGroups(data);
                    setLoading(false);
                })
                .catch((error) => {
                    setError(error);
                    setLoading(false);
                });
        }
    }, [examGroups]);

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        if (name === "examinationGroupId") setState({ ...state, [name]: parseInt(value) });
        else setState({ ...state, [name]: value });
    };

    const [infoAlert, setInfoAlert] = useState(false);
    const [examinationResults, setExaminationResults] = useState(null);
    const [errorResults, setErrorResults] = useState(null);
    const [loadingResults, setLoadingResults] = useState(false);

    const [page, setPage] = useState(0);
    const handlePageChange = (event, value) => {
        setPage(value);
    };

    useEffect(() => {
        if (page === 0) return;
        const { examinationGroupId, keyword } = state;
        setInfoAlert(false);
        setLoadingResults(true);
        setExaminationResults(null);
        const request = { size: 4, page: page - 1 };
        if (examinationGroupId !== 0) request.examinationGroupId = examinationGroupId;
        if (keyword !== "") request.keyword = keyword;
        IdikaDoctorService.getSpecialtyExamsPrices(request)
            .then((response) => {
                let data = response.data.Page;
                data.contents.item.sort(compareExamResultDescriptions);
                setExaminationResults(data);
                setLoadingResults(false);
            })
            .catch((error) => {
                setErrorResults(error);
                setLoadingResults(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    const onSubmit = (e) => {
        e.preventDefault();
        const { examinationGroupId, keyword, checkbox } = state;
        if (examinationGroupId === 0 && keyword === "" && checkbox === false) {
            setInfoAlert(true);
            return;
        }
        setPage(1);
    };

    if (error !== null) return <Alert severity="error"> Κάτι πήγε στραβά! </Alert>;
    else if (loading === true) return <Spinner align="center" animation="spinner-border" />;

    return (
        <div>
            {infoAlert === true ? (
                <div>
                    <Alert severity="error">
                        Πρέπει να συμπληρώσετε τουλάχιστον ένα από τα παρακάτω για να
                        πραγματοποιηθεί η Αναζήτηση!
                    </Alert>
                    <br />
                </div>
            ) : null}
            {errorResults !== null ? (
                <div>
                    <Alert severity="error"> Κάτι πήγε στραβά! </Alert>
                    <br />
                </div>
            ) : null}
            <form onSubmit={onSubmit}>
                <div className="form-group">
                    <label> Κατηγορία Εξετάσεων </label>
                    <select
                        className="form-control"
                        name="examinationGroupId"
                        onChange={handleChange}
                        value={state.examinationGroupId}
                    >
                        <option value={0}></option>
                        {examGroups.examinationGroups.map((examCategory) => {
                            return (
                                <option
                                    value={examCategory.id}
                                    className="form-control"
                                    key={examCategory.id}
                                >
                                    {examCategory.name}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <div className="form-group">
                    <label> Λέξεις - Κλειδιά </label>
                    <input
                        type="text"
                        className="form-control"
                        name="keyword"
                        value={state.keyword}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-check">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        checked={state.checkbox}
                        onChange={() => {
                            setState({ ...state, checkbox: !state.checkbox });
                        }}
                    />
                    <label className="form-check-label"> Μόνο Προληπτικές Εξετάσεις </label>
                </div>
                <br />
                {loadingResults === true ? (
                    <Spinner align="center" animation="spinner-border" />
                ) : (
                    <div>
                        <div align="center">
                            <button className="btn buttonPrimary"> Αναζήτηση </button>
                        </div>
                        <br />
                    </div>
                )}
                <ExamResults exams={examinationResults} />
                {examinationResults && (
                    <Pagination
                        count={examinationResults.totalPages}
                        page={page}
                        onChange={handlePageChange}
                    />
                )}
            </form>
        </div>
    );
}

export default NewFavoriteExam;
