import "../../SignUp/SignUp.css";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useState, useEffect } from "react";
import MediPlusUserService from "../../../services/MediPlus/MediPlusUserService";
import { Spinner } from "../../Utilities/Utilities.js";
import Alert from "@material-ui/lab/Alert";

function CompanyManagement({setCompanies,setCompanyPopUp,companyName,defaultValues,editCompany}) {
    const [producers, setProducers] = useState(null);
    const [error, setError] = useState(null);
    const [alert, setAlert] = useState(null);
    const [loading, setLoading] = useState(true);
    const [value, setValue] = useState(null);
    const [autocompleteValues, setAutocompleteValues] = useState([]);
    


    const handleChange = (e) => {
        e.preventDefault();
        setValue(e.target.value);
    };

    const deleteCompany = () => {

        let request = {
            companyName: value,
        };

        MediPlusUserService.deleteCompany(request)
            .then((response) => {
                setAlert("Η Εταιρεία διαγραφηκε!");
            })
            .catch((error) => {
                setError(error.response.data.errors);
            })
            .finally(() => {
                setLoading(false);
                setCompanyPopUp(false);
            });
        
    };

    const handleAutocompleteChange = (event, value) => {
        setAutocompleteValues(value);
    };

    useEffect(() => {
        MediPlusUserService.getDistinctProducers()
            .then((response) => {
                const data = response.data;
                setProducers(data);
                setValue(companyName);
                setLoading(false);
            })
            .catch((error) => {
                setError(error.response.data);
                setLoading(false);
            });
    }, [companyName]);

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        let text = "";

        for(let i = 0; i < autocompleteValues.length; i ++){
            if(i === autocompleteValues.length - 1){
                text += autocompleteValues[i];
            }else{
                text += autocompleteValues[i] + "$";
            }
            
        }

         let request = {
             companyName: value,
             producers: autocompleteValues,
             originalName: companyName,
             editCompany: editCompany,
             producers2: text
         };

         //create new company
         if(!editCompany){
            MediPlusUserService.setNewCompany(request)
            .then((response) => {
                setAlert("Η Εταιρεία δημιουργήθηκε!");
            })
            .catch((error) => {
                setError(error.response.data.errors);
            })
            .finally(() => {
                setLoading(false);
                setCompanyPopUp(false);
            });

         }else{
            MediPlusUserService.editCompany(request)
            .then((response) => {
                setAlert("Η Εταιρεία δημιουργήθηκε!");
            })
            .catch((error) => {
                setError(error.response.data.errors);
            })
            .finally(() => {
                setLoading(false);
                setCompanyPopUp(false);
            });


         }
         


    };

    if (error !== null) return <Alert severity="error"> Κάτι πήγε στραβά! </Alert>;
    else if (loading === true) return <Spinner align="center" animation="spinner-border" />;

    return (
        
                        <div className="authForm">
                            <h4>{!editCompany ? "ΔΗΜΙΟΥΡΓΙΑ ΝΕΑΣ ΕΤΑΙΡΕΙΑΣ" : "ΕΠΕΞΕΡΓΑΣΙΑ ΕΤΑΙΡΕΙΑΣ " + companyName }  </h4>
                            {alert && <Alert severity="success">{alert}</Alert>}
                            <form onSubmit={onSubmit} className="mb-3">
                                <div className="d-flex justify-content-around">
                                    <TextField
                                        className="formInputUI mr-2 ml-2"
                                        label="Όνομα Εταιρείας"
                                        name="companyName"
                                        required = {editCompany? false : true}
                                        onChange = {handleChange}
                                        placeholder = {companyName}
                                        value = {value}
                                    />
                                </div>
                                <br />
                                <div className="d-flex justify-content-around">
                                    <Autocomplete
                                        multiple
                                        fullwidth
                                        id="tags-outlined"
                                        options={producers}
                                        onChange={handleAutocompleteChange}
                                        filterSelectedOptions
                                        defaultValue={defaultValues}
                                        
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Εταιρείες"
                                                placeholder="Επιλογή Εταιρειών"
                                            />
                                        )}
                                        style={{ width: "500px" }}
                                    />
                                </div>
                                <br />
                                <div align="center">
                                    <button type="submit" className="btn buttonPrimary">
                                    {!editCompany ? "ΔΗΜΙΟΥΡΓΙΑ ΝΕΑΣ ΕΤΑΙΡΕΙΑΣ" : "ΑΠΟΘΗΚΕΥΣΗ ΑΛΛΑΓΩΝ"} 
                                    </button>
                                    {editCompany && 
                                    <button className="btn buttonPrimary" onClick={deleteCompany}>
                                        ΔΙΑΓΡΑΦΗ ΕΤΑΙΡΕΙΑΣ
                                    </button>}
                                </div>
                            </form>
                        </div>
                
    );
}

export default CompanyManagement;
