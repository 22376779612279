import React, { useState } from "react";
import DoctorInfo from "../DoctorVisit/DoctorInfo/DoctorInfo";
import InsuredDetails from "../AddPrescription/InsuredDetails/InsuredDetails";
import PrescriptionInfo from "./PrescriptionInfo/PrescriptionInfo";
import IdikaPrescriptionService from "../../../../services/MediPlus/Idika/IdikaPrescriptionService";
import Diagnosis from "./Diagnosis/Diagnosis";
import Medicines from "./Medicines/Medicines";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Tooltip } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
        flexGrow: 1,
    },
}));

const ViewPrescription = ({
    tabItem,
    setTabItem,
    popPrescriptionOrReferralForRegistration,
    noPaperPrescriptionConsent,
}) => {
    const doctorData = JSON.parse(sessionStorage.getItem("mediPlusDoctorData"));
    const prescription = tabItem.viewPrescription;
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [sendPaper, setSendPaper] = useState(false);

    const onPrint = () => {
        IdikaPrescriptionService.printPrescription(prescription.id).then(({ data }) => {
            let file = new Blob([data], { type: "application/pdf" });
            let fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        });
    };

    const onSend = () => {
        IdikaPrescriptionService.sendNoPaperPrescription(prescription.id)
            .then(({ data }) => {
                setError(false);
                setSendPaper(true);
            })
            .catch((err) => {
                setError(true);
                setSendPaper(false);
            });
    };

    const onCancel = () => {
        IdikaPrescriptionService.cancelPrescription(prescription.id)
            .then(({ data }) => {
                setTabItem({ ...tabItem, viewPrescription: data });
                setError(false);
            })
            .catch((err) => {
                if (err.response.data.status === 511) {
                    setErrorMessage(
                        "O Αριθμός Συνταγής δεν αντιστοιχεί στον συνδεδεμένο γιατρό"
                    );
                } else {
                    setErrorMessage(null);
                }
                setError(true);
            });
    };

    const classes = useStyles();

    let numberOfPrescriptionsAndReferralsToBeRegistered =
        tabItem.visitDetails.referralsToBeRegistered.length +
        tabItem.visitDetails.prescriptionsToBeRegistered.length;

    if (Object.keys(prescription).length === 0) return null;

    return (
        <div className="flexBoxWrap">
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={5}>
                        <DoctorInfo
                            firstname={doctorData.firstname}
                            lastname={doctorData.lastname}
                            specialty={doctorData.specialty}
                            amka={doctorData.amka}
                            etaaRegNo={doctorData.etaaRegNo}
                        />
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <InsuredDetails />
                    </Grid>
                </Grid>
            </div>
            <div className="flexBox">
                <PrescriptionInfo prescription={prescription} />
            </div>
            <div className="flexBox">
                <Diagnosis prescription={prescription} />
            </div>
            {error && (
                <Alert severity="error">{errorMessage ? errorMessage : "Κάτι πήγε στραβά!"}</Alert>
            )}
            {sendPaper && (
                <Alert severity="success">H Ηλεκτρονική Αποστολή ολοκληρώθηκε με επιτυχία!</Alert>
            )}
            <div className="flexBox">
                <Medicines prescription={prescription} />
            </div>
            <br />
            <div className="flexBox">
                {prescription.status !== "ΑΚΥΡΩΜΕΝΗ" &&
                    prescription.status !== "ΜΕΡΙΚΩΣ ΕΚΤΕΛΕΣΜΕΝΗ" && (
                        <button className="btn buttonPrimary" onClick={() => onCancel()}>
                            Ακύρωση Συνταγής
                        </button>
                    )}
                {prescription.status !== "ΠΡΟΣΩΡΙΝΑ ΣΥΝΤΑΓΟΓΡΑΦΗΜΕΝΗ" &&
                    prescription.status !== "ΑΚΥΡΩΜΕΝΗ" &&
                    noPaperPrescriptionConsent && (
                        <button className="btn buttonPrimary" onClick={() => onSend()}>
                            Ηλεκτρονική Αποστολή
                        </button>
                    )}
                {prescription.status !== "ΠΡΟΣΩΡΙΝΑ ΣΥΝΤΑΓΟΓΡΑΦΗΜΕΝΗ" &&
                    prescription.status !== "ΑΚΥΡΩΜΕΝΗ" && (
                        <button className="btn buttonPrimary" onClick={() => onPrint()}>
                            Εκτύπωση Συνταγής
                        </button>
                    )}
                {/* <button className="btn buttonPrimary">Αντιγραφή Συνταγής</button> */}
                {numberOfPrescriptionsAndReferralsToBeRegistered > 0 && (
                    <Tooltip
                        title={`Απομένουν ακόμα ${numberOfPrescriptionsAndReferralsToBeRegistered} καταχωρήσεις`}
                        placement="top"
                    >
                        <button
                            className="btn buttonPrimary"
                            onClick={() => popPrescriptionOrReferralForRegistration()}
                        >
                            Εισαγωγή επόμενης Συνταγής/Παραπεμπτικού
                        </button>
                    </Tooltip>
                )}
            </div>
        </div>
    );
};

export default ViewPrescription;
