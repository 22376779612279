import { useState } from "react";
import { mainAgentTabs } from "../Admin/config";
import { Redirect, useParams } from "react-router";
import { getJwt } from "../Utilities/JwtUtilities";
import { Tabs, Tab, AppBar, Toolbar } from "@material-ui/core";
import Settings from "../Doctor/Management/Settings/Settings";
import MembershipHistory from "../Doctor/Management/MembershipHistory/MembershipHistory";
import SearchStatisticsPrint from "./SearchStatisticsPrint";
import "./PharmaceuticalAgent.css";

function PharmaceuticalAgent() {
    let { printId } = useParams();
    //if an agent scanned qr code this will save link while they are signing in
    sessionStorage.setItem("link", printId);

    const [selectedTab, setSelectedTab] = useState(0);

    const handleTabs = (e, value) => {
        setSelectedTab(value);
    };

    const signOut = () => {
        sessionStorage.clear();
        window.location = "/";
    };

    const showContent = () => {
        if (selectedTab === 0) return <SearchStatisticsPrint id={printId} />;
        else if (selectedTab === 1) return <MembershipHistory />;
        else if (selectedTab === 2) return <Settings admin={false} />;
        else return null;
    };

    const token = getJwt();
    if (token === null) return <Redirect to="/SignIn" />;

    return (
        <div className="bodyWrap">
            <div className="mainHeaderAdmin mb-5">
                <AppBar position="static" className="mainTabs">
                    <Toolbar id="bar">
                        <img
                            className="mr-2"
                            height="40px"
                            src="/images/MediPlusLogoNoPadding.png"
                            alt="MediPlusLogoNoPadding"
                        />
                        <div>
                            <Tabs
                                className="mainTabs"
                                value={selectedTab}
                                onChange={handleTabs}
                                variant="scrollable"
                                scrollButtons="auto"
                                indicatorColor="secondary"
                                allowScrollButtonsMobile={true}
                            >
                                {mainAgentTabs.map((tab, index) => {
                                    return <Tab key={index} {...tab} />;
                                })}
                            </Tabs>
                        </div>
                        <button
                            className="btn buttonPrimary HeaderButton"
                            id="agentLogoutButton"
                            onClick={signOut}
                        >
                            <i className="fa fa-sign-out"></i>
                            Αποσύνδεση
                        </button>
                    </Toolbar>
                </AppBar>
            </div>
            {showContent()}
        </div>
    );
}

export default PharmaceuticalAgent;
