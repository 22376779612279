import { formatDate } from "../../Utilities/Utilities";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { materialUiDataTableOptions } from "../../../config";

const prescriptionStatsTblColumns = [
    { name: "activeSubstance", label: "Δραστική" },
    { name: "prescribedMedicineBarcode", label: "Συν/ένο Barcode", options: { filter: false, sort: false, display:false } },
    { name: "medicineName", label: "Συν/ένο Φάρμακο" },
    { name: "prescribedMedicineProducer", label: "Εταιρεία Συν/ένου Φαρμ."},
    { name: "executedMedicineProducer",   label: "Εταιρεία Εκτ/ένου Φαρμ."},
    { name: "prescribedQuantity", label: "Συν. Ποσ." },
    { name: "executedMedicineBarcode", label: "Εκτ/ένο Barcode", options: { filter: false, sort: false, display:false } },
    { name: "executedMedicine", label: "Εκτ/ένο Φάρμακο" },
    { name: "executedQuantity", label: "Εκτ. Ποσ." },
    { name: "medicineType", label: "Τύπος Φαρμ.", options: {display:false} },
    {
        name: "diffMedicines",
        label: "Αλλαγή Φαρμ.",
        options: {
            customBodyRender: (value) => {
                return <RadioButtonUncheckedIcon color={value ? "primary" : "secondary"} />;
            },
        },
    },
    { name: "status", label: "Status συνταγής" },
    {
        name: "prescriptionBarcode",
        label: "Barcode Συνταγής",
        options: { filter: false, sort: false },
    },
    { name: "prescribingUnit", label: "Μονάδα Συν/σης", options: { display:false } },
    {
        name: "prescriptionIssueDate",
        label: "Δημ/γία Συν.",
        options: {
            customBodyRender: (value) => {
                return <div>{formatDate(value)}</div>;
            },
        },
    },
    {
        name: "prescriptionΕxpirationDate",
        label: "Ημερομηνία Λήξης",
        options: {
            customBodyRender: (value) => {
                return <div>{formatDate(value)}</div>;
            },
        },
    },
    {
        name: "lastExecutionDate",
        label: "Ημερομηνία Εκτέλεσης",
        options: {
            customBodyRender: (value) => {
                return <div>{formatDate(value)}</div>;
            },
        },
    },
    { name: "patientAmka", label: "ΑΜΚΑ Ασθενή", options: { filter: false, sort: false } },
    { name: "patientFullName", label: "Ον/μο Ασθενή" },
];

const medicineTotalsTblColumns = [
    { name: "activeSubstance", label: "Δραστική" },
    { name: "medicineName", label: "Φάρμακο" },
    { name: "prescribedMedicines", label: "Συνταγογρ."},
    { name: "pendingMedicines",   label: "Εκκρεμή"},
    { name: "executedMedicines", label: "Εκτελεσμ." },
    { name: "fromOther", label: "Από άλλο" },
    { name: "nonExecutedMedicines", label: "Ανεκτελ." },
    { name: "toOther", label: "Σε άλλο" },
    { name: "fromRepetition", label: "Από επανάληψη" },
    { name: "totals", label: "Σύνολα" },
    {
        name: "medicineProducer",
        label: "Εταιρία",
        options: {
           display: false,
           viewColumns: false
        }
    }
];

const mainTabs = [
    {
        className: "tabBttns",
        label: "Στατιστικά Φαρμάκων/ Συνταγών",
        disabled: false,
    },
    {
        className: "tabBttns",
        label: "Σύνολα φαρμάκων",
        disabled: false,
    },
];

const prescriptionStatsTableOptions = {
    fixedHeader:true,
    // tableBodyHeight: (window.innerHeight-150).toString() + "px",
    responsive:"standard",
    selectableRows: "none",
    rowsPerPageOptions: [10,20, {label:"All", value:1000000}],
    page: 0,
    pagination: true,
    textLabels: materialUiDataTableOptions.textLabels,
    onDownload: materialUiDataTableOptions.onDownload,
    downloadOptions: materialUiDataTableOptions.downloadOptions,
};

const medicineTotalsTblOptions = {
    selectableRows: "none",
    page: 0,
    print: false,
    textLabels: materialUiDataTableOptions.textLabels,
    onDownload: materialUiDataTableOptions.onDownload,
    downloadOptions: materialUiDataTableOptions.downloadOptions
};

export { prescriptionStatsTblColumns,
            medicineTotalsTblColumns,
            mainTabs,
            prescriptionStatsTableOptions,
            medicineTotalsTblOptions };