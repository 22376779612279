import React, { useState, useEffect } from "react";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import PopUp from "../../../../Utilities/PopUp.js";
// import Radio from "@material-ui/core/Radio";
// import RadioGroup from "@material-ui/core/RadioGroup";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { mainColumns, insertedIcd10Columns } from "./config.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IdikaMasterDataService from "../../../../../services/MediPlus/Idika/IdikaMasterDataService.js";
import IdikaVisitService from "../../../../../services/MediPlus/Idika/IdikaVisitService.js";
import { Spinner } from "../../../../Utilities/Utilities.js";
import Pagination from "@material-ui/lab/Pagination";
import Alert from "@material-ui/lab/Alert";
import MediPlusBannerService from "../../../../../services/MediPlus/MediPlusBannerService.js";

function SearchIcd10({ addOrRemoveIcd10, checkIfIcd10IsSelected }) {
    // const [radio, setRadio] = useState("Όλα");
    // const handleRadioChange = (event) => {
    //     setRadio(event.target.value);
    // };

    const [state, setState] = useState({
        icd10description: "",
        icd10code: "",
    });

    const handleStateChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setState({ ...state, [name]: value });
    };

    const [page, setPage] = useState(1);
    const handleChange = (event, value) => {
        setPage(value);
        setFetch(true);
    };
    const [icd10s, setIcd10s] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const onSubmit = (e) => {
        e.preventDefault();
        handleChange(e, 1);
    };

    const [fetch, setFetch] = useState(false);
    useEffect(() => {
        if (fetch === false) return;
        setLoading(true);
        setError(null);
        IdikaMasterDataService.getIcd10s({ ...state, size: 5, page: page - 1 })
            .then(({ data }) => {
                setIcd10s(data);
            })
            .catch((error) => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
                setFetch(false);
            });
        // eslint-disable-next-line
    }, [fetch]);

    return (
        <div>
            <form onSubmit={onSubmit}>
                {/* <FormControl className="p-1" component="fieldset">
                    <FormLabel component="legend">Αναζήτηση σε</FormLabel>
                    <RadioGroup row value={radio} onChange={handleRadioChange}>
                        <FormControlLabel
                            value="Όλα"
                            control={<Radio color="primary" />}
                            label="Όλα"
                        />
                        <FormControlLabel
                            value="Συχνά Χρησιμοποιούμενα"
                            control={<Radio color="primary" />}
                            label="Συχνά Χρησιμοποιούμενα"
                        />
                    </RadioGroup>
                </FormControl>
                <br /> */}
                <FormControl component="fieldset">
                    <FormLabel component="legend">Κριτήρια</FormLabel>
                    <div className="form-inline">
                        <h1 className="infoP">Περιγραφή </h1>
                        <input
                            type="text"
                            name="icd10description"
                            value={state.icd10description}
                            onChange={handleStateChange}
                        />
                        <h1 className="infoP">Κωδικός ICD-10 </h1>
                        <input
                            type="text"
                            name="icd10code"
                            value={state.icd10code}
                            onChange={handleStateChange}
                        />
                        <button
                            type="submit"
                            onClick={onSubmit}
                            className="btn buttonPrimary"
                            disabled={loading}
                        >
                            {loading ? "Φόρτωση..." : "Αναζήτηση"}
                        </button>
                    </div>
                </FormControl>
            </form>
            {loading ? (
                <Spinner align="center" animation="spinner-border" />
            ) : error ? (
                <Alert severity="error">Kάτι πήγε στραβά!</Alert>
            ) : icd10s && icd10s.icd10s.length === 0 ? (
                <Alert severity="info">Δεν υπάρχουν ICD10s</Alert>
            ) : (
                icd10s && (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {mainColumns.map((column, index) => {
                                        return (
                                            <TableCell key={index}>
                                                <b>{column.name}</b>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {icd10s.icd10s.map((icd10) => {
                                    let buttonText =
                                        checkIfIcd10IsSelected(icd10) !== -1
                                            ? "Διαγραφή"
                                            : "Προσθήκη";
                                    return (
                                        <TableRow key={icd10.code}>
                                            <TableCell>{icd10.code}</TableCell>
                                            <TableCell>{icd10.title}</TableCell>
                                            <TableCell>{icd10.description.split("?")}</TableCell>
                                            <TableCell>
                                                <button
                                                    className={
                                                        buttonText === "Προσθήκη"
                                                            ? "btn buttonPrimary"
                                                            : "btn buttonDanger"
                                                    }
                                                    onClick={() => addOrRemoveIcd10(icd10)}
                                                >
                                                    {buttonText}
                                                </button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        <Pagination count={icd10s.totalPages} page={page} onChange={handleChange} />
                    </TableContainer>
                )
            )}
        </div>
    );
}

function DeleteWarningPopUp({ icd10, setPopUp, addPrescriptionState, setAddPrescriptionState }) {
    const onClick = () => {
        // delete icd10 from icd10s
        let newIcd10s = addPrescriptionState.icd10s.filter((i) => i.code !== icd10.code);
        // delete icd10 from medicines diagnoses
        let newMedicines = [];
        for (let medicine of addPrescriptionState.medicines) {
            let newMedicineDiagnoses = medicine.request.diagnoses.filter(
                (diag) => diag.code !== icd10.code
            );
            medicine.request.diagnoses = newMedicineDiagnoses;
            newMedicines.push(medicine);
        }
        setAddPrescriptionState({
            ...addPrescriptionState,
            icd10s: newIcd10s,
            medicines: newMedicines,
        });
        // close pop up
        setPopUp(false);
    };

    return (
        <div align="center">
            Με τη διαγραφή ICD10 διάγνωσης θα καταργηθούν τυχόν συσχετίσεις φαρμάκων με τη διάγνωση
            αυτή. Θέλετε να συνεχίσετε;
            <br />
            <button className="btn buttonPrimary" onClick={() => onClick()}>
                Ναι
            </button>
        </div>
    );
}

function checkIfIcd10IsAssignedToSomeMedicine(medicines, icd10) {
    for (let medicine of medicines) {
        for (let diagnosis of medicine.request.diagnoses) {
            if (diagnosis.code === icd10.code) {
                return true;
            }
        }
    }
    return false;
}

function Diagnose({ addPrescriptionState, setAddPrescriptionState, setShowBanner,setBanner,openDiagnosePopUp,setOpenDiagnosePopUp }) {  

    const checkIfIcd10IsSelected = (icd10) => {
        return addPrescriptionState.icd10s.findIndex(
            (selectedIcd10) => selectedIcd10.id === icd10.id
        );
    };

    const [deleteWarningPopUp, setDeleteWarningPopUp] = useState(false);
    const [icd10ForDeletion, setIcd10ForDeletion] = useState(null);

    const addOrRemoveIcd10 = (icd10) => {
        let index = checkIfIcd10IsSelected(icd10);
        if (index !== -1) {
            if (checkIfIcd10IsAssignedToSomeMedicine(addPrescriptionState.medicines, icd10)) {
                setIcd10ForDeletion(icd10);
                setDeleteWarningPopUp(true);
            } else {
                let newSelectedIcd10s = addPrescriptionState.icd10s.filter(
                    (selectedIcd10, selIcd10Index) => selIcd10Index !== index
                );
                setAddPrescriptionState({ ...addPrescriptionState, icd10s: newSelectedIcd10s });
            }
        } else {
            let arrayObject = {
                id: icd10.id,
                code: icd10.code,
                title: icd10.title,
                description: icd10.description,
            };
            let newSelectedIcd10s = [...addPrescriptionState.icd10s, arrayObject];
            setAddPrescriptionState({ ...addPrescriptionState, icd10s: newSelectedIcd10s });

            const doctorData = JSON.parse(sessionStorage.getItem("mediPlusDoctorData"));

            let request = {
                "icdId": icd10.id,
                "specialty": doctorData.specialty,
            }

            MediPlusBannerService.getBannerByIcd(request)
                .then(({ data }) => {
                    if(data !== ""){
                        setBanner(<img
                            alt="img"
                            src={`data:image/jpeg;base64,${data.bytes}`}
                        />);
                        setShowBanner(true);
                    }
                
                })
                .catch((err) => {
                    //console.log(err.response);
                });


        }
    };

    const automaticCorrelation = (icd10) => {
        let newAddPrescriptionState = addPrescriptionState;
        for (let medicineIndex in addPrescriptionState.medicines) {
            let icd10Found = false;
            for (let medicineIcd10 of addPrescriptionState.medicines[medicineIndex].request
                .diagnoses) {
                if (medicineIcd10.id === icd10.id) {
                    icd10Found = true;
                    break;
                }
            }
            if (icd10Found === false) {
                newAddPrescriptionState.medicines[medicineIndex].request.diagnoses = [
                    ...newAddPrescriptionState.medicines[medicineIndex].request.diagnoses,
                    icd10,
                ];
            }
        }
        setAddPrescriptionState({ ...newAddPrescriptionState });
    };

    const [error, setError] = useState(null);
    const addTreatmentProtocolIcd10s = () => {
        setError(null);
        IdikaVisitService.getTreatmentProtocolIcd10s()
            .then(({ data }) => {
                let newIcd10sToBeInserted = [];
                for (let icd10 of data.icd10s) {
                    let icd10Found = addPrescriptionState.icd10s.find((icd) => icd.id === icd10.id);
                    if (icd10Found === undefined) newIcd10sToBeInserted.push(icd10);
                }
                setAddPrescriptionState({
                    ...addPrescriptionState,
                    icd10s: [...addPrescriptionState.icd10s, ...newIcd10sToBeInserted],
                });
            })
            .catch((err) => {
                setError(err.response);
            });
    };

    return (
        <div className="fullBox">
            <div className="boxTitle">
                <h6>Διαγνώσεις</h6>
            </div>
            {error && <Alert severity="error">Kάτι πήγε στραβά!</Alert>}
            <div className="boxContent">
                <div className="flexBase">
                    <p className="infoP alignRight">Διάγνωση</p>
                    <TextareaAutosize
                        rowsMin="6"
                        cols="100"
                        placeholder="(Ελεύθερο Κείμενο)"
                        value={addPrescriptionState.diagnosisComment}
                        onChange={(e) =>
                            setAddPrescriptionState({
                                ...addPrescriptionState,
                                diagnosisComment: e.target.value,
                            })
                        }
                    />
                </div>
                <br />
                <hr />
                <br />
                <button
                    className="btn buttonPrimary"
                    onClick={() => {
                        setOpenDiagnosePopUp(true);
                    }}
                >
                    Προσθήκη Διάγνωσης ICD-10
                </button>
                {addPrescriptionState.insertedTreatmentProtocols.count !== 0 && (
                    <button
                        className="btn buttonPrimary"
                        onClick={() => addTreatmentProtocolIcd10s()}
                    >
                        Προσθήκη Διαγνώσεων ICD-10 από Θεραπευτικά Πρωτόκολλα
                    </button>
                )}
                {addPrescriptionState.icd10s.length > 0 && (
                    <TableContainer className="mt-3" component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {insertedIcd10Columns.map((column, index) => {
                                        return (
                                            <TableCell key={index}>
                                                <b>{column.name}</b>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {addPrescriptionState.icd10s.map((icd10) => {
                                    return (
                                        <TableRow key={icd10.code}>
                                            <TableCell>{icd10.code}</TableCell>
                                            <TableCell>{icd10.title}</TableCell>
                                            <TableCell>
                                                <button
                                                    className="btn buttonPrimary"
                                                    onClick={() => automaticCorrelation(icd10)}
                                                >
                                                    Αυτόματη συσχέτιση
                                                </button>
                                                <button
                                                    className="btn buttonDanger"
                                                    onClick={() => addOrRemoveIcd10(icd10)}
                                                >
                                                    Διαγραφή
                                                </button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </div>
            <PopUp openPopUp={openDiagnosePopUp} setOpenPopUp={setOpenDiagnosePopUp} title={"Αναζήτηση ICD10"}>
                <SearchIcd10
                    addOrRemoveIcd10={addOrRemoveIcd10}
                    checkIfIcd10IsSelected={checkIfIcd10IsSelected}
                />
            </PopUp>
            <PopUp
                openPopUp={deleteWarningPopUp}
                setOpenPopUp={setDeleteWarningPopUp}
                title={"Προσοχή!"}
            >
                <DeleteWarningPopUp
                    icd10={icd10ForDeletion}
                    setPopUp={setDeleteWarningPopUp}
                    addPrescriptionState={addPrescriptionState}
                    setAddPrescriptionState={setAddPrescriptionState}
                />
            </PopUp>
        </div>
    );
}

export default Diagnose;
