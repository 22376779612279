import { useState } from "react";
import { Alert } from "@material-ui/lab";
import { Box, Container, Typography } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { medicineTotalsTblColumns, medicineTotalsTblOptions } from "./config.js";
import { KeyboardDatePicker } from "@material-ui/pickers";
import MediPlusPrescriptionStatisticsService from "../../../services/MediPlus/MediPlusPrescriptionStatisticsService";
import MediPlusPdfService from "../../../services/MediPlus/MediPlusPdfService.js";
import { formatMaterialUiDate, getCurrentDate } from "../../Utilities/Utilities";
import MedicineTotalsToolbar from "./MedicineTotalsToolbar.js";
import moment from "moment";
import "./statistics.css";

let dispData = [];
let tblColumns = [];
let filterParams = [];

function PrescriptionStatistics() {
    const [statistics, setStatistics] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [searchParams, setSearchParams] = useState({});
    const [dateRangeTypes] = useState([
        'Ημ/νίες Έναρξης',
        'Ημ/νίες Εκτέλεσης',
        'Ημ/νίες Έκδοσης'
    ]);

    const displayedColumns = {
        "activeSubstance": 1,
        "medicineName": 1,
        "prescribedMedicines": 1,
        "executedMedicines": 1,
        "pendingMedicines": 1,
        "nonExecutedMedicines": 1,
        "fromOther": 1,
        "toOther": 1,
        "fromRepetition": 1,
        "totals": 1
    };

    const [state, setState] = useState({
        from: getCurrentDate(),
        to: getCurrentDate(),
        dateType: "2"
    });

    medicineTotalsTblOptions.onViewColumnsChange = (changedColumn, action) => {
        displayedColumns[changedColumn] = (action==="add" ? 1 : 0);
    };

    medicineTotalsTblOptions.onFilterChange = (changedColumn, filterList, type, changedColumnIndex, displayData) => {
        // console.log(dispData);
        filterParams = filterList;
        updateMedicineTotalsTblLabels(displayData);
        setState({...state});  //force refresh table view 
    };

    medicineTotalsTblOptions.onTableChange = (coluaction, tableState) => {
        tblColumns = tableState.columns;
        dispData = tableState.displayData;
        updateMedicineTotalsTblLabels(tableState.displayData);
    };

    const updateMedicineTotalsTblLabels = (data) => {
        if (data) {
            let prescribedMedicinesSum = 0;
            let pendingMedicinesSum = 0;
            let executedMedicinesSum = 0;
            let fromOtherMedicinesSum = 0;
            let nonExecMedicinesSum = 0;
            let toOtherMedicinesSum = 0;
            let totalMedicinesSum = 0;

            for(var i=0; i<data.length; i++) {
                if (data[i].data[2] && data[i].data[2]!=null)
                    prescribedMedicinesSum += data[i].data[2];
                if (data[i].data[3] && data[i].data[3]!=null)
                    pendingMedicinesSum += data[i].data[3];
                if (data[i].data[4] && data[i].data[4]!=null)
                    executedMedicinesSum += data[i].data[4];
                if (data[i].data[5] && data[i].data[5]!=null)
                    fromOtherMedicinesSum += data[i].data[5];
                if (data[i].data[6] && data[i].data[6]!=null)
                    nonExecMedicinesSum += data[i].data[6];
                if (data[i].data[7] && data[i].data[7]!=null)
                    toOtherMedicinesSum += data[i].data[7];
                if (data[i].data[8] && data[i].data[8]!=null)
                    totalMedicinesSum += data[i].data[8];
            }

            tblColumns[2].label = medicineTotalsTblColumns[2].label + ' ' + prescribedMedicinesSum;
            tblColumns[3].label = medicineTotalsTblColumns[3].label + ' ' + pendingMedicinesSum;
            tblColumns[4].label = medicineTotalsTblColumns[4].label + ' ' + executedMedicinesSum;
            tblColumns[5].label = medicineTotalsTblColumns[5].label + ' ' + fromOtherMedicinesSum;
            tblColumns[6].label = medicineTotalsTblColumns[6].label + ' ' + nonExecMedicinesSum;
            tblColumns[7].label = medicineTotalsTblColumns[7].label + ' ' + toOtherMedicinesSum;
            tblColumns[8].label = medicineTotalsTblColumns[8].label + ' ' + totalMedicinesSum;
        }
    };

    const onSubmit = (e) => {
        e.preventDefault();

        if (Number.isNaN(Date.parse(state.from)) || Number.isNaN(Date.parse(state.to))) {
            setError({ data: { dates: "Οι ημερομηνίες δεν είναι έγκυρες" }, status: 410 });
            return;
        }
        
        setSearchParams({
            pdfExportTimestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
            from: state.from,
            to: state.to,
            dateType: dateRangeTypes[state.dateType-1],
        });

        setError({});
        setLoading(true);
        MediPlusPrescriptionStatisticsService.getMedicineTotals(state)
            .then(({ data }) => {
                setStatistics(data);
            })
            .catch((err) => {
                // console.log(err.response);
                setError(err.response);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleChange = e => {
        const { value } = e.target;

        setState({
            ...state,
            dateType: value
        });
    };

    const submitPrintouts = () => {
        setLoading(true);
    
        let printouts = [];
        for(var i=0; i<statistics.length; i++) {
            let foundInData = (statistics.length===dispData.length);
            if (statistics.length!==dispData.length) {
                for(var j=0; j<dispData.length; j++) {
                    // console.log(displayData[j]);
                    if (dispData[j].data[0]===statistics[i].activeSubstance && dispData[j].data[1]===statistics[i].medicineName) {
                        foundInData = true;
                        break;
                    }
                }
            }

            if (foundInData) {
                let dataTmp = {};
                let keys = Object.keys(displayedColumns);
                for(let j=0; j<keys.length; j++) {
                    if (displayedColumns[keys[j]] === 1) {
                        dataTmp[keys[j]] = statistics[i][keys[j]];
                    }
                }

                printouts.push({
                    medicineProducer: (statistics[i].medicineProducer==null ? '' : statistics[i].medicineProducer),
                    searchParams: JSON.stringify({
                        ...searchParams,
                        filters: JSON.stringify(filterParams)
                    }),
                    data: JSON.stringify(dataTmp)
                });    
            }
        }

        MediPlusPrescriptionStatisticsService.addSubmittedPrintouts(printouts)
        .then(({ data }) => {
            MediPlusPdfService.generateMedicineTotalsPdf(data).then((result) => {
                // console.log(result.data['ArrayBuffer']);

                let file = new Blob([result.data], { type: "application/pdf" });
                if (file.size>0) {
                    let fileURL = URL.createObjectURL(file);
                    window.open(fileURL);        
                }
            })
            .catch((err) => {
                console.log(err);
            });
        })
        .catch((err) => {
            setError(JSON.stringify(err));
        })
        .finally(() => {
            setLoading(false);
        });

    };

    medicineTotalsTblOptions.customToolbar = () => {
        return (
            <MedicineTotalsToolbar handleClick={submitPrintouts} />
        )
    };

    return (
        <Box mt={5}>
            <Container>
                {error.status !== undefined ? (
                    <Alert severity="error">{error.status === 410 ? error.data.dates : null}</Alert>
                ) : null}
                
                <form onSubmit={onSubmit} className="mb-5">
                    <Typography>
                        <input 
                            type="radio" 
                            value="1" 
                            id="starting-date" 
                            name="date-types" 
                            onChange={handleChange} 
                            checked={state.dateType === "1"}
                            />
                        <label htmlFor="starting-date" className="radio-btn-label">{dateRangeTypes[0]}</label>

                        <input 
                            type="radio" 
                            value="2" 
                            id="execution-date" 
                            name="date-types"
                            onChange={handleChange} 
                            checked={state.dateType === "2"}
                            />
                        <label htmlFor="execution-date" className="radio-btn-label">{dateRangeTypes[1]}</label>
                        
                        <input 
                            type="radio" 
                            value="3" 
                            id="issue-date" 
                            name="date-types" 
                            onChange={handleChange} 
                            checked={state.dateType === "3"}
                            />
                        <label htmlFor="issue-date" className="radio-btn-label">{dateRangeTypes[2]}</label>
                    </Typography>
                    <KeyboardDatePicker
                        format="dd/MM/yyyy"
                        label="Από"
                        value={state.from}
                        onChange={(date) => {
                            setState({
                                ...state,
                                from: formatMaterialUiDate(date),
                            });
                        }}
                        disableToolbar
                        className="mr-3"
                    />
                    <KeyboardDatePicker
                        format="dd/MM/yyyy"
                        label="Έως"
                        value={state.to}
                        onChange={(date) => {
                            setState({
                                ...state,
                                to: formatMaterialUiDate(date),
                            });
                        }}
                        disableToolbar
                    />
                    <button type="submit" className="btn buttonPrimary" disabled={loading}>
                        Αναζήτηση
                    </button>
                </form>
            </Container>
            <MUIDataTable 
                className="mb-5"
                title={"Σύνολα Φαρμάκων"}
                data={statistics}
                columns={medicineTotalsTblColumns}
                options={medicineTotalsTblOptions}
            />
        </Box>
    );
}

export default PrescriptionStatistics;