import { Alert } from "@material-ui/lab";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import React, { useState } from "react";
import MediPlusCalendarEventService from "../../../../../../../../services/MediPlus/MediPlusCalendarEventService";
import { formatMaterialUiDateTime } from "../../../../../../../Utilities/Utilities";

function MakeAppointment({ patient }) {
    const [date, setDate] = useState(new Date());
    const [title, setTitle] = useState(`${patient.firstName} ${patient.lastName} `);

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [successAlert, setSuccessAlert] = useState(false);
    const onSubmit = (e) => {
        e.preventDefault();
        setIsError(false);
        setIsLoading(true);
        let newEvent = {
            date: formatMaterialUiDateTime(date),
            title: title,
        };
        MediPlusCalendarEventService.addCalendarEvent(newEvent)
            .then(({ data }) => {
                setSuccessAlert(true);
            })
            .catch((err) => {
                setIsError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <div>
            {successAlert && (
                <Alert severity="success">Το Ραντεβού δημιουργήθηκε με επιτυχία!</Alert>
            )}
            {isError && <Alert severity="error">Κάτι πήγε στραβά!</Alert>}
            <form onSubmit={onSubmit}>
                <div align="center">
                    <KeyboardDateTimePicker
                        variant="inline"
                        ampm={false}
                        label="Ημερομηνία και Ώρα Ραντεβού"
                        value={date}
                        onChange={setDate}
                        format="dd/MM/yyyy HH:mm"
                    />
                    <div>
                        <textarea
                            rows="10"
                            cols="60"
                            placeholder="Όνομα και Σχόλια Ραντεβού"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className="btn buttonPrimary"
                        disabled={successAlert || isLoading}
                    >
                        Δημιουργία Ραντεβού
                    </button>
                </div>
            </form>
        </div>
    );
}

export default MakeAppointment;
