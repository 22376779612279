const mainTabs = [
    {
        key: 0,
        id: 0,
        label: "Προσωπικες Πληροφοριες",
    },
    {
        key: 1,
        id: 1,
        label: "Βιομετρικα Στοιχεια",
    },
    {
        key: 2,
        id: 2,
        label: "Παράμετροι Θεραπευτικών Πρωτοκόλλων",
    },
    {
        key: 3,
        id: 3,
        label: "Αντενδείξεις - Δυσανεξίες",
    },
];

export { mainTabs };
