import React, { useState, useEffect } from "react";
import IdikaMasterDataService from "../../../../../services/MediPlus/Idika/IdikaMasterDataService";
import "./Treatment.css";
import { Multiselect } from "multiselect-react-dropdown";
import PopUp from "../../../../Utilities/PopUp";
import SimpleMenu from "../../../../Utilities/CustomMenu";
import {
    Checkbox,
    FormControlLabel,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import { Spinner } from "../../../../Utilities/Utilities";

const galenicMedicineObjectForRequest = {
    prescriptionBarcodeId: 0,
    barcode: "",
    comment: "",
    eofCode: "",
    displayName: "",
    doseQuantity: { value: "0", unit: "" },
    rateQuantity: { value: 0, unit: "d" },
    effectiveTime: { id: 0, value: 0, unit: "" },
    diagnoses: [],
    genericMedicine: false,
    insuredParticipationRate: "",
    quantity: 1,
    treatmentProtocolPatientStepId: 0,
    diseaseId: -1,
    highRiskGroupId: 0,
    isConsumable: false,
};

function GalenicMedicine({
    medicine,
    index,
    addPrescriptionState,
    setAddPrescriptionState,
    participationPercentages,
    tabItem,
    noParticipationCases,
    doseUnits,
    medicineFrequencies,
    setOpenPopUp,
    setGalenicIndex,
}) {
    const [participationPercent, setParticipationPercent] = useState([]);
    const [openIngredientsPopUp, setOpenIngredientsPopUp] = useState(false);
    const [addIngredientsErrorPopUp, setAddIngredientsErrorPopUp] = useState(false);
    const [errorsList, setErrorsList] = useState([]);
    const [mustBePositive, setMustBePositive] = useState(false);

    useEffect(() => {
        if (addPrescriptionState.medicines[index].request.medicines.length > 0) {
            addPrescriptionState.medicines[index].request.medicines.forEach((element, elIdx) => {
                if (!element.request) {
                    let medicines = addPrescriptionState.medicines;
                    medicines[index].request.medicines[elIdx].request =
                        galenicMedicineObjectForRequest;
                    medicines[index].request.medicines[elIdx].request.barcode =
                        element.barcode.toString();
                    medicines[index].request.medicines[elIdx].request.displayName =
                        element.commercialName;
                    medicines[index].request.medicines[elIdx].request.doseQuantity.unit =
                        element.doseUnit;
                    medicines[index].request.medicines[elIdx].request.quantity =
                        element.prescriptionQuantity;
                    medicines[index].request.medicines[elIdx].request.doseQuantity.value =
                        element.doseQuantity;
                    medicines[index].request.medicines[elIdx].request.inClusterWithGeneric =
                        element.inClusterWithGeneric;
                    setAddPrescriptionState({ ...addPrescriptionState, medicines: [...medicines] });
                }
            });
        }
    }, []);

    let errors = [];

    let columns = [
        { name: "Τύπος" },
        { name: "Δραστική Ουσία / Έκδοχο" },
        { name: "Ποσότητα" },
        { name: "% Συμμετοχή" },
        { name: "Μονάδα Δόσης" },
        { name: "Ποσότητα Χρήσης" },
        { name: "Σχόλια" },
        { name: "" },
    ];

    let defaultRamaOrActObject = {
        description: "",
        doseQuantity: "",
        insuredParticipationPercentage: "",
        doseUnit: "",
        useQuantity: "",
        comments: "",
    };

    const effectiveTimeArray = [
        {
            id: 0,
            value: 0,
            unit: "",
        },
        {
            id: 1,
            value: 1,
            unit: "d",
        },
        {
            id: 2,
            value: 12,
            unit: "h",
        },
        {
            id: 3,
            value: 8,
            unit: "h",
        },
        {
            id: 4,
            value: 6,
            unit: "h",
        },
        {
            id: 5,
            value: 1,
            unit: "wk",
        },
        {
            id: 6,
            value: 4,
            unit: "d",
        },
        {
            id: 7,
            value: 2,
            unit: "d",
        },
        {
            id: 8,
            value: 1,
            unit: "once",
        },
        {
            id: 9,
            value: 1,
            unit: "pain",
        },
        {
            id: 10,
            value: 2,
            unit: "wk",
        },
        {
            id: 11,
            value: 1,
            unit: "dyspnea",
        },
    ];

    useEffect(() => {
        let patientData = tabItem.patientData;
        let activeInsurances = tabItem.patientData.activeInsurances.activeInsurance;
        let activeInsurance = activeInsurances[patientData.selectedActiveInsurance];
        if (activeInsurance.socialInsurance.isPronoia === true) {
            setParticipationPercent([
                { name: 0, id: 1 },
                ...participationPercentages.participationPercentages.slice(4, 32),
            ]);
        } else if (addPrescriptionState.noPartitionCaseId !== "0") {
            let found = noParticipationCases.list.find(
                (noPartCase) => noPartCase.id.toString() === addPrescriptionState.noPartitionCaseId
            );
            if (found !== undefined && found.filterMedicine === false) {
                setParticipationPercent(
                    participationPercentages.participationPercentages.slice(0, 1)
                );
            } else {
                setParticipationPercent(
                    participationPercentages.participationPercentages.slice(0, 3)
                );
            }
        } else if (addPrescriptionState.beneficialEKAS === true) {
            setParticipationPercent(participationPercentages.participationPercentages.slice(0, 1));
        } else if (tabItem.patientData.ekasLoss) {
            setParticipationPercent(participationPercentages.participationPercentages.slice(0, 1));
            setAddPrescriptionState({ ...addPrescriptionState, beneficialEKAS: true });
        } else {
            setParticipationPercent(participationPercentages.participationPercentages.slice(0, 3));
        }
    }, [
        addPrescriptionState,
        participationPercentages.participationPercentages,
        tabItem.patientData,
        noParticipationCases.list,
        setAddPrescriptionState,
    ]);

    const handleGalenicQuantityChange = (e) => {
        let value = e.target.value;
        if (value.includes(".") || value.includes(",")) {
            value = Math.round(value);
        }
        if (value <= 0 && value.length !== 0) {
            setMustBePositive(true);
        } else {
            setMustBePositive(false);
        }
        let medicines = addPrescriptionState.medicines;
        let medicine = addPrescriptionState.medicines[index];
        medicine.request.galenicQuantity = value !== "" ? parseInt(value) : "";
        medicines[index] = medicine;
        setAddPrescriptionState({ ...addPrescriptionState, medicines: medicines });
    };

    const handleMedicineInsuredParticipationRateChange = (e) => {
        e.preventDefault();
        let medicines = addPrescriptionState.medicines;
        let medicine = addPrescriptionState.medicines[index];
        medicine.request.insuredParticipationRate = parseInt(e.target.value);
        medicines[index] = medicine;
        setAddPrescriptionState({ ...addPrescriptionState, medicines: medicines });
    };

    const handleMedicineDoseQuantityChange = (e) => {
        e.preventDefault();
        let medicines = addPrescriptionState.medicines;
        let medicine = addPrescriptionState.medicines[index];
        medicine.request.doseQuantity.value = e.target.value;
        medicines[index] = medicine;
        setAddPrescriptionState({ ...addPrescriptionState, medicines: medicines });
    };

    const handleMedicineRateQuantityChange = (e) => {
        e.preventDefault();
        let medicines = addPrescriptionState.medicines;
        let medicine = addPrescriptionState.medicines[index];
        medicine.request.rateQuantity.value = parseInt(e.target.value);
        medicines[index] = medicine;
        setAddPrescriptionState({ ...addPrescriptionState, medicines: medicines });
    };

    const handleMedicineTextChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let medicines = addPrescriptionState.medicines;
        let medicine = addPrescriptionState.medicines[index];
        if (name === "doseUnitName") {
            medicine.request.doseQuantity.unit = value.toString();
        } else {
            medicine.request[name] = value.toString();
        }
        medicines[index] = medicine;

        setAddPrescriptionState({ ...addPrescriptionState, medicines: medicines });
    };

    const [effectiveTimeError, setEffectiveTimeError] = useState("");
    const handleMedicineEffectiveTimeChange = (e) => {
        e.preventDefault();
        let medicines = addPrescriptionState.medicines;
        let medicine = addPrescriptionState.medicines[index];
        let id = parseInt(e.target.value);
        if (id >= effectiveTimeArray.length) {
            setEffectiveTimeError(
                "Αυτή η επιλογή δεν υποστηρίζεται από το MediPlus σας Παρακαλώ επικοινωνήστε μαζί μας"
            );
            return;
        }
        setEffectiveTimeError("");
        medicine.request.effectiveTime = effectiveTimeArray[id];
        medicines[index] = medicine;
        setAddPrescriptionState({ ...addPrescriptionState, medicines: medicines });
    };

    const onSelect = (selectedList, removedItem) => {
        let medicines = addPrescriptionState.medicines;
        let medicine = addPrescriptionState.medicines[index];
        medicine.request.diagnoses = selectedList;
        medicines[index] = medicine;
        setAddPrescriptionState({ ...addPrescriptionState, medicines: medicines });
    };

    const onRemove = (selectedList, removedItem) => {
        let medicines = addPrescriptionState.medicines;
        let medicine = addPrescriptionState.medicines[index];
        medicine.request.diagnoses = selectedList;
        medicines[index] = medicine;
        setAddPrescriptionState({ ...addPrescriptionState, medicines: medicines });
    };

    const deleteMedicine = () => {
        let newInsertedMedicines = addPrescriptionState.medicines.filter((medicine, idx) => {
            return index !== idx;
        });

        setAddPrescriptionState({
            ...addPrescriptionState,
            medicines: newInsertedMedicines,
        });
    };

    const deleteIngredient = (type, id) => {
        let newInsertedIngredients = addPrescriptionState.medicines[index].request[type].filter(
            (element, idx) => {
                return id !== idx;
            }
        );

        let meds = addPrescriptionState.medicines;
        meds[index].request[type] = newInsertedIngredients;

        setAddPrescriptionState({ ...addPrescriptionState, medicines: [...meds] });
    };

    const addIngredients = () => {
        let galenicMedicine = addPrescriptionState.medicines[index].request;
        errors = [];
        if (galenicMedicine.displayName.replace(/ /g, "").length === 0) {
            errors.push("Ονομασία");
        }
        if (galenicMedicine.pharmaceuticalForm.replace(/ /g, "").length === 0) {
            errors.push("Φαρμ/κή Μορφή");
        }
        if (
            galenicMedicine.insuredParticipationRate.length === 0 ||
            galenicMedicine.insuredParticipationRate === -1
        ) {
            errors.push("% Συμμετοχή");
        }
        if (galenicMedicine.doseQuantity.value === 0) {
            errors.push("Ποσό Δόσης");
        }
        if (galenicMedicine.doseQuantity.unit.toString().replace(/ /g, "").length === 0) {
            errors.push("Μονάδα Δόσης");
        }
        if (galenicMedicine.effectiveTime.id === 0) {
            errors.push("Συχνότητα");
        }
        if (galenicMedicine.rateQuantity.value === 0) {
            errors.push("Ημέρες");
        }
        if (
            galenicMedicine.galenicQuantity > 0 &&
            galenicMedicine.galenicMeasurementUnit.replace(/ /g, "").length === 0
        ) {
            errors.push("Μονάδα Μέτρησης");
        }

        if (errors.length !== 0) {
            setErrorsList(errors);
            setAddIngredientsErrorPopUp(true);
            setOpenIngredientsPopUp(false);
        } else {
            setOpenIngredientsPopUp(true);
        }
    };

    const closeIngredientsPopUp = () => {
        let flag = checkIfDataIsValid();
        if (flag) {
            setOpenIngredientsPopUp(false);
        } else {
            setAddIngredientsErrorPopUp(true);
        }
    };

    const checkIfDataIsValid = () => {
        let acts = addPrescriptionState.medicines[index].request.acts;
        let medis = addPrescriptionState.medicines[index].request.medicines;
        let ramas = addPrescriptionState.medicines[index].request.ramas;

        let joinedArray = [...acts, ...ramas];
        let checkErrors = [];
        for (let i = 0; i < joinedArray.length; i++) {
            let item = joinedArray[i];
            if (item.description.replace(/ /g, "").length === 0) {
                checkErrors.push("Δραστική Ουσία/ Έκδοχο");
            }

            if (
                item.insuredParticipationPercentage === "" ||
                item.insuredParticipationPercentage === -1
            ) {
                checkErrors.push("Ποσοστό Συμμετοχής");
            }

            if (item.doseQuantity === "" || item.doseQuantity === 0) {
                checkErrors.push("Ποσότητα Χρήσης");
            }
        }

        for (let j = 0; j < medis.length; j++) {
            let item = medis[j].request;
            if (item.quantity === 0 || item.quantity === "0" || item.quantity === -1) {
                checkErrors.push("Ποσότητα");
            }

            if (
                item.doseQuantity.value === "" ||
                item.doseQuantity.value === 0 ||
                item.doseQuantity.value === "0"
            ) {
                checkErrors.push("Ποσότητα Χρήσης");
            }
        }

        if (checkErrors.length > 0) {
            setErrorsList(checkErrors);
            return false;
        } else {
            return true;
        }
    };

    const addMedicine = () => {
        let flag = checkIfDataIsValid();
        if (flag) {
            setGalenicIndex(index);
            setOpenPopUp(true);
        } else {
            setAddIngredientsErrorPopUp(true);
        }
    };

    const addRama = () => {
        const rama = defaultRamaOrActObject;

        let flag = checkIfDataIsValid();
        if (flag) {
            let meds = addPrescriptionState.medicines;
            meds[index].request.ramas = [...meds[index].request.ramas, rama];

            setAddPrescriptionState({ ...addPrescriptionState, medicines: [...meds] });
        } else {
            setAddIngredientsErrorPopUp(true);
        }
    };

    const addActiveSubstance = () => {
        const act = defaultRamaOrActObject;

        let flag = checkIfDataIsValid();
        if (flag) {
            let meds = addPrescriptionState.medicines;
            meds[index].request.acts = [...meds[index].request.acts, act];

            setAddPrescriptionState({ ...addPrescriptionState, medicines: [...meds] });
        } else {
            setAddIngredientsErrorPopUp(true);
        }
    };

    return (
        <div>
            <div className="mb-3">
                <div className="d-flex justify-content-between">
                    <div>
                        <div id="name">
                            <p className="infoP">*Ονομασία</p>
                            <input
                                type="text"
                                className="form-control"
                                value={
                                    addPrescriptionState.medicines[index].request.displayName
                                        ? addPrescriptionState.medicines[index].request.displayName
                                        : ""
                                }
                                onChange={(e) => handleMedicineTextChange(e)}
                                name="displayName"
                            />
                        </div>
                        <div id="pharm-form">
                            <p className="infoP">*Φαρμ/κή Μορφή</p>
                            <input
                                type="text"
                                className="form-control"
                                name="pharmaceuticalForm"
                                value={
                                    addPrescriptionState.medicines[index].request.pharmaceuticalForm
                                }
                                onChange={(e) => handleMedicineTextChange(e)}
                            />
                        </div>
                    </div>

                    <div>
                        <div id="total-amount">
                            <p className="infoP">Συνολική Ποσότητα</p>
                            <input
                                type="number"
                                step="1"
                                min="0"
                                className="form-control"
                                value={
                                    addPrescriptionState.medicines[index].request.galenicQuantity
                                }
                                onChange={handleGalenicQuantityChange}
                            />
                        </div>

                        <div id="measurement-unit">
                            <p className="infoP">
                                {addPrescriptionState.medicines[index].request.galenicQuantity > 0
                                    ? "* "
                                    : ""}
                                Μονάδα Μέτρησης
                            </p>
                            <input
                                type="text"
                                className="form-control"
                                name="galenicMeasurementUnit"
                                value={
                                    addPrescriptionState.medicines[index].request
                                        .galenicMeasurementUnit
                                }
                                onChange={(e) => handleMedicineTextChange(e)}
                            />
                        </div>
                    </div>

                    <div id="participation">
                        <p className="infoP">* % Συμμετοχή</p>
                        <select
                            className="form-control"
                            onChange={(e) => handleMedicineInsuredParticipationRateChange(e)}
                            value={
                                addPrescriptionState.medicines[index].request
                                    .insuredParticipationRate
                            }
                        >
                            <option value={-1}>{""}</option>
                            {participationPercent.map((participationPercentage) => {
                                return (
                                    <option
                                        key={participationPercentage.id}
                                        value={participationPercentage.name}
                                    >
                                        {participationPercentage.name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>

                    <div>
                        <div>
                            <p className="infoP">* Ποσό Δόσης</p>
                            <select
                                className="form-control"
                                onChange={(e) => handleMedicineDoseQuantityChange(e)}
                                value={
                                    addPrescriptionState.medicines[index].request.doseQuantity.value
                                }
                            >
                                <option value={"0"}>{""}</option>;
                                {doseUnits.doseUnits.map((doseUnit, index) => {
                                    return (
                                        <option key={index} value={doseUnit.toString()}>
                                            {doseUnit}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>

                        <div id="done-unit">
                            <p className="infoP">Μονάδα Δόσης</p>
                            <input
                                type="text"
                                className="form-control"
                                name="doseUnitName"
                                value={
                                    addPrescriptionState.medicines[index].request.doseQuantity.unit
                                }
                                onChange={(e) => handleMedicineTextChange(e)}
                            />
                        </div>
                    </div>

                    <div>
                        <div id="frequency">
                            <p className="infoP">* Συχνότητα</p>
                            <select
                                className="form-control"
                                onChange={(e) => handleMedicineEffectiveTimeChange(e)}
                                value={
                                    addPrescriptionState.medicines[index].request.effectiveTime.id
                                }
                            >
                                <option value={0}>{""}</option>;
                                {medicineFrequencies.medicineFrequencies
                                    .filter((medicineFrequency) => medicineFrequency.id !== 0)
                                    .map((medicineFrequency) => {
                                        return (
                                            <option
                                                key={medicineFrequency.id}
                                                value={medicineFrequency.id}
                                            >
                                                {medicineFrequency.name}
                                            </option>
                                        );
                                    })}
                            </select>
                            {/* <div style={{ width: "200px" }}>
                        {effectiveTimeError !== "" && effectiveTimeError}
                    </div> */}
                        </div>
                        <div>
                            <p className="infoP">* Ημέρες</p>
                            <select
                                className="form-control"
                                onChange={(e) => handleMedicineRateQuantityChange(e)}
                                value={
                                    addPrescriptionState.medicines[index].request.rateQuantity.value
                                }
                            >
                                <option value={0}>{""}</option>
                                <option value={1}>1 ημέρα</option>
                                {[
                                    ...Array(
                                        addPrescriptionState.twoMonthRecipe === false ? 29 : 59
                                    ),
                                ].map((elementInArray, index) => (
                                    <option key={index} value={index + 2}>
                                        {index + 2} ημέρες
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div>
                        <div>
                            <p className="infoP">Διαγνώσεις</p>
                            {/* <select>
                        <option value={0}>{"Όλες"}</option>;
                    </select> */}
                            <div id="multiselect">
                                <Multiselect
                                    options={addPrescriptionState.icd10s}
                                    selectedValues={
                                        addPrescriptionState.medicines[index].request.diagnoses
                                    }
                                    displayValue="code"
                                    onSelect={onSelect}
                                    onRemove={onRemove}
                                    placeholder={"Διαγνώσεις"}
                                    id={medicine.barcode}
                                    style={{
                                        multiselectContainer: {
                                            color: "black",
                                        },
                                        option: {
                                            backgroundColor: "white",
                                            color: "#13335a",
                                        },
                                        chips: {
                                            backgroundColor: "#13335a",
                                            color: "white",
                                            height: "17px",
                                        },
                                    }}
                                    emptyRecordMsg={"Δεν υπάρχουν άλλες διαγνώσεις"}
                                />
                            </div>

                            {/* <div>
                        <a
                            href="#/"
                            style={{ color: "green" }}
                            // onClick={() =>
                            //     prescriptionHistory(addPrescriptionState.medicines[index].barcode)
                            // }
                        >
                            Εκτελεσμένες/Εκκρεμείς συνταγές
                        </a>
                    </div> */}
                        </div>

                        <div id="commentSection">
                            <p className="infoP">Σχόλια</p>
                            <input
                                type="text"
                                className="form-control"
                                value={addPrescriptionState.medicines[index].request.comment}
                                name="comment"
                                onChange={(e) => handleMedicineTextChange(e)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex" style={{ alignItems: "baseline" }}>
                <div onClick={() => deleteMedicine()}>
                    <a href="#/" style={{ color: "blue" }}>
                        Διαγραφή
                    </a>
                </div>

                <div>
                    <button className="btn buttonPrimary" onClick={addIngredients}>
                        Καταχώρηση Συστατικών
                    </button>
                </div>
            </div>
            <PopUp
                openPopUp={openIngredientsPopUp}
                setOpenPopUp={setOpenIngredientsPopUp}
                title={"Συστατικά Γαληνικού Σκευάσματος"}
                maxWidth="lg"
                showCloseButton={false}
            >
                <div>
                    <div className="d-flex justify-content-between">
                        <p>ΓΑΛΗΝΙΚΟ ΣΚΕΥΑΣΜΑ</p>
                        <SimpleMenu
                            name={"Προσθήκη"}
                            menuItems={[
                                { name: "Προσθήκη Φαρμάκου", onClick: addMedicine },
                                { name: "Προσθήκη Εκδόχου", onClick: addRama },
                                { name: "Προσθήκη Δρ. Ουσίας", onClick: addActiveSubstance },
                            ]}
                        />
                    </div>
                    <TableContainer component={Paper} id="table" className="mt-3 mb-3">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {columns.map((column, index) => (
                                        <TableCell key={index}>{column.name}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            {addPrescriptionState.medicines[index].request.medicines.length === 0 &&
                            addPrescriptionState.medicines[index].request.ramas.length === 0 &&
                            addPrescriptionState.medicines[index].request.acts.length === 0 ? (
                                <TableBody>
                                    <TableRow>
                                        <TableCell>No data to display</TableCell>
                                    </TableRow>
                                </TableBody>
                            ) : (
                                <TableBody>
                                    {addPrescriptionState.medicines[index].request.medicines.map(
                                        (med, mediIdx) => (
                                            <ActOrRamaTable
                                                addPrescriptionState={addPrescriptionState}
                                                setAddPrescriptionState={setAddPrescriptionState}
                                                index={index}
                                                item={med}
                                                idx={mediIdx}
                                                deleteIngredient={deleteIngredient}
                                                type={"medicines"}
                                                key={mediIdx}
                                            />
                                        )
                                    )}

                                    {addPrescriptionState.medicines[index].request.acts.map(
                                        (act, actIdx) => (
                                            <ActOrRamaTable
                                                addPrescriptionState={addPrescriptionState}
                                                setAddPrescriptionState={setAddPrescriptionState}
                                                index={index}
                                                item={act}
                                                idx={actIdx}
                                                deleteIngredient={deleteIngredient}
                                                type={"acts"}
                                                key={actIdx}
                                            />
                                        )
                                    )}
                                    {addPrescriptionState.medicines[index].request.ramas.map(
                                        (rama, ramaIdx) => (
                                            <ActOrRamaTable
                                                addPrescriptionState={addPrescriptionState}
                                                setAddPrescriptionState={setAddPrescriptionState}
                                                index={index}
                                                item={rama}
                                                idx={ramaIdx}
                                                deleteIngredient={deleteIngredient}
                                                type={"ramas"}
                                                key={ramaIdx}
                                            />
                                        )
                                    )}
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <button className="btn buttonPrimary" onClick={closeIngredientsPopUp}>
                            OK
                        </button>
                    </div>
                </div>
            </PopUp>
            <PopUp
                openPopUp={addIngredientsErrorPopUp}
                setOpenPopUp={setAddIngredientsErrorPopUp}
                title={"Σφάλμα"}
                maxWidth="md"
            >
                <div>
                    <p>
                        Παρακάτω εμφανίζονται σε λίστα τα μηνύματα για αυτή τη σελίδα. Πρέπει να
                        δώσετε μία τιμή στα παρακάτω πεδία
                    </p>
                    <ul>
                        {errorsList.map((error, index) => {
                            return <li key={index}>{error}</li>;
                        })}
                    </ul>
                </div>
            </PopUp>
            <PopUp
                openPopUp={mustBePositive}
                setOpenPopUp={setMustBePositive}
                title={"Σφάλμα"}
                maxWidth="md"
            >
                <div>
                    <p>Πρέπει να είναι θετικός</p>
                </div>
            </PopUp>
        </div>
    );
}

export default GalenicMedicine;

function ActOrRamaTable({
    addPrescriptionState,
    setAddPrescriptionState,
    index,
    item,
    idx,
    type,
    deleteIngredient,
}) {
    const [activeSubstance, setActiveSubstance] = useState("");

    useState(() => {
        if (type === "medicines") {
            if (item.activeSubstanceName) {
                setActiveSubstance(item.activeSubstanceName);
            } else {
                if (item.activeSubstances.activeSubstance.length === undefined) {
                    setActiveSubstance(
                        item.activeSubstances.activeSubstance.activeSubstance.description
                    );
                } else {
                    for (let sub of item.activeSubstances.activeSubstance) {
                        if (sub.mainActiveSubstance === true) {
                            setActiveSubstance(sub.activeSubstance.description);
                        }
                    }
                }
            }

            let medicines = addPrescriptionState.medicines;
            medicines[index].request.medicines[idx].request.insuredParticipationRate = parseInt(
                medicines[index].request.insuredParticipationRate
            );
        }
    }, []);

    const onFieldChange = (e) => {
        const { name, value } = e.target;
        let meds = addPrescriptionState.medicines;
        meds[index].request[type][idx][name] =
            name === "insuredParticipationPercentage" ? parseInt(value) : value;
        setAddPrescriptionState({ ...addPrescriptionState, medicines: [...meds] });
    };

    const onUseQuantityChange = (e) => {
        const { name, value } = e.target;
        if (value.includes(".") || value.includes(",")) {
            value = Math.round(value);
        }
        let meds = addPrescriptionState.medicines;
        if (type === "medicines") {
            meds[index].request[type][idx].request[name].value = parseInt(value);
        } else {
            meds[index].request[type][idx][name] = parseInt(value);
        }

        setAddPrescriptionState({ ...addPrescriptionState, medicines: [...meds] });
    };

    const onDoseUnitChange = (e) => {
        const { name, value } = e.target;
        let meds = addPrescriptionState.medicines;
        if (type === "medicines") {
            meds[index].request[type][idx].request[name].unit = value;
        } else {
            meds[index].request[type][idx][name] = value;
        }
        setAddPrescriptionState({ ...addPrescriptionState, medicines: [...meds] });
    };

    const onQuantityChange = (e) => {
        const { name, value } = e.target;
        let medicines = addPrescriptionState.medicines;
        medicines[index].request.medicines[idx].request.quantity = parseInt(value);
        setAddPrescriptionState({ ...addPrescriptionState, medicines: [...medicines] });
    };

    const handleMedicineGenericChange = () => {
        let medicines = addPrescriptionState.medicines;
        medicines[index].request.medicines[idx].request.genericMedicine =
            !medicines[index].request.medicines[idx].request.genericMedicine;
        setAddPrescriptionState({ ...addPrescriptionState, medicines: [...medicines] });
    };

    return (
        <TableRow key={idx}>
            <TableCell>
                {type === "ramas" ? "Έκδοχο" : type === "acts" ? "Δραστική Ουσία" : "Φάρμακο"}
            </TableCell>
            <TableCell>
                {type === "medicines" ? (
                    <div>
                        <div>{activeSubstance}</div>
                        <div>
                            {item.form ? (
                                <>{`${item.form}`}</>
                            ) : (
                                <>{`${item.content}  ${item.packageForm}`}</>
                            )}
                        </div>
                        {!addPrescriptionState.prescriptionBasedOnCommercialNameId && (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={
                                            addPrescriptionState.medicines[index].request.medicines[
                                                idx
                                            ].inClusterWithGeneric
                                                ? addPrescriptionState.medicines[index].request
                                                      .medicines[idx].request.genericMedicine
                                                : false
                                        }
                                        onChange={() => handleMedicineGenericChange()}
                                        name="monthlyRecipe"
                                        color="primary"
                                        disabled={
                                            !addPrescriptionState.medicines[index].request
                                                .medicines[idx].inClusterWithGeneric
                                        }
                                    />
                                }
                                label="Προτείνεται η χορήγηση γενόσημου σκευάσματος"
                            />
                        )}
                    </div>
                ) : (
                    <input
                        type="text"
                        className="form-control"
                        name="description"
                        onChange={(e) => onFieldChange(e)}
                        value={item.description}
                    />
                )}
            </TableCell>
            <TableCell>
                {type === "medicines" ? (
                    <select
                        className="form-control"
                        name="quantity"
                        onChange={(e) => onQuantityChange(e)}
                        value={
                            addPrescriptionState.medicines[index].request.medicines[idx].request
                                .quantity
                        }
                    >
                        <option value={"-1"}>{""}</option>;<option value={"1"}>{"1"}</option>;
                        <option value={"2"}>{"2"}</option>;
                    </select>
                ) : (
                    <></>
                )}
            </TableCell>
            <TableCell>
                {type === "medicines" ? (
                    <>{addPrescriptionState.medicines[index].request.insuredParticipationRate}</>
                ) : (
                    <select
                        className="form-control"
                        name="insuredParticipationPercentage"
                        onChange={(e) => onFieldChange(e)}
                        value={item.insuredParticipationPercentage}
                    >
                        <option value={-1}>{""}</option>
                        <option
                            value={
                                addPrescriptionState.medicines[index].request
                                    .insuredParticipationRate
                            }
                        >{`${addPrescriptionState.medicines[index].request.insuredParticipationRate}`}</option>
                        <option value={100}>100</option>
                    </select>
                )}
            </TableCell>
            <TableCell>
                <input
                    type="text"
                    className="form-control"
                    name="doseUnit"
                    onChange={(e) => onDoseUnitChange(e)}
                    value={type !== "medicines" ? item.doseUnit : item.request.doseQuantity.unit}
                />
            </TableCell>
            <TableCell>
                <input
                    type="number"
                    min="0"
                    className="form-control"
                    name="doseQuantity"
                    value={
                        type !== "medicines" ? item.doseQuantity : item.request.doseQuantity.value
                    }
                    onChange={(e) => onUseQuantityChange(e)}
                />
            </TableCell>
            <TableCell>
                <input
                    type="text"
                    className="form-control"
                    name="comments"
                    onChange={(e) => onFieldChange(e)}
                    value={item.comments}
                />
            </TableCell>
            <TableCell>
                <a href="#/" style={{ color: "blue" }} onClick={() => deleteIngredient(type, idx)}>
                    Διαγραφή
                </a>
            </TableCell>
        </TableRow>
    );
}
