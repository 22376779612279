import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "@material-ui/core";
import { mainTabs } from "./config.js";
import GeneralData from "./GeneralData/GeneralData.js";
import References from "./References/References.js";
import Prescriptions from "./Prescriptions/Prescriptions.js";
import Referrals from "./Referrals/Referrals.js";
import PrescriptionsToBeRegistered from "./PrescriptionsToBeRegistered/PrescriptionsToBeRegistered.js";
import ReferralsToBeRegistered from "./ReferralsToBeRegistered/ReferralsToBeRegistered.js";
import IdikaVisitService from "../../../../../services/MediPlus/Idika/IdikaVisitService.js";

function VisitListingDetails({ patientData, tabItem, setTabItem, setSelectedTabBttn }) {
    const [selectedTabVisit, setSelectedTab] = useState(0);
    const handleTabs = (e, val) => {
        setSelectedTab(val);
    };

    const [prescriptions, setPrescriptions] = useState(tabItem.visitDetails.prescriptions);
    const [referrals, setReferrals] = useState(tabItem.visitDetails.referrals);
    const [prescriptionsToBeRegistered, setPrescriptionsToBeRegistered] = useState(
        tabItem.visitDetails.prescriptionsToBeRegistered
    );
    const [referralsToBeRegistered, setReferralsToBeRegistered] = useState(
        tabItem.visitDetails.referralsToBeRegistered
    );

    useEffect(() => {
        setPrescriptions(tabItem.visitDetails.prescriptions);
        setReferrals(tabItem.visitDetails.referrals);
        setPrescriptionsToBeRegistered(tabItem.visitDetails.prescriptionsToBeRegistered);
        setReferralsToBeRegistered(tabItem.visitDetails.referralsToBeRegistered);
    }, [tabItem]);

    useEffect(() => {
        if (tabItem.visitDetails.active === false) return;
        IdikaVisitService.getActiveVisit()
            .then(({ data }) => {
                setPrescriptions(data.visitDetails.prescriptions);
                setReferrals(data.visitDetails.referrals);
                let newVisitDetails = { ...tabItem.visitDetails, ...data.visitDetails };
                setTabItem({ ...tabItem, visitDetails: newVisitDetails });
            })
            .catch((error) => {
                console.log(error);
                console.log(error.response);
            });
        // eslint-disable-next-line
    }, []);

    const showContent = () => {
        if (selectedTabVisit === 0)
            return (
                <GeneralData
                    patientData={patientData}
                    prescriptions={prescriptions}
                    referrals={referrals}
                    prescriptionsToBeRegistered={prescriptionsToBeRegistered}
                    referralsToBeRegistered={referralsToBeRegistered}
                />
            );
        else if (selectedTabVisit === 1)
            return (
                <Prescriptions
                    prescriptions={prescriptions}
                    tabItem={tabItem}
                    setTabItem={setTabItem}
                    setSelectedTabBttn={setSelectedTabBttn}
                />
            );
        else if (selectedTabVisit === 2)
            return (
                <Referrals
                    referrals={referrals}
                    tabItem={tabItem}
                    setTabItem={setTabItem}
                    setSelectedTabBttn={setSelectedTabBttn}
                />
            );
        else if (selectedTabVisit === 3) return <References />;
        else if (selectedTabVisit === 4)
            return (
                <PrescriptionsToBeRegistered
                    prescriptionsToBeRegistered={prescriptionsToBeRegistered}
                    setPrescriptionsToBeRegistered={setPrescriptionsToBeRegistered}
                    tabItem={tabItem}
                    setTabItem={setTabItem}
                />
            );
        else if (selectedTabVisit === 5)
            return (
                <ReferralsToBeRegistered
                    referralsToBeRegistered={referralsToBeRegistered}
                    setReferralsToBeRegistered={setReferralsToBeRegistered}
                    tabItem={tabItem}
                    setTabItem={setTabItem}
                />
            );
        else return null;
    };

    return (
        <div className="section">
            <div className="boxTitle">
                <h6>Στοιχεία Καταχωρήσεων Επίσκεψης</h6>
            </div>
            <div className="boxContent">
                <Tabs
                    value={selectedTabVisit}
                    onChange={handleTabs}
                    indicatorColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {mainTabs.map((tab, index) => {
                        return <Tab key={index} {...tab} />;
                    })}
                </Tabs>
                {showContent()}
            </div>
        </div>
    );
}

export default VisitListingDetails;
