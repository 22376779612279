import React, { useState, useEffect } from "react";
import MediPlusFavoriteExamPackageService from "../../../../../../../../services/MediPlus/MediPlusFavoriteExamPackageService";
import { mainColumns } from "./config.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import PopUp from "../../../../../../../Utilities/PopUp";
import { formatDate } from "../../../../../../../Utilities/Utilities";

function ShowDetailsContent({ examDetails }) {
    const diagnosisColumns = ["Κωδικός ICD-10", "Διάγνωση"];
    const examinationColumns = ["Εξέταση", "Διάγνωση", "Λόγος παραπομπής", "Σχόλια"];

    const diagnoses = JSON.parse(examDetails.diagnoses);
    const referrals = JSON.parse(examDetails.referrals);

    return (
        <div>
            <div>
                <b>Όνομα: </b> {examDetails.name}
            </div>
            <div>
                <b>Αριθμός Εξετάσεων: </b> {examDetails.numberOfReferrals}
            </div>
            <div>
                <b>Ημ/νία Δημιουργίας: </b> {examDetails.createdDate}
            </div>
            <div>
                <b>Ημ/νία Επεξεργασίας: </b> {examDetails.updatedDate}
            </div>
            <div>
                <b>Τύπος Συνταγής: </b> {examDetails.prescriptionType}
            </div>
            <div>
                <b>Κατηγορία Εξετάσεων: </b> {examDetails.examinationGroup}
            </div>
            <div>
                <b>Λόγος: </b> {examDetails.reason}
            </div>
            <div>
                <b>Σχόλια: </b> {examDetails.comment}
            </div>
            <br />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {diagnosisColumns.map((column, index) => {
                                return (
                                    <TableCell key={index}>
                                        <b>{column}</b>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {diagnoses.map((diagnosis, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell>{diagnosis.code}</TableCell>
                                    <TableCell>{diagnosis.title}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {examinationColumns.map((column, index) => {
                                return (
                                    <TableCell key={index}>
                                        <b>{column}</b>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {referrals.map((referral, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell>{referral.code}</TableCell>
                                    <TableCell>{referral.displayName}</TableCell>
                                    <TableCell>{referral.diagnosisCode}</TableCell>
                                    <TableCell>{referral.comment}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

function FavoriteExams({ tabItem, setTabItem }) {
    const [page, setPage] = useState(1);
    const handlePageChange = (event, value) => {
        setPage(value);
    };
    const [isLoading, setIsLoading] = useState(true);
    const [isFetching, setIsFetching] = useState(true);
    const [isError, setIsError] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        setIsFetching(true);
        MediPlusFavoriteExamPackageService.getFavoriteExamPackagesWithTypeAndGroup({
            page: page - 1,
            size: 5,
        })
            .then(({ data }) => {
                setData(data);
                setIsFetching(false);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsError(true);
            });
    }, [page]);

    const [examDetails, setExamDetails] = useState({});
    const [popUp, setPopUp] = useState(false);
    const onShowDetails = (exam) => {
        setExamDetails(exam);
        setPopUp(true);
    };

    const addReferral = (exam) => {
        exam.type = "favorite";
        setTabItem({
            ...tabItem,
            visitDetails: {
                ...tabItem.visitDetails,
                referralsToBeRegistered: [...tabItem.visitDetails.referralsToBeRegistered, exam],
            },
        });
    };

    const removeReferral = (exam) => {
        let newReferrals = tabItem.visitDetails.referralsToBeRegistered.filter(
            (alreadyAddedExam) =>
                alreadyAddedExam.type === undefined ||
                (alreadyAddedExam.type === "favorite" && alreadyAddedExam.id !== exam.id)
        );
        setTabItem({
            ...tabItem,
            visitDetails: {
                ...tabItem.visitDetails,
                referralsToBeRegistered: newReferrals,
            },
        });
    };

    const checkIfThePackageIsAlreadyAdded = (exam) => {
        let found = tabItem.visitDetails.referralsToBeRegistered.find(
            (alreadyAddedExam) =>
                alreadyAddedExam.type === "favorite" && alreadyAddedExam.id === exam.id
        );
        if (found === undefined) return false;
        return true;
    };

    return (
        <div>
            <TableContainer component={Paper}>
                {isLoading ? (
                    <CircularProgress className="m-2" />
                ) : isError ? (
                    <Alert severity="error">Κάτι πήγε στραβά!</Alert>
                ) : (
                    <Table>
                        <TableHead>
                            <TableRow>
                                {mainColumns.map((column, index) => {
                                    return (
                                        <TableCell key={index}>
                                            <b>{column.name}</b>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.content.map((exam, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{exam.name}</TableCell>
                                        <TableCell>{exam.numberOfReferrals}</TableCell>
                                        <TableCell>{formatDate(exam.createdDate)}</TableCell>
                                        <TableCell>
                                            {checkIfThePackageIsAlreadyAdded(exam) === false ? (
                                                <button
                                                    className="btn buttonPrimary small-padding"
                                                    onClick={() => addReferral(exam)}
                                                >
                                                    <i className="fa fa-plus"></i>
                                                    Προσθήκη
                                                </button>
                                            ) : (
                                                <button
                                                    className="btn buttonDanger small-padding"
                                                    onClick={() => removeReferral(exam)}
                                                >
                                                    <i className="fa fa-trash"></i>
                                                    Αφαίρεση
                                                </button>
                                            )}
                                            <button
                                                className="btn buttonPrimary small-padding"
                                                onClick={() => onShowDetails(exam)}
                                            >
                                                <i className="fa fa-search"></i>
                                                Λεπτομέρειες
                                            </button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                )}
                {data !== null && (
                    <div className="form-inline">
                        <Pagination
                            count={data !== null ? data.totalPages : 0}
                            page={page}
                            onChange={handlePageChange}
                        />
                        {isFetching ? <span> Φόρτωση...</span> : null}
                    </div>
                )}
            </TableContainer>
            <PopUp
                openPopUp={popUp}
                setOpenPopUp={setPopUp}
                showCloseButton={true}
                title={"Λεπτομέρειες Αγαπημένου Πακέτου Εξετάσεων"}
            >
                <ShowDetailsContent examDetails={examDetails} />
            </PopUp>
        </div>
    );
}

export default FavoriteExams;
