const mainColumns = [
    { name: "Αριθμός Επίσκεψης" },
    { name: "" },
    { name: "Α.Μ.Κ.Α./E.K.A.A." },
    { name: "Επώνυμο" },
    { name: "Όνομα" },
    { name: "Φορέας Κοινωνικής Ασφάλισης" },
    { name: "Ημ/νία - 'Ωρα Έναρξης" },
    { name: "Ημ/νία - 'Ωρα Λήξης" },
    { name: "Κατάσταση Επίσκεψης" },
    { name: "Χρεωμένες στο Όριο Επισκέψεων" },
    { name: "" },
];

export { mainColumns };
