import { useEffect, useState } from "react";
import { Alert } from "@material-ui/lab";
import { CircularProgress } from "@material-ui/core";
import IdikaVisitService from "../../../../../../../services/MediPlus/Idika/IdikaVisitService";
import { mainTabs } from "./config.js";
import { Tabs, Tab } from "@material-ui/core";
import Referrals from "./Referrals";
import Prescriptions from "./Prescriptions";

function CancelVisitPopUp({ cancelVisitId, visits, setVisits, setCancelPopUp }) {
    const [visit, setVisit] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        IdikaVisitService.getVisit(cancelVisitId)
            .then(({ data }) => {
                // console.log(data);
                setVisit(data);
            })
            .catch((error) => {
                // console.log(error.response);
                setError(error.response);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [cancelVisitId]);

    const [cancelError, setCancelError] = useState({});
    const onCancel = () => {
        setCancelError({});
        IdikaVisitService.cancelVisit(cancelVisitId)
            .then(({ data }) => {
                // console.log(data);
                let visitsArray = visits.visits;
                let visitIndex = visitsArray.findIndex((visits) => visits.id === cancelVisitId);
                visitsArray[visitIndex].status = { id: 4, status: "Ακυρωμένη" };
                setVisits({ ...visits, visits: visitsArray });
                setCancelPopUp(false);
            })
            .catch((error) => {
                // console.log(error.response);
                setCancelError(error.response);
            });
    };

    const [selectedTab, setSelectedTab] = useState(0);
    function handleTabs(e, val) {
        setSelectedTab(val);
    }

    const showContent = () => {
        if (selectedTab === 0)
            return <Prescriptions prescriptions={visit.visitDetails.prescriptions} />;
        else if (selectedTab === 1) return <Referrals referrals={visit.visitDetails.referrals} />;
        else return null;
    };

    return (
        <div>
            {loading ? (
                <div align="center">
                    <CircularProgress />
                </div>
            ) : error ? (
                <Alert severity="error">Κάτι πήγε στραβά!</Alert>
            ) : (
                <div>
                    {Object.keys(cancelError).length !== 0 && (
                        <Alert className="mb-3" severity="error">
                            {cancelError.data.errors.error}
                        </Alert>
                    )}
                    <div className="mb-3">
                        <h6>Στοιχεία Επίσκεψης</h6>
                        <div className="flexBoxInfoWrap">
                            <div className="flexColumn">
                                <div className="flexWrap">
                                    <p className="infoP alignRight">Επώνυμο</p>
                                    <p className="noMargin">{visit.patientData.lastName}</p>
                                </div>
                                <div className="flexWrap">
                                    <p className="infoP alignRight">Όνομα</p>
                                    <p className="noMargin">{visit.patientData.firstName}</p>
                                </div>
                                <div className="flexWrap">
                                    <p className="infoP alignRight">A.M.K.A/E.K.A.A.</p>
                                    <p className="noMargin">{visit.patientData.amka}</p>
                                </div>
                                <div className="flexWrap">
                                    <p className="infoP alignRight">Σχόλια / Παρατηρήσεις</p>
                                    <p className="noMargin">{visit.visitDetails.comment}</p>
                                </div>
                            </div>
                            <div className="flexColumn">
                                <div className="flexWrap">
                                    <p className="infoP alignRight">Εναρξη Επίσκεψης</p>
                                    <p className="noMargin">{visit.visitDetails.startDate}</p>
                                </div>
                                <div className="flexWrap">
                                    <p className="infoP alignRight">Τέλος Επίσκεψης</p>
                                    <p className="noMargin">{visit.visitDetails.endDate}</p>
                                </div>
                                <div className="flexWrap">
                                    <p className="infoP alignRight">Κατάσταση Επίσκεψης </p>
                                    <p className="noMargin">{visit.visitDetails.status}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <h6>Στοιχεία Καταχωρήσεων Επίσκεψης</h6>
                        <Tabs value={selectedTab} onChange={handleTabs}>
                            {mainTabs.map((tab, index) => {
                                return <Tab key={index} {...tab} />;
                            })}
                        </Tabs>
                        {showContent()}
                    </div>
                    <div className="mb-3">
                        <h6>Επιβεβαίωση</h6>
                        <div align="center">
                            <p>Είστε σίγουρος οτι θέλετε να ακυρώσετε την επίσκεψη;</p>
                            <button className="btn buttonPrimary" onClick={() => onCancel()}>
                                Ναι
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default CancelVisitPopUp;
