import axios from "axios";
import { baseUrl, getOptions } from "../../config.js";

class IdikaOthersService {
    constructor(baseUrl) {
        this.basePath = "/idika/others";
        this.baseUrl = `${baseUrl}${this.basePath}`;
    }

    getAllAnnouncements() {
        return axios(getOptions(this.baseUrl, "/announcements/all"));
    }
}

export default new IdikaOthersService(baseUrl);
