const roles = ["ROLE_ADMIN", "ROLE_USER","ROLE_SUPER_ADMIN","ROLE_PH_AGENT"];

export function searchAuthoritiesForRole(authorities, role) {
    for (let authority of authorities) if (authority.authority === role) return role;
    return null;
}

export function findRole(authorities) {
    for (let role of roles) {
        let getRole = searchAuthoritiesForRole(authorities, role);
        if (getRole !== null) return getRole;
    }
    return null;
}

export { roles };
