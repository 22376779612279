const mainInputColumns = [
    { name: "" },
    { name: "barcode" },
    { name: "commercialName" },
    { name: "formCode" },
    { name: "content" },
    { name: "packageForm" },
    { name: "atcCode" },
    { name: "type" },
    { name: "" },
    // { name: "" },
    { name: "" },
    { name: "" },
    { name: "" },
];

const mainColumns = [
    { name: "" },
    { name: "Barcode φαρμάκου" },
    { name: "Εμπορική Ονομασία" },
    { name: "Μορφή" },
    { name: "Περιεκτικότητα" },
    { name: "Συσκευασία" },
    { name: "ATC" },
    { name: "Τύπος(*)" },
    { name: "ΙΦΕΤ" },
    // { name: "Δραστική Ουσία" },
    { name: "Τιμή Λιανικής" },
    { name: "Τιμή Αποζ. Ασφ." },
    { name: "" },
];

const effectiveTimeArray = [
    {
        id: 0,
        value: 0,
        unit: "",
    },
    {
        id: 1,
        value: 1,
        unit: "d",
    },
    {
        id: 2,
        value: 12,
        unit: "h",
    },
    {
        id: 3,
        value: 8,
        unit: "h",
    },
    {
        id: 4,
        value: 6,
        unit: "h",
    },
    {
        id: 5,
        value: 1,
        unit: "wk",
    },
    {
        id: 6,
        value: 4,
        unit: "d",
    },
    {
        id: 7,
        value: 2,
        unit: "d",
    },
    {
        id: 8,
        value: 1,
        unit: "once",
    },
    {
        id: 9,
        value: 1,
        unit: "pain",
    },
    {
        id: 10,
        value: 2,
        unit: "wk",
    },
    {
        id: 11,
        value: 1,
        unit: "dyspnea",
    },
];

const initialStateForCheckBoxes = {
    limitedExecution: false,
    limitedExecutionUnitId: "",

    highCost: false,
    n3816: false,
    executedAtIka: false,
    drug: false,
    notInPharCost: false,

    eopyyPreapproval: false,
    ifet: false,
    desensitizationVaccine: false,
    negativeList: false,
    isAntibiotic: false,

    galenicPrescription: false,
    isConsumable: false,
};

const medicineHistoryTblColumns = ["Εμπορική Ονομασία","Ημ/νία Έκδοσης Συνταγής","Ημ/νία Έναρξης Ισχύος Συνταγής","Ημ/νία Λήξης Ισχύος Συνταγής",
                                    "Ημ/νία Έκτέλεσης Συνταγής" ,"Συνταγογραφημένη Ποσότητα","Εκτελεσμένη Ποσότητα" ,"Υπέρβαση Κανόνα" ];

export { mainInputColumns, mainColumns, effectiveTimeArray, initialStateForCheckBoxes, medicineHistoryTblColumns};
