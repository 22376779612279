const mainColumns = [
    { name: "Αριθμός Παραπεμπτικού" },
    { name: "Ημ/νια  Έκδοσης" },
    { name: "Κατηγορία Εξετάσεων" },
    { name: "Κατάσταση" },
    { name: "" },
];

const diagnosisColumns = [{ name: "Κωδικός ICD-10" }, { name: "Διάγνωση" }];
const diagnosisFields = [{ name: "code" }, { name: "title" }];

const referralColumns = [
    { name: "Εξέταση" },
    { name: "Διάγνωση" },
    { name: "Λόγος παραπομπής" },
    { name: "Σχόλια" },
    { name: "Ημ/νία Εκτέλεσης" },
];

const referralFields = [
    { name: "title" },
    { name: "diagnosisCode" },
    { name: "reason" },
    { name: "comment" },
    { name: "executionDate" },
];

export { mainColumns, diagnosisColumns, referralColumns, diagnosisFields, referralFields };
