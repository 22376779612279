import { columns } from "./config.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useEffect, useRef, useState } from "react";
import PopUp from "../../../Utilities/PopUp.js";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import parser from "html-react-parser";
import MediPlusCustomPrescriptionService from "../../../../services/MediPlus/MediPlusCustomPrescriptionService.js";
import MediPlusImageService from "../../../../services/MediPlus/MediPlusImageService.js";
import { CircularProgress, Grid, Radio } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import MediPlusUserService from "../../../../services/MediPlus/MediPlusUserService.js";
import MediPlusCustomPrescriptionImagePositionService from "../../../../services/MediPlus/MediPlusCustomPrescriptionImagePositionService.js";
import axios from "axios";
import { ckeditorToolbar } from "../../../../config.js";

function LoadingScreen({loading}) {
    return (
        loading 
            ? 
            <div style={{position:"absolute", backgroundColor:"#fff", left:0, top:0, width:"100%", height:"100%", zIndex:1000, opacity: 0.8 }}>
                <CircularProgress style={{ left:"50%", top:"50%", position:"absolute"}} />
            </div>
            :
            null
    );
}

function DeleteLogoImg({image, setImages, setSelectedImagePosition, setLoading, setErrors}) {
    const deleteLogo = () => {
        setErrors(null);
        setLoading(true);

        MediPlusImageService.deleteImage(image.id).then(() => {
            MediPlusImageService.getAllImages().then((resp) => {
                setImages(resp.data);
                setSelectedImagePosition(1);
                setLoading(false);
            }).catch((err) => {
                console.log(err);    
                setLoading(false);
            });
        }).catch((err) => {
            setErrors(err.response);
            setLoading(false);
        });
    };

    let userIdNaN = false;
    try {
        userIdNaN = isNaN(parseInt(image.userId));
    } catch (error) {}
    
    if (userIdNaN) {
        return null;
    } else {
        return (
            <div
                style={{
                    position: "relative",
                    right: 0
                }}                                                
            >
                <button
                    style={{
                        border: 0,
                        position: "absolute",
                        
                        backgroundColor: "transparent"
                    }}    
                >
                    <img 
                        onClick={deleteLogo}
                        src="/cancel.png"
                        alt="Delete logo"
                        title="Delete logo"
                        border="0"
                        height="18"
                    />
                </button>
            </div>
        );            
    }
    
}

function UploadImageBtn({images, setImages, setLoading, setErrors}) {
    const fileInputRef = useRef();

    const uploadImage = (event) => {
        event.preventDefault();
        fileInputRef.current.click();
    };

    const saveImage = (file) => {
        setErrors(null);

        const uploadImageData = new FormData();
        uploadImageData.append("imageFile", file, file.name);

        MediPlusImageService.uploadImage(uploadImageData)
            .then(({ data }) => {
                setImages([data, ...images]);
                setLoading(false);
            });
    };

    const handleFileChange = (event) => {
        setErrors(null);

        try {
            const file = event.target.files[0];
            if (file) {
    
                if (file.type.substr(0, 5) !== "image") {
                    setErrors("Ο τύπος αρχείου δεν υποστηρίζεται!");
                    return;    
                }
                
                if (file.size > 60000) {
                    setErrors("Το μέγεθος της εικόνας είναι μεγαλύτερο από το επιτρεπτό!");
                    return;
                }
    
                setLoading(true);
                const reader = new FileReader();
                reader.readAsDataURL(file);
    
                reader.onloadend = () => {
                    saveImage(file);
                };
            }                
        } catch (error) {
            setLoading(false);
            setErrors(error);
        }
    };

    return (
        <div>
            <input
                type="file"
                style={{ display: "none" }}
                ref={fileInputRef}
                accept="image/*"
                onChange={handleFileChange}
            />

            <button
                className="btn buttonPrimary align-self-center mb-5"
                onClick={uploadImage}
            >Προσθήκη Logo
            </button>

        </div>
    );
}

function CustomPrescriptions() {
    const [selectedCustomPrescription, setSelectedCustomPrescription] = useState(null);
    const [viewCustomPrescription, setViewCustomPrescription] = useState(false);

    const onView = (customPrescription) => {
        setSelectedCustomPrescription(customPrescription);
        setViewCustomPrescription(true);
    };

    const [deleteError, setDeleteError] = useState(null);
    const onDelete = (customPrescription) => {
        MediPlusCustomPrescriptionService.deleteCustomPrescription(
            customPrescription.id,
            customPrescription
        )
            .then((response) => {
                setData(data.filter((cP) => cP.id !== customPrescription.id));
                if (selectedCustomPrescriptionId === customPrescription.id)
                    setSelectedCustomPrescriptionId(-1);
            })
            .catch((err) => {
                setDeleteError(err.response);
            });
    };

    const [newCustomPrescription, setNewCustomPrescription] = useState(false);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    useEffect(() => {
        let mounted = true;
        MediPlusCustomPrescriptionService.getCustomPrescriptions()
            .then(({ data }) => {
                if (mounted) {
                    setData(data.customPrescriptions);
                    setSelectedCustomPrescriptionId(data.defaultCustomPrescriptionId);
                }
            })
            .catch((err) => {
                // console.log(err.response);
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
        return () => (mounted = false);
    }, []);

    const [selectedCustomPrescriptionId, setSelectedCustomPrescriptionId] = useState(-1);
    const [radioButtonLoading, setRadioButtonLoading] = useState(false);
    const handleSelectedCustomPrescriptionIdChange = (event) => {
        setRadioButtonLoading(true);
        MediPlusUserService.updateUserDetailsAll({
            defaultCustomPrescriptionId: parseInt(event.target.value),
        })
            .then((response) => {
                setSelectedCustomPrescriptionId(parseInt(event.target.value));
            })
            .finally(() => {
                setRadioButtonLoading(false);
            });
    };

    return (
        <div className="section mb-5">
            <div className="boxTitle">
                <h6>Ιατρικό Σημείωμα</h6>
            </div>
            <div className="boxContent">
                {loading ? (
                    <CircularProgress />
                ) : error ? (
                    <Alert severity="error">Κάτι πήγε στραβά!</Alert>
                ) : (
                    <div>
                        {deleteError && (
                            <Alert className="mt-2 mb-2" severity="error">
                                Κάτι πήγε στραβά!
                            </Alert>
                        )}
                        <div align="center" className="mb-2">
                            <button
                                className="btn buttonPrimary"
                                onClick={() => setNewCustomPrescription(true)}
                            >
                                Προσθήκη
                            </button>
                        </div>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column, index) => {
                                            return (
                                                <TableCell key={index}>
                                                    <b>{column.name}</b>
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((customPrescription) => {
                                        return (
                                            <TableRow key={customPrescription.id}>
                                                <TableCell>
                                                    <Radio
                                                        color="primary"
                                                        value={customPrescription.id}
                                                        checked={
                                                            selectedCustomPrescriptionId ===
                                                            customPrescription.id
                                                        }
                                                        onChange={
                                                            handleSelectedCustomPrescriptionIdChange
                                                        }
                                                        disabled={radioButtonLoading}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    {parser(customPrescription.header || "")}
                                                </TableCell>
                                                <TableCell>
                                                    {parser(customPrescription.footer || "")}
                                                </TableCell>
                                                <TableCell>
                                                    <button
                                                        className="btn buttonPrimary"
                                                        onClick={() => onView(customPrescription)}
                                                    >
                                                        Προβολή
                                                    </button>
                                                    <button
                                                        className="btn buttonDanger"
                                                        onClick={() => onDelete(customPrescription)}
                                                    >
                                                        Διαγραφή
                                                    </button>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                )}
            </div>
            <PopUp
                openPopUp={newCustomPrescription}
                setOpenPopUp={setNewCustomPrescription}
                title={"Νέο Ιατρικό Σημείωμα"}
                maxWidth="md"
            >
                <NewCustomPrescription data={data} setData={setData} />
            </PopUp>
            <PopUp
                openPopUp={viewCustomPrescription}
                setOpenPopUp={setViewCustomPrescription}
                title={"Στοιχεία Ιατρικού Σημειώματος"}
                maxWidth="md"
            >
                <CustomPrescriptionDetails
                    data={data}
                    setData={setData}
                    selectedCustomPrescription={selectedCustomPrescription}
                />
            </PopUp>
        </div>
    );
}

function NewCustomPrescription({ data, setData }) {
    const [header, setHeader] = useState("");
    const [footer, setFooter] = useState("");

    const [images, setImages] = useState([]);
    const [customPrescriptionImagePositions, setCustomPrescriptionImagePositions] = useState([]);
    const [loadingImages, setLoadingImages] = useState(true);
    const [errorImages, setErrorImages] = useState(false);
    useEffect(() => {
        const requestOne = MediPlusImageService.getAllImages();
        const requestTwo = MediPlusCustomPrescriptionImagePositionService.getCustomPrescriptionImagePositions();
        axios
            .all([requestOne, requestTwo])
            .then(
                axios.spread((...responses) => {
                    const responseOne = responses[0];
                    const responseTwo = responses[1];
                    setImages(responseOne.data);
                    setCustomPrescriptionImagePositions(responseTwo.data);
                })
            )
            .catch((err) => {
                // console.log(err.response);
                setErrorImages(err.response);
            })
            .finally(() => {
                setLoadingImages(false);
            });
    }, []);

    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState();

    const updateError = (msg) => {
        setErrorMsg(msg);
        if (msg && msg!=="") setError(true);
        else
            setError(false);
    };

    const onSave = () => {
        setError(false);
        setSuccess(false);
        setLoading(true);
        MediPlusCustomPrescriptionService.addCustomPrescription({
            header,
            footer,
            customPrescriptionImagePositionId: selectedImagePosition,
            imageId: selectedImage,
        })
            .then((response) => {
                setData([response.data, ...data]);
                setSuccess(true);
            })
            .catch((err) => {
                // console.log(err.response);
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const [selectedImage, setSelectedImage] = useState(0);
    const [selectedImagePosition, setSelectedImagePosition] = useState(1);

    const handleImagePositionChange = (e) => {
        e.preventDefault();
        if (parseInt(e.target.value) === 1) setSelectedImage(0);
        setSelectedImagePosition(parseInt(e.target.value));
    };

    return (
        <div>
            <LoadingScreen loading={loading} />

            {loadingImages ? (
                <div align="center">
                    <CircularProgress />
                </div>
            ) : errorImages ? (
                <Alert severity="error">Κάτι πήγε στραβά!</Alert>
            ) : (
                <div>
                    {error && (
                        <Alert className="mt-2 mb-4" severity="error">
                            {errorMsg ? errorMsg : "Κάτι πήγε στραβά!"}
                        </Alert>
                    )}
                    {success && (
                        <Alert className="mt-2 mb-4" severity="success">
                            Το Ιατρικό Σημείωμα προστέθηκε με επιτυχία!
                        </Alert>
                    )}
                    <div className="mb-5">
                        <h3>Αρχή Κειμένου</h3>
                        <CKEditor
                            editor={ClassicEditor}
                            config={{
                                toolbar: ckeditorToolbar,
                            }}
                            data={header}
                            onChange={(event, editor) => setHeader(editor.getData())}
                        />
                    </div>
                    <div className="mb-5">
                        <h3>Τέλος Κειμένου</h3>
                        <CKEditor
                            editor={ClassicEditor}
                            config={{
                                toolbar: ckeditorToolbar,
                            }}
                            data={footer}
                            onChange={(event, editor) => setFooter(editor.getData())}
                        />
                    </div>
                    <div className="mb-5">
                        <h5>Θέση Logo</h5>
                        <select
                            className="form-control"
                            style={{ width: "40%" }}
                            onChange={handleImagePositionChange}
                        >
                            {customPrescriptionImagePositions.map(
                                (customPrescriptionImagePosition) => {
                                    return (
                                        <option
                                            key={customPrescriptionImagePosition.id}
                                            value={customPrescriptionImagePosition.id}
                                        >
                                            {customPrescriptionImagePosition.name}
                                        </option>
                                    );
                                }
                            )}
                        </select>
                    </div>
                    <div>
                        <h5>Προτεινόμενα Logo</h5>
                        <Grid container className="mb-5">
                            <div className="d-flex flex-row">
                                {images.map((storedImage) => {
                                    return (
                                        <Grid item xs={images.size} align="center" key={storedImage.id}>
                                            <div align="center">
                                                <DeleteLogoImg 
                                                    image={storedImage}
                                                    setImages={setImages}
                                                    setSelectedImagePosition={setSelectedImagePosition}
                                                    setErrors={updateError}
                                                    setLoading={setLoading}
                                                />

                                                <img
                                                    style={{
                                                        height: "50px",
                                                        cursor:
                                                            selectedImagePosition === 1
                                                                ? "default"
                                                                : "pointer",
                                                        border:
                                                            selectedImage === storedImage.id &&
                                                            selectedImagePosition !== 1
                                                                ? "1px solid black"
                                                                : "none",
                                                    }}
                                                    value={storedImage.id}
                                                    alt="img"
                                                    src={`data:image/jpeg;base64,${storedImage.bytes}`}
                                                    onClick={
                                                        selectedImagePosition === 1
                                                            ? null
                                                            : () => setSelectedImage(storedImage.id)
                                                    }
                                                />
                                            </div>
                                        </Grid>
                                    );
                                })}
                            </div>
                        </Grid>
                        <UploadImageBtn 
                            images={images} 
                            setImages={setImages} 
                            setErrors={updateError}
                            setLoading={setLoading}
                        />
                        
                    </div>
                    <div align="center">
                        <button className="btn buttonPrimary" onClick={onSave} disabled={loading}>
                            Αποθήκευση
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

function CustomPrescriptionDetails({ data, setData, selectedCustomPrescription }) {
    const [header, setHeader] = useState(selectedCustomPrescription.header);
    const [footer, setFooter] = useState(selectedCustomPrescription.footer);
    
    const [images, setImages] = useState([]);
    const [customPrescriptionImagePositions, setCustomPrescriptionImagePositions] = useState([]);
    const [loadingImages, setLoadingImages] = useState(true);
    const [errorImages, setErrorImages] = useState(false);
    useEffect(() => {
        const requestOne = MediPlusImageService.getAllImages();
        const requestTwo = MediPlusCustomPrescriptionImagePositionService.getCustomPrescriptionImagePositions();
        axios
            .all([requestOne, requestTwo])
            .then(
                axios.spread((...responses) => {
                    const responseOne = responses[0];
                    const responseTwo = responses[1];
                    setImages(responseOne.data);
                    setCustomPrescriptionImagePositions(responseTwo.data);
                })
            )
            .catch((err) => {
                // console.log(err.response);
                setErrorImages(err.response);
            })
            .finally(() => {
                setLoadingImages(false);
            });
    }, []);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState();

    const updateError = (msg) => {
        setErrorMsg(msg);
        if (msg && msg!=="") setError(true);
        else
            setError(false);
    };

    const onUpdate = () => {
        setError(false);
        setSuccess(false);
        setLoading(true);
        MediPlusCustomPrescriptionService.updateCustomPrescription(selectedCustomPrescription.id, {
            header,
            footer,
            customPrescriptionImagePositionId: selectedImagePosition,
            imageId: selectedImage,
        })
            .then((response) => {
                let newData = data;
                let index = data.findIndex((cP) => cP.id === selectedCustomPrescription.id);
                newData[index] = response.data;
                setData([...newData]);
                setSuccess(true);
            })
            .catch((err) => {
                // console.log(err.response);
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const [selectedImage, setSelectedImage] = useState(selectedCustomPrescription?.imageId ?? 0);
    const [selectedImagePosition, setSelectedImagePosition] = useState(
        selectedCustomPrescription?.customPrescriptionImagePosition?.id ?? 1
    );

    const handleImagePositionChange = (e) => {
        e.preventDefault();
        if (parseInt(e.target.value) === 1) setSelectedImage(0);
        setSelectedImagePosition(parseInt(e.target.value));
    };

    return (
        <div>
            <LoadingScreen loading={loading} />

            {loadingImages ? (
                <div align="center">
                    <CircularProgress />
                </div>
            ) : errorImages ? (
                <Alert severity="error">Κάτι πήγε στραβά!</Alert>
            ) : (
                <div>
                    {error && (
                        <Alert className="mt-2 mb-4" severity="error">
                            {errorMsg ? errorMsg : "Κάτι πήγε στραβά!"}
                        </Alert>
                    )}
                    {success && (
                        <Alert className="mt-2 mb-4" severity="success">
                            Το Ιατρικό Σημείωμα ενημερώθηκε με επιτυχία!
                        </Alert>
                    )}
                    <div className="mb-5">
                        <h3>Αρχή Κειμένου</h3>
                        <CKEditor
                            editor={ClassicEditor}
                            config={{
                                toolbar: ckeditorToolbar,
                            }}
                            data={header}
                            onChange={(event, editor) => setHeader(editor.getData())}
                        />
                    </div>
                    <div className="mb-5">
                        <h3>Τέλος Κειμένου</h3>
                        <CKEditor
                            editor={ClassicEditor}
                            config={{
                                toolbar: ckeditorToolbar,
                            }}
                            data={footer}
                            onChange={(event, editor) => setFooter(editor.getData())}
                        />
                    </div>
                    <div className="mb-5">
                        <h5>Θέση Logo</h5>
                        <select
                            className="form-control"
                            style={{ width: "40%" }}
                            value={selectedImagePosition}
                            onChange={handleImagePositionChange}
                        >
                            {customPrescriptionImagePositions.map(
                                (customPrescriptionImagePosition) => {
                                    return (
                                        <option
                                            key={customPrescriptionImagePosition.id}
                                            value={customPrescriptionImagePosition.id}
                                        >
                                            {customPrescriptionImagePosition.name}
                                        </option>
                                    );
                                }
                            )}
                        </select>
                    </div>
                    <div>
                        <h5>Προτεινόμενα Logo</h5>
                        <Grid container className="mb-5">
                            <div className="d-flex flex-row">
                                {images.map((storedImage) => {
                                    return (
                                        <Grid item xs={images.size} key={storedImage.id}>
                                            <div align="center">
                                                <DeleteLogoImg 
                                                    image={storedImage}
                                                    setImages={setImages}
                                                    setSelectedImagePosition={setSelectedImagePosition}
                                                    setErrors={updateError}
                                                    setLoading={setLoading}
                                                />

                                                <img
                                                    style={{
                                                        height: "50px",
                                                        cursor:
                                                            selectedImagePosition === 1
                                                                ? "default"
                                                                : "pointer",
                                                        border:
                                                            selectedImage === storedImage.id &&
                                                            selectedImagePosition !== 1
                                                                ? "1px solid black"
                                                                : "none",
                                                    }}
                                                    value={storedImage.id}
                                                    alt="img"
                                                    src={`data:image/jpeg;base64,${storedImage.bytes}`}
                                                    onClick={
                                                        selectedImagePosition === 1
                                                            ? null
                                                            : () => setSelectedImage(storedImage.id)
                                                    }
                                                />                                                
                                            </div>
                                        </Grid>
                                    );
                                })}
                            </div>
                        </Grid>
                        <UploadImageBtn 
                            images={images} 
                            setImages={setImages} 
                            setErrors={updateError}
                            setLoading={setLoading}
                        />
                    </div>
                    <div align="center">
                        <button className="btn buttonPrimary" onClick={onUpdate} disabled={loading}>
                            Ενημέρωση
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default CustomPrescriptions;
