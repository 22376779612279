import React, { useState } from "react";
import { mainTabs, diagnosisColumns, diagnosisFields } from "./config.js";
import { Tabs, Tab } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IdikaReferralService from "../../../../../services/MediPlus/Idika/IdikaReferralService.js";
import Tooltip from "@material-ui/core/Tooltip";
import Alert from "@material-ui/lab/Alert";
import PopUp from "../../../../Utilities/PopUp.js";

const RefInfo = ({
    referral,
    tabItem,
    setTabItem,
    popPrescriptionOrReferralForRegistration,
    noPaperReferralConsent,
}) => {
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [sendPaper, setSendPaper] = useState(false);
    const [onCancelTempErrorPopUp, setOnCancelTempErrorPopUp] = useState(false);

    const onPrint = ({ barcode }) => {
        IdikaReferralService.printReferral(barcode).then(({ data }) => {
            let file = new Blob([data], { type: "application/pdf" });
            let fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        });
    };

    const onSend = ({ barcode }) => {
        IdikaReferralService.sendNoPaperReferral(barcode)
            .then(({ data }) => {
                setError(false);
                setSendPaper(true);
            })
            .catch((err) => {
                setError(true);
                setSendPaper(false);
            });
    };

    const onCancel = ({ barcode }) => {
        IdikaReferralService.cancelReferral(barcode)
            .then(({ data }) => {
                setTabItem({ ...tabItem, viewReferral: data });
                setError(false);
            })
            .catch((error) => {
                if (error.response.data.status === 512) {
                    setErrorMessage(
                        "O Αριθμός Παραπεμπτικού δεν αντιστοιχεί στον συνδεδεμένο γιατρό"
                    );
                } else if (error.response.data.status === 610) {
                    setOnCancelTempErrorPopUp(true);
                } else {
                    setErrorMessage(null);
                }
                setError(true);
            });
    };

    let numberOfPrescriptionsAndReferralsToBeRegistered =
        tabItem.visitDetails.referralsToBeRegistered.length +
        tabItem.visitDetails.prescriptionsToBeRegistered.length;

    return (
        <div style={{ marginLeft: "20px" }}>
            <div className="flexWrap">
                <p className="infoP alignRight noMargin">Κατάσταση Παραπεμπτικού</p>
                <p className="noMargin">{referral.status}</p>
            </div>
            <div className="flexWrap">
                <p className="infoP alignRight noMargin">Αριθμός Παραπεμπτικού</p>
                <p className="noMargin">{referral.barcode}</p>
            </div>
            <div className="flexWrap">
                <p className="infoP alignRight noMargin">Κατηγορία Εξετάσεων</p>
                <p className="noMargin">{referral.category}</p>
            </div>
            <div className="flexWrap">
                <p className="infoP alignRight noMargin">Αιτιολογία Παραπεμπτικού</p>
                <p className="noMargin">{referral.reason}</p>
            </div>
            <div className="flexWrap">
                <p className="infoP alignRight noMargin">Σημειώσεις</p>
                <p className="noMargin">{referral.notes}</p>
            </div>
            <br />
            <div className="flexWrap">
                <p className="infoP alignRight noMargin">Απώλεια Ε.Κ.Α.Σ.</p>
                <p className="noMargin">
                    <input type="checkbox" disabled />
                </p>
            </div>
            <div className="">
                <p className="infoP noMargin">Περιπτώσεις Μηδενικής Συμμετοχής </p>
                <p className="noMargin">
                    {Object.keys(referral.noParticipationCase).length !== 0 &&
                        referral.noParticipationCase.noParticipationCase.noParticipationCase}
                </p>
            </div>
            {Object.keys(referral.noParticipationCase).length !== 0 &&
                referral.noParticipationCase.noParticipationCase.needsDecision && (
                    <div>
                        <div className="flexWrap">
                            <p className="infoP alignRight noMargin">Ημ/νία Απόφασης</p>
                            <p className="noMargin">{referral.noParticipationCase.date}</p>
                        </div>
                        <div className="flexWrap">
                            <p className="infoP alignRight noMargin">Αριθμός Απόφασης</p>
                            <p className="noMargin">{referral.noParticipationCase.number}</p>
                        </div>
                    </div>
                )}
            <br />
            {error && (
                <Alert severity="error">{errorMessage ? errorMessage : "Κάτι πήγε στραβά!"}</Alert>
            )}
            {sendPaper && (
                <Alert severity="success">H Ηλεκτρονική Αποστολή ολοκληρώθηκε με επιτυχία!</Alert>
            )}
            <div>
                <button
                    className="btn buttonPrimary"
                    disabled={
                        referral.status === "ΑΚΥΡΩΜΕΝΟ" || referral.status === "ΕΚΤΕΛΕΣΜΕΝΟ"
                            ? true
                            : false
                    }
                    onClick={() => onCancel(referral)}
                >
                    Ακύρωση
                </button>
                {referral.status !== "ΑΚΥΡΩΜΕΝΟ" &&
                    referral.status !== "ΠΡΟΣΩΡΙΝΑ ΣΥΝΤΑΓΟΓΡΑΦΗΜΕΝΟ" &&
                    noPaperReferralConsent && (
                        <button className="btn buttonPrimary" onClick={() => onSend(referral)}>
                            Ηλεκτρονική Aποστολή
                        </button>
                    )}
                {referral.status !== "ΑΚΥΡΩΜΕΝΟ" &&
                    referral.status !== "ΠΡΟΣΩΡΙΝΑ ΣΥΝΤΑΓΟΓΡΑΦΗΜΕΝΟ" && (
                        <button className="btn buttonPrimary" onClick={() => onPrint(referral)}>
                            Εκτύπωση Παραπεμπτικού
                        </button>
                    )}
                {/* <button className="btn buttonPrimary">Αντιγραφή Παραπεμπτικού</button> */}
                <button className="btn buttonPrimary" disabled>
                    Εισαγωγή Αποτελεσμάτων Εξετάσεων
                </button>
                {numberOfPrescriptionsAndReferralsToBeRegistered > 0 && (
                    <Tooltip
                        title={`Απομένουν ακόμα ${numberOfPrescriptionsAndReferralsToBeRegistered} καταχωρήσεις`}
                        placement="top"
                    >
                        <button
                            className="btn buttonPrimary"
                            onClick={() => popPrescriptionOrReferralForRegistration()}
                        >
                            Εισαγωγή επόμενης Συνταγής/Παραπεμπτικού
                        </button>
                    </Tooltip>
                )}
            </div>
            <PopUp
                openPopUp={onCancelTempErrorPopUp}
                setOpenPopUp={setOnCancelTempErrorPopUp}
                title={"Προσοχή!"}
                maxWidth="md"
            >
                <div>
                    <p>Δεν είναι δυνατή η ακύρωση παραπεμπτικού που είναι Προσωρινά Καταχωρημένο</p>

                    <Alert severity="warning">
                        Για να συνεχίσετε προχωρήστε σε <strong>Καταχώρηση</strong> και στη συνέχεια{" "}
                        <strong>Ακύρωση</strong> του παραπεμπτικού
                    </Alert>
                </div>
            </PopUp>
        </div>
    );
};

const Diagnosis = ({ referral }) => {
    return (
        <div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {diagnosisColumns.map((column, index) => {
                                return (
                                    <TableCell key={index}>
                                        <b>{column.name}</b>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {referral.diagnosis.map((diaCol, index) => {
                            return (
                                <TableRow key={index}>
                                    {diagnosisFields.map((field, indexField) => {
                                        return (
                                            <TableCell key={indexField}>
                                                {diaCol[field.name]}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

function ReferralInfo({
    referral,
    tabItem,
    setTabItem,
    popPrescriptionOrReferralForRegistration,
    noPaperReferralConsent,
}) {
    const [selectedTab, setSelectedTab] = useState(0);
    function handleTabs(e, val) {
        setSelectedTab(val);
    }

    const showContent = () => {
        if (selectedTab === 0)
            return (
                <RefInfo
                    referral={referral}
                    tabItem={tabItem}
                    setTabItem={setTabItem}
                    popPrescriptionOrReferralForRegistration={
                        popPrescriptionOrReferralForRegistration
                    }
                    noPaperReferralConsent={noPaperReferralConsent}
                />
            );
        else if (selectedTab === 1) return <Diagnosis referral={referral} />;
        else return null;
    };

    return (
        <div className="section">
            <Tabs value={selectedTab} onChange={handleTabs}>
                {mainTabs.map((tab, index) => {
                    return <Tab key={index} {...tab} />;
                })}
            </Tabs>
            {showContent()}
        </div>
    );
}

export default ReferralInfo;
