import axios from "axios";
import { baseUrl, getOptions, postOptions, putOptions, deleteOptions } from "../config.js";

class MediPlusVisitReasonService {
    constructor(baseUrl) {
        this.basePath = "/visitReasons";
        this.baseUrl = `${baseUrl}${this.basePath}`;
    }

    getVisitReasons() {
        return axios(getOptions(this.baseUrl));
    }

    addVisitReason(visitReason) {
        return axios(postOptions(this.baseUrl, "", { reason: visitReason }));
    }

    // not tested
    updateVisitReason(id, visitReason) {
        return axios(putOptions(this.baseUrl, `/${id}`, { reason: visitReason }));
    }

    deleteVisitReason(id) {
        return axios(deleteOptions(this.baseUrl, `/${id}`));
    }
}

export default new MediPlusVisitReasonService(baseUrl);
