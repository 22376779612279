import { useState } from "react";
import { makeStyles, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Redirect, useLocation } from "react-router-dom";
import queryString from "query-string";
import { locationMap } from "../../config";
import { getJwt } from "../Utilities/JwtUtilities";
import MediPlusResetPasswordService from "../../services/MediPlus/MediPlusResetPasswordService";

const useStyles = makeStyles(() => ({
    root: {
        width: "80%",
        marginTop: "10px",
        marginBottom: "10px",
    },
}));

// this component uses the token parameter from the url
// without the token you would be redirected at homepage
function ChangePassword() {
    const { search } = useLocation();
    const { token } = queryString.parse(search);
    const classes = useStyles();
    const [state, setState] = useState({
        newPassword: "",
        confirmNewPassword: "",
    });
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setState({ ...state, [name]: value });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (state.newPassword !== state.confirmNewPassword) {
            setError({ passwords: "Οι Κωδικοί δεν ταιριάζουν" });
            return;
        }
        setError({});
        setLoading(true);
        setSuccess(false);
        let request = { newPassword: state.newPassword, token };
        MediPlusResetPasswordService.changePasswordWithToken(request)
            .then(({ data }) => {
                setSuccess(true);
            })
            .catch((err) => {
                // console.log(err.response);
                setError(err.response);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const authToken = getJwt();
    if (authToken !== null) return <Redirect to={locationMap[sessionStorage.getItem("role")]} />;
    else if (token === undefined) return <Redirect to="/" />;

    return (
        <div className="loginForm bodyWrap">
            <div className="loginBody">
                <div className="flexBox">
                    <div className="fullBox">
                        <div className="authForm">
                            <h4> ΕΠΑΝΑΦΟΡΑ ΚΩΔΙΚΟΥ ΠΡΟΣΒΑΣΗΣ </h4>
                            <div align="center" className="mb-3">
                                <div className={classes.root}>
                                    {Object.keys(error).length !== 0 && (
                                        <Alert severity="error">
                                            {error.passwords !== undefined
                                                ? "Οι Κωδικοί δεν ταιριάζουν!"
                                                : error?.data?.errors?.token !== undefined
                                                ? "H διαδικασία αλλαγής κωδικού πρόσβασης έχει λήξει."
                                                : "Κάτι πήγε στραβά!"}
                                        </Alert>
                                    )}
                                    {success && (
                                        <Alert severity="success">Ο Κωδικός σας άλλαξε!</Alert>
                                    )}
                                </div>
                            </div>
                            <form onSubmit={onSubmit}>
                                <TextField
                                    className="formInputUI mb-5"
                                    label="Νέος Κωδικός"
                                    name="newPassword"
                                    value={state.newPassword}
                                    onChange={handleChange}
                                    type="password"
                                    error={error.passwords !== undefined ? true : false}
                                    required
                                />
                                <TextField
                                    className="formInputUI mb-5"
                                    label="Επιβεβαίωση νέου Κωδικού"
                                    name="confirmNewPassword"
                                    value={state.confirmNewPassword}
                                    onChange={handleChange}
                                    type="password"
                                    error={error.passwords !== undefined ? true : false}
                                    required
                                />
                                <button className="btn buttonPrimary" disabled={loading}>
                                    Αλλαγή Κωδικού
                                </button>
                                <br />
                                <br />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChangePassword;
