import { useState } from "react";
import { Tabs, Tab } from "@material-ui/core";
import { mainTabs } from "./config.js";
import PrescriptionStatistics from "./PrescriptionStatistics.js";
import MedicineTotals from "./MedicineTotals.js"

function Statistics() {    
    const [selectedTabBttn, setSelectedTabBttn] = useState(0);
    const handleTabsBttns = (e, val) => {
        setSelectedTabBttn(val);
    };

    const showContent = () => {
        if (selectedTabBttn === 0) 
            return (
                <PrescriptionStatistics />
            );
        else if (selectedTabBttn === 1)
            return (
                <MedicineTotals />
            );
        else return null;
    };
    
    return (
        <div>
            <Tabs
                value={selectedTabBttn}
                onChange={handleTabsBttns}
                indicatorColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                className="tabsBttns stickyTabs innerTabs"
            >

                {mainTabs.map((tab, index) => {
                    return (
                        <Tab
                            {...tab}
                            key={index}
                        />
                    );
                })}
                
            </Tabs>

            {showContent()}
        </div>
    );
}

export default Statistics;
