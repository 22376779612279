const mainColumns = [
    { name: "Αριθμός Επίσκεψης" },
    { name: "Λόγος Επίσκεψης" },
    { name: "Ημ/νια Έναρξης" },
    { name: "Κατάσταση" },
    { name: "" },
];

const icpc2Columns = [{ name: "Κωδικός" }, { name: "Τίτλος" }];

export { mainColumns, icpc2Columns };
