import axios from "axios";
import { baseUrl, getOptions, postOptions, putOptions, deleteOptions } from "../config.js";

class MediPlusFavoriteExamPackageService {
    constructor(baseUrl) {
        this.basePath = "/favoriteExamPackages";
        this.baseUrl = `${baseUrl}${this.basePath}`;
    }

    getFavoriteExamPackagesWithTypeAndGroup(parameters) {
        return axios(getOptions(this.baseUrl, "/withTypeAndGroup", parameters));
    }

    addFavoriteExamPackage(favoriteExamPackage) {
        return axios(postOptions(this.baseUrl, "", favoriteExamPackage));
    }

    updateFavoriteExamPackage(id, favoriteExamPackage) {
        return axios(putOptions(this.baseUrl, `/${id}`, favoriteExamPackage));
    }

    deleteFavoriteExamPackage(id) {
        return axios(deleteOptions(this.baseUrl, `/${id}`));
    }
}

export default new MediPlusFavoriteExamPackageService(baseUrl);
