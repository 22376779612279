import axios from "axios";
import { baseUrl, getOptions, postOptions, deleteOptions } from "../config.js";

class MediPlusImageService {
    constructor(baseUrl) {
        this.basePath = "/images";
        this.baseUrl = `${baseUrl}${this.basePath}`;
    }

    getAllImages() {
        return axios(getOptions(this.baseUrl));
    }

    uploadImage(data) {
        return axios(postOptions(this.baseUrl, "", data));
    }

    deleteImage(imageId) {
        return axios(deleteOptions(this.baseUrl, "/delete/" + imageId));
    }
}

export default new MediPlusImageService(baseUrl);
