const mainColumns = [
    { name: "Εξέταση" },
    { name: "Λέξεις-Κλειδιά" },
    { name: "Τιμή Εξέτασης" },
    // { name: "Ποσότητα" },
    // { name: "Προληπτική" },
    { name: "" },
];

export { mainColumns };
