import React, { useEffect, useState } from "react";
import { mainColumns } from "./config.js";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PopUp from "../../../../Utilities/PopUp.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";
import MediPlusFavoritePrescriptionPackageService from "../../../../../services/MediPlus/MediPlusFavoritePrescriptionPackageService.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ReceiptIcon from "@material-ui/icons/Receipt";
import { ShowDetailsContent } from "../../DoctorVisit/InsuredDetails/InsuredInformation/Buttons/FavoritePrescriptions/FavoritePrescriptions.js";

function EditPrescription({ prescriptionDetails, inputData, setData, setEditPopUp }) {
    const [name, setName] = useState(prescriptionDetails.name);
    const [error, setError] = useState(null);
    const onSubmit = (e) => {
        e.preventDefault();
        let request = { ...prescriptionDetails, name: name };
        MediPlusFavoritePrescriptionPackageService.updateFavoritePrescriptionPackage(
            prescriptionDetails.id,
            request
        )
            .then(({ data }) => {
                let content = inputData.content.map((prescription) => {
                    return prescription.id !== prescriptionDetails.id ? prescription : request;
                });
                setData({ ...inputData, content: content });
                setEditPopUp(false);
            })
            .catch((error) => {
                setError(error);
            });
    };

    return (
        <div>
            {error && (
                <div>
                    <Alert severity="error">Κάτι πήγε στραβά!</Alert>
                    <br />
                </div>
            )}
            <form onSubmit={onSubmit}>
                <span>Όνομα Αγαπημένου Πακέτου Συνταγής *</span>
                <div align="center">
                    <input
                        type="text"
                        className="form-control"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                    <button type="submit" className="btn buttonPrimary">
                        Αποθήκευση
                    </button>
                </div>
            </form>
        </div>
    );
}

function DeletePrescription({ prescriptionDetails, inputData, setData, setDeletePopUp }) {
    const [error, setError] = useState(null);
    const onClick = (e) => {
        e.preventDefault();
        MediPlusFavoritePrescriptionPackageService.deleteFavoritePrescriptionPackage(
            prescriptionDetails.id
        )
            .then(({ data }) => {
                let content = inputData.content.filter(
                    (prescription) => prescription.id !== prescriptionDetails.id
                );
                setData({ ...inputData, content: content });
                setDeletePopUp(false);
            })
            .catch((error) => {
                setError(error);
            });
    };

    return (
        <div>
            {error && (
                <div>
                    <Alert severity="error">Κάτι πήγε στραβά!</Alert>
                    <br />
                </div>
            )}
            <div align="center">
                <h5>
                    Είστε σίγουρος ότι θέλετε να διαγράψετε τo Πακέτο Συνταγών `
                    {prescriptionDetails.name}`;
                </h5>
                <button className="btn buttonDanger" onClick={onClick}>
                    Διαγραφή
                </button>
            </div>
        </div>
    );
}

function FavoritePrescriptionPackage() {
    const [isOpen, setIsOpen] = useState(false);
    const [page, setPage] = useState(1);
    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const [isLoading, setIsLoading] = useState(true);
    const [isFetching, setIsFetching] = useState(true);
    const [isError, setIsError] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        setIsFetching(true);
        MediPlusFavoritePrescriptionPackageService.getFavoritePrescriptionPackages({
            page: page - 1,
            size: 5,
        })
            .then(({ data }) => {
                setData(data);
                setIsFetching(false);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsError(true);
            });
    }, [page]);

    const [prescriptionDetails, setPrescriptionDetails] = useState({});
    const [popUp, setPopUp] = useState(false);
    const onShowDetails = (prescription) => {
        setPrescriptionDetails(prescription);
        setPopUp(true);
    };

    const [deletePopUp, setDeletePopUp] = useState(false);
    const onDelete = (prescription) => {
        setPrescriptionDetails(prescription);
        setDeletePopUp(true);
    };

    const [editPopUp, setEditPopUp] = useState(false);
    const onEdit = (prescription) => {
        setPrescriptionDetails(prescription);
        setEditPopUp(true);
    };

    return (
        <div className="flexBox">
            <Accordion className="doctorAccordion">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    onClick={() => {
                        setIsOpen(!isOpen);
                    }}
                >
                    <h6>Αγαπημένα Πακέτα Συνταγογράφησης</h6>
                </AccordionSummary>
                <AccordionDetails>
                    <TableContainer component={Paper}>
                        {isLoading ? (
                            <CircularProgress className="m-2" />
                        ) : isError ? (
                            <Alert severity="error">Κάτι πήγε στραβά!</Alert>
                        ) : (
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {mainColumns.map((column, index) => {
                                            return (
                                                <TableCell key={index}>
                                                    <b>{column.name}</b>
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.content.map((prescription, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>{prescription.name}</TableCell>
                                                <TableCell>
                                                    {prescription.numberOfPrescriptions}
                                                </TableCell>
                                                <TableCell>{prescription.createdDate}</TableCell>
                                                <TableCell>
                                                    <ReceiptIcon
                                                        style={{ cursor: "pointer" }}
                                                        color="primary"
                                                        onClick={() => onShowDetails(prescription)}
                                                    />
                                                    <EditIcon
                                                        style={{ cursor: "pointer" }}
                                                        color="primary"
                                                        onClick={() => onEdit(prescription)}
                                                    />
                                                    <DeleteIcon
                                                        style={{ cursor: "pointer" }}
                                                        color="secondary"
                                                        onClick={() => onDelete(prescription)}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        )}
                        {data !== null && (
                            <div className="form-inline">
                                <Pagination
                                    count={data !== null ? data.totalPages : 0}
                                    page={page}
                                    onChange={handlePageChange}
                                />
                                {isFetching ? <span> Φόρτωση...</span> : null}
                            </div>
                        )}
                    </TableContainer>
                </AccordionDetails>
            </Accordion>
            <PopUp
                openPopUp={popUp}
                setOpenPopUp={setPopUp}
                showCloseButton={true}
                title={"Λεπτομέρειες Αγαπημένου Πακέτου Συνταγών"}
                maxWidth="lg"
            >
                <ShowDetailsContent prescriptionDetails={prescriptionDetails} />
            </PopUp>
            <PopUp
                openPopUp={editPopUp}
                setOpenPopUp={setEditPopUp}
                showCloseButton={true}
                title={"Επεξεργασία Αγαπημένου Πακέτου Συνταγών"}
                className="bigModal"
            >
                <EditPrescription
                    prescriptionDetails={prescriptionDetails}
                    inputData={data}
                    setData={setData}
                    setEditPopUp={setEditPopUp}
                />
            </PopUp>
            <PopUp
                openPopUp={deletePopUp}
                setOpenPopUp={setDeletePopUp}
                showCloseButton={true}
                title={"Διαγραφή Αγαπημένου Πακέτου Συνταγών"}
                className="bigModal"
            >
                <DeletePrescription
                    prescriptionDetails={prescriptionDetails}
                    inputData={data}
                    setData={setData}
                    setDeletePopUp={setDeletePopUp}
                />
            </PopUp>
        </div>
    );
}

export default FavoritePrescriptionPackage;
