import React, { useState } from "react";
import { mainColumns, referralFields } from "./config.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IdikaReferralService from "../../../../../../services/MediPlus/Idika/IdikaReferralService.js";
import Alert from "@material-ui/lab/Alert";

function Referrals({ referrals, tabItem, setTabItem, setSelectedTabBttn }) {
    const [error, setError] = useState(null);
    const [referralId, setReferralId] = useState(-1);

    if (referrals === undefined) return null;

    function onIdClick(referralId) {
        setError(null);
        setReferralId(referralId);
        IdikaReferralService.getReferral(referralId)
            .then(({ data }) => {
                setTabItem({ ...tabItem, subTab: 8, viewReferral: data });
                setSelectedTabBttn(8);
            })
            .catch((error) => {
                setError(error);
                setReferralId(-1);
            });
    }

    function onEdit(referralId) {
        setError(null);
        IdikaReferralService.getReferral(referralId, { convert: true })
            .then(({ data }) => {
                console.log(data);
                setTabItem({ ...tabItem, subTab: 3, addReferral: data });
                setSelectedTabBttn(3);
            })
            .catch((error) => {
                setError(error);
            });
    }

    return (
        <div>
            {error && <Alert severity="error">Κάτι πήγε στραβά!</Alert>}
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {mainColumns.map((column, index) => {
                                return (
                                    <TableCell key={index}>
                                        <b>{column.name}</b>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {referrals.map((referral, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell>
                                        <button
                                            onClick={() => onIdClick(referral.id)}
                                            className="btn buttonPrimary"
                                            disabled={referral.id === referralId}
                                        >
                                            {referral.id === referralId ? (
                                                <div className="form-inline">
                                                    <i className="fa fa-spinner fa-pulse mr-3"></i>
                                                    Άντληση....
                                                </div>
                                            ) : (
                                                referral.id
                                            )}
                                        </button>
                                    </TableCell>
                                    {referralFields.map((field, indexField) => {
                                        return (
                                            <TableCell key={indexField}>
                                                {referral[field.name]}
                                            </TableCell>
                                        );
                                    })}
                                    <TableCell>
                                        {referral.status === "ΠΡΟΣΩΡΙΝΑ ΣΥΝΤΑΓΟΓΡΑΦΗΜΕΝΟ" && (
                                            <button
                                                className="btn buttonPrimary"
                                                onClick={() => onEdit(referral.id)}
                                            >
                                                Επεξεργασία
                                            </button>
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default Referrals;
