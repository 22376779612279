import React from "react";

function PrescriptionInfo({ prescription }) {
    // console.log(prescription);
    return (
        <div className="fullBox">
            <div className="boxTitle">
                <h6>Στοιχεία Συνταγής</h6>
            </div>
            <div className="boxContent">
                <div className="docInfo">
                    <div className="docInfoItem">
                        <p className="infoP">Αριθμός Συνταγής</p>
                        <p id="prescriptionNumber">{prescription.id}</p>
                    </div>
                    <div className="docInfoItem">
                        <p className="infoP">Τύπος Συνταγής</p>
                        <p id="prescriptionType">{prescription.type.nameForPrescription}</p>
                    </div>
                    <div className="docInfoItem">
                        <p className="infoP">Επανάλήψεις Συνταγής</p>
                        <p id="prescriptionRepetition">{prescription.repeat.id}</p>
                    </div>
                    <div className="docInfoItem">
                        <p className="infoP">Περίοδος Επανάληψης</p>
                        <p id="prescriptionRepetition">{prescription.interval === ""  ? "-" : prescription.interval.intervalDescription}</p>
                    </div>
                    <div className="docInfoItem">
                        <p className="infoP">Ημ/νία Έκδοσης Συνταγής</p>
                        <p id="prescriptionIssueDate">{prescription.issueDate}</p>
                    </div>
                    <div className="docInfoItem">
                        <p className="infoP">Ημ/νία Πρoθεσμίας Εκτέλεσης</p>
                        <p id="prescriptionDeadlineDate">{prescription.endDate}</p>
                    </div>
                    <div className="docInfoItem">
                        <p className="infoP">Κατάσταση Συνταγής</p>
                        <p id="prescriptionState">{prescription.status}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PrescriptionInfo;
