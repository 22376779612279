import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import MediPlusUserService from "../../../services/MediPlus/MediPlusUserService";
import PopUp from "../../Utilities/PopUp";
import { Spinner } from "../../Utilities/Utilities";

const tableColumns = [
    { name: "'Ονομα Εταιρείας" },
    { name: "Αριθμός Προτεραιότητας" },
    { name: "" },
];

function UserCompanyPreference({ data, users }) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [companyPreferences, setCompanyPreferences] = useState([]);
    const [openNewPriorityPopUp, setOpenNewPriorityPopUp] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [fetch, setFetch] = useState(true);

    const userId = users[data.rowIndex].id;

    useEffect(() => {
        if (fetch) {
            setLoading(true);
            MediPlusUserService.getUserCompanyPreference({ userId: userId })
                .then(({ data }) => {
                    setCompanyPreferences(data);
                    setError(null);
                })
                .catch((error) => {
                    setError(error.response.data);
                })
                .finally(() => {
                    setLoading(false);
                    setFetch(false);
                });
        }
    }, [fetch]);

    const onPriorityChange = (index, e) => {
        const items = companyPreferences;
        items[index].priority = e.target.value;
        setCompanyPreferences([...items]);
    };

    const onUpdateCompanyPriority = (index) => {
        const data = {
            priority: companyPreferences[index].priority,
            companyName: companyPreferences[index].id.companyName,
            userId: userId,
        };

        MediPlusUserService.addUserCompanyPreference(data)
            .then((response) => {
                setError(null);
            })
            .catch((error) => {
                setError(error.response.data);
            });
    };

    const onAddCompanyPriority = () => {
        const temp = [];
        companyPreferences.forEach((element) => temp.push(element.id.companyName));
        setCompanies([...temp]);
        setOpenNewPriorityPopUp(true);
    };

    const onDeleteCompanyPriority = (item) => {
        MediPlusUserService.deleteCompanyPreference(userId, item.id.companyName)
            .then((response) => {
                setError(null);
                setFetch(true);
            })
            .catch((error) => {
                setError(error.response.data);
            });
    };

    if (error !== null) return <Alert severity="error"> Κάτι πήγε στραβά! </Alert>;
    else if (loading === true) return <Spinner align="center" animation="spinner-border" />;

    return (
        <div className="authForm">
            {companyPreferences.length === 0 ? (
                <p>Δεν έχουν επιλεχθεί εταιρείες</p>
            ) : (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {tableColumns.map((column, index) => {
                                    return (
                                        <TableCell key={index}>
                                            <b>{column.name}</b>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {companyPreferences.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>{item.id.companyName}</TableCell>
                                    <TableCell>
                                        <input
                                            type="number"
                                            min={1}
                                            value={item.priority}
                                            onChange={(e) => onPriorityChange(index, e)}
                                            onBlur={() => onUpdateCompanyPriority(index)}
                                        ></input>
                                    </TableCell>
                                    <TableCell>
                                        <button
                                            className="btn buttonPrimary"
                                            onClick={() => onDeleteCompanyPriority(item)}
                                        >
                                            Διαγραφή
                                        </button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <button className="btn buttonPrimary" onClick={onAddCompanyPriority}>
                Προσθήκη
            </button>
            <PopUp
                openPopUp={openNewPriorityPopUp}
                setOpenPopUp={setOpenNewPriorityPopUp}
                title={"Προσθήκη Εταιρείας"}
                maxWidth="md"
            >
                <AddCompanyPriority
                    existingCompanies={companies}
                    userId={userId}
                    setFetch={setFetch}
                    setOpenNewPriorityPopUp={setOpenNewPriorityPopUp}
                />
            </PopUp>
        </div>
    );
}

export default UserCompanyPreference;

function AddCompanyPriority({ existingCompanies, userId, setFetch, setOpenNewPriorityPopUp }) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [producers, setProducers] = useState([]);
    const [item, setItem] = useState({ priority: 1, companyName: "", userId: userId });
    useEffect(() => {
        MediPlusUserService.getDistinctProducers()
            .then((response) => {
                const data = response.data;
                setError(null);
                setProducers(data.filter((element) => !existingCompanies.includes(element)));
            })
            .catch((error) => {
                setError(error.response.data);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const handleAutocompleteChange = (event, value) => {
        setItem({ ...item, companyName: value });
    };

    const onPriorityChange = (e) => {
        let value = e.target.value;
        if (value.includes(".") || value.includes(",")) {
            value = Math.round(value);
        }
        if (value > 0) {
            setItem({ ...item, priority: value });
        }
    };

    const onSave = () => {
        MediPlusUserService.addUserCompanyPreference(item)
            .then((response) => {
                setError(null);
            })
            .catch((error) => {
                setError(error.response.data);
            })
            .finally(() => {
                setFetch(true);
                setOpenNewPriorityPopUp(false);
            });
    };

    if (error !== null) return <Alert severity="error"> Κάτι πήγε στραβά! </Alert>;
    else if (loading === true) return <Spinner align="center" animation="spinner-border" />;

    return (
        <div className="authForm">
            <div className="d-flex justify-content-around">
                <Autocomplete
                    fullwidth
                    id="tags-outlined"
                    options={producers}
                    onChange={handleAutocompleteChange}
                    filterSelectedOptions
                    // defaultValue={defaultValues}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Εταιρείες"
                            placeholder="Επιλογή Εταιρειών"
                        />
                    )}
                    style={{ width: "500px" }}
                />
                <TextField
                    label="Προτεραιότητα"
                    variant="outlined"
                    onChange={onPriorityChange}
                    type="number"
                    value={item.priority}
                    required
                />
            </div>
            <button className="btn buttonPrimary" onClick={onSave}>
                Αποθήκευση
            </button>
        </div>
    );
}
