import React, { useState, useEffect } from "react";
// import { useContext } from "react";
import MediPlusVisitReasonService from "../../../../../../services/MediPlus/MediPlusVisitReasonService.js";
import PopUp from "../../../../../Utilities/PopUp.js";

// import { TabContext } from "../../../../Doctor.js";
import AddVisitReason from "./AddVisitReason/AddVisitReason.js";
import DeleteVisitReason from "./DeleteVisitReason/DeleteVisitReason.js";

function GeneralData({
    visitDetails,
    setVisitDetails,
    //  tabIndex, tabItem, setTabItem
    visitReasonValue,
    setVisitReasonValue,
}) {
    // const { tabList, setTabList } = useContext(TabContext);
    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setVisitDetails({ ...visitDetails, [name]: value });
    };

    // useEffect(() => {
    //     if (tabItem !== undefined) setTabItem({ ...tabItem, visitDetails: visitDetails });
    //     // eslint-disable-next-line
    // }, [visitDetails]);

    // useEffect(() => {
    //     if (tabItem !== undefined) {
    //         let newTabList = [...tabList];
    //         newTabList[tabIndex] = tabItem;
    //         setTabList(newTabList);
    //     }
    //     // eslint-disable-next-line
    // }, [tabItem]);

    useEffect(() => {
        MediPlusVisitReasonService.getVisitReasons().then(({ data }) => {
            setVisitReasons(data);
        });
    }, []);

    const [visitReasons, setVisitReasons] = useState([]);
    const handleSelect = (e) => {
        e.preventDefault();
        let value = e.target.value;
        if (
            value === "" ||
            visitDetails.status === "Ακυρωμένη" ||
            visitDetails.status === "Ολοκληρωμένη"
        )
            return;
        let newReason = visitDetails.reason !== "" ? visitDetails.reason + ", " + value : value;
        setVisitDetails({ ...visitDetails, reason: newReason });
        setVisitReasonValue(value);
    };

    const [addVisitReason, setAddVisitReason] = useState(false);
    const [deleteVisitReason, setDeleteVisitReason] = useState(false);

    return (
        <div>
            <div className="tabContent">
                <div className="flex">
                    <p className="infoP alignRight">Κατάσταση Επίσκεψης:</p>
                    <p className="noMargin">{visitDetails.status !== "" && visitDetails.status}</p>
                </div>
                <div className="flexWrap">
                    <p className="infoP alignRight noMargin">Εναρξη Επίσκεψης</p>
                    <p className="noMargin">
                        {visitDetails.startDate !== "" && visitDetails.startDate}
                    </p>
                </div>
                <div className="flexWrap">
                    <p className="infoP alignRight noMargin">Τέλος Επίσκεψης</p>
                    <p className="noMargin">
                        {visitDetails.endDate !== "" && visitDetails.endDate}
                    </p>
                </div>
                <div className="flexWrap">
                    <p className="infoP alignRight">* Λόγος Επίσκεψης</p>
                    <textarea
                        type="text"
                        className="halfBoxInput"
                        name="reason"
                        value={visitDetails.reason}
                        onChange={handleChange}
                        disabled={
                            visitDetails.status === "Συνταγογραφημένη" || visitDetails.status === ""
                                ? false
                                : true
                        }
                    />
                    <select
                        style={{ marginLeft: "15px", width: "50px" }}
                        onChange={handleSelect}
                        value={visitReasonValue}
                    >
                        <option value="">{""}</option>
                        {visitReasons.map((visitReason) => (
                            <option key={visitReason.id} value={visitReason.reason}>
                                {visitReason.reason}
                            </option>
                        ))}
                    </select>
                    <i
                        style={{ marginLeft: "5px" }}
                        className="fa fa-plus"
                        aria-hidden="true"
                        onClick={() => setAddVisitReason(true)}
                    ></i>
                    <i
                        style={{ marginLeft: "5px" }}
                        className="fa fa-minus"
                        aria-hidden="true"
                        onClick={() => setDeleteVisitReason(true)}
                    ></i>
                </div>
                <div className="flexWrap">
                    <p className="infoP alignRight">Σχόλια / Παρατηρήσεις</p>
                    <textarea
                        type="text"
                        className="halfBoxInput"
                        name="comment"
                        value={visitDetails.comment}
                        onChange={handleChange}
                        disabled={
                            visitDetails.status === "Συνταγογραφημένη" || visitDetails.status === ""
                                ? false
                                : true
                        }
                    />
                </div>
                <div className="flex">
                    <p className="infoP alignRight noMargin">Υπολογίζεται στο Οριο Επισκέψεων</p>
                    <p className="noMargin" id="">
                        ____
                    </p>
                </div>
            </div>
            <PopUp
                openPopUp={addVisitReason}
                setOpenPopUp={setAddVisitReason}
                title={"Προσθήκη νέου Λόγου Επίσκεψης"}
                maxWidth="sm"
            >
                <AddVisitReason visitReasons={visitReasons} setVisitReasons={setVisitReasons} />
            </PopUp>
            <PopUp
                openPopUp={deleteVisitReason}
                setOpenPopUp={setDeleteVisitReason}
                title={"Διαγραφή Λόγου Επίσκεψης"}
            >
                <DeleteVisitReason visitReasons={visitReasons} setVisitReasons={setVisitReasons} />
            </PopUp>
        </div>
    );
}

export default GeneralData;
