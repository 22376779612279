import React, { useState, useEffect } from "react";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import IdikaDoctorService from "../../../../../services/MediPlus/Idika/IdikaDoctorService.js";
import { Spinner } from "../../../../Utilities/Utilities.js";

import UnitResults from "./UnitResults/UnitResults.js";
import Alert from "@material-ui/lab/Alert";

function DoctorUnits() {
    const [units, setUnits] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (isOpen === true && units === null) {
            IdikaDoctorService.getUnits()
                .then((response) => {
                    let data = response.data.List;
                    if (data.count === 0) data = [];
                    else if (data.count === 1) data = [data.contents.item];
                    else data = data.contents.item;
                    setUnits(data);
                    setLoading(false);
                })
                .catch((error) => {
                    setError(error);
                    setLoading(false);
                });
        }
    }, [isOpen, units]);

    return (
        <div className="flexBox">
            <Accordion className="doctorAccordion">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    onClick={() => {
                        setIsOpen(!isOpen);
                    }}
                >
                    <h6>Μονάδες συνταγογράφησης</h6>
                </AccordionSummary>
                {error !== null ? (
                    <Alert severity="error"> Κάτι πήγε στραβά! </Alert>
                ) : loading === true ? (
                    <Spinner align="center" animation="spinner-border" />
                ) : (
                    <AccordionDetails>
                        <UnitResults units={units} />
                    </AccordionDetails>
                )}
            </Accordion>
        </div>
    );
}

export default DoctorUnits;
