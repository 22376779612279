import React, { useEffect, useState } from "react";
import { mainColumns } from "./config.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import MediPlusFavoritePrescriptionPackageService from "../../../../../../../../services/MediPlus/MediPlusFavoritePrescriptionPackageService.js";
import PopUp from "../../../../../../../Utilities/PopUp.js";
import {
    diagnosisColumns,
    medicineColumns,
    galenicColumns,
} from "../PrescriptionHistory/config.js";
import { formatDate } from "../../../../../../../Utilities/Utilities.js";
import { findEffectiveTimes } from "../../../../../../../../config.js";
import { GalenicIngredients } from "../../../../../ViewPrescription/Medicines/Medicines";

export function ShowDetailsContent({ prescriptionDetails }) {
    const [openIngredientsPopUp, setOpenIngredientsPopUp] = useState(false);
    const [ingredients, setIngredients] = useState(null);
    const prescriptionDetailsParsed = JSON.parse(prescriptionDetails.prescriptions);
    const isGalenic = prescriptionDetailsParsed.galenicPrescription
        ? prescriptionDetailsParsed.galenicPrescription
        : false;

    const columns = isGalenic ? galenicColumns : medicineColumns;

    const findMainActiveSubstance = (activeSubstances) => {
        for (let substance of activeSubstances) {
            if (substance.mainActiveSubstance) {
                return substance.activeSubstance.description;
            }
        }
    };

    const onIngredientsHandle = (medicine) => {
        setIngredients(medicine.request);
        setOpenIngredientsPopUp(true);
    };

    return (
        <div>
            <div>
                <b>Όνομα: </b> {prescriptionDetails.name}
            </div>
            <div>
                <b>Αριθμός Συνταγών: </b> {prescriptionDetails.numberOfPrescriptions}
            </div>
            <div>
                <b>Ημ/νία Δημιουργίας: </b> {prescriptionDetails.createdDate}
            </div>
            <div>
                <b>Ημ/νία Επεξεργασίας: </b> {prescriptionDetails.updatedDate}
            </div>
            <div className="mb-3">
                <h6 style={{ marginLeft: "10px", marginBottom: "10px" }}>Διαγνώσεις Συνταγής</h6>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {diagnosisColumns.map((column, index) => {
                                    return (
                                        <TableCell key={index}>
                                            <b>{column.name}</b>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {prescriptionDetailsParsed.icd10s.map((innerDiagnosis, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{innerDiagnosis.code}</TableCell>
                                        <TableCell>{innerDiagnosis.title}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div className="mb-3">
                <h6 style={{ marginLeft: "10px", marginBottom: "10px" }}>Φάρμακα</h6>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((column, index) => {
                                    return (
                                        <TableCell key={index}>
                                            <b>{column.name}</b>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        {!isGalenic ? (
                            <TableBody>
                                {prescriptionDetailsParsed.medicines.map((medicine, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>
                                                {medicine.request.genericMedicine === true && (
                                                    <i className="fa fa-check"></i>
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {`-- Χωρίς Θεραπευτικό Πρωτόκολλο --`}
                                            </TableCell>
                                            <TableCell>{`${medicine.commercialName} ${medicine.formCode} ${medicine.content}  ${medicine.packageForm}`}</TableCell>
                                            <TableCell>
                                                {Array.isArray(
                                                    medicine.activeSubstances.activeSubstance
                                                )
                                                    ? findMainActiveSubstance(
                                                          medicine.activeSubstances.activeSubstance
                                                      )
                                                    : medicine.activeSubstances.activeSubstance
                                                          .activeSubstance.description}
                                            </TableCell>
                                            <TableCell>
                                                {medicine.request.doseQuantity.unit}
                                            </TableCell>
                                            <TableCell>
                                                {medicine.request.doseQuantity.value}
                                            </TableCell>
                                            <TableCell>
                                                {findEffectiveTimes(medicine.request.effectiveTime)}
                                            </TableCell>
                                            <TableCell>
                                                {medicine.request.rateQuantity.value}
                                            </TableCell>
                                            <TableCell>{medicine.request.quantity}</TableCell>
                                            <TableCell>
                                                {medicine.request.insuredParticipationRate}%
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        ) : (
                            <TableBody>
                                {prescriptionDetailsParsed.medicines.map((medicine, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>{medicine.request.displayName}</TableCell>
                                            <TableCell>
                                                {medicine.request.pharmaceuticalForm}
                                            </TableCell>
                                            <TableCell>
                                                {medicine.request.galenicMeasurementUnit}
                                            </TableCell>
                                            <TableCell>
                                                {medicine.request.galenicQuantity}
                                            </TableCell>
                                            <TableCell>
                                                {medicine.request.doseQuantity.unit}
                                            </TableCell>
                                            <TableCell>
                                                {medicine.request.doseQuantity.value}
                                            </TableCell>
                                            <TableCell>
                                                {medicine.request.effectiveTime.name}
                                            </TableCell>
                                            <TableCell>
                                                {medicine.request.rateQuantity.value}
                                            </TableCell>
                                            <TableCell>
                                                {medicine.request.percentageParticipation}%
                                            </TableCell>
                                            <TableCell>
                                                {medicine.request.insuredParticipation +
                                                    medicine.request.insuredDifference}
                                                €
                                            </TableCell>
                                            <TableCell>
                                                {medicine.request.insuredParticipation}€
                                            </TableCell>
                                            <TableCell>
                                                {medicine.request.insuredDifference}€
                                            </TableCell>
                                            <TableCell>{medicine.request.comment}</TableCell>
                                            <TableCell>
                                                <button
                                                    className="btn buttonPrimary"
                                                    onClick={() => onIngredientsHandle(medicine)}
                                                >
                                                    Συστατικά
                                                </button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </div>
            <PopUp
                openPopUp={openIngredientsPopUp}
                setOpenPopUp={setOpenIngredientsPopUp}
                title={"Συστατικά"}
                maxWidth="lg"
                maxHeight="md"
            >
                <GalenicIngredients
                    prescription={prescriptionDetailsParsed}
                    ingredients={ingredients}
                />
            </PopUp>
        </div>
    );
}

function FavoritePrescriptions({ tabItem, setTabItem }) {
    const [page, setPage] = useState(1);
    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const [isLoading, setIsLoading] = useState(true);
    const [isFetching, setIsFetching] = useState(true);
    const [isError, setIsError] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        setIsFetching(true);
        MediPlusFavoritePrescriptionPackageService.getFavoritePrescriptionPackages({
            page: page - 1,
            size: 5,
        })
            .then(({ data }) => {
                setData(data);
                setIsFetching(false);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsError(true);
            });
    }, [page]);

    const [prescriptionDetails, setPrescriptionDetails] = useState({});
    const [popUp, setPopUp] = useState(false);
    const onShowDetails = (prescription) => {
        setPrescriptionDetails(prescription);
        setPopUp(true);
    };

    const addPrescription = (prescription) => {
        let prescriptionParsed = JSON.parse(prescription.prescriptions);
        prescriptionParsed.favoritePrescriptionId = prescription.id;
        prescriptionParsed.id = "";
        prescriptionParsed.galenicTherapy = prescriptionParsed.galenicPrescription
            ? prescriptionParsed.galenicPrescription
            : false;
        let newPrescription = { ...prescription, ...prescriptionParsed };
        setTabItem({
            ...tabItem,
            visitDetails: {
                ...tabItem.visitDetails,
                prescriptionsToBeRegistered: [
                    ...tabItem.visitDetails.prescriptionsToBeRegistered,
                    newPrescription,
                ],
            },
        });
    };

    const removePrescription = (prescription) => {
        let newPrescriptions = tabItem.visitDetails.prescriptionsToBeRegistered.filter(
            (alreadyAddedPrescription) =>
                alreadyAddedPrescription.favoritePrescriptionId === undefined ||
                (alreadyAddedPrescription.favoritePrescriptionId !== undefined &&
                    alreadyAddedPrescription.favoritePrescriptionId !== prescription.id)
        );
        setTabItem({
            ...tabItem,
            visitDetails: {
                ...tabItem.visitDetails,
                prescriptionsToBeRegistered: newPrescriptions,
            },
        });
    };

    const checkIfThePackageIsAlreadyAdded = (prescription) => {
        let found = tabItem.visitDetails.prescriptionsToBeRegistered.find(
            (alreadyAddedPrescription) =>
                alreadyAddedPrescription.favoritePrescriptionId !== undefined &&
                alreadyAddedPrescription.favoritePrescriptionId === prescription.id
        );
        if (found === undefined) return false;
        return true;
    };

    return (
        <div>
            <TableContainer component={Paper}>
                {isLoading ? (
                    <CircularProgress className="m-2" />
                ) : isError ? (
                    <Alert severity="error">Κάτι πήγε στραβά!</Alert>
                ) : (
                    <Table>
                        <TableHead>
                            <TableRow>
                                {mainColumns.map((column, index) => {
                                    return (
                                        <TableCell key={index}>
                                            <b>{column.name}</b>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.content.map((prescription, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{prescription.name}</TableCell>
                                        <TableCell>{prescription.numberOfPrescriptions}</TableCell>
                                        <TableCell>
                                            {formatDate(prescription.createdDate)}
                                        </TableCell>
                                        <TableCell>
                                            {checkIfThePackageIsAlreadyAdded(prescription) ===
                                            false ? (
                                                <button
                                                    className="btn buttonPrimary small-padding"
                                                    onClick={() => addPrescription(prescription)}
                                                >
                                                    <i className="fa fa-plus"></i>
                                                    Προσθήκη
                                                </button>
                                            ) : (
                                                <button
                                                    className="btn buttonDanger small-padding"
                                                    onClick={() => removePrescription(prescription)}
                                                >
                                                    <i className="fa fa-trash"></i>
                                                    Αφαίρεση
                                                </button>
                                            )}
                                            <button
                                                className="btn buttonPrimary small-padding"
                                                onClick={() => onShowDetails(prescription)}
                                            >
                                                <i className="fa fa-search"></i>
                                                Λεπτομέρειες
                                            </button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                )}
                {data !== null && (
                    <div className="form-inline">
                        <Pagination
                            count={data !== null ? data.totalPages : 0}
                            page={page}
                            onChange={handlePageChange}
                        />
                        {isFetching ? <span> Φόρτωση...</span> : null}
                    </div>
                )}
            </TableContainer>
            <PopUp
                openPopUp={popUp}
                setOpenPopUp={setPopUp}
                showCloseButton={true}
                title={"Λεπτομέρειες Αγαπημένου Πακέτου Συνταγών"}
                maxWidth="lg"
            >
                <ShowDetailsContent prescriptionDetails={prescriptionDetails} />
            </PopUp>
        </div>
    );
}

export default FavoritePrescriptions;
