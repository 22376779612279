const mainTabs = [
    { label: "Γενικά Στοιχεία", disabled: false, className: "tabBttns" },
    { label: "Συνταγές", disabled: false, className: "tabBttns" },
    { label: "Παραπεμπτικά", disabled: false, className: "tabBttns" },
    { label: "Παραπομπές", disabled: false, className: "tabBttns" },
    { label: "Συνταγές προς Καταχώρηση", disabled: false, className: "tabBttns" },
    { label: "Παραπεμπτικά προς Καταχώρηση", disabled: false, className: "tabBttns" },
];

export { mainTabs };
