import Group from "@material-ui/icons/Group";
import ReceiptIcon from "@material-ui/icons/Receipt";
import GavelIcon from "@material-ui/icons/Gavel";
import HelpIcon from "@material-ui/icons/Help";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import SettingsIcon from "@material-ui/icons/Settings";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import BusinessIcon from '@material-ui/icons/Business';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import CropFreeIcon from "@material-ui/icons/CropFree";
import HistoryIcon from '@material-ui/icons/History';

const mainTabs = [
    {
        label: "ΔΙΑΧΕΙΡΙΣΗ ΧΡΗΣΤΩΝ",
        icon: <Group />,
    },
    {
        label: "ΔΙΑΧΕΙΡΙΣΗ ΔΙΑΧΕΙΡΙΣΤΩΝ",
        icon: <SupervisorAccountIcon />,
    },
    {
        label: "ΔΙΑΧΕΙΡΙΣΗ Φ.A",
        icon: <PeopleAltIcon />,
    },
    {
        label: "ΔΙΑΧΕΙΡΙΣΗ ΕΤΑΙΡΕΙΩΝ",
        icon: <BusinessIcon />,
    },
    {
        label: "ΙΑΤΡΙΚΟ ΣΗΜΕΙΩΜΑ",
        icon: <ReceiptIcon />,
    },
    {
        label: "ΟΡΟΙ ΕΦΑΡΜΟΓΗΣ",
        icon: <GavelIcon />,
    },
    {
        label: "ΣΤΟΙΧΕΙΑ ΕΠΙΚΟΙΝΩΝΙΑΣ",
        icon: <HelpIcon />,
    },
    {
        label: "ΔΗΜΙΟΥΡΓΙΑ ΝΕΟΥ ΔΙΑΧΕΙΡΙΣΤΗ",
        icon: <PersonAddIcon />,
    },
    {
        label: "ΕΓΓΡΑΦΗ ΦΑΡ. ΑΝΤΙΠΡΟΣΩΠΟΥ",
        icon: <PersonAddIcon />,
    },
    {
        label: "ΡΥΘΜΙΣΕΙΣ",
        icon: <SettingsIcon />,
    },
];

const mainAdminTabs = [
    {
        label: "ΔΙΑΧΕΙΡΙΣΗ ΧΡΗΣΤΩΝ",
        icon: <Group />,
    },
    {
        label: "ΔΙΑΧΕΙΡΙΣΗ Φ.A",
        icon: <PeopleAltIcon />,
    },
    {
        label: "ΕΓΓΡΑΦΗ ΦΑΡ. ΑΝΤΙΠΡΟΣΩΠΟΥ",
        icon: <PersonAddIcon />,
    },
    {
        label: "ΡΥΘΜΙΣΕΙΣ",
        icon: <SettingsIcon />,
    },
];

const mainAgentTabs = [
    {
        label: "ΑΝΑΖΗΤΗΣΗ ΕΚΤΥΠΩΣΗΣ",
        icon: <CropFreeIcon />,
    },
    {
        label: "ΙΣΤΟΡΙΚΟ ΣΥΝΔΡΟΜΗΣ",
        icon: <HistoryIcon />,
    },
    {
        label: "ΡΥΘΜΙΣΕΙΣ",
        icon: <SettingsIcon />,
    },
];

export { mainTabs,mainAdminTabs,mainAgentTabs};
