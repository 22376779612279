const steps = [
    "Επιλογή Πρωτοκόλλου",
    "Διαγνώσεις",
    "Επιλογή Βήματος",
    "Καθoρισμός Παραμέτρων",
    "Καθoρισμός Παραμέτρων (συνέχεια)",
    "Νέο Βήμα",
    "Επιλογή Δραστικών Ουσιών",
    "Ολοκλήρωση Διαδικασίας",
];

export { steps };
