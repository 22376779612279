const tokenKeyName = "mediPlusToken";

export function saveJwt(token) {
    sessionStorage.setItem(tokenKeyName, token);
}

export function getJwt() {
    return sessionStorage.getItem(tokenKeyName);
}

export function removeJwt() {
    sessionStorage.removeItem(tokenKeyName);
}
